import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { BiblicalTermMarker } from '../../models3/BiblicalTermMarker'
import { ProjectTerm } from '../../models3/ProjectTerm'
import { getTermSenseName } from '../../resources/Lemmas'
import { CheckIcon } from '../utils/Icons'

import './TermsTable.css'

interface TermEntry {
    term: ProjectTerm
    termString: string
    sourceGlosses: string
    hasMarker: boolean
}

const TermsTableRow = ({
    termEntry,
    viewTermInfo
}: {
    termEntry: TermEntry
    viewTermInfo: (lexicalLink: string) => void
}) => {
    const { term, termString, sourceGlosses, hasMarker } = termEntry
    return (
        <tr>
            <td>{hasMarker && <CheckIcon className="er-term-found" tooltip="" />}</td>
            <td>
                <span className="key-term-link" onClick={() => viewTermInfo(term.lexicalLink)}>
                    {termString}
                </span>
            </td>
            <td>{sourceGlosses}</td>
            <td>{term.glosses.map((gloss) => gloss.text).join('; ')}</td>
        </tr>
    )
}

export const TermsTable = ({
    terms,
    markers,
    viewTermInfo
}: {
    terms: ProjectTerm[]
    markers: BiblicalTermMarker[]
    viewTermInfo: (lexicalLink: string) => void
}) => {
    const { t, i18n } = useTranslation()

    const augmentedTerms = terms
        .reduce((filtered, term) => {
            const lemma = term.getMarbleLemma()
            if (lemma) {
                const lexMeaning = lemma.meanings.find((meaning) => meaning.lexicalLink === term.lexicalLink)
                const termString = lexMeaning ? getTermSenseName(lemma, lexMeaning) : ''
                const sourceGlosses = lexMeaning ? lexMeaning.glosses(i18n.language) : ''
                const hasMarker = term.glosses.some((gloss) =>
                    markers.find((marker) => marker.targetGlossId === gloss._id)
                )
                return [...filtered, { term, termString, sourceGlosses, hasMarker }]
            }
            return filtered
        }, [] as TermEntry[])
        .sort((a, b) => {
            // place Hebrew (OT) before Greek (NT)
            return a.termString.localeCompare(b.termString, ['he-IL', 'el-GR'])
        })

    return (
        <Table>
            <thead className="terms-list-table-head">
                <tr>
                    <th>{t('termMarked')}</th>
                    <th>{t('biblicalTermHeader')}</th>
                    <th>{t('sourceGlossesHeader')}</th>
                    <th>{t('targetGlossesHeader')}</th>
                </tr>
            </thead>
            <tbody>
                {augmentedTerms.map((termEntry) => {
                    return <TermsTableRow key={termEntry.term._id} termEntry={termEntry} viewTermInfo={viewTermInfo} />
                })}
            </tbody>
        </Table>
    )
}
