import { FC, useEffect, useRef, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { useGetProgress } from './useGetProgress'
import { Root } from '../../models3/Root'
import { VideoCache } from '../../models3/VideoCache'
import { getEmailPrefix } from '../utils/Helpers'
import { LoadingIcon } from '../utils/Icons'
import LoadingMessage from '../utils/InitializationMessage'

interface VideoMessageProps {
    rt: Root
}

export const VideoMessage: FC<VideoMessageProps> = observer(({ rt }) => {
    const { portion, passage, iAmTranslator } = rt
    const { t } = useTranslation()

    const showMessage = (message: string, showLoading = false) => (
        <div className="video-message">
            {showLoading && <LoadingMessage loadingMessage={message} />}
            {!showLoading && message}
        </div>
    )

    if (!portion) {
        return showMessage(t('No portions have been added to this project.'))
    }

    if (!passage) {
        return showMessage(t('No passages have been added for this portion.'))
    }

    if (passage.videoBeingCompressed) {
        return showMessage(passage.compressionProgressMessage, true)
    }

    if (!passage.videos.length || !passage.videosNotDeleted.length) {
        return showMessage(t('recordingNoneAvailable'))
    }

    // At this point we have a portion with passages selected
    if (iAmTranslator) {
        return showMessage(t('At left, select a passage or click the plus button to add a passage.'))
    }

    return showMessage(t('At left, select a passage.'))
})

type VideoDownloadingMessageProps = {
    urls: string[]
    creator?: string
    fontSizePt: number
}

interface ILine {
    x1: number
    y1: number
    x2: number
    y2: number
}

type CanvasProps = {
    draw: (context: CanvasRenderingContext2D) => void
    width: number
    height: number
    className?: string
}

const Canvas: FC<CanvasProps> = ({ draw, width, height, className }) => {
    const ref = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        const context = ref.current?.getContext('2d')
        if (context) {
            draw(context)
        }
    })

    return <canvas {...{ className, ref, width, height }} />
}

type DownloadingMessageProps = {
    url: string
}

export const VideoDownloadingMessage: FC<VideoDownloadingMessageProps> = observer(({ urls, creator, fontSizePt }) => {
    const [showCanvas, setShowCanvas] = useState(false)

    const { numberUploaded, numberDownloaded, totalBlobs, progressQueried } = useGetProgress(urls)

    // We only want to show progress if it is taking a while, otherwise there is unnecessary flashing
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowCanvas(true)
        }, 3000)
        return () => {
            if (timer) clearTimeout(timer)
        }
    }, [])

    const canvasWidth = 500
    const canvasHeight = 400

    function draw(ctx: CanvasRenderingContext2D) {
        ctx.clearRect(0, 0, canvasWidth, canvasHeight)
        ctx.font = '900 48px "Font Awesome 5 Free"'
        ctx.fillStyle = 'gray'
        ctx.fillText('\uf0c2', 220, 70)

        const uploadArrow: ILine = { x1: 110, y1: 300, x2: 220, y2: 100 }
        const downloadArrow: ILine = { x1: 280, y1: 100, x2: 390, y2: 300 }

        const TOP_LEFT_CORNER_X = uploadArrow.x1
        const TOP_LEFT_CORNER_Y = uploadArrow.y2
        const TOP_LEFT_CORNER_DOWNLOAD_X = downloadArrow.x1
        const TOP_LEFT_CORNER_DOWNLOAD_Y = downloadArrow.y1

        ctx.beginPath()
        ctx.lineWidth = 2
        ctx.moveTo(uploadArrow.x1, uploadArrow.y1)
        ctx.lineTo(uploadArrow.x2, uploadArrow.y2)
        ctx.moveTo(downloadArrow.x1, downloadArrow.y1)
        ctx.lineTo(downloadArrow.x2, downloadArrow.y2)

        // Arrowheads
        ctx.moveTo(uploadArrow.x2, uploadArrow.y2)
        ctx.lineTo(uploadArrow.x2 - 20, uploadArrow.y2 + 20)
        ctx.moveTo(uploadArrow.x2, uploadArrow.y2)
        ctx.lineTo(uploadArrow.x2 - 6, uploadArrow.y2 + 28)

        ctx.moveTo(downloadArrow.x2, downloadArrow.y2)
        ctx.lineTo(downloadArrow.x2 - 6, downloadArrow.y2 - 28)
        ctx.moveTo(downloadArrow.x2, downloadArrow.y2)
        ctx.lineTo(downloadArrow.x2 - 20, downloadArrow.y2 - 20)
        ctx.stroke()

        ctx.font = `${fontSizePt}pt sans-serif`
        ctx.fillStyle = 'black'
        if (creator) {
            const creatorText = getEmailPrefix(creator)
            ctx.fillText(creatorText, uploadArrow.x1 - ctx.measureText(creatorText).width / 2, uploadArrow.y1 + 40)
        }

        function drawUploadProgress() {
            const width = Math.abs(uploadArrow.x2 - uploadArrow.x1)
            const height = Math.abs(uploadArrow.y2 - uploadArrow.y1)

            const pos = (percent: number) => ({
                x: (width / 100) * percent + TOP_LEFT_CORNER_X,
                y: (height / 100) * (100 - percent) + TOP_LEFT_CORNER_Y
            })

            let percent = (numberUploaded / totalBlobs) * 100
            if (isNaN(percent) || percent === Infinity) {
                // Divided by 0
                percent = 0
            }

            const _pos = pos(percent)
            ctx.beginPath()
            ctx.fillStyle = 'red'
            const radius = 7.5
            ctx.arc(_pos.x, _pos.y, radius, 0, 2 * Math.PI, false)
            ctx.fill()
        }

        function drawDownloadProgress() {
            const width = Math.abs(downloadArrow.x2 - downloadArrow.x1)
            const height = Math.abs(downloadArrow.y2 - downloadArrow.y1)

            const pos = (percent: number) => ({
                x: (width / 100) * percent + TOP_LEFT_CORNER_DOWNLOAD_X,
                y: (height / 100) * percent + TOP_LEFT_CORNER_DOWNLOAD_Y
            })

            let percent = (numberDownloaded / totalBlobs) * 100
            if (isNaN(percent) || percent === Infinity) {
                // Divided by 0
                percent = 0
            }

            const _pos = pos(percent)
            ctx.beginPath()
            ctx.fillStyle = 'red'
            const radius = 7.5
            ctx.arc(_pos.x, _pos.y, radius, 0, 2 * Math.PI, false)
            ctx.fill()
        }

        if (numberUploaded === 0 && !progressQueried) {
            return
        }

        if (numberUploaded < totalBlobs) {
            drawUploadProgress()
        } else {
            drawDownloadProgress()
        }
    }

    return (
        <div className="video-loading-progress-message">
            {showCanvas && (
                <Canvas
                    className="video-loading-message-canvas"
                    draw={draw}
                    width={canvasWidth}
                    height={canvasHeight}
                />
            )}
        </div>
    )
})

export const DownloadingMessage = observer(({ url }: DownloadingMessageProps) => {
    const [numberDownloaded, setNumberDownloaded] = useState(0)
    const [totalBlobs, setTotalBlobs] = useState(1)

    const { t } = useTranslation()

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null
        function getProgressLoop() {
            if (url.trim() === '') {
                setNumberDownloaded(0)
                setTotalBlobs(1)
            } else {
                const { downloaded, total } = VideoCache.queryProgressMultiple([url])
                setNumberDownloaded(downloaded)
                setTotalBlobs(total || 1)
                if (downloaded < total) {
                    timer = setTimeout(getProgressLoop, 1000)
                }
            }
        }
        getProgressLoop()

        return () => {
            if (timer) {
                clearTimeout(timer)
                timer = null
            }
        }
    }, [url])

    const downloadPercent = Math.round((numberDownloaded / totalBlobs) * 100)

    return (
        <div className="progress-message">
            <LoadingIcon className="audio-loading-icon" />
            {t('recordingLoadingDownloadMessage', { downloadPercent })}
        </div>
    )
})
