export interface PlanStructure {
    stages: {
        name: string
        tasks: {
            name: string
            description: string
            difficulty: number
        }[]
    }[]
}

export const plans = {
    'Basic Plan': {
        stages: []
    },
    'Scripture Review Board Plan': {
        stages: [
            {
                name: 'Translation Team',
                tasks: [
                    {
                        name: 'Familiarization and Internalization',
                        description: '',
                        difficulty: 1.0
                    },
                    {
                        name: 'First Recording',
                        description: '',
                        difficulty: 1.0
                    },
                    {
                        name: 'Review',
                        description: '',
                        difficulty: 1.0
                    }
                ]
            },
            {
                name: 'Peer Review',
                tasks: [
                    {
                        name: 'Peer Review',
                        description: '',
                        difficulty: 1.0
                    },
                    {
                        name: 'Update Recording',
                        description: '',
                        difficulty: 1.0
                    }
                ]
            },
            {
                name: 'Community Review',
                tasks: [
                    {
                        name: 'Community Review',
                        description: '',
                        difficulty: 1.0
                    },
                    {
                        name: 'Update Recording',
                        description: '',
                        difficulty: 1.0
                    }
                ]
            },
            {
                name: 'Scripture Authentication Elder Review',
                tasks: [
                    {
                        name: 'Elder Review of Scripture',
                        description: '',
                        difficulty: 1.0
                    },
                    {
                        name: 'Update Recording',
                        description: '',
                        difficulty: 1.0
                    },
                    {
                        name: 'Approval of Scripture',
                        description: '',
                        difficulty: 1.0
                    }
                ]
            },
            {
                name: 'Church Use and Review',
                tasks: [
                    {
                        name: 'Churches Review',
                        description: '',
                        difficulty: 1.0
                    },
                    {
                        name: 'Update Recording',
                        description: '',
                        difficulty: 1.0
                    }
                ]
            },
            {
                name: 'Scripture Review Board Reviews',
                tasks: [
                    {
                        name: 'Board Review of Scripture',
                        description: '',
                        difficulty: 1.0
                    },
                    {
                        name: 'Update Recordings',
                        description: '',
                        difficulty: 1.0
                    },
                    {
                        name: 'Approval of Final Scriptures',
                        description: '',
                        difficulty: 1.0
                    }
                ]
            }
        ]
    },
    'Consultant Review Plan': {
        stages: [
            {
                name: 'Team',
                tasks: [
                    {
                        name: 'First Version',
                        description: 'Record a first version of the passage',
                        difficulty: 1.0
                    },
                    {
                        name: 'Review First Version',
                        description: 'Review the first version of the passage',
                        difficulty: 1.0
                    },
                    {
                        name: 'Team Review',
                        description: 'Team checks the passage for accuracy, ...',
                        difficulty: 1.0
                    },
                    {
                        name: 'Second Version',
                        description: '',
                        difficulty: 1.0
                    }
                ]
            },
            {
                name: 'Consultant',
                tasks: [
                    {
                        name: 'Consultant Review',
                        description: '',
                        difficulty: 1.0
                    },
                    {
                        name: 'Third Version',
                        description: '',
                        difficulty: 1.0
                    }
                ]
            }
        ]
    }
}
