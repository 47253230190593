import { useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { PlanStructure } from './plan/defaultPlans'
import { PlanAddModal } from './plan/PlanAddModal'
import StatusTabs, { TabsList } from './StatusTabs'
import { Passage } from '../../models3/Passage'
import { Portion } from '../../models3/Portion'
import { Project } from '../../models3/Project'
import { Root } from '../../models3/Root'
import { useOnlineStatus } from '../app/OnlineStatusContext'
import { GenericIconButton } from '../utils/Buttons'
import LoadingMessage from '../utils/InitializationMessage'

interface NoPlanDataViewProps {
    rt: Root
    addPlan: (plan: PlanStructure) => void
}

export const NoProjectPlansView = observer(({ rt, addPlan }: NoPlanDataViewProps) => {
    const { t } = useTranslation()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { isOnline } = useOnlineStatus()
    const { iAmAdmin, useMobileLayout } = rt

    if (!iAmAdmin) {
        return <div>{t('noProjectPlanNoPermission')}</div>
    }

    return (
        <div>
            <div>{t('selectProjectPlanHelperText')}</div>
            {useMobileLayout && <div>{t('Switch to a larger screen to create a project plan.')}</div>}
            {isModalOpen && <PlanAddModal closeModal={() => setIsModalOpen(false)} addPlan={addPlan} />}
            <GenericIconButton
                enabled={isOnline}
                onClick={() => setIsModalOpen(true)}
                className="portion-add-button"
                tooltip={isOnline ? t('selectProjectPlan') : t('noInternet')}
                iconName="fa-plus-square"
                iconType="far"
            />
        </div>
    )
})

interface PlansViewProps {
    project: Project
    rt: Root
}

const StatusEditorBody = ({ project, rt }: PlansViewProps) => {
    const { t } = useTranslation()
    const [isAddingPlan, setIsAddingPlan] = useState(false)
    const [initialTabIndex, setInitialTabIndex] = useState(TabsList.STATUS)

    const addPlan = async (plan: PlanStructure) => {
        setIsAddingPlan(true)
        await project.addDefaultProjectPlan(plan)
        setInitialTabIndex(TabsList.PLAN)
        setIsAddingPlan(false)
    }

    if (isAddingPlan) {
        return <LoadingMessage loadingMessage={t('addingPlan')} />
    }

    if (project.plans.length) {
        return <StatusTabs rt={rt} initialIndex={initialTabIndex} />
    }

    return <NoProjectPlansView rt={rt} addPlan={addPlan} />
}

interface StatusEditorProps {
    rt: Root
}

export type FilteredPortionPassages = {
    portion: Portion
    passages: Passage[]
}

const StatusEditor = observer(({ rt }: StatusEditorProps) => {
    const { initialized, loadingMessage, project } = rt

    if (!initialized) {
        return <LoadingMessage {...{ loadingMessage }} />
    }

    return <StatusEditorBody project={project} rt={rt} />
})

export default StatusEditor
