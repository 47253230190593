import { observable } from 'mobx'

import { DBObject } from './DBObject'

export type AttributeResponseType = 'text' | 'select'

interface ResponseOption {
    id: string
    text: string
}

export class ReviewProjectProfileAttribute extends DBObject {
    @observable label = ''

    @observable options: ResponseOption[] = []

    @observable responseType: AttributeResponseType = 'text'

    @observable isActive = true

    private static model = 22

    toDocument() {
        const { label, options, responseType, rank, isActive } = this
        return this._toDocument({
            label,
            options,
            responseType,
            model: ReviewProjectProfileAttribute.model,
            rank,
            isActive
        })
    }

    // This object is used in the AVTT review app. To make things easier, we save the entire
    // state to a document any time the state changes, instead of just the parts that changed.
    async setValues({
        label,
        options,
        responseType
    }: {
        label: string
        options: ResponseOption[]
        responseType: string
    }) {
        if (
            label === this.label &&
            JSON.stringify(options) === JSON.stringify(this.options) &&
            responseType === this.responseType
        ) {
            return
        }
        const document = this.toDocument()
        document.label = label
        document.options = options
        document.responseType = responseType
        await this.db.put(document)
    }

    async setIsActive(isActive: boolean) {
        if (isActive === this.isActive) {
            return
        }
        const doc = this.toDocument()
        doc.isActive = isActive
        await this.db.put(doc)
    }

    async setRank(rankNumber: number) {
        const doc = this.toDocument()
        doc.rank = DBObject.numberToRank(rankNumber)
        await this.db.put(doc)
    }

    createNewResponseOption(): ResponseOption {
        return { id: crypto.randomUUID(), text: '' }
    }
}
