import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import TextareaAutosize from 'react-textarea-autosize'

import { AudioClip } from '../../models3/AudioClip'
import { PassageSegment } from '../../models3/PassageSegment'
import { SegmentDocumentType } from '../../types'
import { useAppRoot } from '../app/RootContext'
import { AudioPlayerWithPlaceholder } from '../audio/AudioPlayerWithPlaceholder'
import { OKButton, CancelButton, DeleteButton, PencilButton, RecordButton } from '../utils/Buttons'
import { displayError } from '../utils/Errors'
import { OralTranscriberMultiSelect } from '../utils/oralTranscriber/OralTranscriberMultiSelect'
import { OralTranscriberRecorder } from '../utils/oralTranscriber/OralTranscriberRecorder'
import { WarningList } from '../utils/WarningList'
import { RecordingDoneParams, VideoUploader } from '../video/VideoUploader'

import './Segments.css'

const EditorHeader = ({
    documentType,
    enableEditButton,
    setIsEditing,
    setIsEditingSegment
}: {
    documentType: SegmentDocumentType
    enableEditButton: boolean
    setIsEditing: (value: boolean) => void
    setIsEditingSegment: (value: boolean) => void
}) => {
    const { t } = useTranslation()

    return (
        <div className="segment-dialog-heading-group">
            <div className="segment-dialog-heading">
                {documentType === 'transcription' ? t('transcriptionPanel') : t('backTranslationPanel')}
            </div>
            <div className="button-row-space-between">
                <div className="button-row">
                    <PencilButton
                        enabled={enableEditButton}
                        onClick={() => {
                            setIsEditing(true)
                            setIsEditingSegment(true)
                        }}
                        className="right-pane-button default-blue-icon"
                        tooltip={t('Edit')}
                    />
                </div>
            </div>
        </div>
    )
}

const RecordingControls = ({
    enableDeleteButton,
    setAudioUrl,
    setIsRecording,
    setRecordedBlobs
}: {
    enableDeleteButton: boolean
    setAudioUrl: (value: string) => void
    setIsRecording: (value: boolean) => void
    setRecordedBlobs: (value: Blob[]) => void
}) => {
    const { t } = useTranslation()

    return (
        <div className="audio-button-row">
            <RecordButton
                enabled
                className="small-button audio-recorder-record-button"
                onClick={() => setIsRecording(true)}
                tooltip={t('Start recording')}
            />
            <DeleteButton
                enabled={enableDeleteButton}
                className="small-button"
                onClick={() => {
                    setRecordedBlobs([])
                    setAudioUrl('')
                }}
                tooltip={t('Cancel')}
            />
            <OralTranscriberMultiSelect />
        </div>
    )
}

// initialText !== text || initialAudioUrl !== audioUrl || hasRecorded
const FormControls = ({
    enableSaveButton,
    handleDocumentSave,
    resetEditing
}: {
    enableSaveButton: boolean
    handleDocumentSave: () => Promise<void>
    resetEditing: () => void
}) => {
    const { t } = useTranslation()

    return (
        <div className="segment-document-bottom button-row">
            <OKButton
                enabled={enableSaveButton}
                onClick={handleDocumentSave}
                buttonClassName=""
                className="large-right-pane-button segment-document-ok-button"
                tooltip={t('Save edits and close')}
            />
            <CancelButton
                enabled
                className="large-right-pane-button"
                tooltip={t('Cancel edits')}
                onClick={resetEditing}
            />
        </div>
    )
}

interface SegmentDocumentEditorProps {
    segment: PassageSegment
    documentType: SegmentDocumentType
    className: string
    isEditingSegment: boolean
    setIsEditingSegment: (value: boolean) => void
    readOnly?: boolean
    showMessage?: boolean
    recordingDate?: string
}

export const SegmentDocumentEditor = ({
    segment,
    documentType,
    className,
    isEditingSegment,
    setIsEditingSegment,
    recordingDate,
    readOnly,
    showMessage
}: SegmentDocumentEditorProps) => {
    const { rt } = useAppRoot()
    const { t } = useTranslation()

    const [isEditing, setIsEditing] = useState(false)
    const [isRecording, setIsRecording] = useState(false)
    const [recordedBlobs, setRecordedBlobs] = useState<Blob[]>([])

    const audioClip = documentType === 'backTranslationText' ? segment.getCurrentAudioClip() : undefined
    const initialAudioUrl = audioClip ? audioClip.url : ''

    const document = segment.getCurrentDocument(documentType)
    const { text: initialText, modDate: initialModDate } = document ?? { text: '', modDate: '' }

    const [audioUrl, setAudioUrl] = useState(initialAudioUrl)
    const [text, setText] = useState(initialText)
    const [date, setDate] = useState(initialModDate)

    useEffect(() => {
        // reset to not editing whenever new segment is detected
        setIsEditing(false)
        setIsEditingSegment(false)
        setRecordedBlobs([])

        setText(initialText)
        setDate(initialModDate)
        setAudioUrl(initialAudioUrl)
    }, [initialAudioUrl, initialModDate, initialText, setIsEditingSegment])

    const handleAudioRecordingSave = async (audioClipToSave: AudioClip) => {
        const onRecordingDone = async ({ err, blobsCount, url, duration }: Partial<RecordingDoneParams>) => {
            if (err) {
                displayError(err)
                return
            }

            if (url !== undefined && url.trim() !== '' && blobsCount !== undefined) {
                audioClipToSave.url = `${url}-${blobsCount}`
            }

            if (duration !== undefined) {
                audioClipToSave.duration = duration
            }

            await segment.addAudioClip(audioClipToSave)

            setRecordedBlobs([])
            setAudioUrl(audioClipToSave.url)
        }

        const uploader = new VideoUploader(audioClipToSave.url, onRecordingDone)
        for (let i = 0; i < recordedBlobs.length; i++) {
            await uploader.pushVideoBlob(recordedBlobs[i], i === recordedBlobs.length - 1)
        }
    }

    const handleDocumentSave = async () => {
        try {
            if (initialText !== text) {
                if (document) {
                    await document.setText(text)
                } else {
                    const newDocument = segment.createDocument(documentType)
                    newDocument.text = text
                    segment.addDocument(documentType, newDocument)
                }
            }

            if (recordedBlobs.length) {
                const audioClipToSave = segment.createAudioClip(rt?.name ?? '')
                await handleAudioRecordingSave(audioClipToSave)
            } else if (audioClip && !audioUrl) {
                await segment.removeAudioClip(audioClip._id)
            }

            setIsEditing(false)
            setIsEditingSegment(false)
        } catch (error) {
            displayError(error)
        }
    }

    const allowRecording = documentType === 'backTranslationText'
    const hasRecorded = recordedBlobs.length > 0

    const editorClassName = 'segment-text-editor__editor'

    return (
        <div className={`segment-transcription ${className} ${isEditing ? 'editing' : ''}`}>
            <EditorHeader
                documentType={documentType}
                enableEditButton={!readOnly && !isEditing && !isEditingSegment}
                setIsEditing={setIsEditing}
                setIsEditingSegment={setIsEditingSegment}
            />

            {showMessage && recordingDate && date && date < recordingDate && (
                <WarningList warnings={[{ text: t('olderThanSegment'), key: 'older-than-segment' }]} />
            )}

            {isEditing && allowRecording && !isRecording && (
                <RecordingControls
                    enableDeleteButton={Boolean(hasRecorded || audioUrl)}
                    setAudioUrl={setAudioUrl}
                    setRecordedBlobs={setRecordedBlobs}
                    setIsRecording={setIsRecording}
                />
            )}

            {isRecording ? (
                <OralTranscriberRecorder
                    closeRecorder={() => {
                        setRecordedBlobs([])
                        setIsRecording(false)
                    }}
                    onDoneRecording={(blobs) => {
                        setRecordedBlobs(blobs)
                        setIsRecording(false)
                    }}
                    setTranscription={setText}
                />
            ) : (
                (audioUrl || hasRecorded) && (
                    <div className="segment-document-player">
                        <AudioPlayerWithPlaceholder url={audioUrl} recordedBlobs={recordedBlobs} />
                    </div>
                )
            )}

            <TextareaAutosize
                className={editorClassName}
                value={text}
                onChange={(e) => setText(e.target.value)}
                disabled={!isEditing}
                dir="auto"
            />

            {isEditing && !isRecording && (
                <FormControls
                    enableSaveButton={initialText !== text || initialAudioUrl !== audioUrl || hasRecorded}
                    handleDocumentSave={handleDocumentSave}
                    resetEditing={() => {
                        setIsEditing(false)
                        setIsEditingSegment(false)
                        setRecordedBlobs([])
                        setText(initialText)
                        setAudioUrl(initialAudioUrl)
                    }}
                />
            )}
        </div>
    )
}
