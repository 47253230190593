/* eslint-disable react/prefer-stateless-function */

// Controls for fine position adjustment of main video. Controls for zooming timeline.

import { observer } from 'mobx-react'

import { Passage } from '../../models3/Passage'
import { PassageVideo } from '../../models3/PassageVideo'
import { AdjustCurrentTimeButtons, ZoomInTimelineButton, ZoomOutTimelineButton } from '../utils/Buttons'

import './Video.css'

const SECONDS_PER_HOUR = 3600

interface VideoPositionBarControlsProps {
    videoPositionControls: VideoPositionControls
    zoomIn: () => void
    zoomOut: () => void
    zoomInEnabled: boolean
    zoomOutEnabled: boolean
    adjustTimeEnabled: boolean
}

interface VideoPositionControls {
    currentTime: number
    duration: number
    resetCurrentTime: (newTime: number) => void
    setTimelineZoom: (zoom: number) => void
    useMobileLayout: boolean
    passage: Passage | null
    passageVideo: PassageVideo | null
}

export const VideoPositionBarControls = observer(
    ({
        zoomIn,
        zoomOut,
        videoPositionControls,
        zoomInEnabled,
        zoomOutEnabled,
        adjustTimeEnabled
    }: VideoPositionBarControlsProps) => {
        const { currentTime, useMobileLayout, resetCurrentTime, passage, passageVideo } = videoPositionControls

        // eslint-disable-next-line
        passageVideo && passageVideo._rev // for render on any passage video changes
        // eslint-disable-next-line
        passage && passage._rev // for render on any passage changes

        // Check to see if segment time have changed and if so reset them
        if (passage && passageVideo) {
            passageVideo.setSegmentTimes(passage)
        }

        return (
            <div data-id="video-positionbar-controls">
                <div className="sl-adjust-current-time">
                    <div className="video-timecode">
                        {new Date(Math.max(currentTime * 1000, 0))
                            .toISOString()
                            .substring(currentTime < SECONDS_PER_HOUR ? 14 : 11, 22)}{' '}
                    </div>
                    {!useMobileLayout && passageVideo && (
                        <AdjustCurrentTimeButtons
                            enabled={adjustTimeEnabled}
                            goToBeginning={() => resetCurrentTime(0)}
                            goToEnd={() => resetCurrentTime(passageVideo.computedDuration)}
                        />
                    )}
                    <div className="video-positionbar-placeholder">
                        <ZoomInTimelineButton enabled={zoomInEnabled} onClick={zoomIn} />
                        <ZoomOutTimelineButton enabled={zoomOutEnabled} onClick={zoomOut} />
                    </div>
                </div>
            </div>
        )
    }
)
