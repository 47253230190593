/* eslint-disable react/no-array-index-key */

import React, { FC } from 'react'

import { observer } from 'mobx-react'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { getEmailPrefix } from './Helpers'
import { MembersIcon } from './Icons'
import { Member } from '../../models3/Member'
import { Passage } from '../../models3/Passage'
import { Project } from '../../models3/Project'

import './MemberDisplay.css'

type ImageSize = 'small' | 'large'
type MemberAvatarProps = {
    imageUrl: string
    variation: ImageSize
}

function getMemberAvatarClassName(imageSize: ImageSize) {
    let className = ''
    if (imageSize === 'large') {
        className = 'member-image-container-lg'
    } else if (imageSize === 'small') {
        className = 'member-image-container-sm'
    }
    return className
}

function getDefaultImageClassName(imageSize: ImageSize) {
    let defaultImageClassName = ''
    if (imageSize === 'large') {
        defaultImageClassName = 'default-member-image-lg'
    } else if (imageSize === 'small') {
        defaultImageClassName = 'default-member-image-sm'
    }
    return defaultImageClassName
}

const UserAvatar: FC<MemberAvatarProps> = observer(({ imageUrl, variation }) => {
    const className = getMemberAvatarClassName(variation)
    const defaultImageClassName = getDefaultImageClassName(variation)

    if (imageUrl) {
        return <img className={className} src={imageUrl} />
    }

    return <MembersIcon className={`${className} ${defaultImageClassName}`} tooltip="" />
})

type UsernameWithImageProps = {
    imageUrl: string
    username: string
    imageSize: ImageSize
    showFullName?: boolean
    onlyShowAvailableParts?: boolean
    onlyShowUsernameIfNoImage?: boolean
}

export const UsernameWithImage = ({
    onlyShowAvailableParts,
    onlyShowUsernameIfNoImage,
    imageSize,
    imageUrl,
    username
}: UsernameWithImageProps) => {
    if (onlyShowAvailableParts) {
        if (imageUrl) {
            return (
                <span className="member-display-full inline-flex-centered">
                    <span className="member-image-container-extra-space">
                        <UserAvatar imageUrl={imageUrl} variation={imageSize} />
                    </span>
                    {username}
                </span>
            )
        }

        return <span>{username}</span>
    }

    if (onlyShowUsernameIfNoImage) {
        if (imageUrl) {
            return (
                <span className="member-display inline-flex-centered">
                    <UserAvatar imageUrl={imageUrl} variation={imageSize} />
                </span>
            )
        }

        return <span>{username}</span>
    }

    return (
        <span className="member-display-full inline-flex-centered">
            <span className="member-image-container-extra-space">
                <UserAvatar imageUrl={imageUrl} variation={imageSize} />
            </span>
            {username}
        </span>
    )
}

type MemberDisplayProps = {
    member: Member
    imageSize: ImageSize
    showFullName?: boolean
    onlyShowAvailableParts?: boolean
    onlyShowUsernameIfNoImage?: boolean
}

export const MemberDisplay: FC<MemberDisplayProps> = observer(
    ({ member, imageSize, showFullName, onlyShowAvailableParts, onlyShowUsernameIfNoImage }) => {
        const { imageUrl, email } = member
        const username = showFullName ? email : getEmailPrefix(email)

        return (
            <UsernameWithImage
                imageUrl={imageUrl}
                username={username}
                imageSize={imageSize}
                onlyShowAvailableParts={onlyShowAvailableParts}
                onlyShowUsernameIfNoImage={onlyShowUsernameIfNoImage}
            />
        )
    }
)

type MemberSelectorProps = {
    project: Project
    passage: Passage
    enabled: boolean
    assignableMembers: Member[]
}

export const MemberSelector: FC<MemberSelectorProps> = ({ project, passage, enabled, assignableMembers }) => {
    const { t } = useTranslation()
    const currentMember = project.members.find((mem) => mem.email === passage.assignee)

    return (
        <span className="member-selector-current-status">
            {currentMember ? (
                <MemberDisplay member={currentMember} imageSize="small" onlyShowUsernameIfNoImage />
            ) : (
                <div className="member-display">{t('Unassigned')}</div>
            )}
            <Dropdown>
                <Dropdown.Toggle
                    id="member-selector"
                    title=""
                    className="sl-dropdown member-selector-dropdown styled-dropdown-select"
                    disabled={!enabled}
                />
                <Dropdown.Menu className="styled-dropdown-select-menu">
                    <Dropdown.Item onClick={() => passage.setAssignee('')}>
                        <div className="member-display">{t('Unassigned')}</div>
                    </Dropdown.Item>
                    {assignableMembers.map((member, i) => (
                        <Dropdown.Item key={i} onClick={() => passage.setAssignee(member.email)}>
                            <MemberDisplay member={member} imageSize="small" />
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </span>
    )
}
