import { observable } from 'mobx'

import { PassageVideoMarker } from './PassageVideoMarker'

export class BiblicalTermMarker extends PassageVideoMarker {
    @observable targetGlossId = ''

    readonly model = 21

    toDocument(useExistingModDate?: boolean) {
        const { targetGlossId, position } = this
        return this._toDocument({ targetGlossId, position, model: this.model }, useExistingModDate)
    }

    copy() {
        let copy = new BiblicalTermMarker(this._id, this.db, new Date(this.creationDate))
        copy = Object.assign(copy, this)
        return copy
    }

    async setPosition(position: number) {
        if (this.position === position) {
            return
        }

        const doc = this._toDocument({ position, model: this.model })
        this.db.submitChange(doc)
    }

    async setTargetGlossId(targetGlossId: string) {
        if (this.targetGlossId === targetGlossId) {
            return
        }
        await this.db.put(this._toDocument({ model: this.model, targetGlossId }))
    }
}
