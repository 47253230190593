import { t } from 'i18next'

import { avttVersion } from '../components/app/slttAvtt'
import { PublishedBible } from '../types'

const ORIGINAL_LANGUAGE_NAME = 'Original'

// force re-caching whenever avttVersion changes
const PUBLISHED_BIBLES_INDEX_PATH = `${process.env.REACT_APP_RESOURCE_URL}/public/SLMARBLE/ers/publishedBibles.json?avttVersion=${avttVersion}`

export const bookExists = (bookNumber: number, version: PublishedBible) => {
    return bookNumber >= version.startBook && bookNumber <= version.endBook
}

export const audioBookExists = (audioBookNumber: number, version: PublishedBible) => {
    const { audioStartBook, audioEndBook, hasAudio, startBook, endBook } = version

    return hasAudio && audioBookNumber >= (audioStartBook ?? startBook) && audioBookNumber <= (audioEndBook ?? endBook)
}

export const getPublishedBibles = async () => {
    const response = await fetch(PUBLISHED_BIBLES_INDEX_PATH)

    if (!response.ok) {
        throw Error(`${response.url}: ${response.statusText}`)
    }

    const publishedBibles = (await response.json()) as PublishedBible[]

    // localize "Original" language name and add it last to the sorted list
    const localizedOriginalLanguageName = `${t('originalLanguage')}`
    return publishedBibles
        .map((bible) => ({
            ...bible,
            language: {
                ...bible.language,
                name:
                    bible.language.name === ORIGINAL_LANGUAGE_NAME ? localizedOriginalLanguageName : bible.language.name
            }
        }))
        .sort((a, b) => {
            if (a.language.name === localizedOriginalLanguageName) return 1
            if (b.language.name === localizedOriginalLanguageName) return -1
            return a.language.name.localeCompare(b.language.name)
        })
}
