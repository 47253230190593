import { useEffect, useState } from 'react'

import { ReviewComment } from '../../../hooks/useReviewComments'
import API from '../../../models3/API'
import { ReviewProject } from '../../../models3/ReviewProject'
import { UsernameWithImage } from '../../utils/MemberDisplay'

import './ReviewResponses.css'

export const ReviewRespondent = ({
    comment,
    reviewProject,
    projectName
}: {
    comment: ReviewComment
    reviewProject: ReviewProject
    projectName: string
}) => {
    const [imageUrl, setImageUrl] = useState('')

    const { respondent } = comment

    useEffect(() => {
        let url = ''
        const getSignedUrl = async () => {
            if (respondent.imageFileName) {
                const blob = await API.getReviewRecordingBlobFromNetwork(projectName, respondent.imageFileName)
                url = URL.createObjectURL(blob)
                setImageUrl(url)
            }
        }
        getSignedUrl()

        return () => {
            if (url) {
                URL.revokeObjectURL(url)
            }
        }
    }, [projectName, respondent.imageFileName])

    return (
        <div>
            <UsernameWithImage imageUrl={imageUrl} username={respondent.title} imageSize="small" />
            <div>
                {respondent.respondentProfile.map(([attributeKey, entries]) => (
                    <span key={attributeKey}>
                        <span className="comments-table-attribute-label">
                            {reviewProject.profileAttributes.find((attribute) => attribute._id === attributeKey)?.label}
                            :
                        </span>{' '}
                        {entries.map(({ value }) => value).join(', ')}{' '}
                    </span>
                ))}
            </div>
        </div>
    )
}
