import { FC, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { TermsTable } from './TermsTable'
import { Passage } from '../../models3/Passage'
import { PassageVideo } from '../../models3/PassageVideo'
import { Portion } from '../../models3/Portion'
import { Project } from '../../models3/Project'
import { getVisiblePassageOrPortionRefRanges } from '../../models3/ProjectReferences'
import { Root } from '../../models3/Root'
import { refRangesToDisplay } from '../../resources/RefRange'
import ERTermModal from '../enhancedResources/ERTermModal'
import { HelpLinkWithMessage } from '../utils/Buttons'

import './PassageBiblicalTermsViewer.css'

type PassageBiblicalTermsViewerProps = {
    portion: Portion | null
    passage: Passage | null
    passageVideo: PassageVideo | null
    project: Project
    rt: Root
}

const PassageBiblicalTermsViewer: FC<PassageBiblicalTermsViewerProps> = observer(
    ({ rt, passage, passageVideo, project, portion }: PassageBiblicalTermsViewerProps) => {
        const { t } = useTranslation()
        const [termId, setTermId] = useState('')

        if (!passage || !portion) return null

        const markers = passageVideo?.getVisibleBiblicalTermMarkers(passage) ?? []
        const refRanges = getVisiblePassageOrPortionRefRanges({ passage, portion })
        const displayableReferences = refRangesToDisplay(refRanges, project)
        const terms = project.getKeyTermsThatOccurInVerses(refRanges)

        return (
            <div className="passage-biblical-terms-content">
                {termId && <ERTermModal rt={rt} termId={termId} setTermId={setTermId} />}
                {displayableReferences.trim() !== '' && <p>{displayableReferences}</p>}
                {terms.length === 0 ? (
                    <HelpLinkWithMessage message={t('noPassageBiblicalTerms')} id="biblical-terms" />
                ) : (
                    <TermsTable terms={terms} markers={markers} viewTermInfo={setTermId} />
                )}
            </div>
        )
    }
)

export default PassageBiblicalTermsViewer
