import { observer } from 'mobx-react'
import { TFunction, useTranslation } from 'react-i18next'

import FilePicker from './FilePicker'
import { importEAFFile } from '../../elan/ELANCreator'
import { downloadFcpxml } from '../../finalcutpro/finalcutpro'
import { FfmpegParameters } from '../../models3/FfmpegParameters'
import { Passage } from '../../models3/Passage'
import { PassageVideo } from '../../models3/PassageVideo'
import { Root } from '../../models3/Root'
import { VideoCacheRecord } from '../../models3/VideoCacheRecord'
import { displayError, displayInfo } from '../utils/Errors'
import { fmt } from '../utils/Fmt'
import { downloadWithFilename } from '../utils/Helpers'
import { appendSilenceToEndOfRecording, encodeOpus, shouldEncodeOpus } from '../utils/Opus'
import VideoCompressor from '../utils/VideoCompressor'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:BaseRecordingFilePicker')

interface BaseRecordingFilePickerProps {
    enabled: boolean
    rt: Root
    passage: Passage
    className: string
}

const writeTestFileToCache = async (file: File) => {
    const _id = `${file.name.slice(2, -4)}-1`
    log('writeTestFileToCache', _id)

    const vcr = VideoCacheRecord.get(_id)
    await vcr.deleteBlobs() // if existing video data for this file, remove it
    vcr.size = file.size
    await vcr.addBlob(file)

    log('!!!writeTestFileToCache DONE', _id)
}

const needsCompression = (file: File) => {
    if (file.name.includes('_force_compression_')) return true

    const sizeMB = file.size / (1024 * 1024)
    log('needsCompression?', fmt({ sizeMB: sizeMB.toFixed(0) }))

    if (shouldEncodeOpus(file.name)) return true

    if (file.name.toLowerCase().endsWith('.mxf')) return true
    if (file.name.toLowerCase().endsWith('.mov')) return true
    if (file.name.toLowerCase().endsWith('.wmv')) return true

    return sizeMB > 100
}

const validateFile = (file: File, maxVideoSizeMB: number, t: TFunction) => {
    if (file.size > maxVideoSizeMB * 1024 * 1024) {
        throw new Error(t('fileSizeTooLarge', { maxVideoSizeMB }))
    }

    const video = document.createElement('video')
    if (!video.canPlayType(file.type)) {
        throw new Error(t('Cannot play this type of file.'))
    }
}

interface UploaderProps {
    file: File
    rt: Root
    passage: Passage
    t: TFunction
    videoToCopyFrom?: PassageVideo
    options?: { preserveSegmentation?: boolean; warnAboutOldData?: boolean }
}

export const uploadRecording = async ({ file, rt, passage, t, videoToCopyFrom, options }: UploaderProps) => {
    const uploadFile = async (fileToUpload: File, ffmpegParametersUsed?: FfmpegParameters) => {
        const video = await passage.uploadFile(fileToUpload, rt.name)

        if (ffmpegParametersUsed !== undefined) {
            video.ffmpegParametersUsed = ffmpegParametersUsed
        }

        // This will indirectly trigger uploading the video blob in the cache to S3
        // when DBAcceptor calls VideoCache.acceptPassageVideo
        const newVideo = await passage.addNewBaseVideo({
            video,
            videoToCopyFrom,
            project: rt.project,
            options
        })

        await rt.setPassage(passage)

        // We set rt.passageVideo to null first in order to ensure that
        // VideoMain will update. Otherwise there is a race condition
        // where DBAcceptor partially set up the rt.passageVideo and triggers
        // a VideoMain render before the rt.passageVideo has all the info to correctly display.
        await rt.setPassageVideo(null)
        await rt.setPassageVideo(newVideo)
    }

    const uploadVideo = async (fileToUpload: File, ffmpegParametersUsed?: FfmpegParameters) => {
        try {
            validateFile(fileToUpload, rt.project.maxVideoSizeMB, t)
            await uploadFile(fileToUpload, ffmpegParametersUsed)
        } catch (err) {
            displayError(err)
        }
    }

    const compressAndUpload = async (fileToUpload: File, setProgressMessage: (message: string) => void) => {
        log('compressAndUpload start')

        try {
            if (shouldEncodeOpus(fileToUpload.name)) {
                setProgressMessage(t('Starting compression...'))
                const compressedFile = await encodeOpus(fileToUpload)
                uploadVideo(compressedFile)
                setProgressMessage('')
                return
            }

            const isCompressorRunning = await VideoCompressor.checkIfServerRunning()
            if (!isCompressorRunning) return

            let resolution = rt.project.compressedVideoResolution
            let quality = rt.project.compressedVideoQuality
            while (true) {
                const compressor = new VideoCompressor(
                    {
                        crf: rt.project.compressedVideoQuality,
                        resolution: rt.project.compressedVideoResolution,
                        maxFileSizeMB: rt.project.maxVideoSizeMB
                    },
                    setProgressMessage
                )
                const { compressedFile, ffmpegParameters } = await compressor.compressVideo(fileToUpload)

                const sizeMB = compressedFile.size / (1024 * 1024)
                log(
                    'compressAndUpload compressVideo',
                    fmt({
                        sizeMB,
                        resolution,
                        quality
                    })
                )

                if (sizeMB <= rt.project.maxVideoSizeMB) {
                    uploadVideo(compressedFile, ffmpegParameters)
                    break
                }

                if (resolution > 480) {
                    displayInfo(
                        `${t('Compressed 720p file too large. Try compressing to 480p.')} [${sizeMB.toFixed(0)}mb]`
                    )
                    resolution = 480
                    continue
                }

                if (quality < 28) {
                    quality = Math.min(rt.project.compressedVideoQuality + 3, 28)
                    displayInfo(
                        `${t('recordingCompressedTooLargeTryingAgain')} [${sizeMB.toFixed(0)}mb, crf=${quality}]`
                    )
                    continue
                }

                displayError(t('recordingCompressedTooLarge'))
                break
            }
        } catch (err) {
            const error = err as Error
            log('compressAndUpload ERROR', JSON.stringify(error))

            setProgressMessage('')

            if (error.name === 'NotEnoughFreeSpace') {
                displayError(t('Your hard drive does not have enough free space.'))
            } else if (error.name === 'PayloadTooLarge') {
                displayError(t('File upload is too large.'))
            } else if (error.name === 'CompressedFileTooLarge') {
                displayError(
                    t('recordingCouldNotCompressSmallerThanMax', {
                        maxVideoSizeMB: rt.project.maxVideoSizeMB
                    }) + t('Retry with different compression settings.')
                )
            } else {
                displayError(t('recordingCouldNotCompress'))
            }
        }
    }

    const testCompressionServer = async (fileToTest: File, setProgressMessage: (message: string) => void) => {
        const isCompressorRunning = await VideoCompressor.checkIfServerRunning()
        if (!isCompressorRunning) {
            return
        }

        try {
            const compressor = new VideoCompressor(
                {
                    crf: rt.project.compressedVideoQuality,
                    resolution: rt.project.compressedVideoResolution,
                    maxFileSizeMB: rt.project.maxVideoSizeMB
                },
                setProgressMessage
            )
            const compressedData = await compressor.compressVideo(fileToTest)
            const { compressedFile } = compressedData

            let fileExtension = 'mp4'
            if (compressedFile.type.startsWith('audio')) {
                fileExtension = 'mp3'
            }

            // Download compressed video file
            const href = window.URL.createObjectURL(compressedFile)
            downloadWithFilename(href, `compressed-video.${fileExtension}`)
        } catch (err) {
            const error = err as Error
            setProgressMessage('')
            displayError(error.name)
        }
    }

    const upload = async () => {
        // Files with rt.names starting __video are treated as test files and directly
        // loaded to the video cache
        if (file.name.startsWith('__video')) {
            await writeTestFileToCache(file)
            return
        }

        if (!rt.iAmTranslator) {
            displayError(t('recordingOnlyTranslatorsCanUpload'))
            return
        }

        const setProgressMessage = passage.setCompressionProgressMessage.bind(passage)

        const isAudioFile = file.type.startsWith('audio')
        const isVideoFile = file.type.startsWith('video')

        if (file.name.startsWith('_test_comp_')) {
            await testCompressionServer(file, setProgressMessage)
            return
        }

        if (rt.passageVideo) {
            // Process Final Cut Pro file
            if (file.name.endsWith('.fcpxml')) {
                downloadFcpxml(rt, passage, rt.passageVideo, file).catch(displayError)
                return
            }

            // Import ELAN gloss file
            if (file.name.endsWith('.eaf')) {
                importEAFFile(rt, passage, rt.passageVideo, file).catch(displayError)
                return
            }
        }

        if (rt.project.recordAudioOnly && !isAudioFile) {
            displayError(t('This file must be an audio file.'))
            return
        }

        if (!rt.project.recordAudioOnly && !isVideoFile) {
            displayError(t('This file must be a video file.'))
            return
        }

        const updatedFile = isAudioFile ? await appendSilenceToEndOfRecording(file) : file
        if (needsCompression(updatedFile)) {
            await compressAndUpload(updatedFile, setProgressMessage)
            return
        }

        await uploadVideo(updatedFile)
    }

    return upload()
}

export const BaseRecordingFilePicker = observer(({ enabled, rt, passage, className }: BaseRecordingFilePickerProps) => {
    const { t } = useTranslation()
    const videoToCopyFrom = passage.latestVideo
    return (
        <FilePicker
            enabled={enabled}
            setSelectedFiles={(fileList) => {
                if (fileList.length > 0) {
                    const file = fileList[0]
                    uploadRecording({ file, rt, passage, t, videoToCopyFrom, options: { warnAboutOldData: true } })
                }
            }}
            className={className}
            iconName={rt.project.recordAudioOnly ? 'fa-file-audio' : 'fa-file-video'}
            accept="audio/*,video/*"
        />
    )
})
