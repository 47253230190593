import { FC } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import FilePicker from './FilePicker'
import { displayError } from '../utils/Errors'

interface PassageResourceFilePickerProps {
    enabled: boolean
    className: string
    resourceType: 'audio' | 'pdf'
    uploadFile: (file: File) => void
}

export const PassageResourceFilePicker: FC<PassageResourceFilePickerProps> = observer(
    ({ enabled, className, resourceType, uploadFile }) => {
        const { t } = useTranslation()

        const accept = resourceType === 'audio' ? 'audio/*' : 'application/pdf'

        const upload = (file: File) => {
            if (
                (resourceType === 'audio' && !file.type.startsWith('audio/')) ||
                (resourceType === 'pdf' && file.type !== 'application/pdf')
            ) {
                displayError(t('Cannot upload this type of file.'))
                return
            }

            const maxFileSize = 50 * 1024 * 1024
            if (file.size > maxFileSize) {
                displayError(t('File must be smaller than 50MB.'))
                return
            }

            uploadFile(file)
        }

        return (
            <FilePicker
                className={className}
                enabled={enabled}
                accept={accept}
                setSelectedFiles={(fileList) => {
                    if (fileList.length > 0) {
                        const singleFile = fileList[0]
                        upload(singleFile)
                    }
                }}
                tooltip={resourceType === 'audio' ? t('importAudio') : t('importPDF')}
                iconName={resourceType === 'audio' ? 'fa-file-audio' : 'fa-file-pdf'}
                iconType={resourceType === 'audio' ? 'fas' : 'far'}
            />
        )
    }
)
