import { useState } from 'react'

import JSZip from 'jszip'
import { Modal } from 'react-bootstrap'
import { TFunction, useTranslation } from 'react-i18next'

import { _LevelupDB } from '../../../models3/_LevelupDB'
import { Project } from '../../../models3/Project'
import { Root } from '../../../models3/Root'
import { VideoCache } from '../../../models3/VideoCache'
import { FileExtension, MimeType } from '../../../types'
import { LocalStorageKeys } from '../../app/slttAvtt'
import FilePicker from '../../filePickers/FilePicker'
import {
    clearProjectRestoreInProgress,
    exportToFile,
    safeFileName,
    setProjectRestoreInProgress
} from '../../utils/Helpers'
import { ModalFooter, ProgressModalBodyAndFooter } from '../Modals'

const projectBackupFileName = (project: Project) => `AVTT-${project.displayName}-backup`

const isProjectBackupFileName = (project: Project, fileName: string) =>
    fileName.startsWith(safeFileName(projectBackupFileName(project))) && fileName.endsWith(FileExtension.ZIP)

interface ProjectRestoreModalSuccessBodyProps {
    closeModal: () => void
    error: string
    loading: boolean
}

const ProjectBackupModalResultsPage = ({ closeModal, error, loading }: ProjectRestoreModalSuccessBodyProps) => {
    const { t } = useTranslation()
    return (
        <ProgressModalBodyAndFooter
            closeModal={closeModal}
            error={error}
            loading={loading}
            successBody={<div>{t('backupProjectFileDownloaded')}</div>}
        />
    )
}

const ProjectRestoreModalResultsPage = ({ closeModal, error, loading }: ProjectRestoreModalSuccessBodyProps) => {
    const { t } = useTranslation()
    return (
        <ProgressModalBodyAndFooter
            closeModal={closeModal}
            error={error}
            helpId="backup-restore"
            loading={loading}
            loadingMessage={t('projectRestoreProgress')}
            successBody={<div>{t('projectRestoreSuccess')}</div>}
        />
    )
}

interface BackupProjectModalProps {
    rt: Root
    setOpen: (value: boolean) => void
}

export const BackupProjectModal = ({ rt, setOpen }: BackupProjectModalProps) => {
    const { t } = useTranslation()
    const [showResultsPage, setShowResultsPage] = useState(false)
    const [error, setError] = useState('')
    const [backupInProgress, setBackupInProgress] = useState(false)

    const { project } = rt

    const backupProject = async () => {
        try {
            const db = project.db as _LevelupDB
            const zip = new JSZip()
            const [docs, { videoBlobs, cachedVideos }] = await Promise.all([
                db.readDBRecords(),
                VideoCache.export(new RegExp(`^${project.name}/`))
            ])
            zip.file('docs.json', new Blob([JSON.stringify(docs)], { type: MimeType.JSON }))
            videoBlobs.forEach((videoBlob) => {
                zip.file(`videoBlobs/${videoBlob._id}`, videoBlob.blob)
            })
            zip.file('cachedVideos.json', new Blob([JSON.stringify(cachedVideos)], { type: MimeType.JSON }))
            const zipFile = await zip.generateAsync({ type: 'blob' })
            const blob = new Blob([zipFile])
            exportToFile(blob, projectBackupFileName(project), FileExtension.ZIP)

            localStorage.setItem(LocalStorageKeys.LAST_SUCCESSFUL_BACKUP, Date.now().toString())

            setBackupInProgress(false)
        } catch (err) {
            setError(`${t('Error')}: ${err}`)
        }
    }
    return (
        <Modal show onHide={() => setOpen(false)} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t('backupProject')}</Modal.Title>
            </Modal.Header>
            {showResultsPage && (
                <ProjectBackupModalResultsPage
                    closeModal={() => setOpen(false)}
                    error={error}
                    loading={backupInProgress}
                />
            )}
            {!showResultsPage && (
                <>
                    <Modal.Body>{t('backupProjectMessage', { projectName: project.displayName })}</Modal.Body>
                    <ModalFooter
                        onOK={() => {
                            setBackupInProgress(true)
                            setShowResultsPage(true)
                            backupProject()
                        }}
                        onCancel={() => setOpen(false)}
                    />
                </>
            )}
        </Modal>
    )
}

const readBackupFile = async (file: File, projectName: string, t: TFunction) => {
    try {
        const zip = new JSZip()
        const newZip = await zip.loadAsync(file)

        // Restore project from backup.
        const entries: {
            type: 'docs' | 'cachedVideos' | 'videoBlob'
            relativePath: string
            zipEntry: JSZip.JSZipObject
        }[] = []
        newZip.forEach((relativePath, zipEntry) => {
            if (relativePath === 'docs.json') {
                entries.push({ type: 'docs', relativePath, zipEntry })
            } else if (relativePath === 'cachedVideos.json') {
                entries.push({ type: 'cachedVideos', relativePath, zipEntry })
            } else if (relativePath.startsWith('videoBlobs/')) {
                if (relativePath !== 'videoBlobs/' && !relativePath.startsWith(`videoBlobs/${projectName}/`)) {
                    throw new Error()
                }
                if (!zipEntry.dir) {
                    entries.push({ type: 'videoBlob', relativePath, zipEntry })
                }
            }
        })

        const docsEntry = entries.find((entry) => entry.type === 'docs')
        if (!docsEntry) {
            throw new Error()
        }
        const docsString = await docsEntry.zipEntry.async('string')
        const docs = JSON.parse(docsString)
        if (!Array.isArray(docs)) {
            throw new Error()
        }

        const cachedVideosEntry = entries.find((entry) => entry.type === 'cachedVideos')
        if (!cachedVideosEntry) {
            throw new Error()
        }

        const cachedVideosString = await cachedVideosEntry.zipEntry.async('string')
        const cachedVideoItems = JSON.parse(cachedVideosString)
        if (!Array.isArray(cachedVideoItems)) {
            throw new Error()
        }

        const videoBlobEntries = entries.filter((entry) => entry.type === 'videoBlob')
        const videoBlobItems = await Promise.all(
            videoBlobEntries.map(async (entry) => {
                const blob = await entry.zipEntry.async('blob')
                return { blob, _id: entry.relativePath.substring('videoBlobs/'.length) }
            })
        )

        return { docs, cachedVideoItems, videoBlobItems }
    } catch (error) {
        throw new Error(t('projectRestoreValidationError'))
    }
}

export const RestoreProjectModal = ({ rt, setOpen }: BackupProjectModalProps) => {
    const { t } = useTranslation()
    const [error, setError] = useState('')
    const [showProgressPage, setShowProgressPage] = useState(false)
    const [restoreInProgress, setRestoreInProgress] = useState(false)
    const [file, setFile] = useState<File>()

    const { project } = rt

    const restoreProject = async (zipFile: File) => {
        const db = project.db as _LevelupDB

        try {
            if (await db.unsyncedChangesExist()) {
                throw new Error(t('projectRestoreUnsyncedError'))
            }

            if (!isProjectBackupFileName(project, zipFile.name)) {
                throw new Error(t('projectRestoreValidationError'))
            }

            const { docs, cachedVideoItems, videoBlobItems } = await readBackupFile(zipFile, project.name, t)

            setProjectRestoreInProgress()
            await Promise.all([
                db.rebuild(docs, true),
                VideoCache.putItems({ cachedVideoItems, videoBlobItems, allow: true })
            ])
            clearProjectRestoreInProgress()
            setRestoreInProgress(false)
            location.reload()
        } catch (err) {
            setError(String(err))
            clearProjectRestoreInProgress()
        }
    }

    return (
        <Modal show onHide={() => {} /* don't allow closing via <Esc> key */} backdrop="static">
            <Modal.Header>
                <Modal.Title>{t('projectRestore')}</Modal.Title>
            </Modal.Header>
            {showProgressPage && (
                <ProjectRestoreModalResultsPage
                    closeModal={() => setOpen(false)}
                    error={error}
                    loading={restoreInProgress}
                />
            )}
            {!showProgressPage && (
                <>
                    <Modal.Body>
                        <div className="modal-file-picker">
                            <FilePicker
                                className="modal-file-picker-icon"
                                enabled
                                accept={MimeType.ZIP}
                                setSelectedFiles={(fileList) => {
                                    if (fileList.length > 0) {
                                        const singleFile = fileList[0]
                                        setFile(singleFile)
                                    }
                                }}
                            />
                            {t('chooseFile')}
                        </div>
                        {file && <div>{file.name}</div>}
                    </Modal.Body>
                    <ModalFooter
                        enabledOK={Boolean(file)}
                        onOK={() => {
                            if (file) {
                                setRestoreInProgress(true)
                                setShowProgressPage(true)
                                restoreProject(file)
                            }
                        }}
                        onCancel={() => setOpen(false)}
                    />
                </>
            )}
        </Modal>
    )
}
