import { useEffect, useRef, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { SimpleAudioPlayer } from './SimpleAudioPlayer'
import { Project } from '../../models3/Project'
import {
    ChapterTimeCodes,
    PlaybackRange,
    convertBbbcccvvvToPublishedAudioVerse,
    getAllVersesInRange,
    getAudio,
    getTimeCodes,
    getTimeRangeForVerses
} from '../../resources/AudioResource'
import { RefRange } from '../../resources/RefRange'
import { PublishedBible } from '../../types'
import { incrementTime, nnn } from '../utils/Helpers'
import { LoadingIcon } from '../utils/Icons'

import './SimpleAudioPlayer.css'

const getBibleAudio = async (bible: PublishedBible, reference: RefRange) => {
    const chapters = Array.from(reference.chapterIterator())
    const firstChapter = chapters[0]
    const verses = reference
        .getAllVerses(bible.versification)
        .filter((refString) => refString.startsWith(firstChapter))
        .map((verse) => convertBbbcccvvvToPublishedAudioVerse(verse))

    if (!verses.length) {
        return
    }
    const [blob, timeCodes] = await Promise.all([
        getAudio(bible.id, firstChapter),
        getTimeCodes(bible.id, firstChapter)
    ])
    const timeRange = getTimeRangeForVerses(verses, timeCodes)
    return {
        timeRange,
        blob,
        timeCodes: {
            chapter: firstChapter,
            codes: timeCodes
        } as ChapterTimeCodes
    }
}

const getCurrentTimeCodeIndex = (currentTime: number, chapterTimeCodes: ChapterTimeCodes) => {
    return chapterTimeCodes.codes.findIndex(
        (code) => currentTime >= Number(code.start) && currentTime <= Number(code.end)
    )
}

const getNextVerse = (currentTime: number, chapterTimeCodes: ChapterTimeCodes) => {
    const { codes } = chapterTimeCodes
    if (!codes.length) {
        return
    }
    const currentTimeCodeIndex = getCurrentTimeCodeIndex(currentTime, chapterTimeCodes)
    if (currentTimeCodeIndex === codes.length - 1) {
        return
    }
    return codes[currentTimeCodeIndex + 1]
}

const getPreviousVerse = (currentTime: number, chapterTimeCodes: ChapterTimeCodes) => {
    const { codes } = chapterTimeCodes
    if (!codes.length) {
        return
    }
    const currentTimeCodeIndex = getCurrentTimeCodeIndex(currentTime, chapterTimeCodes)
    if (currentTimeCodeIndex < 1) {
        return codes[0]
    }
    return codes[currentTimeCodeIndex - 1]
}

export const BibleAudioPlayer = observer(
    ({
        bible,
        reference,
        project,
        onVerseUpdate
    }: {
        bible: PublishedBible
        reference: RefRange
        project: Project
        onVerseUpdate: (bbbcccvvv: string) => void
    }) => {
        const { t } = useTranslation()
        const [url, setUrl] = useState('')
        const [timeRange, setTimeRange] = useState<PlaybackRange>({
            start: undefined,
            end: undefined
        })
        const [chapterTimeCodes, setChapterTimeCodes] = useState<ChapterTimeCodes>()
        const [isError, setIsError] = useState(false)
        const [isPlaying, setIsPlaying] = useState(false)
        const latestRequestRef = useRef<string>('')

        useEffect(() => {
            const resourceRef = bible.id + reference.startRef + reference.endRef + project.versification
            latestRequestRef.current = resourceRef

            let audioUrl = ''
            const getData = async () => {
                setIsError(false)
                try {
                    const audioBible = await getBibleAudio(bible, reference)

                    if (!audioBible) {
                        throw new Error('No audio Bible')
                    }

                    // Make sure the last call to update state is the one that takes precedence
                    // so we avoid a race condition.
                    if (resourceRef === latestRequestRef.current) {
                        setChapterTimeCodes(audioBible.timeCodes)
                        setTimeRange(audioBible.timeRange)
                        audioUrl = URL.createObjectURL(audioBible.blob)
                        setUrl(audioUrl)
                    }
                } catch (error) {
                    if (resourceRef === latestRequestRef.current) {
                        setIsError(true)
                    }
                }
            }

            getData()

            return () => {
                if (resourceRef === latestRequestRef.current) {
                    setUrl('')
                }

                if (audioUrl) {
                    URL.revokeObjectURL(audioUrl)
                }
            }
        }, [bible, reference, project])

        const updateVerse = (time: number) => {
            if (!isPlaying) {
                return
            }
            if (chapterTimeCodes) {
                const index = getCurrentTimeCodeIndex(time, chapterTimeCodes)
                const currentTimeCode = index >= 0 ? chapterTimeCodes.codes[index] : undefined
                const versesInRange = currentTimeCode ? getAllVersesInRange(currentTimeCode.verseId) : []
                const bbbcccvvv = versesInRange.length
                    ? `${chapterTimeCodes.chapter}${nnn(Number(versesInRange[0]))}`
                    : ''
                onVerseUpdate(bbbcccvvv)
            }
        }

        const getNextSkipTime = (currentTime: number, limit: number) => {
            if (chapterTimeCodes) {
                const nextVerse = getNextVerse(currentTime, chapterTimeCodes)
                if (nextVerse && Number(nextVerse.start) <= limit) {
                    // add a little bit to make sure we start playing
                    // in the next verse and not the current one
                    return incrementTime(Number(nextVerse.start))
                }
                return limit
            }
            return currentTime
        }

        const getPreviousSkipTime = (currentTime: number, limit: number) => {
            if (chapterTimeCodes) {
                const previousVerse = getPreviousVerse(currentTime, chapterTimeCodes)
                if (previousVerse && Number(previousVerse.start) >= limit) {
                    // add a little bit to make sure we start playing
                    // in the previous verse and not the one before it
                    return incrementTime(Number(previousVerse.start))
                }
                return limit
            }
            return currentTime
        }

        return (
            <>
                {!isError && url.length === 0 && (
                    <div className="centered-controls">
                        <LoadingIcon className="published-audio-playback-button" />
                    </div>
                )}
                {!isError && url.length > 0 && (
                    <SimpleAudioPlayer
                        url={url}
                        startTime={timeRange.start}
                        endTime={timeRange.end}
                        onPause={() => {
                            setIsPlaying(false)
                            onVerseUpdate('')
                        }}
                        onPlay={() => setIsPlaying(true)}
                        onTimeUpdate={updateVerse}
                        getNextSkipTime={getNextSkipTime}
                        getPreviousSkipTime={getPreviousSkipTime}
                        nextSkipTooltip={t('goToNextVerse')}
                        previousSkipTooltip={t('goToPreviousVerse')}
                    />
                )}
            </>
        )
    }
)
