import React, { FC } from 'react'

export interface ILocalizedChoice {
    choice: string
    localizedChoice: string
    title: string
}

type IMakeLocalizedChoice = {
    choices: ILocalizedChoice[]
    choice: string
    setChoice: (_choice: string) => void
}

export const MakeLocalizedChoice: FC<IMakeLocalizedChoice> = ({ choices, choice, setChoice }) => {
    return (
        <select value={choice} onChange={(e) => setChoice(e.target.value)}>
            {choices.map((pt) => (
                <option title={pt.title} value={pt.choice} key={pt.choice}>
                    {pt.localizedChoice}
                </option>
            ))}
        </select>
    )
}
