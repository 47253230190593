export class PassageSegmentLabel {
    constructor(
        public x: number,
        public y: number,
        public xText: number = x,
        public yText: number = y,
        public text: string = ''
    ) {}

    dbg() {
        const type = this.constructor.name
        const { x, y, xText, yText, text } = this
        const doc = { type, x, y, xText, yText, text }
        return doc
    }

    copy() {
        return new PassageSegmentLabel(this.x, this.y, this.xText, this.yText, this.text)
    }
}
