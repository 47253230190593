import { Component, useState, useEffect, useContext } from 'react'

import { t } from 'i18next'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import InputSlider from 'react-input-slider'

import RangeVideoPlayer from './RangeVideoPlayer'
import { VideoTimeline, PositionSetter } from './VideoTimeline'
import VideoTimelinePlayButtons from './VideoTimelinePlayButtons'
import { ViewableVideoCollection } from './ViewableVideoCollection'
import { AudioWaveformCreator, WaveformVisualizer } from './WaveformVisualizer'
import { Passage } from '../../models3/Passage'
import { PassageNote } from '../../models3/PassageNote'
import { PassageVideo } from '../../models3/PassageVideo'
import { createVisiblePassage } from '../../models3/VisiblePassageVideo'
import { RootContext } from '../app/RootContext'
import { INoteRoot } from '../notes/NoteDialog'
import { PaneCloseButton, AdjustNoteMarkerButtons } from '../utils/Buttons'
import { systemError, displayError } from '../utils/Errors'
import { decrementTime } from '../utils/Helpers'

import './NoteLocationEditor.css'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:NoteLocationEditor')

const fx = (val?: number) => val?.toFixed(2)

/*
    GLOSSARY

        position - a time measured within one specific video
        time - a time viewed in the main video timeline
        domain - area in the main video +/- 30 seconds from note position.
            startPosition/endPosition for note must lie in this range
        citation - a time range of the SL video (or one of its patches) that this note is about

    Allow the user to adjust the start/stop times for the note citation within
    the main video. 
    
    Also allow adjusting the position of the note itself.
    We do not currently allow the note to be moved to a different video
    or patch video since that is complicated.
 */

// const rounded = (num: number, decimalPlaces: number) => parseFloat(num.toFixed(decimalPlaces))

interface IVideoTimelineAdjusters {
    adjustBeginningMarker: (delta: number) => void
    adjustEndMarker: (delta: number) => void
    enabled: boolean
}

export const VideoTimelineAdjusters = (props: IVideoTimelineAdjusters) => {
    const { adjustBeginningMarker, adjustEndMarker, enabled } = props
    return (
        <div className="note-marker-time-adjusters">
            <AdjustNoteMarkerButtons enabled={enabled} adjustTime={adjustBeginningMarker} />
            <AdjustNoteMarkerButtons enabled={enabled} adjustTime={adjustEndMarker} />
        </div>
    )
}

type NoteAudioWaveformVisualizerProps = {
    visiblePassageVideo: PassageVideo
    passage: Passage
    startTime: number
    endTime: number
}

const NoteAudioWaveformVisualizer = observer(
    ({ visiblePassageVideo, passage, startTime, endTime }: NoteAudioWaveformVisualizerProps) => {
        const [waveformData, setWaveformData] = useState<number[]>([])

        const rt = useContext(RootContext)
        if (!rt) {
            return null
        }

        useEffect(() => {
            let mounted = true

            async function getTheData() {
                if (!rt) {
                    return
                }
                const samplesOnScreen = 300
                const waveformCreator = new AudioWaveformCreator()
                const vvc = new ViewableVideoCollection()
                const audioSlices = await visiblePassageVideo.getPlayableSlices(passage, vvc)
                const waveform = await waveformCreator.getNormalizedPCMValues(
                    audioSlices,
                    samplesOnScreen,
                    startTime,
                    endTime
                )
                if (mounted) {
                    setWaveformData(waveform.normalizedPCMValues)
                }
            }
            getTheData()

            return () => {
                mounted = false
            }
        }, [rt, passage, visiblePassageVideo, startTime, endTime])

        return <WaveformVisualizer waveformData={waveformData} className="note-audio-waveform-visualizer" />
    }
)

interface INoteLocationEditor {
    noteRoot: INoteRoot
    onDoneEditing: () => void
}
class NoteLocationEditor extends Component<INoteLocationEditor> {
    @observable isPlaying = false

    @observable currentTime = 0

    rangeVideoPlayer: RangeVideoPlayer | null = null

    @observable note: PassageNote

    // These become defined when every the corresponding segment paramater
    // has been changed by the user
    newTime?: number

    newStartTime?: number

    newEndTime?: number

    setters: PositionSetter[] = [] // passed to VideoTimeline to allow updating of positions relating to note
    // in order the are note.startPosition, note.position, note.endPosition, currentTime

    // note 'position' must stay between these limits to keep it on the same video
    lowTime = 0

    highTime = 0

    // The following positions are constrained to be in increasing order
    domainStartTime = 0 // start of area viewable in this editor, this may be negative!

    startTime = 0 // start of citation for note, this may be negative!

    time = 0 // point of focus for note

    endTime = 0 // end of citation for note

    domainEndTime = 0 // end of area viewable in this editor

    // video paramaters
    @observable noteVideo: PassageVideo | null = null // video containing only patches from when note was created

    @observable notePassage: Passage | null = null // passage that contains noteVideo and all the patches on it

    @observable currentVideos: ViewableVideoCollection | null = null

    @observable noteSetupComplete = false

    constructor(props: INoteLocationEditor) {
        super(props)

        const { noteRoot } = this.props

        this.note = noteRoot.note
        this.setupNote(noteRoot.note).catch(displayError)
    }

    // We do not save the updated note position until the user navigates away
    // from the current note
    async componentDidUpdate() {
        const { noteRoot } = this.props

        if (this.note._id === noteRoot.note._id) return

        try {
            await this.saveNoteIfChangesExist()
        } catch (err) {
            systemError(err)
        }

        await this.setupNote(noteRoot.note)
    }

    async componentWillUnmount() {
        const { onDoneEditing } = this.props
        try {
            await this.saveNoteIfChangesExist()
        } catch (err) {
            systemError(err)
        }

        onDoneEditing()
    }

    onPlayingStatus = (isPlaying: boolean) => {
        // log('onPlayingStatus', this.isPlaying, isPlaying)

        if (this.isPlaying && !isPlaying) {
            log('onPlayingStatus reset')
            // reset to start of citation plus a smidge so we can see cursor
            const resetTime = this.startTime + (this.domainEndTime - this.domainStartTime) / 60

            setTimeout(() => {
                this.setters[3].setValue(resetTime)
            }, 200)
        }

        this.isPlaying = isPlaying
    }

    // We do not allow a note position to move to a different segment because that is complicated.
    setHighLowPositionLimits() {
        const { noteRoot } = this.props
        const { note } = noteRoot
        const { noteVideo, notePassage } = this

        if (!noteVideo || !notePassage) {
            return
        }

        const segmentIndex = noteVideo.timeToSegmentIndex(note.time)
        const actualSegment = noteVideo.getAllBaseSegments()[segmentIndex].actualSegment(notePassage)
        if (actualSegment) {
            this.lowTime = actualSegment.positionToTime(actualSegment.position)

            // Segment x's end position is the same as segment (x + 1)'s start position. Set the
            // max time for the note to be a little less than the end position of the segment.
            // Otherwise, it's hard to tell which segment the note is in.
            this.highTime = decrementTime(actualSegment.positionToTime(actualSegment.endPosition))
        }

        log(
            `setHighLowPositionLimits [${this.lowTime.toFixed(2)}...${this.highTime.toFixed(
                2
            )}], index=${segmentIndex}...${segmentIndex}`
        )
    }

    // Set domain around position which is viewable in this editor
    setDomainLimits() {
        const timeBuffer = 30

        const { noteVideo, time } = this
        if (!noteVideo) {
            return
        }

        // domain start position is position-30 or start of video (whichever is later)
        this.domainStartTime = Math.max(time - timeBuffer, 0)

        // domain end position is position+30 or end of video (whichever is earlier)
        this.domainEndTime = Math.min(time + timeBuffer, noteVideo.computedDuration)
    }

    // Convert relative position to time and pass to player
    setCurrentTimePosition(currentTime: number) {
        this.currentTime = currentTime
        // log('setCurrentTimePosition', fx(this.currentTime), this.isPlaying)

        if (!this.isPlaying) {
            // if we are not playing move the player to the selected point in time
            this.rangeVideoPlayer?.setCurrentTime(this.currentTime)
        }
    }

    setPositionLimits = () => {
        const { noteRoot } = this.props
        const { noteVideo, notePassage } = this
        if (!noteVideo || !notePassage) {
            return
        }

        const actualVideoSnapshot = notePassage.findVideo(noteRoot.note._id)
        if (!actualVideoSnapshot) {
            return
        }

        this.newTime = undefined
        this.newStartTime = undefined
        this.newEndTime = undefined

        this.time = actualVideoSnapshot.positionToTime(noteRoot.note.position)
        this.startTime = actualVideoSnapshot.positionToTime(noteRoot.note.startPosition)
        this.endTime = actualVideoSnapshot.positionToTime(noteRoot.note.endPosition)

        this.setHighLowPositionLimits()

        this.setters[1].value = this.time
        this.setters[1].setValue(this.time)

        this.setDomainLimits()

        this.setters[0].value = this.startTime
        this.setters[0].setValue(this.startTime)

        this.setters[2].value = this.endTime
        this.setters[2].setValue(this.endTime)

        this.setters[3].value = 0
        const domain = this.domainEndTime - this.domainStartTime
        this.setters[3].setValue(this.startTime + domain / 60)
    }

    setupSetters() {
        this.setters = [
            // set startPosition, enforce range domainStartPosition .. position
            new PositionSetter(
                '0',
                () => this.domainStartTime,
                () => this.time - 0.05,
                (value: number) => {
                    this.newStartTime = value
                    this.startTime = value

                    this.setCurrentTimePosition(value)
                }
            ),

            // set position; in range segmentStartPosition..segmentEndPosition
            new PositionSetter(
                '1',
                () => this.lowTime,
                () => this.highTime,
                (value: number) => {
                    this.newTime = value
                    this.time = value

                    this.setDomainLimits() // reset domain

                    // Since the position has change, the valid value for start and end position have
                    // changed, reset the positions to make sure they are in the valid range
                    this.setters[0].setValue(this.startTime)
                    this.setters[2].setValue(this.endTime)

                    this.setCurrentTimePosition(value)
                }
            ),

            // set endPosition, enforce range position .. domainEndPosition
            new PositionSetter(
                '2',
                () => this.time + 0.05,
                () => this.domainEndTime,
                (value: number) => {
                    this.newEndTime = value
                    this.endTime = value

                    this.setCurrentTimePosition(value)
                }
            ),

            // set currentTime
            new PositionSetter(
                '3',
                () => this.domainStartTime,
                () => this.domainEndTime,
                (value: number) => {
                    this.setCurrentTimePosition(value)
                }
            )
        ]
    }

    async setupNote(note: PassageNote) {
        this.noteSetupComplete = false

        this.setupSetters()
        await this.setupNoteVideo(note)
        this.setPositionLimits()

        this.note = note

        this.noteSetupComplete = true
    }

    // Setup noteVideo to contain only the patches were available
    // at the time the note was originally created.
    //
    // Start download process
    async setupNoteVideo(note: PassageNote) {
        const { noteRoot } = this.props
        const { passage, passageVideo } = noteRoot
        if (!passage || !passageVideo) return

        // Create a new note video object which contains just the patches that are not
        // newer than the note creation date
        const visiblePassage = createVisiblePassage(passage, note.creationDate)
        const video = visiblePassage.videos.find((v) => v._id === passageVideo?._id)
        if (!video) {
            return
        }

        video.setSegmentTimes(visiblePassage, true)

        this.noteVideo = video
        this.notePassage = visiblePassage
        log('setupNoteVideo', this.noteVideo)

        this.currentVideos = new ViewableVideoCollection()
        this.currentVideos.setup(this.notePassage, this.noteVideo)
        this.currentVideos.download().catch(displayError)
    }

    adjustTime = (currentTime: number) => {
        this.currentTime = currentTime
        log('adjustTime', fx(currentTime))

        const { rangeVideoPlayer, setters } = this
        if (rangeVideoPlayer) {
            rangeVideoPlayer.setCurrentTime(currentTime)
        }
        setters[3].setValue(currentTime)
    }

    pause = () => {
        const { rangeVideoPlayer } = this
        if (rangeVideoPlayer) {
            rangeVideoPlayer.stop()
        }
    }

    playAll = async () => {
        this.isPlaying = true
        const { rangeVideoPlayer } = this
        if (!rangeVideoPlayer) return

        let endTime = this.endTime
        const startTime = this.currentTime
        if (startTime < this.startTime || startTime > this.endTime) {
            endTime = this.domainEndTime
        }
        log(`playAll ${fx(startTime)}..${fx(endTime)}`)

        await rangeVideoPlayer.playRange(startTime, endTime)
    }

    setCurrentTime = (time: number) => {
        const { setters, noteVideo } = this
        if (!noteVideo) return

        setters[3].setValue(time)
        // log('setCurrentTime', time.toFixed(2), fx(position), fx(this.currentTime))
    }

    public async saveNoteIfChangesExist() {
        const { note, newTime, newStartTime, newEndTime } = this

        if (newTime === undefined && newStartTime === undefined && newEndTime === undefined) return

        log('saveNoteIfChangesExist')

        const { noteRoot } = this.props
        const { passage, passageVideo } = noteRoot
        const { noteVideo, notePassage } = this
        if (!passage || !passageVideo || !noteVideo || !notePassage) {
            return
        }

        log(`saveNoteIfChangesExist values=${newStartTime}/${newTime}/${newEndTime}`)

        // Find the passage video that the note should be saved to. Save to the original version,
        // not a deep copy.
        const actualVideoSnapshot = notePassage.findVideo(note._id)
        const actualVideo = passage.findVideo(note._id)
        if (!actualVideoSnapshot || !actualVideo) {
            return
        }

        await actualVideo.addNote(note)
        await note.setPositions(
            newTime === undefined ? note.position : actualVideoSnapshot.timeToPosition(notePassage, newTime),
            newStartTime === undefined
                ? note.startPosition
                : actualVideoSnapshot.timeToPosition(notePassage, newStartTime),
            newEndTime === undefined ? note.endPosition : actualVideoSnapshot.timeToPosition(notePassage, newEndTime)
        )
    }

    render() {
        const { noteRoot, onDoneEditing } = this.props
        const { note, useMobileLayout, playbackRate, setPlaybackRate, iAmInterpreter, passageVideo } = noteRoot
        const { noteVideo, notePassage, currentVideos, noteSetupComplete } = this
        log('render', noteVideo, currentVideos)

        if (!noteVideo || !notePassage || !currentVideos || !noteSetupComplete) {
            return null
        }

        // to force re-rendering of toolbar
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { isPlaying } = this

        // Originally we restricted position editing to only the creator of the note.
        // but this leaves people confused as to why they can't adjust it when it is wrong.
        // let allowAdjustingPositions = username === note.creator

        const allowAdjustingPositions = iAmInterpreter

        return (
            <div
                className={
                    passageVideo?.isAudioOnly() ? 'audio-note-location-editor-content' : 'note-location-editor-content'
                }
            >
                <div className="note-location-editor-video">
                    <RangeVideoPlayer
                        className={
                            passageVideo?.isAudioOnly()
                                ? 'audio-note-location-editor-player'
                                : 'note-location-editor-player'
                        }
                        ref={(rvp) => (this.rangeVideoPlayer = rvp)}
                        passage={notePassage}
                        video={noteVideo}
                        currentVideos={currentVideos}
                        startTime={this.startTime}
                        endTime={this.endTime}
                        playbackRate={playbackRate}
                        setPlaybackRate={setPlaybackRate}
                        onPlayingStatus={this.onPlayingStatus}
                        onTick={this.setCurrentTime}
                    />
                    <div className="video-timeline-area">
                        <VideoTimelinePlayButtons
                            isPlaying={this.isPlaying}
                            playAll={this.playAll}
                            pause={this.pause}
                        />
                        <div className="video-timeline">
                            <NoteAudioWaveformVisualizer
                                visiblePassageVideo={noteVideo}
                                passage={notePassage}
                                startTime={this.domainStartTime}
                                endTime={this.domainEndTime}
                            />
                            <VideoTimeline
                                setters={this.setters}
                                domainStartPosition={this.domainStartTime}
                                domainEndPosition={this.domainEndTime}
                                adjustTime={this.adjustTime}
                                enabled={!note.resolved}
                                allowAdjustingPositions={allowAdjustingPositions}
                            />
                            {!useMobileLayout && (
                                <VideoTimelineAdjusters
                                    adjustBeginningMarker={(delta) => {
                                        this.setters.find((s) => s.key === '0')?.setValue(this.startTime + delta)
                                    }}
                                    adjustEndMarker={(delta) => {
                                        this.setters.find((s) => s.key === '2')?.setValue(this.endTime + delta)
                                    }}
                                    enabled={allowAdjustingPositions && !note.resolved}
                                />
                            )}
                        </div>
                        <div className="note-location-editor-speed-controls">
                            <div className="u-slider u-slider-y video-player-slider">
                                <InputSlider
                                    className="slider video-rate-input-slider"
                                    slidertooltip={`${t('Playback speed')} = ${playbackRate.toFixed(1)}`}
                                    axis="y"
                                    y={2.0 - playbackRate}
                                    ymax={2}
                                    ystep={0.1}
                                    onChange={(pos) => setPlaybackRate(2.0 - pos.y)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {!passageVideo?.isAudioOnly() && (
                    <div>
                        <PaneCloseButton
                            onClick={() => onDoneEditing()}
                            enabled
                            tooltip={t('Close pane')}
                            className="sl-pane-close-button"
                        />
                    </div>
                )}
            </div>
        )
    }
}

export default observer(NoteLocationEditor)
