import { useTranslation } from 'react-i18next'

import { GenericIcon } from '../../utils/Icons'
import { Switch } from '../../utils/Switch'

const MIN_COUNTDOWN = 0
const MAX_COUNTDOWN = 3

interface RecordingPreferencesProps {
    iconClassName: string
    recordingCountdown: number
    setRecordingCountdown: (value: number) => void
    autoGainControl: boolean
    setAutoGainControl: (value: boolean) => void
}

export const RecordingPreferences = ({
    iconClassName,
    recordingCountdown,
    setRecordingCountdown,
    autoGainControl,
    setAutoGainControl
}: RecordingPreferencesProps) => {
    const { t } = useTranslation()

    return (
        <div className="project-preferences-row">
            <GenericIcon iconName="fa-circle" className={iconClassName} tooltip={t('recordingCountdown')} />
            <label>{t('recordingCountdown')}</label>
            <input
                className="countdown-input"
                required
                type="number"
                value={recordingCountdown}
                step={1}
                onChange={(e) => {
                    // the min and max attributes of the input field don't always work
                    const countdown = Math.max(MIN_COUNTDOWN, Math.min(MAX_COUNTDOWN, Number(e.target.value)))
                    setRecordingCountdown(countdown)
                }}
            />
            <div className="project-preferences-gain-control-container">
                <label>{t('autoGainControl')}</label>
                <Switch
                    className="project-preferences-gain-control"
                    value={autoGainControl}
                    setValue={(value) => setAutoGainControl(value)}
                    tooltip={t('autoGainControl')}
                />
            </div>
        </div>
    )
}
