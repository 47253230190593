import React from 'react'

import { t } from 'i18next'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import InputSlider from 'react-input-slider'

import VideoDownloading from './VideoDownloading'
import { LocalStorageKeys } from '../app/slttAvtt'

interface IPlayer {
    videoUrl: string
    className?: string
}

class Player extends React.Component<IPlayer> {
    @observable private error = ''

    @observable playbackRate = 1.0

    @observable src = ''

    firstUpdate = true

    private vc: HTMLVideoElement | null = null

    constructor(props: IPlayer) {
        super(props)
        const { className } = this.props
        const rateString = localStorage.getItem(`${LocalStorageKeys.VIDEO_PLAYBACK_RATE}${className}`)
        const rate = rateString ? parseFloat(rateString) : 1.0
        this.playbackRate = rate
    }

    componentDidUpdate() {
        const { vc } = this
        if (!vc) return

        // WARNING - hacky code!
        // Users want to be able to click into the player timeline to
        // go to a particular position. Sadly if we just start up a video
        // the timeline is disabled. I suspect this has to do with how
        // the video is encoded by the Chrome webcam.
        //
        // What to do? It appears that if we first seek out to the end
        // of the video the timeline becomes enabled. We can then
        // see back and play it. Sigh.
        if (this.firstUpdate) {
            vc.currentTime = 10000 // seek to end
            setTimeout(() => {
                vc.currentTime = 0.2 // back to origin
                vc.play()
            }, 200)
        }

        this.firstUpdate = false
    }

    rateChange = (pos: any) => {
        const { vc } = this
        if (vc === null) return
        vc.pause()

        let rate = 2.0 - pos.y
        rate = rate > 0.1 ? rate : 0.1

        let { className } = this.props
        className = className || ''
        localStorage.setItem(`${LocalStorageKeys.VIDEO_PLAYBACK_RATE}${className}`, rate.toString())
        this.playbackRate = rate
        vc.playbackRate = rate
    }

    render() {
        const { videoUrl, className } = this.props
        const { error, playbackRate, src } = this

        if (error) return <div>{t('recordingDownloadFailed')}</div>

        const roundedPlaybackRate = playbackRate.toFixed(1)
        const tooltip = `${t('Playback speed')} = ${roundedPlaybackRate}`

        if (src) {
            return (
                <div className={`${className}-video-area`}>
                    <div className={`${className}-video-content`}>
                        <video
                            className={`${className}-video`}
                            controls
                            src={src}
                            ref={(vc) => (this.vc = vc)}
                            autoPlay
                            onPause={() => console.log('done')}
                        />
                    </div>
                    <div className="video-player-controls">
                        <div className="video-player-slider">
                            <InputSlider
                                className="slider video-rate-input-slider"
                                slidertooltip={tooltip}
                                axis="y"
                                y={2.0 - playbackRate}
                                ymax={2}
                                ystep={0.1}
                                onChange={this.rateChange}
                            />
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <VideoDownloading
                className={`${className}-video-placeholder`}
                videoUrl={videoUrl}
                fontSizePt={28}
                onEnded={(blob: Blob) => {
                    this.src = window.URL.createObjectURL(blob)
                }}
            />
        )
    }
}

export default observer(Player)
