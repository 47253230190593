// Show the queue of video blobs waiting to be downloaded.
// Updates every second(ish).
// Items relating to currently selected passage are currentPassageFlaged.

import { Component } from 'react'

import { t } from 'i18next'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import _ from 'underscore'

import { Root } from '../../../models3/Root'
import { VideoCache } from '../../../models3/VideoCache'
import { ScheduledDownload } from '../../../models3/VideoCacheDownloader'

interface IScheduledDownloadView {
    rt: Root
    sd: ScheduledDownload
}

function videoName(rt: Root, url: string) {
    // url e.g. = "ASL_FGH/190614_000231/190614_000440/190614_000631/191128_213112/191128_213127-"
    const parts = url.split('/')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [projectId, portionId, passageId, videoId] = parts

    const portion = _.findWhere(rt.project.portions, { _id: portionId })
    if (!portion) return url

    const passage = _.findWhere(portion.passages, { _id: `${portionId}/${passageId}` })
    if (!passage) return `${[portion.name, ...parts.slice(1)].join(' / ')}-`

    const video = _.findWhere(passage.videos, { _id: `${portionId}/${passageId}/${videoId}` })
    if (!video) return `${[portion.name, passage.name, ...parts.slice(2)].join(' / ')}-`

    const rest = parts.slice(3).join('/')
    const { dateFormatter } = rt
    const creationDate = dateFormatter.format(new Date(video.creationDate))
    return `${portion.getLongPassageName(passage)} / =${creationDate}=${rest ? `    /${rest}` : ''}-`
}

const ScheduledDownloadView = (props: IScheduledDownloadView) => {
    const { rt, sd } = props

    let progress = ''
    // sd.progress is non-null when a internet fetch is in progress
    if (sd.progress) {
        const percent = sd.progress.total ? (100 * sd.progress.loaded) / sd.progress.total : 0
        progress = `  / ${Math.round(sd.progress.total / 1024)} KB (${Math.round(percent)}%)`
    }

    return (
        <div>
            {`[${sd.priority}-${sd.retries}] `}
            {`${videoName(rt, sd.videoUrl.slice(0, -2))}-${sd.chunkNum}`}
            {progress}
        </div>
    )
}

interface IDebugScheduledDownloads {
    rt: Root
}

class DebugScheduledDownloads extends Component<IDebugScheduledDownloads> {
    @observable scheduledDownloads: ScheduledDownload[] = []

    @observable usage = ''

    @observable quota = ''

    handler: any = null

    constructor(props: IDebugScheduledDownloads) {
        super(props)

        VideoCache.videoCacheDownloader.getQuotaAndUsage().then((quotaAndUsage) => {
            let { quota, usage } = quotaAndUsage
            usage = (usage / (1024 * 1024)).toFixed(0)
            quota = (quota / (1024 * 1024)).toFixed(0)
            this.usage = usage
            this.quota = quota
        })
    }

    componentDidMount() {
        const _fetch = () => {
            this.scheduledDownloads = VideoCache.queryScheduledDownloads()
        }

        this.handler = setInterval(_fetch, 500)
    }

    componentWillUnmount() {
        if (this.handler) {
            clearInterval(this.handler)
        }
        this.handler = null
    }

    render() {
        const { scheduledDownloads, usage, quota } = this
        const { rt } = this.props

        const cachedMessage = t('scheduledDownloadsCached', { usage, quota })
        return (
            <>
                <h4>{t('Download Activity')}</h4>
                <div>
                    {scheduledDownloads.length === 0 && <div>{t('Nothing waiting to be downloaded.')}</div>}
                    {scheduledDownloads.map((sd) => (
                        <ScheduledDownloadView key={`${sd.videoUrl}-${sd.chunkNum}`} rt={rt} sd={sd} />
                    ))}
                    <div>{this.usage !== '' && this.quota !== '' && cachedMessage}</div>
                </div>
            </>
        )
    }
}

export default observer(DebugScheduledDownloads)
