import { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { avttVersion } from '../components/app/slttAvtt'

// force re-caching whenever avttVersion changes
const BIBLE_PERICOPES_PATH = `${process.env.REACT_APP_RESOURCE_URL}/public/SRV/exegeticalResources/pericopes.json?avttVersion=${avttVersion}`

type PericopeType = {
    id: string
    title: string
}

type Pericope = {
    title: string
    startRef: string
    endRef: string
}

type BookPericopes = {
    [bookId: string]: Pericope[]
}

export type BiblePericopes = {
    [id: string]: BookPericopes
}

export const pericopesInRef = ({
    pericopes,
    startRef,
    endRef
}: {
    pericopes: Pericope[]
    startRef: string
    endRef: string
}) => {
    const fullEndRef = endRef.length === 6 ? `${endRef}999` : endRef
    return pericopes.filter((pericope) => pericope.startRef >= startRef && pericope.endRef <= fullEndRef)
}

export const useBiblePericopes = () => {
    const { t } = useTranslation()
    const [biblePericopes, setBiblePericopes] = useState<BiblePericopes>()
    const [pericopeTypes, setPericopeTypes] = useState<PericopeType[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)

    useEffect(() => {
        const fetchBiblePericopes = async () => {
            try {
                const response = await fetch(BIBLE_PERICOPES_PATH)

                if (!response.ok) {
                    throw Error(`${response.url}: ${response.statusText}`)
                }

                const pericopes = (await response.json()) as BiblePericopes
                if (pericopes && Object.keys(pericopes).length) {
                    // t('publishedBibles')
                    const types = Object.keys(pericopes).map((id) => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        return { id, title: t(id) }
                    }) as PericopeType[]
                    setPericopeTypes(types)
                    setBiblePericopes(pericopes)
                }
            } catch (err) {
                console.error(err)
                setIsError(true)
            }
            setIsLoading(false)
        }

        fetchBiblePericopes()
    }, [t])

    return { biblePericopes, pericopeTypes, isError, isLoading }
}
