import { FormGroup, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { MediaType } from '../../../types'

interface MediaTypeChooserProps {
    mediaTypes: MediaType[]
    selected: MediaType
    setSelected: (mediaType: MediaType) => void
    enabled: boolean
}

export const MediaTypeChooser = ({ mediaTypes, selected, setSelected, enabled }: MediaTypeChooserProps) => {
    const { t } = useTranslation()
    return (
        <form>
            <FormGroup controlId="media-type-chooser">
                <Form.Label>{t('mediaType')}</Form.Label>
                {mediaTypes.map((mediaType) => (
                    <Form.Check
                        type="radio"
                        id={mediaType}
                        key={mediaType}
                        onChange={() => {
                            setSelected(mediaType)
                        }}
                        checked={selected === mediaType}
                        label={t(mediaType)}
                        disabled={enabled === false}
                    />
                ))}
            </FormGroup>
        </form>
    )
}
