import { ReactNode } from 'react'

import { Dropdown } from 'react-bootstrap'

import './ReviewResponses.css'

type ReviewDropdownOption = {
    key: string
    value: ReactNode
    onSelect: () => void
}

type ReviewDropdownProps = {
    id: string
    toggle: ReactNode
    options: ReviewDropdownOption[]
}

const ReviewDropdown = ({ id, toggle, options }: ReviewDropdownProps) => {
    return (
        <Dropdown id={id}>
            <Dropdown.Toggle className="styled-dropdown-select">{toggle}</Dropdown.Toggle>
            <Dropdown.Menu className="styled-dropdown-select-menu">
                {options.map(({ key, value, onSelect }) => (
                    <Dropdown.Item key={key} onClick={onSelect}>
                        {value}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default ReviewDropdown
