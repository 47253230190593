import React, { useState } from 'react'

import { observer } from 'mobx-react'
import { confirmAlert } from 'react-confirm-alert'
import { useTranslation } from 'react-i18next'
import { SortEnd, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import _ from 'underscore'

import PassageEditor from './PassageEditor'
import PassageView from './PassageView'
import PortionSelector from './PortionSelector'
import { rerankPortionPassages } from './utils'
import { Passage } from '../../models3/Passage'
import { Portion } from '../../models3/Portion'
import { Root } from '../../models3/Root'
import { GenericIconButton } from '../utils/Buttons'
import { displayError, systemError, CustomErrorBoundary } from '../utils/Errors'
import { useHoverDelay } from '../utils/Hooks'
import { DragAndDropIcon } from '../utils/Icons'

import './Passage.css'

const DragHandle = SortableHandle(() => <DragAndDropIcon className="passage-handle" />)

interface ISortableElement {
    rt: Root
    passage: Passage
}

const SortableItem = SortableElement(function (props: ISortableElement) {
    const [copyModalOpen, setCopyModalOpen] = useState(false)
    const [editing, setEditing] = useState(false)
    const [mouseOver, setMouseOver] = useState(false)
    const showEditingIcons = useHoverDelay(mouseOver)

    const { rt, passage } = props
    const { useMobileLayout, username } = rt
    const assigned = passage.assignee === username && passage.task !== 'Finished'

    return (
        <div
            className={`passage passage-list-item ${assigned ? 'passage-assigned' : ''}`}
            data-id={`passage-${props.passage.name}`}
            onMouseEnter={() => setMouseOver(true)}
            onMouseLeave={() => setMouseOver(false)}
        >
            {!useMobileLayout && !editing && rt.iAmTranslator && <DragHandle />}
            {editing && <PassageEditor {...{ rt, passage, setEditing }} />}
            <PassageView {...{ rt, passage, copyModalOpen, setCopyModalOpen, editing, setEditing, showEditingIcons }} />
        </div>
    )
})

interface ISortableContainer {
    rt: Root
    items: Array<Passage>
}

const SortableList = SortableContainer((props: ISortableContainer) => {
    const { t } = useTranslation()
    return (
        <div>
            {props.items.map((passage, index) => (
                <CustomErrorBoundary
                    key={passage._id}
                    fallbackUI={
                        <div
                            className="passage passage-error"
                            data-id={`passage-${passage.name}`}
                            title={t('Something went wrong while displaying this passage')}
                        >
                            <b>{`${passage.name}???`}</b>
                        </div>
                    }
                    fallbackAction={() => {
                        props.rt.setPassage(null)
                    }}
                >
                    <SortableItem disabled={!props.rt.iAmTranslator} index={index} rt={props.rt} passage={passage} />
                </CustomErrorBoundary>
            ))}
        </div>
    )
})

interface IPassageList {
    rt: Root
}

const PassageList = observer(({ rt }: IPassageList) => {
    const { t } = useTranslation()
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)

    const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { project } = rt

        const _id = event.target.value
        const portions: Array<Portion> = (project.portions && project.portions) || []
        const portion = _.findWhere(portions, { _id })
        if (portion) {
            rt.setPortion(portion).catch(displayError)
        }
    }

    const onSortEnd = (indices: SortEnd) => {
        const { portion } = rt
        const { oldIndex, newIndex } = indices

        if (!portion) {
            return
        }

        portion.movePassage(portion.passages[oldIndex]._id, newIndex).catch(systemError)
    }

    const confirmSort = (onConfirm: () => void) => {
        confirmAlert({
            title: t('reorderPassagesTitle'),
            message: t('reorderPassagesMessage'),
            buttons: [
                {
                    label: t('No'),
                    onClick: () => {}
                },
                {
                    label: t('Yes'),
                    onClick: onConfirm
                }
            ]
        })
    }

    const { project, portion, useMobileLayout, iAmTranslator, iAmAdmin } = rt

    const items = (portion && portion.passages) || []
    /* eslint-disable-next-line @typescript-eslint/no-unused-expressions */
    items.length // required to access length or list appears empty in view!!! why?

    const initial = (rt.portion && rt.portion._id) || ''

    return (
        <div className="passages">
            {isEditModalOpen && <PassageEditor rt={rt} setEditing={setIsEditModalOpen} />}
            <PortionSelector onChange={onChange} portions={project.portions} selectedValue={initial} />
            <SortableList rt={rt} items={items} onSortEnd={onSortEnd} distance={5} useDragHandle />
            <div className="passage-list-buttons">
                {portion && !useMobileLayout && iAmTranslator && (
                    <GenericIconButton
                        enabled
                        onClick={() => setIsEditModalOpen(true)}
                        className="passage-list-button"
                        tooltip={t('addSinglePassage')}
                        iconName="fa-square-plus"
                        iconType="far"
                    />
                )}
                {iAmAdmin && portion && (
                    <GenericIconButton
                        enabled
                        onClick={() => confirmSort(() => rerankPortionPassages(portion))}
                        className="passage-list-button"
                        tooltip={t('reorderPassagesByReference')}
                        iconName="fa-arrow-down-1-9"
                        iconType="fas"
                    />
                )}
            </div>
        </div>
    )
})

export default PassageList
