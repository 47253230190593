/* eslint-disable @typescript-eslint/no-non-null-assertion */

// This component plays a PassageVideo by knowing about the main video
// and its patch videos and switching between them as the absolute time
// advances.
//
// This component should not know anything about what environment it is in
// so it should NOT contain any references to Root.
//
// The routine play(), stop(), setCurrentTime() are directly invoked by
// the parent in order to initiate those operations.

import { Component } from 'react'

import { observable } from 'mobx'
import { observer } from 'mobx-react'

import VideoPlayerCore from './VideoPlayerCore'
import { ViewableVideoCollection } from './ViewableVideoCollection'
import { Passage } from '../../models3/Passage'
import { PassageSegment } from '../../models3/PassageSegment'
import { PassageVideo } from '../../models3/PassageVideo'
import { displayError } from '../utils/Errors'
import { fmt } from '../utils/Fmt'
import { isCloseEnough } from '../utils/Helpers'

import './Video.css'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:VideoPlayer2')

const aLittleBit = 0.05

const almostDone = (a: number, b: number) => a + aLittleBit >= b

const getNextNonHiddenSegment = (segments: PassageSegment[], index: number) => {
    let i = index + 1
    while (i < segments.length) {
        if (segments[i].isVisible()) {
            return { segment: segments[i], index: i }
        }
        i++
    }
    return { segment: undefined, index: i }
}

interface IVideoPlayer {
    passage: Passage
    video: PassageVideo
    // This may be the base video or a VisiblePassageVideo built to contain only
    // the patches up to a certain point in time.
    vvc: ViewableVideoCollection
    // It is the responsibility of the parent component to setup() this
    // and initiate a download()

    initialTime?: number
    playbackRate: number
    autoPlay?: boolean
    disablePlay?: boolean // ignore requests to play
    className?: string
    onEnded?: () => void
    onTick?: (currentTime: number) => void
    onPlayingStatus?: (playing: boolean) => void
    onSegmentChange?: (segmentIndex: number) => void
    onCanPlayThrough?: (video: PassageVideo, duration: number) => void
    disableOnClick?: boolean // ignore clicks

    // Inform parent that user has requested a play or a stop.
    play: (startTime: number, endingTime?: number) => void

    setVideoWidth?: (width: number) => void // inform parent of change of video width
    muted?: boolean
}

@observer
export default class VideoPlayer extends Component<IVideoPlayer> {
    // PassageVideo/segmgmentIndex corresponding to the segment that is currently playing
    @observable segmentIndex = 0

    @observable actualSegment: PassageSegment | null = null
    // Currently selected segment.
    // "actual" means that if this segment is a patch, we store the segment for the patch
    // video rather than the segment from the base video.

    @observable playing = false

    /* Normally we want to notify our parent when the video changes status between
     * playing and not playing so that it can adjust its controls to reflect this.
     * However sometimes this player has to temporarily stop one video and start another
     * video to switch from one patch to another. When we are doing that we don't
     * want our parent to update since the change is only temporary and should not
     * affect the UI.
     */
    disableOnPlayingStatus = false

    currentTime = 0

    endingTime?: number = undefined

    ended = false

    videoInitialized = ''

    vpc: VideoPlayerCore | null = null
    // VideoPlayerCore component manages all the videos in the PassageVideo and plays
    // them as directed by the VideoPlayer2.

    constructor(props: IVideoPlayer) {
        super(props)
        log('constructor')

        const { autoPlay } = this.props
        if (autoPlay) this.doInitialPlay().catch(displayError)
    }

    public setCurrentTime(time: number, _skipTinySegments?: boolean) {
        const { video, passage } = this.props
        const { playing } = this

        log('setCurrentTime (and stop)', fmt({ time }))
        if (this.vpcNotSet('setCurrentTime')) return

        if (playing) {
            this.vpc!.stop()
        }

        const segments = video.getAllBaseSegments()
        let _segmentIndex = video.timeToSegmentIndex(time)
        if (_skipTinySegments) {
            const _segmentIndex2 = this.skipTinySegments(_segmentIndex)
            if (_segmentIndex2 !== _segmentIndex) {
                time = segments[_segmentIndex2].time
                _segmentIndex = _segmentIndex2
            }
        }

        this.selectSegment(_segmentIndex)
        this.currentTime = time

        const actualVideo = segments[_segmentIndex].patchVideo(passage) || video

        const { actualSegment } = this
        this.vpc!.setVideo(actualVideo, actualSegment!.timeToPosition(time))
    }

    doInitialPlay = async () => {
        const { video, vvc, play } = this.props

        const endingTime = video.computedDuration
        log('doInitialPlay', fmt({ video, endingTime }))

        this.currentTime = 0

        await vvc.waitUntilDownloaded()

        this.setCurrentTime(0)

        // Call parent to start this video (and maybe other windows) playing
        play(0, endingTime)
    }

    /* This is the central function of this component.
     * When a video is playing this called on every tick of the clock.
     * It must respond to segment boundary transitions and change which video
     * is playing when it hits a patch segment.
     *
     * Remember that in SLTT a 'position' is a time offset relative to one specific
     * video. A 'time' is an absolute time offset withing the entire collection of
     * videos for a specific draft of a passage.
     */
    onTick = (position: number /* position in this video in seconds */) => {
        const { onTick, video } = this.props
        const { endingTime, actualSegment, segmentIndex } = this
        if (actualSegment === null) {
            log('###onTick aborted, no segment')
            return
        }

        const time = actualSegment.positionToTime(position)
        const segmentEndTime = actualSegment.positionToTime(actualSegment.endPosition)

        // log('onTick', fmt({ segmentIndex, position, time, segmentEndTime, endingTime}))

        if (endingTime !== undefined && almostDone(time, endingTime)) {
            this.stop()
            return
        }

        if (almostDone(time, segmentEndTime) && this.segmentHasGapAtEnd(segmentIndex)) {
            this.playVideoForNextSegment()
            return
        }

        this.currentTime = time
        onTick?.(time)

        const _segmentIndex = video.timeToSegmentIndex(time)
        if (_segmentIndex !== segmentIndex) {
            this.selectSegment(_segmentIndex)
        }
    }

    onCanPlayThrough = (_video: PassageVideo, duration: number) => {
        const { video, onCanPlayThrough, initialTime } = this.props
        const { videoInitialized } = this

        // log('onCanPlayThrough', fmt({ _video, duration, video, videoInitialized}))

        /**
         * Videos made with the webcam can take a few ms before the image stabilizes.
         * When the main video is first loaded, seek a short time into the video to avoid
         * showing the image from the video before the camera has stabilized.
         */
        if (_video._id === video._id && videoInitialized !== video._id) {
            log('onCanPlayThrough initialize', fmt({ _video }))

            const time = initialTime || 0.05
            this.setCurrentTime(time)
            this.videoInitialized = video._id
        }

        onCanPlayThrough?.(video, duration)
    }

    onPlayingStatus = (playing: boolean) => {
        const { onPlayingStatus } = this.props
        const { disableOnPlayingStatus } = this

        this.playing = playing
        if (!disableOnPlayingStatus && onPlayingStatus) {
            onPlayingStatus(playing)
        }
    }

    onStop = () => {
        const { onPlayingStatus, onEnded } = this.props
        const { disableOnPlayingStatus } = this

        if (this.ended) {
            setTimeout(() => onEnded?.(), 500)
        }
        if (!disableOnPlayingStatus && onPlayingStatus) {
            onPlayingStatus(false)
        }

        this.playing = false
    }

    /* Determine if it is necessary to at end of segment to stop playing
     * the current video and go to a different video or a different time.
     */
    segmentHasGapAtEnd = (segmentIndex: number) => {
        const { video, passage } = this.props

        const segments = video.getAllBaseSegments()

        if (segmentIndex + 1 >= segments.length) return false

        const seg1 = segments[segmentIndex]
        const { segment: seg2 } = getNextNonHiddenSegment(segments, segmentIndex)
        if (!seg1 || !seg2) return true

        const video1 = seg1.patchVideo(passage) || video
        const video2 = seg2.patchVideo(passage) || video

        if (video1 !== video2 || !isCloseEnough(seg1.endPosition, seg2.position, aLittleBit)) {
            log('segmentHasGapAtEnd', fmt({ seg1, seg2 }))
            return true
        }

        return false
    }

    playVideoForNextSegment() {
        const { video } = this.props
        const segments = video.getAllBaseSegments()
        const { segment, index: segmentIndex } = getNextNonHiddenSegment(segments, this.segmentIndex)

        log(
            'playVideoForNextSegment',
            fmt({
                segment: segment || '### NO segment',
                time: segment?.time,
                length: segments.length,
                segmentIndex
            })
        )

        if (segmentIndex >= segments.length || !segment) {
            this.stop()
            return
        }

        this.disableOnPlayingStatus = true // don't update toolbar play status while switching videos

        this.setCurrentTime(segment.time, true)

        if (this.vpcNotSet('playNextSegment')) return
        this.vpc!.play()
            .then(() => {
                this.disableOnPlayingStatus = false
            })
            .catch((err) => {
                this.disableOnPlayingStatus = false
                displayError(err)
            })
    }

    // --------------------------
    // ---- Called by parent ----
    // --------------------------

    // startTime = null, means play from current position
    // endTime = null, means play through until end
    // Called externally.
    // eslint-disable-next-line react/no-unused-class-component-methods
    public async play(startTime: number, endingTime?: number) {
        const { disablePlay, video } = this.props
        const { playing, currentTime } = this
        console.clear()
        log('play', fmt({ currentTime, startTime, endingTime, playing }))

        // Once playing has started we don't want to do any additional initialization
        // because it will disrupt the play.
        this.videoInitialized = video._id

        if (this.vpcNotSet('play')) return

        if (disablePlay) {
            log('play [disablePlay=true]')
            return
        }

        this.disableOnPlayingStatus = false
        this.ended = false

        this.endingTime = endingTime ?? video.computedDuration
        log(
            'play',
            fmt({
                video: JSON.stringify(video.dbgSegments(), null, 4),
                startTime,
                endingTime: this.endingTime,
                currentTime: this.currentTime,
                playing
            })
        )

        // If very close to end, go back to start
        if (startTime + 0.1 >= this.endingTime) {
            startTime = 0
        }

        this.setCurrentTime(startTime, true)

        log('!!!play')
        await this.vpc!.play()
    }

    public stop() {
        if (this.vpcNotSet('stop')) return

        log(`stop`)
        this.ended = true
        this.vpc!.stop()
    }

    // May be invoked internally or externally.
    // Stops video from playing.
    // Sets the currently active video as well as the current time in that video
    skipTinySegments(segmentIndex: number) {
        const { video } = this.props
        const segments = video.getAllBaseSegments()

        while (segmentIndex + 1 < segments.length) {
            const { segment: nextNonHiddenSegment } = getNextNonHiddenSegment(segments, segmentIndex)
            if (!nextNonHiddenSegment) {
                // This is the last non-hidden segment
                break
            }

            if (nextNonHiddenSegment.time - segments[segmentIndex].time >= 3 * aLittleBit) {
                break
            }

            segmentIndex += 1
            log('skipTinySegment')
        }

        return segmentIndex
    }

    // --- local utility functions
    vpcNotSet(message?: string) {
        if (this.vpc) return false

        log('###checkVpc failed', message)
        return true
    }

    selectSegment(segmentIndex: number) {
        const { passage, video, onSegmentChange } = this.props

        const segments = video.getAllBaseSegments()
        let segment = segments[segmentIndex]

        if (!segment) {
            log('### selectSegment failed', fmt({ video, segmentIndex }))
            const { segment: firstNonHiddenSegment, index } = getNextNonHiddenSegment(segments, 0)
            if (!firstNonHiddenSegment) {
                this.stop()
                return
            }

            segment = firstNonHiddenSegment
            segmentIndex = index
        }

        const actualSegment = segment.actualSegment(passage)
        log('selectSegment', fmt({ segmentIndex, actualSegment, position: actualSegment.position }))

        this.actualSegment = actualSegment
        this.segmentIndex = segmentIndex
        onSegmentChange?.(segmentIndex)
    }

    render() {
        const { vvc, className, children, playbackRate, setVideoWidth, muted } = this.props
        // log('render', fmt({actualSegment: this.actualSegment}))

        const _className = `video-player${className ? ` ${className}` : ''}`

        return (
            <div className={_className}>
                <VideoPlayerCore
                    className="video-player-video-video"
                    vvc={vvc}
                    playbackRate={playbackRate}
                    ref={(vpc) => {
                        this.vpc = vpc
                    }}
                    onStop={this.onStop}
                    onTick={this.onTick}
                    onPlayingStatus={this.onPlayingStatus}
                    setVideoWidth={setVideoWidth}
                    onCanPlayThrough={this.onCanPlayThrough}
                    muted={muted}
                >
                    {children}
                </VideoPlayerCore>
            </div>
        )
    }
}
