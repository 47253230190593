import { observable } from 'mobx'

import { DBObject } from './DBObject'

export class ReviewProjectGeneralQuestion extends DBObject {
    @observable text = ''

    @observable fileName = ''

    @observable isActive = true

    private static model = 22

    toDocument() {
        const { text, fileName, rank, isActive } = this
        return this._toDocument({ text, fileName, isActive, model: ReviewProjectGeneralQuestion.model, rank })
    }

    // This object is used in the AVTT review app. To make things easier, we save the entire
    // state to a document any time the state changes, instead of just the parts that changed.
    async setValues(text: string, fileName: string) {
        if (text === this.text && fileName === this.fileName) {
            return
        }
        const document = this.toDocument()
        document.text = text
        document.fileName = fileName
        await this.db.put(document)
    }

    async setIsActive(isActive: boolean) {
        if (isActive === this.isActive) {
            return
        }
        const doc = this.toDocument()
        doc.isActive = isActive
        await this.db.put(doc)
    }

    async setRank(rankNumber: number) {
        const doc = this.toDocument()
        doc.rank = DBObject.numberToRank(rankNumber)
        await this.db.put(doc)
    }
}
