// Singleton AppRoot object.
// When initializingStarted contains all the projects for current user.
import { changeLanguage } from 'i18next'
import { observable } from 'mobx'

import { _LevelupDB } from './_LevelupDB'
import API from './API'
import { MemberRole, memberExistsWithRole } from './Member'
import { Passage } from './Passage'
import { Portion } from './Portion'
import { Project } from './Project'
import { ProjectEntity } from './ProjectEntity'
import { Root } from './Root'
import { normalizeUsername } from './Utils'
import { VideoCache } from './VideoCache'
import { LocalStorageKeys } from '../components/app/slttAvtt'
import { displayError, systemError } from '../components/utils/Errors'
import { s } from '../components/utils/Fmt'
import { MimeType } from '../types'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:AppRoot')

export type AuthType = 'cognito' | 'legacy'
export const getLocalStorageAuthType = () =>
    (localStorage.getItem(LocalStorageKeys.SLTT_AUTH_TYPE) ?? 'legacy') as AuthType
export const getLocalStorageUsername = () =>
    normalizeUsername(localStorage.getItem(LocalStorageKeys.SLTT_USERNAME) ?? '')
export const getLocalStorageIdToken = () => localStorage.getItem(LocalStorageKeys.SLTT_ID_TOKEN) ?? ''
export const getLocalStorageCurrentProjectName = () =>
    localStorage.getItem(LocalStorageKeys.SLTT_CURRENT_PROJECT_NAME) ?? ''

export class AppRoot {
    @observable rts: Root[] = []

    @observable rtsMap: Map<string, Root> = new Map()

    @observable initializingStarted = false

    @observable projectsInitialized = false

    @observable auth_type: AuthType = 'legacy'

    @observable id_token = ''

    @observable username = ''

    @observable iAmRoot = false

    @observable currentProjectName = ''

    @observable useMobileLayout = false

    @observable useNarrowWidthLayout = false

    @observable uiLanguageChanging = false

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selectPage: (selection: string) => void

    constructor(selectPage: (selection: string) => void) {
        this.auth_type = getLocalStorageAuthType()
        API.auth_type = this.auth_type

        this.id_token = getLocalStorageIdToken()
        API.id_token = this.id_token

        this.username = getLocalStorageUsername()
        this.currentProjectName = getLocalStorageCurrentProjectName()
        this.selectPage = selectPage
        this.setLocale = this.setLocale.bind(this)
    }

    getRootFromProjectName(projectName: string) {
        if (!this.rtsMap.size) {
            return // no project found, give up
        }

        // If can't find this project, default to 1st project
        let rt = this.rtsMap.get(projectName)
        if (!rt) {
            const { done, value } = this.rtsMap.values().next()
            if (done) {
                return
            }
            rt = value
        }

        return rt
    }

    async setCurrentProject(projectName: string) {
        const rt = this.getRootFromProjectName(projectName)
        if (rt) {
            this.currentProjectName = rt.name
            localStorage.setItem(LocalStorageKeys.SLTT_CURRENT_PROJECT_NAME, this.currentProjectName)
            await this.initializeProject(rt)
        }
        return rt
    }

    get rt(): Root | null {
        if (!this.rtsMap.size) return null

        const rt = this.rtsMap.get(this.currentProjectName)
        if (!rt) return null

        return rt
    }

    // Usage:
    //    window.appRoot.dbg('')
    //    window.appRoot.dbg('n')
    //    window.appRoot.dbg('ns')

    dbg(details?: string) {
        const { rt } = this

        const _dbg: any = {
            passageVideo: rt?.passageVideo?.dbg(rt.passage, details),
            root: rt?.dbg(details)
        }

        const passage = rt?.passage
        if (passage && details?.includes('v')) {
            _dbg.passageVideos = passage.videos.map((video) => video.dbg(passage, details))
        }

        log(`dbg`, JSON.stringify(_dbg, null, 3))
    }

    signOut() {
        this.setUser('', '')
        location.reload() // fix memory leaks where we continue to have a reference to old projects
    }

    setUser(username: string, id_token: string, auth_type: AuthType = 'legacy') {
        log('setUser', username)
        this.username = normalizeUsername(username)
        localStorage.setItem(LocalStorageKeys.SLTT_USERNAME, username)

        this.auth_type = auth_type
        API.auth_type = this.auth_type
        localStorage.setItem(LocalStorageKeys.SLTT_AUTH_TYPE, auth_type)

        this.setUserToken(id_token)
    }

    setUserToken(id_token: string) {
        log('setUserToken', id_token)
        this.id_token = id_token
        API.id_token = this.id_token
        localStorage.setItem(LocalStorageKeys.SLTT_ID_TOKEN, id_token)
    }

    async reinitializeProjects() {
        log('reinitializeProjects')
        this.initializingStarted = false
        this.rts = []
        this.rtsMap = new Map()

        try {
            await this.initializeProjects()
        } catch (err) {
            systemError(err)
        }
    }

    // Initialize projects for this user.
    // Automatically rerun by autorun whenever id_token changes.
    async initializeProjects() {
        // Prepare video cache to receive requests to upload/download videos
        await VideoCache.initialize()

        const { id_token, username } = this
        log(`(---start 1) username=${username} id_token=${id_token && id_token.slice(0, 60)}`)

        if (!id_token || !username) {
            log(`((---start 1 ERR)) no id_token, done`)
            this.clear() // If we no longer have a token or username, clear the projects
            return
        }

        if (this.initializingStarted) return

        this.initializingStarted = true
        this.projectsInitialized = false

        try {
            log(`((---start 2)) get project names user can access`)

            const authorizedProjects = await API.getAuthorizedProjects()
            log(`((---start 2 done)) projects`, authorizedProjects)

            this.iAmRoot = authorizedProjects.iAmRoot

            this.rts = authorizedProjects.projects.map((projectEntity) => this.createRoot(projectEntity))
            this.rtsMap = new Map<string, Root>(this.rts.map((root) => [root.name, root]))
            log(`((---start done done)) rts (${this.rts.length}) created`)
        } catch (error) {
            log(`((---start 2 ERR))`, error)
            displayError(error)
        }

        this.currentProjectName = this.getCurrentProjectName()
        this.projectsInitialized = true

        const rt = await this.setCurrentProject(this.currentProjectName)

        // Initialize all projects that have unsynced data in them. Don't wait for them to
        // finish initializing.
        Object.keys(localStorage)
            .filter((entry) => entry.endsWith(LocalStorageKeys.UNSYNCED_DATA_EXISTS_TAG))
            .forEach((entry) => {
                const projectName = entry.split(LocalStorageKeys.UNSYNCED_DATA_EXISTS_TAG)[0]
                const projectRoot = this.getRootFromProjectName(projectName)
                if (projectRoot) {
                    this.initializeProject(projectRoot)
                }
            })

        return rt
    }

    async initializeProject(rt: Root) {
        // group must be set up first, because this project needs access to it when setting up
        if (rt.group) {
            const groupRt = this.rtsMap.get(rt.group)
            if (groupRt) {
                await groupRt.initialize()
            }
        }

        await rt.initialize() // Load Db entries, this is a NOP if it has already been done
    }

    getCurrentProjectName() {
        const { origin, pathname, hash } = window.location
        const nonHashURL = origin.concat(pathname).concat(hash.slice(2))
        const url = new URL(nonHashURL)
        const searchParams = new URLSearchParams(url.search)
        const project = searchParams.get('project')
        return project && this.rts.find((r) => r.name === project) ? project : getLocalStorageCurrentProjectName()
    }

    createRoot(projectEntity: ProjectEntity) {
        const { username, id_token, iAmRoot, selectPage, useMobileLayout, useNarrowWidthLayout } = this
        log(`[${projectEntity.project}] createRoot (${username})`)

        const db = new _LevelupDB(projectEntity.project, username)

        // Inject VideoCache methods
        Project.copyFileToVideoCache = VideoCache.copyFileToVideoCache
        Project.videoCacheAcceptor = VideoCache.accept

        const projectObject = new Project(projectEntity.project, db, projectEntity.displayName)
        const rt = new Root({
            project: projectObject,
            iAmRoot,
            group: projectEntity.group,
            groupProjects: projectEntity.groupProjects,
            username,
            id_token,
            selectPage
        })
        rt.useMobileLayout = useMobileLayout
        rt.useNarrowWidthLayout = useNarrowWidthLayout
        return rt
    }

    getProjectsByRole = ({ requiredRole, excludeProject }: { requiredRole: MemberRole; excludeProject?: string }) =>
        this.rts
            .filter(
                (rt) =>
                    rt.project.name !== excludeProject &&
                    (rt.iAmRoot ||
                        memberExistsWithRole({ members: rt.project.members, email: this.username, requiredRole }))
            )
            .map((rt) => rt.project)
            .sort((a, b) => a.getFormattedDisplayName().localeCompare(b.getFormattedDisplayName()))

    setLocale(locale: string) {
        changeLanguage(locale)

        this.uiLanguageChanging = true
        setTimeout(() => {
            this.uiLanguageChanging = false
        }, 2000)
    }

    clear() {
        const { rts } = this
        log('clear', rts.length)

        // rts.forEach(rt => rt.project.db.cancel())

        if (rts.length) this.rts = []

        this.initializingStarted = false
    }

    /**
     * Write entry to database indicating that file upload is complete.
     * This accepting this on other systems will trigger the download.
     * @param _id
     */
    async uploadDone(url: string) {
        const match = /(.*?)\/(.*)-\d+$/.exec(url)
        if (!match) {
            systemError(`uploadDone[${url}]: bad url`)
            return
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_unused, projectName, _id] = match

        log(`uploadDone ${projectName}, ${_id}`)

        // let rt = this.rts.find(rt => rt.name === projectName)
        // if (!rt) {
        //     systemError(`uploadDone[${_id}]: no matching project`)
        //     return
        // }

        // try {
        //     await rt.project.db.put({_id: `${_id}@uploaded`})
        // } catch (error) {
        //     systemError(`uploadDone[${_id}]: ${error.toString}`)
        // }
    }

    makeProgressSnapshot() {
        const progressSnapshot = async () => {
            const results: string[] = []

            for (const rt of this.rts) {
                const progress = await this.makeProjectProgressSnapshot(rt)
                log(`progressSnapshot ${rt.name}, ${progress.videos}, ${progress.notes}`)
                results.push(`${rt.name}\t${progress.videos}\t${progress.notes}`)
            }

            log('results', results.join('\n'))
        }

        progressSnapshot().catch((error) => log('FAILED!!!', error))
    }

    async makeProjectProgressSnapshot(rt: Root) {
        const { name } = rt.project
        // if (name !== 'TEST1') return

        log('makeProgressSnapshot initializing', name)
        await rt.initialize()

        log('makeS3ProjectSnapshot syncing')
        await rt.project.db.doSync()

        let videos = 0
        let notes = 0

        for (const portion of rt.project.portions) {
            for (const passage of portion.passages) {
                for (const video of passage.videos) {
                    ++videos
                    notes += video.notes.length
                }
            }
        }

        return { videos, notes }
    }

    // Usage:
    //    window.appRoot.makeS3Snapshot()

    makeS3Snapshot() {
        const _makeS3Snapshot = async () => {
            for (const rt of this.rts) {
                log('makeS3ProjectSnapshot initializing', name)
                await rt.initialize()
                await rt.project.db.doSync()
            }

            log('all rts initialized')

            for (const rt of this.rts) {
                await this.makeS3ProjectSnapshot(rt)
            }
        }

        _makeS3Snapshot().catch((error) => log('FAILED!!!', error))
    }

    async makeS3ProjectSnapshot(rt: Root) {
        const { name } = rt.project
        log('makeS3ProjectSnapshot', name)

        this.makeProjectTopLevelSnapshot(rt)

        for (const portion of rt.project.portions) {
            log('makeS3ProjectSnapshot portion', portion.name)

            for (const passage of portion.passages) {
                await this.makeS3PassageSnapshot(rt, portion, passage)
            }
        }
    }

    async makeProjectTopLevelSnapshot(rt: Root) {
        const snapshot = rt.project.toSnapshot()
        const blob = new Blob([JSON.stringify(snapshot)], { type: MimeType.JSON })
        const path = `${rt.project.name}/snapshot.json`
        // log(`makeProjectTopLevelSnapshot`, JSON.stringify(snapshot, null, 4))
        log(`makeProjectTopLevelSnapshot`)

        await API.pushBlob(rt.project.name, path, 1, blob)
    }

    async makeS3PassageSnapshot(rt: Root, portion: Portion, passage: Passage) {
        const snapshot = passage.toSnapshot()
        const blob = new Blob([JSON.stringify(snapshot)], { type: MimeType.JSON })
        const path = `${rt.project.name}/${passage._id}/snapshot.json`
        log(`makeS3PassageSnapshot ${passage.name}[${JSON.stringify(snapshot).length}]`)

        await API.pushBlob(rt.project.name, path, 1, blob)
    }

    async initializeAll() {
        for (const rt of this.rts) {
            const { project } = rt
            if (project.name === '_log_') continue

            log('===initialize', project.name)
            await rt.initialize()
        }
    }

    async countAll() {
        await this.initializeAll()
        console.table(this.rts.map((rt) => rt.project.countAll()))
    }

    async fixDupsAll() {
        const dryRun = false

        await this.initializeAll()

        for (const rt of this.rts) {
            const {
                name,
                project: { plans }
            } = rt
            if (name === '_log_') continue

            log('===PROJECT', name)
            if (!plans.length) {
                continue
            }
            const plan = plans[0]
            if (plan.checkIds()) {
                // duplicates found
                await plan.fixIds(dryRun)
            }
        }
    }

    // Stub routine for code manipulating model
    // Invoke: window.appRoot.c()
    c() {
        async function _c(rt: Root | null) {
            log(s(rt?.project.messages.map((m) => m.dbg())))
        }

        _c(this.rt).catch(log)
    }
}
