import { observable } from 'mobx'

import { DBObject } from './DBObject'

export class ReviewProjectPassageRecording extends DBObject {
    @observable title = ''

    @observable passageRecordingId = ''

    @observable fileName = ''

    @observable duration = 0

    @observable mimeType = ''

    @observable reference = ''

    @observable transcription = ''

    @observable isActive = true

    private static model = 22

    toDocument() {
        const { title, passageRecordingId, fileName, duration, mimeType, reference, transcription, isActive, rank } =
            this
        return this._toDocument({
            title,
            passageRecordingId,
            fileName,
            duration,
            mimeType,
            reference,
            transcription,
            isActive,
            rank,
            model: ReviewProjectPassageRecording.model
        })
    }

    async setIsActive(isActive: boolean) {
        if (isActive === this.isActive) {
            return
        }
        const doc = this.toDocument()
        doc.isActive = isActive
        await this.db.put(doc)
    }
}
