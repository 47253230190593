/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/no-cycle */

import { useState, useEffect } from 'react'

import { Authenticator, useTheme, Heading, View, Text } from '@aws-amplify/ui-react'
import { Amplify, Auth, I18n } from 'aws-amplify'
import { useTranslation } from 'react-i18next'

import { canAccessInternet } from './OnlineStatusContext'
import SLTool from './SLTool'
import { avttVersion, websiteShortName } from './slttAvtt'
import { getLocalStorageAuthType, getLocalStorageUsername, getLocalStorageIdToken } from '../../models3/AppRoot'
import { HelpLink } from '../utils/Icons'

// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css'
import './SLToolWithCognito.css'

const {
    REACT_APP_AWS_REGION = '',
    REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID = '',
    REACT_APP_AWS_USER_POOLS_ID = '',
    REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID = ''
} = process.env

Amplify.configure({
    aws_cognito_identity_pool_id: REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    aws_cognito_region: REACT_APP_AWS_REGION,
    aws_project_region: REACT_APP_AWS_REGION,
    aws_user_pools_id: REACT_APP_AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
})

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:SLToolWithCognito')

const SLToolWithCognito = () => {
    const { t } = useTranslation()
    const [skipCognito, setSkipCognito] = useState(false)
    const [isGettingCurrentUser, setIsGettingCurrentUser] = useState(false)

    I18n.putVocabulariesForLanguage('en', {
        // Sign in form
        'Forgot your password?': t('signInForgotYourPassword?'),
        Password: t('signInPassword'),
        'Sign in': t('signIn'),
        Email: t('signInUsername'),
        // Send code form
        'Reset your password': t('signInResetYourPassword'),
        'Enter your email': t('signInEnterYourEmail'),
        'Send code': t('signInSendCode'),
        'Back to Sign In': t('signInBack'),
        Sending: t('signInSending'),
        // Reset password form
        Code: t('signInCode'),
        'New Password': t('signInNewPassword'),
        'Confirm Password': t('signInConfirmPassword'),
        Submit: t('signInSubmit'),
        'Resend Code': t('signInResendCode'),
        'Invalid verification code provided, please try again.': t('signInInvalidCode'),
        'Password does not conform to policy: Password not long enough': t('signInPasswordNotLongEnough'),
        'Your passwords must match': t('signInPasswordsMustMatch')
    })

    useEffect(() => {
        const getCurrentUser = async (): Promise<void> => {
            setIsGettingCurrentUser(true)

            try {
                await Auth.currentAuthenticatedUser()
            } catch (error) {
                log('Error while getting currentAuthenticatedUser', error)

                if (error === 'The user is not authenticated') {
                    const authType = getLocalStorageAuthType()
                    const username = getLocalStorageUsername()
                    const idToken = getLocalStorageIdToken()

                    // use canAccessInternet instead of useOnlineStatus to prevent reloading
                    if (
                        username &&
                        idToken &&
                        authType === 'cognito' &&
                        !(await canAccessInternet('Cognito getCurrentUser'))
                    ) {
                        log('Previously validated user is offline so, keep going')
                        setSkipCognito(true)
                    }
                }
            }

            setIsGettingCurrentUser(false)
        }

        getCurrentUser()
    }, [])

    if (isGettingCurrentUser) {
        // We should be able to return a string, but typescript doesn't think so. Wrap in a
        // fragment as a workaround. See
        // https://stackoverflow.com/questions/65708107/how-to-return-string-or-jsx-element-in-typescript-react-component
        return <>Authenticating user...</>
    }

    if (skipCognito) {
        return <SLTool authType="cognito" />
    }

    const components = {
        Header() {
            const { tokens } = useTheme()
            return (
                <View textAlign="center" padding={tokens.space.large}>
                    <Heading level={1} translate="no">
                        {websiteShortName} {avttVersion}
                    </Heading>
                </View>
            )
        },
        Footer() {
            const { tokens } = useTheme()
            return (
                <View textAlign="center" padding={tokens.space.large}>
                    <Text color={`${tokens.colors.neutral['80']}`} translate="no">
                        <HelpLink className="fa-2x" tooltip={t('Get help')} />
                    </Text>
                </View>
            )
        },
        SignIn: {
            Header() {
                const { tokens } = useTheme()
                return (
                    <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={4} translate="no">
                        {t('signInToYourProject')}
                    </Heading>
                )
            }
        }
    }

    return (
        <Authenticator loginMechanisms={['email']} components={components} hideSignUp>
            {({ user }) => {
                // This component unmounts and remounts when the outer component rerenders
                const username = user?.attributes?.email
                const idToken = user?.getSignInUserSession()?.getIdToken().getJwtToken()
                return <SLTool username={username} idToken={idToken} authType="cognito" />
            }}
        </Authenticator>
    )
}

export default SLToolWithCognito
