import { observable } from 'mobx'

import ImageCollection from './ImageCollection'
import { ImageMetadata } from './ImageMetadata'
import { RefRange } from './RefRange'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:ImagesRoot')

/**
 * Common data shared by all controls used in the right hand side images pane
 */

export class ImagesRoot {
    @observable searchRefs: RefRange[] = []

    @observable images: ImageMetadata[] = []

    imageCollection = new ImageCollection()

    setSearchRefs = async (searchRefs: RefRange[]) => {
        log('ImagesRoot setSearchRefs', JSON.stringify(searchRefs))
        this.searchRefs = searchRefs

        try {
            this.images = await this.imageCollection.fetchImages(searchRefs)
            log('ImagesRoot setSearchRefs found=', this.images.length)
        } catch (error) {
            this.images = []
            console.log('### cannot fetch images', error)
        }
    }
}
