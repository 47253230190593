import { useEffect, useState } from 'react'

import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { LoadingIcon } from './Icons'
import { AppRoot } from '../../models3/AppRoot'
import { Passage } from '../../models3/Passage'
import { PassageNote } from '../../models3/PassageNote'
import { PassageSegment } from '../../models3/PassageSegment'
import { PassageVideo } from '../../models3/PassageVideo'
import { Portion } from '../../models3/Portion'
import { Root } from '../../models3/Root'
import { VideoCache } from '../../models3/VideoCache'
import { VideoCacheRecord } from '../../models3/VideoCacheRecord'
import { useAppRoot } from '../app/RootContext'
import { VisibleDocument, getAllDocumentsForPassage } from '../passages/PassageDocuments'

import './MissingRecordings.css'

export interface MissingRecording {
    portion: Portion
    passage: Passage
    missingSegments: {
        recordings: number[]
        backTranslationRecordings: number[]
    }
    missingPassageDocuments: VisibleDocument[]
    hasMissingNoteRecordings: boolean
}

const recordingCached = (url: string, blobIds: string[]) => {
    const videoCacheRecord = VideoCache.getVideoCacheRecord(url)
    const baseUrl = `${VideoCacheRecord.baseUrl(videoCacheRecord._id)}-`
    const count = blobIds.filter((result) => result.startsWith(baseUrl)).length
    return count === videoCacheRecord.totalBlobs
}

const getMissingSegments = ({
    passage,
    passageVideo,
    blobIds
}: {
    passage: Passage
    passageVideo: PassageVideo
    blobIds: string[]
}) => {
    const missingSegments = []

    if (!recordingCached(passageVideo.url, blobIds)) {
        for (const [index, segment] of passageVideo.getVisibleBaseSegments().entries()) {
            if (!segment.isPatched) {
                missingSegments.push(index)
            }
        }
    }

    for (const { patchVideo, nonHiddenSegmentIndex } of passageVideo.patchVideos(passage, { onlyOnTop: true })) {
        if (!recordingCached(patchVideo.url, blobIds)) {
            missingSegments.push(nonHiddenSegmentIndex)
        }
    }
    return missingSegments.sort((a, b) => a - b) // .sort() sorts alphabetically, so we have to write our own sort
}

const getNotesWithMissingRecordings = ({
    passage,
    passageVideo,
    blobIds
}: {
    passage: Passage
    passageVideo: PassageVideo
    blobIds: string[]
}) => {
    const allNotes = passageVideo.getVisibleNotes(passage, true)
    return allNotes.reduce((prev, note) => {
        if (note.items.some((item) => Boolean(item.url) && !recordingCached(item.url, blobIds))) {
            return [...prev, note]
        }
        return prev
    }, [] as PassageNote[])
}

const getSegmentsWithMissingBackTranslationRecordings = ({
    passage,
    passageVideo,
    blobIds
}: {
    passage: Passage
    passageVideo: PassageVideo
    blobIds: string[]
}) => {
    const visibleSegments = passageVideo.visibleSegments(passage)
    const missingSegments = visibleSegments.reduce((prev, segment, index) => {
        if (segment.audioClips.some((clip) => Boolean(clip.url) && !recordingCached(clip.url, blobIds))) {
            return [...prev, index]
        }
        return prev
    }, [] as number[])
    return missingSegments.sort((a, b) => a - b) // .sort() sorts alphabetically, so we have to write our own sort
}

const getMissingPassageResources = ({
    passage,
    blobIds,
    rt,
    appRoot
}: {
    passage: Passage
    blobIds: string[]
    rt: Root
    appRoot: AppRoot
}) => {
    const missingResources = []
    const allResources = getAllDocumentsForPassage({ passage, rt, appRoot })
    for (const resource of allResources) {
        const { audioUrl, pdfUrl } = resource.document
        const urls = [audioUrl, pdfUrl].filter((url) => url.trim() !== '')
        if (!urls.every((url) => recordingCached(url, blobIds))) {
            missingResources.push(resource)
        }
    }
    return missingResources
}

const getCachedBlobIds = async () => {
    const cachedBlobKeys = await VideoCache.getAllVideoBlobKeys()
    return cachedBlobKeys.map((result) => result.toString())
}

const getMissingPassageRecording = ({
    passage,
    portion,
    blobIds,
    rt,
    appRoot
}: {
    passage: Passage
    portion: Portion
    blobIds: string[]
    rt: Root
    appRoot: AppRoot
}) => {
    const missingPassageDocuments = getMissingPassageResources({ passage, blobIds, rt, appRoot })
    const latestRecording = passage.getDefaultVideo('')
    const recordings = latestRecording ? getMissingSegments({ passage, passageVideo: latestRecording, blobIds }) : []
    const notes = latestRecording
        ? getNotesWithMissingRecordings({ passage, passageVideo: latestRecording, blobIds })
        : []
    const backTranslationRecordings = latestRecording
        ? getSegmentsWithMissingBackTranslationRecordings({ passage, passageVideo: latestRecording, blobIds })
        : []

    if (!recordings.length && !missingPassageDocuments.length && !notes.length && !backTranslationRecordings.length) {
        return []
    }
    return [
        {
            portion,
            passage,
            missingSegments: {
                recordings,
                backTranslationRecordings
            },
            missingPassageDocuments,
            hasMissingNoteRecordings: notes.length > 0
        }
    ]
}

const getMissingPortionRecording = (portion: Portion, blobIds: string[], rt: Root, appRoot: AppRoot) => {
    return portion.passages
        .map((passage) => getMissingPassageRecording({ passage, portion, blobIds, rt, appRoot }))
        .flat()
}

const getMissingRecordingsInPortions = async (portions: Portion[], rt: Root, appRoot: AppRoot) => {
    const blobIds = await getCachedBlobIds()
    return portions.map((portion) => getMissingPortionRecording(portion, blobIds, rt, appRoot)).flat()
}

const requestNoteDownload = (note: PassageNote) => {
    note.items.filter((item) => Boolean(item.url)).forEach((item) => VideoCache.implicitVideoDownload(item.url))
}

const requestSegmentDownload = (segment: PassageSegment) => {
    segment.audioClips.forEach((audioClip) => VideoCache.implicitVideoDownload(audioClip.url))
}

const requestPassageVideoDownload = (video: PassageVideo) => {
    VideoCache.implicitVideoDownload(video.url)
    video.getVisibleBaseSegments().forEach(requestSegmentDownload)
    video.notes.forEach(requestNoteDownload)
}

const requestPassageDownload = (passage: Passage) => {
    for (const document of passage.documents) {
        const urls = [document.audioUrl, document.pdfUrl].filter((url) => url.trim() !== '')
        urls.forEach((url) => VideoCache.implicitVideoDownload(url))
    }

    const latestRecording = passage.getDefaultVideo('')
    if (!latestRecording) {
        return
    }
    const patchVideos = [...latestRecording.patchVideos(passage, { onlyOnTop: true })]
    const allVideos = [latestRecording, ...patchVideos.map((pv) => pv.patchVideo)]
    allVideos.forEach(requestPassageVideoDownload)
}

const requestPortionDownload = (portion: Portion) => {
    portion.passages.forEach(requestPassageDownload)
}

const requestAllPortionsDownload = (portions: Portion[]) => {
    portions.forEach(requestPortionDownload)
}

const requestGroupResourcesDownload = (groupRoot: Root) => {
    for (const portion of groupRoot.project.portions) {
        for (const passage of portion.passages) {
            for (const document of passage.documents) {
                const urls = [document.audioUrl, document.pdfUrl].filter((url) => url.trim() !== '')
                urls.forEach((url) => VideoCache.implicitVideoDownload(url))
            }
        }
    }
}

interface MissingRecordingsTableCoreProps {
    missingRecordings: MissingRecording[]
}

const MissingRecordingsTableCore = ({ missingRecordings }: MissingRecordingsTableCoreProps) => {
    const { t } = useTranslation()
    if (!missingRecordings.length) {
        return <div>{t('missingRecordingsNone')}</div>
    }

    return (
        <Table>
            <thead>
                <tr>
                    <th>{t('Portion')}</th>
                    <th>{t('Passage')}</th>
                    <th>{t('missingRecordingsColumn')}</th>
                    <th>{t('undownloadedBackTranslations')}</th>
                    <th>{t('undownloadedResources')}</th>
                    <th>{t('hasUndownloadedNotes')}</th>
                </tr>
            </thead>
            <tbody>
                {missingRecordings.map((recording) => {
                    return (
                        <tr key={recording.passage._id}>
                            <td>{recording.portion.name}</td>
                            <td>{recording.passage.name}</td>
                            <td>{recording.missingSegments.recordings.map((segment) => segment + 1).join()}</td>
                            <td>
                                {recording.missingSegments.backTranslationRecordings
                                    .map((segment) => segment + 1)
                                    .join()}
                            </td>
                            <td>
                                <ul className="undownloaded-resources-list">
                                    {recording.missingPassageDocuments.map((doc) => (
                                        <li key={doc.document._id}>
                                            {doc.group ? `(${t('Group')}) ` : ''}
                                            {doc.document.title}
                                        </li>
                                    ))}
                                </ul>
                            </td>
                            <td>{recording.hasMissingNoteRecordings ? t('Yes') : ''}</td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}

interface MissingRecordingsTableProps {
    rt: Root
    missingRecordings: MissingRecording[]
    loading: boolean
    header?: JSX.Element
}

const MissingRecordingsTable = ({ rt, missingRecordings, loading, header }: MissingRecordingsTableProps) => {
    const { t } = useTranslation()
    const appRoot = useAppRoot()

    useEffect(() => {
        requestAllPortionsDownload(rt.project.portions)
        if (rt.group) {
            const groupRoot = appRoot.rtsMap.get(rt.group)
            if (groupRoot) {
                requestGroupResourcesDownload(groupRoot)
            }
        }
    }, [rt.project.portions, rt.group, appRoot])

    return (
        <div>
            {header ?? <h4>{t('missingRecordingsHeader')}</h4>}
            {loading && <LoadingIcon className="" />}
            {!loading && <MissingRecordingsTableCore missingRecordings={missingRecordings} />}
        </div>
    )
}

interface AllMissingRecordingsTableProps {
    rt: Root
    header?: JSX.Element
}

const CHECK_FOR_MISSING_RECORDINGS_TIMEOUT_MS = 5000

export const AllMissingRecordingsTable = ({ rt, header }: AllMissingRecordingsTableProps) => {
    const [loading, setLoading] = useState(true)
    const [missingRecordings, setMissingRecordings] = useState<MissingRecording[]>([])
    const appRoot = useAppRoot()

    useEffect(() => {
        const getMissingRecordings = async () => {
            setLoading(true)
            const recordings = await getMissingRecordingsInPortions(rt.project.portions, rt, appRoot)
            setMissingRecordings(recordings)
            setLoading(false)
            setTimeout(() => setLoading(true), CHECK_FOR_MISSING_RECORDINGS_TIMEOUT_MS)
        }
        if (loading) {
            getMissingRecordings()
        }
    }, [rt, loading, appRoot])

    return <MissingRecordingsTable rt={rt} missingRecordings={missingRecordings} loading={loading} header={header} />
}
