import React, { FC, useState, useEffect } from 'react'

import { observer /* inject */ } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import _ from 'underscore'

import { getLastSuccessfulSyncTimeMs } from '../../models3/_LevelupDB'
import { Root } from '../../models3/Root'
import { VideoCache } from '../../models3/VideoCache'
import { VideoCacheRecord } from '../../models3/VideoCacheRecord'
import { LoadingIcon } from '../utils/Icons'

import '../utils/Icons.css'

async function getUploadVcrs() {
    // first uploads that have not yet started then most recent uploads first
    const sortKey = (vcr: VideoCacheRecord) => (vcr.uploadStartTimeMs > 0 ? -vcr.uploadStartTimeMs : -9999999999999)

    let vcrs = await VideoCache.getAllVcrs()
    vcrs = vcrs.filter((vcr) => vcr.uploadeds.length > 0)
    vcrs = _.sortBy(vcrs, sortKey)
    return vcrs
}

const LastSuccessfulSyncMessage = ({ rt }: { rt: Root }) => {
    const { t } = useTranslation()
    const syncTimeMs = getLastSuccessfulSyncTimeMs(rt.name)
    if (syncTimeMs <= 0) {
        return <p>{t('There has not been a successful sync.')}</p>
    }
    return <p>{t('successfulSyncDate', { dateString: rt.dateFormatter.format(new Date(syncTimeMs)) })}</p>
}

interface ChangesDatabaseReportProps {
    rt: Root
}

const ChangesDatabaseReport: FC<ChangesDatabaseReportProps> = ({ rt }) => {
    const { t } = useTranslation()

    const [unsyncedChangesExist, setUnsyncedChangesExist] = useState(false)
    const [loading, setLoading] = useState(true)
    const [firstTimeLoading, setFirstTimeLoading] = useState(true)

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null

        async function localChangesExist() {
            setLoading(true)
            const changesExist = await rt.project.db.unsyncedChangesExist()
            setUnsyncedChangesExist(changesExist)
            setLoading(false)
            setFirstTimeLoading(false)
            timer = setTimeout(localChangesExist, 15000)
        }

        localChangesExist()

        return () => {
            if (timer) clearTimeout(timer)
        }
    }, [rt.project.db])

    if (loading && firstTimeLoading) {
        return (
            <>
                <h4>{t('Data changes')}</h4>
                <LoadingIcon className="" />
            </>
        )
    }

    return (
        <>
            <h4>{t('Data changes')}</h4>
            <LastSuccessfulSyncMessage rt={rt} />
            {unsyncedChangesExist && (
                <p>
                    {t(
                        'Some of your changes have not been backed up. They will only be backed up when you are connected to the internet and AVTT is open.'
                    )}
                </p>
            )}
        </>
    )
}

interface FilesReportProps {
    rt: Root
}

const FilesReport: FC<FilesReportProps> = ({ rt }) => {
    const { t } = useTranslation()

    const [vcrs, setVcrs] = useState<VideoCacheRecord[]>([])
    const [loading, setLoading] = useState(true)
    const [firstTimeLoading, setFirstTimeLoading] = useState(true)

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null

        async function getAllVcrs() {
            if (!vcrs.length) {
                setLoading(true)
            }

            const _vcrs = await getUploadVcrs()
            setVcrs(_vcrs)
            setLoading(false)
            setFirstTimeLoading(false)
            timer = setTimeout(getAllVcrs, 15000)
        }

        getAllVcrs()

        return () => {
            if (timer) clearTimeout(timer)
        }
    }, [vcrs.length])

    if (loading && firstTimeLoading) {
        return (
            <>
                <h4>{t('Files (recordings, documents,...)')}</h4>
                <LoadingIcon className="" />
            </>
        )
    }

    const vcrsToDisplay = vcrs.filter((vcr) => vcr.needsUploaded).filter((vcr) => vcr.projectName() === rt.project.name)

    return (
        <>
            <h4>{t('Files (recordings, documents,...)')}</h4>
            {vcrsToDisplay.length === 0 ? (
                <p>{t('All files created on this computer have been uploaded.')}</p>
            ) : (
                <p>
                    {t(
                        'Not all files have been uploaded to the AVTT server. Please connect to the internet so they can be synced and become available for your teammates.'
                    )}
                </p>
            )}
        </>
    )
}

interface IUploadReport {
    rt: Root
}

const UploadReport: FC<IUploadReport> = observer(({ rt }) => {
    const { t } = useTranslation()

    return (
        <div>
            <h3>{t('Upload Report')}</h3>
            <ChangesDatabaseReport rt={rt} />
            <FilesReport rt={rt} />
        </div>
    )
})

export default UploadReport
