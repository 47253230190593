import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { IDateFormatter } from '../../models3/DateUtilities'
import { PassageVideo } from '../../models3/PassageVideo'

import './Segments.css'
import '../translation/Translation.css'

interface OldSegmentDropdownProps {
    dateFormatter: IDateFormatter
    options: PassageVideo[]
    onSelect: (id: string) => void
    enabled: boolean
    isAppendedSegment: boolean
}

export const OldSegmentDropdown = ({
    dateFormatter,
    options,
    onSelect,
    enabled,
    isAppendedSegment
}: OldSegmentDropdownProps) => {
    const { t } = useTranslation()
    return (
        <Dropdown>
            <Dropdown.Toggle
                title=""
                id="old-segment-dropdown"
                className="sl-dropdown old-segment-dropdown styled-dropdown-select"
                disabled={!enabled}
            />
            <Dropdown.Menu className="styled-dropdown-select-menu">
                {options.map((item, index) => {
                    const created = dateFormatter.format(new Date(item.creationDate))
                    const label = item.isPatch
                        ? isAppendedSegment && index === options.length - 1
                            ? `${t('Added')} ${created}`
                            : `${t('Patch')} ${created}`
                        : t('Original')
                    return (
                        <Dropdown.Item key={item._id} eventKey={item._id} onClick={() => onSelect(item._id)}>
                            {label}
                        </Dropdown.Item>
                    )
                })}
            </Dropdown.Menu>
        </Dropdown>
    )
}
