import { useEffect, useRef } from 'react'

import { systemError } from '../components/utils/Errors'
import { VideoCache } from '../models3/VideoCache'

export const useFetchFromVideoCache = ({ url, onEnded }: { url: string; onEnded: (blob: Blob) => void }) => {
    const currentUrl = useRef('')

    useEffect(() => {
        let mounted = true

        currentUrl.current = url

        let timer: NodeJS.Timeout | undefined
        async function fetchStatus() {
            try {
                const query = await VideoCache.queryVideoDownload(url)
                if (query.blob) {
                    // Make sure the last call is the one that takes precedence so we
                    // avoid a race condition
                    if (currentUrl.current === url && mounted) {
                        onEnded(query.blob)
                    }
                } else {
                    timer = setTimeout(() => fetchStatus(), 2000)
                }
            } catch (error) {
                if (timer) {
                    clearTimeout(timer)
                    timer = undefined
                }
                systemError(error)
            }
        }

        if (url.trim() !== '') {
            fetchStatus()
        }

        return () => {
            mounted = false
            if (timer) {
                clearTimeout(timer)
                timer = undefined
            }
        }
    }, [onEnded, url])
}
