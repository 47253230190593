import { PropsWithChildren, ReactNode, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { LocalStorageKeys } from '../app/slttAvtt'

import './HorizontalAccordion.css'

interface AccordionItemProps {
    title: string
    isOpen: boolean
    onClick: () => void
    disabled?: boolean
    maxWidth?: string
}

const AccordionItem = ({
    title,
    isOpen,
    disabled,
    onClick,
    maxWidth,
    children
}: PropsWithChildren<AccordionItemProps>) => {
    const { t } = useTranslation()
    const tooltip = !disabled
        ? isOpen
            ? t('horizontalAccordionTooltipCollapse')
            : t('horizontalAccordionToolipExpand')
        : ''

    return (
        <div className={`accordion-card ${isOpen ? '' : 'closed'}`} style={{ maxWidth }}>
            <div className="accordion-card-header" aria-disabled={disabled} onClick={onClick} title={tooltip}>
                <h3>{title}</h3>
            </div>

            <div className="accordion-card-content width">{children}</div>
        </div>
    )
}

type AccordionItemVariant = 'left' | 'middle' | 'right'

type AccordionItems = {
    [key in AccordionItemVariant]: {
        title: string
        content: ReactNode
    }
}

type HorizontalAccordionProps = AccordionItems & {
    maxWidth?: {
        item: AccordionItemVariant
        width: string
    }
}

const getPersistedOpenState = (key: string) => {
    const value = localStorage.getItem(key)
    if (value === null) {
        // Never been persisted before, so return the default value
        return true
    }
    return value === 'true'
}

const persistOpenState = (key: string, isOpen: boolean) => {
    localStorage.setItem(key, isOpen ? 'true' : '')
}

const HorizontalAccordion = ({ left, middle, right, maxWidth }: HorizontalAccordionProps) => {
    // At least one pane must be open at all times
    const [isLeftOpen, setIsLeftOpen] = useState(getPersistedOpenState(LocalStorageKeys.ACCORDION_LEFT_IS_OPEN))
    const [isMiddleOpen, setIsMiddleOpen] = useState(getPersistedOpenState(LocalStorageKeys.ACCORDION_MIDDLE_IS_OPEN))
    const [isRightOpen, setIsRightOpen] = useState(getPersistedOpenState(LocalStorageKeys.ACCORDION_RIGHT_IS_OPEN))

    return (
        <div className="accordion width">
            <AccordionItem
                title={left.title}
                maxWidth={(isMiddleOpen || isRightOpen) && maxWidth?.item === 'left' ? maxWidth.width : '100%'}
                isOpen={isLeftOpen}
                onClick={() => {
                    setIsLeftOpen(!isLeftOpen)
                    persistOpenState(LocalStorageKeys.ACCORDION_LEFT_IS_OPEN, !isLeftOpen)
                }}
                disabled={isLeftOpen && !isRightOpen && !isMiddleOpen}
            >
                {left.content}
            </AccordionItem>
            <AccordionItem
                title={middle.title}
                isOpen={isMiddleOpen}
                maxWidth={(isLeftOpen || isRightOpen) && maxWidth?.item === 'middle' ? maxWidth.width : '100%'}
                onClick={() => {
                    setIsMiddleOpen(!isMiddleOpen)
                    persistOpenState(LocalStorageKeys.ACCORDION_MIDDLE_IS_OPEN, !isMiddleOpen)
                }}
                disabled={isMiddleOpen && !isLeftOpen && !isRightOpen}
            >
                {middle.content}
            </AccordionItem>
            <AccordionItem
                title={right.title}
                isOpen={isRightOpen}
                maxWidth={(isLeftOpen || isMiddleOpen) && maxWidth?.item === 'right' ? maxWidth.width : '100%'}
                onClick={() => {
                    setIsRightOpen(!isRightOpen)
                    persistOpenState(LocalStorageKeys.ACCORDION_RIGHT_IS_OPEN, !isRightOpen)
                }}
                disabled={!isLeftOpen && !isMiddleOpen && isRightOpen}
            >
                {right.content}
            </AccordionItem>
        </div>
    )
}

export default HorizontalAccordion
