import { useState, useEffect } from 'react'

import { groupBy } from '../components/utils/Helpers'
import API, { ReviewCommentsSearchParams } from '../models3/API'

type GroupedRespondentProfileValues = [string, { key: string; value: string; avttProfileAttributeId: string }[]][]

type RespondentProfileValue = {
    type: 'text' | 'checkbox'
    value: string
    checked: boolean
}

type RespondentProfileKey = string

type RespondentProfile = Record<RespondentProfileKey, RespondentProfileValue>

type SerializedRespondent = {
    title: string
    respondentProfile: string | null
    imageFileName: string | null
    fileName: string | null
}

type Respondent = Omit<SerializedRespondent, 'respondentProfile'> & {
    respondentProfile: GroupedRespondentProfileValues
}

type SerializedReviewComment = {
    id: string
    questionId: string
    content: string | null
    fileName: string | null
    createdAt: string
    respondentId: string
    respondent: SerializedRespondent
}

export type ReviewComment = Omit<SerializedReviewComment, 'respondent'> & {
    respondent: Respondent
}

type SerializedReviewComments = {
    items: SerializedReviewComment[]
    nextToken: string | null
    startedAt: number | null
}

const REVIEW_ID_SEPARATOR = '~'
const PROFILE_ATTRIBUTE_ID_LENGTH = 'rPrj/rpa_123456_123456'.length

const convertRespondentProfileKeyToAVTTId = (key: string) => {
    const parts = key.split(REVIEW_ID_SEPARATOR)

    // do not include project name
    return [parts[0], parts[2]].join('/').slice(0, PROFILE_ATTRIBUTE_ID_LENGTH)
}

const groupProfileValuesByAttribute = (profile: RespondentProfile) => {
    const values = Object.entries(profile)
        .filter((entries) => {
            const { type, checked } = entries[1]
            return type === 'text' || checked
        })
        .map(([key, { value }]) => {
            const avttProfileAttributeId = convertRespondentProfileKeyToAVTTId(key)
            return { key, value, avttProfileAttributeId }
        })
    const groups = groupBy(values, (value) => value.avttProfileAttributeId)

    // Sort attributes in a consistent way
    return Object.entries(groups).sort((a, b) => a[0].localeCompare(b[0]))
}

const deserializeComment = (comment: SerializedReviewComment) => {
    const { respondent } = comment
    const { respondentProfile: profile } = respondent
    const respondentProfile = profile ? (JSON.parse(profile) as RespondentProfile) : {}

    return {
        ...comment,
        respondent: {
            ...respondent,
            respondentProfile: groupProfileValuesByAttribute(respondentProfile)
        }
    }
}

const deserializeComments = (items: SerializedReviewComment[]) => {
    return items
        .map(deserializeComment)
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()) // sort in reverse order
}

export const useReviewComments = ({ projectName, reviewPassageId, reviewQuestionId }: ReviewCommentsSearchParams) => {
    const [comments, setComments] = useState<ReviewComment[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)

    useEffect(() => {
        const fetchComments = async () => {
            try {
                const { items } = (await API.getReviewComments({
                    projectName,
                    reviewPassageId,
                    reviewQuestionId
                })) as SerializedReviewComments

                const deserializedItems = deserializeComments(items)
                setComments(deserializedItems)
            } catch (err) {
                console.error(err)
                setIsError(true)
            }
            setIsLoading(false)
        }

        fetchComments()
    }, [projectName, reviewPassageId, reviewQuestionId])

    return { comments, isError, isLoading }
}
