import { PassageNote } from '../../models3/PassageNote'
import { NoteMarker } from '../../types'
import { CircleNoteMarker, SquareNoteMarker, TriangleNoteMarker } from '../utils/Buttons'

export const getNoteColor = (note: PassageNote, noteColors: string[], enabled: boolean) => {
    const { onTop, inIgnoredSegment, consultantOnly, resolved, type } = note
    const typeIndex = parseInt(type)
    let color = ''
    if (!enabled) {
        color = 'grey'
    } else if (!onTop || inIgnoredSegment) {
        color = 'black'
    } else if (consultantOnly) {
        color = 'purple'
    } else if (!resolved) {
        color = noteColors[typeIndex]
    }
    return color
}

export const getAllNoteMarkers = ({ className }: { className: string }): NoteMarker[] => {
    return [
        { component: <CircleNoteMarker className={className} enabled />, shape: 'circle' },
        { component: <CircleNoteMarker className={className} enabled />, shape: 'circle' },
        { component: <CircleNoteMarker className={className} enabled />, shape: 'circle' },
        { component: <SquareNoteMarker className={className} enabled />, shape: 'square' },
        { component: <SquareNoteMarker className={className} enabled />, shape: 'square' },
        { component: <SquareNoteMarker className={className} enabled />, shape: 'square' },
        { component: <TriangleNoteMarker className={className} enabled />, shape: 'triangle' },
        { component: <TriangleNoteMarker className={className} enabled />, shape: 'triangle' },
        { component: <TriangleNoteMarker className={className} enabled />, shape: 'triangle' }
    ]
}
