import JSZip from 'jszip'

import { serializeResponse } from './ResponseSerializer'
import { MimeType, FileExtension, CacheRequest, CacheType, CacheIndexEntry, CacheSource } from '../../../types'
import { exportToFile } from '../../utils/Helpers'

export const RESOURCE_EXPORT_FILE_PREFIX = 'AVTT-resources-'
const PUBLIC_RESOURCES_CACHE = 'avtt-resources-public'
const PRIVATE_RESOURCES_CACHE = 'avtt-resources-private'

const isPublicResource = (url: string) =>
    Boolean(url.match(/^https:\/\/.*\.cloudfront\.net\/public\/(APIBIBLE|SLMARBLE|SRV)\//))

const isPrivateResource = (url: string) =>
    Boolean(url.match(/^https:\/\/.*\.execute-api\..*\.amazonaws\.com\/.*\/resources\/(APIBIBLE|SLMARBLE)\//))

const getCacheName = (url: string) => {
    if (isPublicResource(url)) {
        return PUBLIC_RESOURCES_CACHE
    }
    if (isPrivateResource(url)) {
        return PRIVATE_RESOURCES_CACHE
    }
    return ''
}

const serializeCacheStorage = async (responses: Response[]) => {
    const fileContents: { name: string; contents: Blob }[] = []
    let index = 0
    for (const response of responses) {
        const { url } = response
        const cacheName = getCacheName(url)
        if (!cacheName) {
            continue
        }

        const serializedResponse = await serializeResponse(response)
        const contents = { url, response: serializedResponse }
        fileContents.push({
            name: `${CacheSource.CacheStorage}/${cacheName}/${index}${FileExtension.JSON}`,
            contents: new Blob([JSON.stringify(contents)], { type: MimeType.JSON })
        })
        index++
    }
    return fileContents
}

const serializeCacheIndex = (cacheType: CacheType, indexEntries: CacheIndexEntry[]) => {
    return [
        {
            name: `${CacheSource.CacheIndex}/${cacheType}${FileExtension.JSON}`,
            contents: new Blob([JSON.stringify(indexEntries)], { type: MimeType.JSON })
        }
    ]
}

const createZipFile = async (fileEntries: { name: string; contents: Blob }[]) => {
    const zip = new JSZip()
    for (const { name, contents } of fileEntries) {
        zip.file(name, contents)
    }
    return zip.generateAsync({ type: 'blob' })
}

export const exportTranslationResources = async ({
    responses,
    cacheIndexEntries,
    cacheType,
    mediaType,
    bibleVersion,
    language
}: {
    responses: Response[]
    cacheIndexEntries: CacheIndexEntry[]
} & CacheRequest) => {
    const fileName = [
        cacheType,
        cacheType === CacheType.EXEGETICAL_RESOURCES && language,
        bibleVersion?.abbreviation,
        mediaType
    ]
        .filter(Boolean)
        .join('_')

    const [cacheStorageEntries, indexedDBCaches] = await Promise.all([
        serializeCacheStorage(responses),
        serializeCacheIndex(cacheType, cacheIndexEntries)
    ])
    const exportedCaches = [...cacheStorageEntries, ...indexedDBCaches]

    const zipFile = await createZipFile(exportedCaches)
    exportToFile(zipFile, `${RESOURCE_EXPORT_FILE_PREFIX}${fileName}`, FileExtension.ZIP)
}
