import { FC, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { ExegeticalResourceBookIndex, fetchExegeticalResourceBookIndexes } from '../../resources/ExegeticalResources'
import { MediaType } from '../../types'

interface ExegeticalResourcesContextValue {
    exegeticalResourceBookIndexes?: ExegeticalResourceBookIndex
    doesExegeticalResourceBookExist: (bookId: string, mediaType: MediaType) => boolean
    exegeticalResourceMediaTypes: MediaType[]
}

const ExegeticalResourcesContext = createContext<ExegeticalResourcesContextValue | undefined>(undefined)

export const ExegeticalResourcesProvider: FC = ({ children }) => {
    const {
        i18n: { language }
    } = useTranslation()
    const [exegeticalResourceBookIndexes, setExegeticalResourceBookIndexes] = useState<ExegeticalResourceBookIndex>()

    useEffect(() => {
        const fetchIndexes = async () => {
            try {
                const indexes = await fetchExegeticalResourceBookIndexes(language)
                setExegeticalResourceBookIndexes(indexes)
            } catch (error) {
                console.error(`Error while fetching exegetical resource book indexes for language ${language}.`, error)
            }
        }
        fetchIndexes()
    }, [language])

    const doesExegeticalResourceBookExist = useCallback(
        (bookId: string, mediaType: MediaType) => {
            const bookIndexes = exegeticalResourceBookIndexes?.bookIndexes
            if (!bookIndexes) {
                return false
            }
            return bookIndexes.some(
                ({ bookChapterId, audioFiles }) =>
                    bookChapterId.startsWith(bookId) && (mediaType === MediaType.TEXT || audioFiles.length)
            )
        },
        [exegeticalResourceBookIndexes]
    )

    const exegeticalResourceMediaTypes = useMemo(
        () =>
            Object.values(MediaType).filter((mediaType) => {
                const bookIndexes = exegeticalResourceBookIndexes?.bookIndexes
                if (!bookIndexes) {
                    return false
                }
                return mediaType === MediaType.TEXT
                    ? bookIndexes.length > 0
                    : bookIndexes.some(({ audioFiles }) => audioFiles.length)
            }),
        [exegeticalResourceBookIndexes]
    )

    return (
        <ExegeticalResourcesContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                exegeticalResourceBookIndexes,
                doesExegeticalResourceBookExist,
                exegeticalResourceMediaTypes
            }}
        >
            {children}
        </ExegeticalResourcesContext.Provider>
    )
}

export const useExegeticalResources = () => {
    const context = useContext(ExegeticalResourcesContext)
    if (!context) {
        throw new Error('useExegeticalResources must be used within a ExegeticalResourcesProvider')
    }
    return context
}
