import { useState } from 'react'

import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { Passage } from '../../models3/Passage'
import { PassageDocument } from '../../models3/PassageDocument'
import { Root } from '../../models3/Root'
import { ModalFooter } from '../modals/Modals'
import { ReferenceInput } from '../referenceInput/ReferenceInput'
import { GenericIcon } from '../utils/Icons'
import LabeledFieldWithIcon from '../utils/LabeledFieldWithIcon'
import { Switch } from '../utils/Switch'
import { SimpleTextInput } from '../utils/TextInput'

import './Passage.css'

export interface PassageDocumentInputParams {
    title: string
    isGlobal: boolean
}

interface PassageDocumentModalProps {
    rt: Root
    passage: Passage
    document?: PassageDocument
    setOpen: (value: boolean) => void
    save: ({ title, isGlobal }: PassageDocumentInputParams) => Promise<void>
}

export const PassageDocumentModal = ({ rt, passage, document, save, setOpen }: PassageDocumentModalProps) => {
    const { t } = useTranslation()
    const [title, setTitle] = useState(document?.title ?? '')
    const [titleError, setTitleError] = useState('')
    const [isGlobal, setIsGlobal] = useState(document?.isGlobal ?? false)

    const handleSetVisibility = (newValue: boolean) => {
        setIsGlobal(newValue)
    }

    const validateTitle = (newTitle: string) => {
        return newTitle.trim() === '' ? t('cannotBeEmpty') : ''
    }

    const handleTitleChange = (value: string) => {
        const error = validateTitle(value)
        setTitleError(error)
        setTitle(value)
    }

    return (
        <Modal show onHide={() => setOpen(false)} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t('passageResource')}</Modal.Title>{' '}
            </Modal.Header>
            <Modal.Body>
                <div className="passages-modal-toggle">
                    {t('resourceVisibilityToggleLabel')}
                    <Switch
                        value={isGlobal}
                        setValue={handleSetVisibility}
                        tooltip={isGlobal ? t('resourceVisibilityGlobal') : t('resourceVisibilityPassage')}
                    />
                </div>
                <LabeledFieldWithIcon
                    iconName="fa-tag"
                    title={t('Title')}
                    field={
                        <SimpleTextInput
                            autoFocus
                            value={title}
                            setValue={handleTitleChange}
                            errorMessage={titleError}
                            iconRight={
                                isGlobal ? (
                                    <GenericIcon
                                        className="passage-document-global-icon"
                                        iconName="fa-globe"
                                        iconType="fas"
                                        tooltip={t('resourceVisibilityGlobal')}
                                    />
                                ) : undefined
                            }
                        />
                    }
                />
                <LabeledFieldWithIcon
                    iconName="fa-book"
                    title={t('References')}
                    field={
                        <ReferenceInput
                            refInput={rt}
                            refs={passage.references}
                            disabled
                            errored={false}
                            setRefs={() => {}}
                            setErrored={() => {}}
                        />
                    }
                />
            </Modal.Body>
            <ModalFooter
                enabledOK={!validateTitle(title)}
                onOK={() => {
                    save({ title, isGlobal })
                    setOpen(false)
                }}
                okTooltip={t('Save')}
                onCancel={() => setOpen(false)}
            />
        </Modal>
    )
}
