import React, { FunctionComponent } from 'react'

import { AppRoot } from '../../models3/AppRoot'
import { Root } from '../../models3/Root'

export const RootContext = React.createContext<Root | null>(null)

interface IRootProvider {
    initialValue: Root | null
}

export const RootProvider: FunctionComponent<IRootProvider> = ({ initialValue, children }) => {
    return <RootContext.Provider value={initialValue}>{children}</RootContext.Provider>
}

interface IRootConsumer {
    children: (context: Root | null) => JSX.Element | null
}

export const RootConsumer: FunctionComponent<IRootConsumer> = ({ children }) => {
    return <RootContext.Consumer>{(context) => children(context)}</RootContext.Consumer>
}

export const AppRootContext = React.createContext<AppRoot | undefined>(undefined)

type AppRootProviderProps = {
    initialValue: AppRoot
}

const AppRootProvider: FunctionComponent<AppRootProviderProps> = ({ children, initialValue }) => {
    return <AppRootContext.Provider value={initialValue}>{children}</AppRootContext.Provider>
}

type AppRootConsumerProps = {
    children: (props: { context: AppRoot }) => JSX.Element
}

const AppRootConsumer: FunctionComponent<AppRootConsumerProps> = ({ children }) => {
    return (
        <AppRootContext.Consumer>
            {(context) => {
                if (!context) {
                    throw new Error('AppRootConsumer must be used within an AppRootProvider')
                }
                return children({ context })
            }}
        </AppRootContext.Consumer>
    )
}

function useAppRoot() {
    const context = React.useContext(AppRootContext)
    if (!context) {
        throw new Error('useAppRoot must be used within an AppRootProvider')
    }
    return context
}

export { AppRootProvider, AppRootConsumer, useAppRoot }
