import { observable } from 'mobx'

import { PassageVideoMarker } from './PassageVideoMarker'
import { fmt } from '../components/utils/Fmt'
import { RefRange } from '../resources/RefRange'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:Models')

export class ReferenceMarker extends PassageVideoMarker {
    @observable references: RefRange[] = []

    readonly model = 6

    // We track this because we want to delete newly created references on ESC
    newlyCreated = false

    toDocument(useExistingModDate?: boolean) {
        const { references, position } = this
        const serializedReferences = JSON.stringify(references)
        return this._toDocument({ references: serializedReferences, position, model: this.model }, useExistingModDate)
    }

    copy() {
        let copy = new ReferenceMarker(this._id, this.db, new Date(this.creationDate))
        copy = Object.assign(copy, this)

        copy.references = this.references.map((ref) => ref.copy())
        return copy
    }

    async setPosition(position: number) {
        if (this.position === position) {
            log('setPosition ! position changed')
            return
        }

        const doc = this._toDocument({ position, model: this.model })
        log('setPosition', fmt(doc))
        this.db.submitChange(doc)
    }

    async setReferences(references: RefRange[]) {
        const serializedReferences = JSON.stringify(references)

        log('setReferences', serializedReferences)
        if (JSON.stringify(this.references) === serializedReferences) {
            log('setReferences no change')
            return
        }

        this.references = references
        const doc = this._toDocument({
            references: JSON.stringify(this.references),
            model: this.model
        })

        await this.db.put(doc)
    }

    dbg() {
        const doc = this.toDocument()
        return doc
    }
}
