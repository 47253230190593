import { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { useOnlineStatus } from '../components/app/OnlineStatusContext'
import { useAppRoot } from '../components/app/RootContext'
import { systemError } from '../components/utils/Errors'
import { isValidIdCharacters, isValidIdLength } from '../components/utils/Helpers'
import API from '../models3/API'
import { GroupProjectConfiguration, Project } from '../models3/Project'
import { ProjectEntity } from '../models3/ProjectEntity'
import { Root } from '../models3/Root'

export const useAddProject = ({
    groupRoot,
    projectName,
    isGroup,
    groupProjectConfig
}: {
    groupRoot?: Root
    projectName: string
    isGroup: boolean
    groupProjectConfig: GroupProjectConfiguration
}) => {
    const { t } = useTranslation()
    const [creationInProgress, setCreationInProgress] = useState(false)
    const [newProjectName, setNewProjectName] = useState('')
    const [error, setError] = useState('')
    const appRoot = useAppRoot()
    const { checkIsOnline } = useOnlineStatus()

    const createProject = async () => {
        const group = groupRoot?.project
        const newName = await API.createProject(projectName, isGroup, group?.name)
        const { projects } = await API.getAuthorizedProjects()
        return projects.find((prj) => prj.project === newName)
    }

    const setUpGroupProject = async (newProject: ProjectEntity, group: Project) => {
        const newProjectRoot = appRoot.createRoot(newProject)
        await newProjectRoot.initialize()
        await newProjectRoot.project.copyDataToGroupProject(groupProjectConfig, group)
    }

    const addProjectCore = async () => {
        try {
            const isOnline = await checkIsOnline()
            if (!isOnline) {
                setError(t('projectCreateNoInternetError'))
                return
            }

            const newProject = await createProject()
            if (!newProject) {
                setError(t('projectCreateError'))
                return
            }

            setNewProjectName(newProject.project)
            if (groupRoot) {
                await setUpGroupProject(newProject, groupRoot.project)
            }
        } catch (e) {
            systemError(e)
            setError(t('projectCreateError'))
        }
    }

    const addProject = async () => {
        setCreationInProgress(true)
        await addProjectCore()
        setCreationInProgress(false)
    }

    const validateProjectName = (name: string) => {
        if (!isValidIdCharacters(name)) {
            return t('Invalid characters')
        }

        if (!isValidIdLength(name)) {
            return t('Too short or long')
        }

        const projectNameWithGroup = groupRoot ? `${groupRoot.name}:${name}` : name
        const rootNamesLowerCase = appRoot.rts.map((rt) => rt.name.toLowerCase())
        if (rootNamesLowerCase.includes(projectNameWithGroup.toLowerCase())) {
            return t('Duplicate name')
        }

        return ''
    }

    return { addProject, validateProjectName, creationInProgress, error, newProjectName }
}

export default useAddProject
