import { FC } from 'react'

import { Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useRegisterServiceWorker } from '../../hooks/useRegisterServiceWorker'

const ServiceWorkerWrapper: FC = () => {
    const { t } = useTranslation()
    const { isUpdateAvailable, updateServiceWorker } = useRegisterServiceWorker()

    if (!isUpdateAvailable) {
        return null
    }

    return (
        <Alert>
            {t('An update is available.')}
            <button type="button" onClick={updateServiceWorker}>
                {t('Update')}
            </button>
        </Alert>
    )
}

export default ServiceWorkerWrapper
