import { FC, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { SortEnd, SortableContainer } from 'react-sortable-hoc'

import { NoPortionsMessage } from './NoPortionsMessage'
import PortionAdder from './PortionAdder'
import PortionEditor from './PortionEditor'
import PortionListItem from './PortionView'
import { Portion } from '../../models3/Portion'
import { Root } from '../../models3/Root'
import { RefRange } from '../../resources/RefRange'
import { ExportSourceType } from '../../types'
import { ExportModal } from '../modals/export/ExportModal'
import { CopyProjectDataModal } from '../modals/project/CopyDataModal'
import { CopyButton, DownloadButton } from '../utils/Buttons'
import { systemError, CustomErrorBoundary, displayError } from '../utils/Errors'
import LoadingMessage from '../utils/InitializationMessage'

import './Portion.css'

interface ISortableContainer {
    rt: Root
    portions: Portion[]
}

interface IPortionsEditor {
    rt: Root
}

export const PortionsEditor: FC<IPortionsEditor> = observer(({ rt }) => {
    const { t } = useTranslation()
    const { project, initialized, loadingMessage, useMobileLayout, iAmTranslator } = rt
    const [isCopyPortionsModalOpen, setIsCopyPortionsModalOpen] = useState(false)
    const [isExportModalOpen, setIsExportModalOpen] = useState(false)
    const [isAddPortionModalOpen, setIsAddPortionModalOpen] = useState(false)

    const { portions } = project

    /* eslint-disable-next-line @typescript-eslint/no-unused-expressions */
    portions.length // necessary to force re-render, don't know why

    if (!initialized) return <LoadingMessage {...{ loadingMessage }} />

    const onSortEnd = (result: SortEnd) => {
        const { oldIndex, newIndex } = result

        project.movePortion(project.portions[oldIndex]._id, newIndex).catch(systemError)
    }

    const SortableList = SortableContainer((props: ISortableContainer) => {
        return (
            <div>
                {props.portions.map((portion, index) => (
                    <CustomErrorBoundary
                        key={portion._id}
                        fallbackUI={
                            <div
                                className="portion-portion portion-error"
                                title={t('Something went wrong while displaying this portion')}
                            >
                                <b>{`${portion.name}???`}</b>
                            </div>
                        }
                    >
                        <PortionListItem
                            disabled={!props.rt.iAmTranslator}
                            index={index}
                            rt={props.rt}
                            portion={portion}
                        />
                    </CustomErrorBoundary>
                ))}
            </div>
        )
    })

    const save = async (name: string, references: RefRange[]) => {
        try {
            const portion = project.createPortion(name)
            portion.references = references
            const newPortion = await project.addPortionFromExisting(portion)
            rt.setPortion(newPortion)
            rt.setDbsRefs(rt.portion)
        } catch (error) {
            displayError(error)
        }
    }

    return (
        <div className="portions-editor">
            <h3>
                {t('Portions')} {`(${project.displayName})`}
            </h3>

            {project.portions.length > 0 && (
                <div className="portions-menu">
                    <CopyButton
                        onClick={() => setIsCopyPortionsModalOpen(true)}
                        buttonClassName=""
                        className="portions-menu-item"
                        tooltip={t('Copy portions')}
                        enabled
                    />
                    {isCopyPortionsModalOpen && (
                        <CopyProjectDataModal
                            setOpenModal={setIsCopyPortionsModalOpen}
                            source={project}
                            copyDataType="portions"
                        />
                    )}

                    <DownloadButton
                        className="portions-menu-item"
                        tooltip={t('Export')}
                        onClick={() => setIsExportModalOpen(true)}
                        enabled
                    />

                    {isExportModalOpen && (
                        <ExportModal
                            rt={rt}
                            exportSourceType={ExportSourceType.PROJECT}
                            setOpen={setIsExportModalOpen}
                        />
                    )}
                </div>
            )}

            {isAddPortionModalOpen && <PortionEditor rt={rt} setEditing={setIsAddPortionModalOpen} save={save} />}

            {portions.length === 0 && <NoPortionsMessage allowedToEdit={iAmTranslator} />}

            <SortableList portions={project.portions} rt={rt} useDragHandle onSortEnd={onSortEnd} distance={5} />
            {!useMobileLayout && iAmTranslator && <PortionAdder onClick={() => setIsAddPortionModalOpen(true)} />}
        </div>
    )
})
