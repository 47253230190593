import { useState } from 'react'

import { observer } from 'mobx-react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { ReviewProject } from '../../models3/ReviewProject'
import { CancelButton, OKButton, PencilButton } from '../utils/Buttons'
import { GenericIcon } from '../utils/Icons'
import { SimpleTextInput } from '../utils/TextInput'

import './ProjectReview.css'

interface GeneralConfigModalProps {
    closeModal: () => void
    project: ReviewProject
}

const GeneralConfigModal = ({ closeModal, project }: GeneralConfigModalProps) => {
    const [projectCode, setProjectCode] = useState(project.projectCode)
    const [title, setTitle] = useState(project.title)
    const { t } = useTranslation()

    return (
        <Modal show onHide={closeModal} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t('projectReviewGeneralConfigurationTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="pr-text-modal-field">
                    <div className="text-input-with-label">
                        <GenericIcon
                            iconName="fa-tag"
                            className="project-review-button default-blue-icon icon-spacer fa-fw"
                            tooltip={t('Title')}
                        />
                        <SimpleTextInput value={title} setValue={setTitle} errorMessage="" />
                    </div>
                </div>
                <div className="pr-text-modal-field">
                    <div className="text-input-with-label">
                        <GenericIcon
                            iconName="fa-key"
                            className="project-review-button default-blue-icon icon-spacer fa-fw"
                            tooltip={t('projectReviewKey')}
                        />
                        {project.projectKey}
                    </div>
                </div>
                <div className="pr-text-modal-field">
                    <div className="text-input-with-label">
                        <GenericIcon
                            iconName="fa-lock"
                            className="project-review-button default-blue-icon icon-spacer fa-fw"
                            tooltip={t('projectReviewCode')}
                        />
                        <SimpleTextInput value={projectCode} setValue={setProjectCode} errorMessage="" />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="project-review-modal-footer button-row">
                    <OKButton
                        enabled
                        onClick={() => {
                            project.setValues({ projectKey: project.projectKey, projectCode, title })
                            closeModal()
                        }}
                        buttonClassName=""
                        className="ok-button"
                        tooltip={t('OK')}
                    />
                    <CancelButton enabled onClick={closeModal} className="cancel-button" tooltip={t('Cancel')} />
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export const ProjectInfo = observer(({ project }: { project: ReviewProject }) => {
    const [showGeneralConfigModal, setShowGeneralConfigModal] = useState(false)
    const { t } = useTranslation()
    const { projectKey, projectCode, title } = project

    return (
        <div className="project-review-general-config-display">
            {showGeneralConfigModal && (
                <GeneralConfigModal project={project} closeModal={() => setShowGeneralConfigModal(false)} />
            )}
            <div className="text-input-with-label">
                <GenericIcon
                    iconName="fa-tag"
                    className="project-review-button default-blue-icon icon-spacer fa-fw"
                    tooltip={t('Title')}
                />
                <input value={title} disabled className="icon-spacer" />
            </div>
            <div className="text-input-with-label">
                <GenericIcon
                    iconName="fa-key"
                    className="project-review-button default-blue-icon icon-spacer fa-fw"
                    tooltip={t('projectReviewKey')}
                />
                <input value={projectKey} disabled className="icon-spacer" />
            </div>
            <div className="text-input-with-label">
                <GenericIcon
                    iconName="fa-lock"
                    className="project-review-button default-blue-icon icon-spacer fa-fw"
                    tooltip={t('projectReviewCode')}
                />
                <input value={projectCode} disabled className="icon-spacer" />
            </div>
            <PencilButton
                enabled
                onClick={() => setShowGeneralConfigModal(true)}
                className="project-review-button default-blue-icon"
                tooltip={t('Edit')}
            />
        </div>
    )
})
