// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

// The service worker lifecycle is confusing. Read more about it at:
// https://web.dev/articles/service-worker-lifecycle
// https://redfin.engineering/service-workers-break-the-browsers-refresh-button-by-default-here-s-why-56f9417694
// https://redfin.engineering/how-to-fix-the-refresh-button-when-using-service-workers-a8e27af6df68

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export function register(config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    const loadHandler = () => {
      const swUrl = `${process.env.PUBLIC_URL}/sw.js`;

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit https://bit.ly/CRA-PWA'
          );
        });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    }

    // Load event may have fired before we get here.
    // readyState of complete is the same as the load event firing
    if (document.readyState === 'complete') {
      loadHandler()
    } else {
      window.addEventListener('load', () => {
        loadHandler()
      });
    }
  }
}

const newContentMessage = 'New content is available and will be used when all tabs for this page are closed. See https://bit.ly/CRA-PWA.';

// NOTE: By default, the very first time a service worker is installed, it will not
// control the page. The next time the page is reloaded, the service worker will
// take control.
function registerValidSW(swUrl, config) {
  let refreshing = false
  // Fires when a new worker takes control of the page, like when skipWaiting() is called.
  // Some fetches could have been handled by an old service worker, so trigger a refresh.
  // This will cause the new service worker to handle the requests.
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    // Make sure the page is only refreshed once, to prevent an infinite refresh
    // loop during development. This can happen when "update on reload" is turned
    // on in dev tools.
    if (refreshing) {
      return
    }
    refreshing = true
    window.location.reload()
  })

  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      // A new service worker is waiting. Notify the user.
      if (registration.waiting && config && config.onUpdate) {
        console.log(newContentMessage);
        config.onUpdate(registration)
      }

      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker === null) {
          return;
        }

        // At this point, we know that a service worker is installing
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // A service worker is already controlling the client. The new worker has been
              // installed and the updated precached content has been fetched. The old worker
              // will continue to serve old content until the new worker takes control. Notify
              // the user that an update is available.
              console.log(newContentMessage);

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // There is no service worker controlling the client, so this is the first
              // install. The worker has been installed and all precached content has been
              // fetched. Because the page request itself did not go through a service worker,
              // you need to refresh the page to see the effects of the worker.
              console.log('Content is cached for offline use.');

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch(error => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    });
}