/**
 * This component can set/play/stop a single video in a ViewableVideoCollection.
 *
 * The routines setVideo, play, amd stop are directly invoked by
 * the parent in order to initiate those operations.
 *
 * This component is responsible for setting up <video> element for the main
 * video and all its patches. It ensures the correct video is visible.
 *
 * This component also polls the currently playing video element in order to inform
 * it's parents about the current position in the video.
 */

import { Component } from 'react'

import { observable } from 'mobx'
import { observer } from 'mobx-react'

import { VideoPlayerPoller } from './VideoPlayerPoller'
import { ViewableVideoCollection, ViewableVideo } from './ViewableVideoCollection'
import { PassageVideo } from '../../models3/PassageVideo'
import { fmt } from '../utils/Fmt'

import './Video.css'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:VideoPlayerCore')

interface IVideoPlayerCore {
    vvc: ViewableVideoCollection
    // It is the responsibility of the parent component to setup() this
    // and initiate a download() this

    playbackRate: number
    disablePlay?: boolean
    className?: string

    onTick: (currentPosition: number) => void
    onPlayingStatus: (playing: boolean) => void
    onCanPlayThrough: (video: PassageVideo, duration: number) => void
    onStop: () => void

    setVideoWidth?: (width: number) => void
    // Call this when the width of the video changes.
    muted?: boolean
}

@observer
export default class VideoPlayerCore extends Component<IVideoPlayerCore> {
    @observable visibleVideo?: PassageVideo

    timeout = 0

    poller?: VideoPlayerPoller

    vc?: HTMLVideoElement

    videoWidthObserver?: ResizeObserver

    @observable playing = false

    // Mapping from VideoPassage._id to the <video> element that plays it
    videoControls: { [_id: string]: { vc: HTMLVideoElement /* , alreadySet: boolean */ } } = {}

    // eslint-disable-next-line react/no-unused-class-component-methods
    video: PassageVideo | null = null
    // video being played. Must be in vvc.

    seeking = false

    constructor(props: IVideoPlayerCore) {
        super(props)
        log('constructor')
        this.setVideo = this.setVideo.bind(this)
        this.selectVideo = this.selectVideo.bind(this)
        this.setPlaybackRate = this.setPlaybackRate.bind(this)
    }

    componentDidMount() {
        this.setupVideoWidthObserver()
    }

    componentDidUpdate() {
        const { playbackRate } = this.props
        this.setPlaybackRate(playbackRate)
        this.setupVideoWidthObserver()
    }

    componentWillUnmount() {
        this.poller?.stopUpdater()
        this.videoWidthObserver?.disconnect()
    }

    setupVideoWidthObserver = () => {
        const { setVideoWidth } = this.props

        this.videoWidthObserver?.disconnect()

        this.videoWidthObserver = new ResizeObserver((entries) => {
            // Normally, we would not need to debounce the callback to a ResizeObserver.
            // For some reason, Cypress tests fail if we don't.
            const { timeout } = this
            if (timeout) {
                window.cancelAnimationFrame(timeout)
            }

            this.timeout = window.requestAnimationFrame(() => {
                if (entries.length) {
                    const { width } = entries[0].contentRect
                    setVideoWidth?.(width)
                }
            })
        })

        const { visibleVideo, videoControls } = this
        if (visibleVideo) {
            const element = videoControls[visibleVideo._id]
            if (element) {
                const { vc } = element
                this.videoWidthObserver?.observe(vc)
            }
        }
    }

    // ----------------------------
    // ---- Events             ----
    // ----------------------------

    /* When a video is playing this called on every tick of the clock.
     */
    onTick = (position: number /* position in this video in seconds */) => {
        const { onTick } = this.props
        onTick?.(position)
    }

    onPlayingStatus = (playing: boolean) => {
        const { onPlayingStatus } = this.props
        this.playing = playing
        onPlayingStatus(playing)
    }

    onError = (e: any) => {
        // Values for 'error' attribute of element and event
        // 1=MEDIA_ERR_ABORTED, 2=MEDIA_ERR_NETWORK, 3=MEDIA_ERR_DECODE, 4=MEDIA_ERR_SRC_NOT_SUPPORTED
        const { onPlayingStatus } = this.props

        log(
            '###VideoPlayerCore onError',
            fmt({
                code: e.target?.error?.code,
                message: e.target?.error?.message
            })
        )

        onPlayingStatus(false)
    }

    onEnded = () => {
        log('onEnded')
        const { onPlayingStatus, onStop } = this.props

        // if (fullScreen) this.cancelFullScreen()

        onPlayingStatus(false)
        onStop()
    }

    onPause = () => {
        log('onPause')
        const { onPlayingStatus } = this.props
        onPlayingStatus(false)
    }

    onCanPlayThrough = (vv: ViewableVideo) => {
        const { onCanPlayThrough } = this.props

        const _video = vv.video
        const videoInfo = this.videoControls[vv.video._id]
        const { vc } = videoInfo

        if (vc.duration === Infinity) {
            /**
             * Infinity means that the video does not know its own length yet.
             * Seek to the end and back to the beginning to force the length to be set.
             * This will trigger another 'canPlayThrough' which will inform the parent component
             * of the actual length.
             */
            vc.currentTime = 100000 // seek to end
            this.seeking = true
            setTimeout(() => {
                this.seeking = false
                vc.currentTime = 0.2 // back to origin
            }, 200)
            return
        }

        /**
         * If still seeking do not signal parent, otherwise the parents autoplay will
         * be interrupted by the time reset above performed after the seek.
         */
        if (this.seeking) return

        log('onCanPlayThrough')
        onCanPlayThrough(_video, vc.duration)
    }

    // -------------------------------------
    // ---- EXTERNALLY CALLED FUNCTIONS ----
    // -------------------------------------

    // May be invoked internally or externally.
    // Stops video if current playing.
    // Sets the visible video and i'ts current position
    public setVideo = (video: PassageVideo, position: number) => {
        const { visibleVideo } = this
        log('setVideo (and stop)', fmt({ video, visibleVideo, position }))

        this.stop()

        this.selectVideo(video)

        if (!this.vc) {
            return
        }

        this.vc.currentTime = position
    }

    setVideoControl(id: string, newVc: HTMLVideoElement | null) {
        // log('setVideoControl', id, newVc)
        if (!newVc) return

        this.videoControls[id] = { vc: newVc }
    }

    setPlaybackRate(rate: number) {
        if (!this.vc) {
            return
        }

        this.vc.playbackRate = rate
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    public play = async () => {
        const { disablePlay, playbackRate } = this.props
        const { playing, vc, visibleVideo } = this

        if (!vc || playing || disablePlay) {
            log('not playing', JSON.stringify({ vc, playing, disablePlay }))
            return
        }

        if (this.vcNotSet('play')) return

        log('play', fmt({ visibleVideo }))

        this.setPlaybackRate(playbackRate)

        await vc.play()
        log('play started')

        this.poller?.startUpdater()
    }

    // Pause video if it is playing.
    // Stop time updater if it is running.
    // Notify onPlayingStatus(false) and onStop()
    public stop = () => {
        const { playing, vc } = this
        const { onPlayingStatus, onStop } = this.props
        log(`stop`, fmt({ playing }))

        if (playing && vc) {
            vc.pause()
        }
        this.playing = false

        this.poller?.stopUpdater()

        onPlayingStatus(false)
        onStop?.()
    }

    selectVideo(video: PassageVideo) {
        const { vc, playing } = this

        this.poller?.stopUpdater()

        if (playing && vc) {
            vc.pause()
        }
        this.playing = false

        this.visibleVideo = video

        const videoInfo = this.videoControls[video._id]
        this.vc = (videoInfo && videoInfo.vc) || undefined
        if (!this.vc) {
            log('###setupVideo no vc', fmt({ video, keys: Object.keys(this.videoControls) }))
            return
        }

        this.poller = new VideoPlayerPoller(this.vc, this.onTick, this.onPlayingStatus)
    }

    // ----------------------------
    // ---- internal functions ----
    // ----------------------------

    vcNotSet(message?: string) {
        if (this.vc) return false

        log('###checkVc failed', message)
        return true
    }

    // public requestFullScreen() {
    //     let { vc } = this
    //     vc && vc.requestFullscreen().then().catch()
    // }

    // public cancelFullScreen() {
    //     document.exitFullscreen().then().catch()
    // }

    render() {
        const { vvc, className, children, muted } = this.props
        const { visibleVideo } = this
        // log('render', fmt({visibleVideo}))

        if (!vvc.downloaded) {
            return null
        }

        if (!vvc.allSourcesPresent) {
            return <div className="video-message">.....</div>
        }

        const _className = `video-player${className ? ` ${className}` : ''}`

        const isHidden = (vv: ViewableVideo) => {
            // log(`isHidden ${vv.video._id}/${visibleVideo?._id} = ${vv.video._id !== visibleVideo?._id}`)
            return vv.video._id !== visibleVideo?._id
        }

        // We have a collection of videos with their sources already loaded.
        // We only want to allow the user to see one of these at a time.

        return (
            <div className={_className}>
                {vvc.viewableVideos.map((v) => (
                    <div className="video-player-video" key={v.video._id} hidden={isHidden(v)}>
                        <video
                            className="video-player-video-video"
                            ref={(vc) => this.setVideoControl(v.video._id, vc)}
                            src={v.src}
                            onError={this.onError}
                            onEnded={this.onEnded}
                            onPause={this.onPause}
                            onCanPlayThrough={() => this.onCanPlayThrough(v)}
                            hidden={isHidden(v)}
                            muted={muted}
                        />
                        {!isHidden(v) && children}
                    </div>
                ))}
            </div>
        )
    }
}
