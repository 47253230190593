import { Modal, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { DownloadExportProgress } from './DownloadExportProgress'
import { ResultsRow } from './ResultsRow'
import { CacheType, CachingProgress, ExportProgress, MediaType, PublishedBible } from '../../../types'
import { useTranslationResourceCaching } from '../../app/TranslationResourceCachingContext'
import { Option } from '../../projectSettings/projectResources/MultiSelect'
import { CancelButton, OKButton, GenericIconButton } from '../../utils/Buttons'
import { chunkArray } from '../../utils/Helpers'

interface ResultsPageProps {
    cacheType: CacheType
    mediaType?: MediaType
    publishedBible?: PublishedBible
    selectedOptions: Option<number>[]
    closeModal: () => void
    exportProgress: ExportProgress
    exportResources: boolean
    resetModal?: () => void
}

export const ResultsPage = ({
    cacheType,
    mediaType,
    publishedBible,
    selectedOptions,
    closeModal,
    exportProgress,
    exportResources,
    resetModal
}: ResultsPageProps) => {
    const {
        t,
        i18n: { language }
    } = useTranslation()
    const { getProgress } = useTranslationResourceCaching()

    const progresses = selectedOptions.map((option) =>
        getProgress({ bookNumber: option.value, bibleVersion: publishedBible, cacheType, language, mediaType })
    )

    const isDoneDownloading = progresses.every(
        (progress) => progress && [CachingProgress.CACHED, CachingProgress.ERROR].includes(progress)
    )

    const isDone =
        isDoneDownloading &&
        (!exportResources || [ExportProgress.FINISHED, ExportProgress.ERROR].includes(exportProgress))

    const chunkSize = selectedOptions.length > 20 ? Math.ceil(selectedOptions.length / 3) : selectedOptions.length
    const chunkedOptions = chunkArray(selectedOptions, chunkSize)
    const bibleAbbreviation = cacheType === CacheType.BIBLES && publishedBible?.abbreviation

    return (
        <>
            <Modal.Body>
                <div className="modal-subheading">
                    {bibleAbbreviation && <span className="bible-abbreviation">{bibleAbbreviation}</span>}
                    {!bibleAbbreviation && <span className="cache-type"> {t(cacheType)}</span>}
                    {mediaType && <span className="media-type"> {t(mediaType)}</span>}
                </div>
                <div className="modal-results">
                    {chunkedOptions.map((options) => (
                        <Table striped key={JSON.stringify(options)}>
                            <tbody>
                                {options.map((option) => (
                                    <ResultsRow
                                        key={option.value}
                                        option={option}
                                        bibleVersion={publishedBible}
                                        cacheType={cacheType}
                                        mediaType={mediaType}
                                    />
                                ))}
                            </tbody>
                        </Table>
                    ))}
                </div>
                {exportResources && <DownloadExportProgress loading={!isDone} exportProgress={exportProgress} />}
            </Modal.Body>
            <Modal.Footer className="flex-centered">
                <div className="button-row">
                    <OKButton enabled={isDone} onClick={closeModal} className="ok-button" tooltip={t('OK')} />
                    {isDone && resetModal && (
                        <GenericIconButton
                            iconName="fa-circle-left"
                            className="modal-go-back-icon"
                            tooltip={t('goBackToDownloadResources')}
                            onClick={resetModal}
                            enabled
                        />
                    )}
                    {!isDone && (
                        <CancelButton
                            enabled
                            onClick={() => {
                                location.reload()
                            }}
                            className="cancel-button"
                            tooltip={t('Cancel')}
                        />
                    )}
                </div>
            </Modal.Footer>
        </>
    )
}
