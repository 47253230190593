import React, { FC } from 'react'

import { CustomPicker, CustomPickerInjectedProps } from 'react-color'
import { Hue, Saturation } from 'react-color/lib/components/common'

import './ColorPicker.css'

const HuePointer: FC = () => <div className="hue-pointer" />
const SaturationPointer: FC = () => <div className="saturation-pointer" />

const ColorPickerBridge: FC<CustomPickerInjectedProps> = ({ hex, hsl, rgb, hsv, onChange }) => {
    return (
        <div className="color-picker-controls">
            <div className="saturation-slider">
                <Saturation {...{ hex, hsl, hsv, rgb, onChange }} pointer={SaturationPointer} />
            </div>
            <div className="hue-slider">
                <Hue {...{ hex, hsl, hsv, rgb, onChange }} direction="vertical" pointer={HuePointer} />
            </div>
        </div>
    )
}

const CustomColorPicker = CustomPicker(ColorPickerBridge)

interface IColorPicker {
    color: string
    setColor?: (hexColor: string) => void
}

const ColorPicker: FC<IColorPicker> = ({ color, setColor }) => {
    return (
        <div className="color-picker">
            <CustomColorPicker
                color={color}
                onChangeComplete={(newColor) => setColor?.(newColor.hex)}
                hex={undefined}
                hsl={undefined}
                hsv={undefined}
                rgb={undefined}
                source={undefined}
                oldHue={undefined}
            />
        </div>
    )
}

export default ColorPicker
