import { observer } from 'mobx-react'

import { EditableProjectTask } from './EditableProjectTask'
import ProjectTaskAdder from './ProjectTaskAdder'
import { ProjectStage } from '../../../models3/ProjectStage'
import { ProjectTask } from '../../../models3/ProjectTask'
import { Root } from '../../../models3/Root'
import { displayError } from '../../utils/Errors'

interface ITask {
    index: number
    task: ProjectTask
    stage: ProjectStage
    addTask: (name: string, index: number) => void
    rt: Root
}

const Task = observer(({ index, task, stage, addTask, rt }: ITask) => {
    const { iAmAdmin, useMobileLayout, project } = rt
    return (
        <div className="task">
            {index === 0 && iAmAdmin && !useMobileLayout && (
                <ProjectTaskAdder stage={stage} addTask={(name) => addTask(name, index)} />
            )}
            <EditableProjectTask
                task={task}
                stage={stage}
                deleteTask={() => stage.removeTask(task._id, project)}
                rt={rt}
            />
            {iAmAdmin && !useMobileLayout && (
                <ProjectTaskAdder stage={stage} addTask={(name) => addTask(name, index + 1)} />
            )}
        </div>
    )
})

interface IEditableTaskList {
    stage: ProjectStage
    rt: Root
}

export const EditableTaskList = observer(({ stage, rt }: IEditableTaskList) => {
    const addTask = async (name: string, taskIndex: number) => {
        const { plans } = rt.project
        if (!plans.length) {
            return
        }

        const plan = plans[0]
        try {
            await stage.addTask(plan, taskIndex, name)
        } catch (error) {
            displayError(error)
        }
    }

    const { iAmAdmin, useMobileLayout } = rt
    return (
        <div>
            {stage.tasks.length > 0 && (
                <div className="tasks">
                    {stage.tasks.map((task, index) => (
                        <Task key={task._id} index={index} task={task} stage={stage} addTask={addTask} rt={rt} />
                    ))}
                </div>
            )}
            {stage.tasks.length === 0 && iAmAdmin && !useMobileLayout && (
                <ProjectTaskAdder stage={stage} addTask={(name) => addTask(name, 0)} />
            )}
        </div>
    )
})

export default Task
