import { DBObject } from './DBObject'

// A highlighted range of words in a written enhanced resource text, e.g. RSV89

export class PassageHighlight extends DBObject {
    public color = 0

    public firstId = ''

    public lastId = ''

    public resourceName = ''

    toDocument() {
        const { color, firstId, lastId, resourceName } = this
        return this._toDocument({ color, firstId, lastId, resourceName })
    }

    copy() {
        let copy = new PassageHighlight(this._id, this.db)
        copy = Object.assign(copy, this)
        return copy
    }

    // Look through highlights and find the last highlight for this resource
    // and word id. Return its color. If not found return 0.
    static highlighted(highlights: PassageHighlight[], spanId: string, resourceName: string) {
        if (!spanId) return 0

        for (let i = highlights.length - 1; i >= 0; --i) {
            const h = highlights[i]
            if (h.resourceName !== resourceName) continue
            if (spanId >= h.firstId && spanId <= h.lastId) {
                return h.color
            }
        }

        return 0
    }
}
