import { useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { TermsTable } from './TermsTable'
import { BiblicalTermMarker } from '../../models3/BiblicalTermMarker'
import { Root } from '../../models3/Root'
import ERTermModal from '../enhancedResources/ERTermModal'
import { HelpLinkWithMessage } from '../utils/Buttons'
import LoadingMessage from '../utils/InitializationMessage'

export const ProjectBiblicalTermsViewer = observer(({ rt }: { rt: Root }) => {
    const { t } = useTranslation()
    const [termId, setTermId] = useState('')

    const { initialized, loadingMessage, project } = rt

    if (!initialized) {
        return <LoadingMessage loadingMessage={loadingMessage} />
    }

    const markers: BiblicalTermMarker[] = []
    for (const portion of project.portions) {
        for (const passage of portion.passages) {
            const latestRecording = passage.getDefaultVideo('')
            if (latestRecording) {
                markers.push(...latestRecording.getVisibleBiblicalTermMarkers(passage))
            }
        }
    }
    const terms = project.terms.filter((term) => term.isKeyTerm)

    return (
        <div>
            <h3>{t('projectBiblicalTerms')}</h3>
            {termId && <ERTermModal rt={rt} termId={termId} setTermId={setTermId} />}
            {terms.length === 0 ? (
                <HelpLinkWithMessage message={t('noBiblicalTerms')} id="biblical-terms" />
            ) : (
                <TermsTable terms={terms} markers={markers} viewTermInfo={setTermId} />
            )}
        </div>
    )
})
