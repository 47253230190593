import { LocalStorageKeys } from '../components/app/slttAvtt'

const intest = localStorage.getItem(LocalStorageKeys.INTEST) === 'true'

/** Get the duration of a file or a blob representing a video */
export async function getVideoDuration(object: File | Blob): Promise<number> {
    // This function hangs during Cypress testing so stub it out
    if (intest) return 60

    const src = URL.createObjectURL(object)
    const videoElement = document.createElement('video')
    videoElement.src = src
    videoElement.preload = 'metadata'

    return new Promise((resolve) => {
        videoElement.onloadedmetadata = () => {
            let duration = videoElement.duration
            // Currently, a bug exists in Chrome in which the duration of a video
            // is sometimes incorrect.
            // https://bugs.chromium.org/p/chromium/issues/detail?id=642012
            // The workaround is to seek past the end of the video, which causes
            // the duration to be set properly
            if (duration === Infinity) {
                videoElement.currentTime = 1e101
                videoElement.ontimeupdate = () => {
                    duration = videoElement.duration
                    URL.revokeObjectURL(videoElement.src)
                    resolve(duration)
                }
            } else {
                URL.revokeObjectURL(videoElement.src)
                resolve(duration)
            }
        }
    })
}
