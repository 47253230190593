import { observable } from 'mobx'

import { FilteredPortionPassages } from './StatusEditor'
import { Passage } from '../../models3/Passage'
import { Portion } from '../../models3/Portion'
import { ProjectPlan } from '../../models3/ProjectPlan'
import { ProjectTask } from '../../models3/ProjectTask'

export class DragAndDropData {
    @observable columns: ColumnData[]

    constructor(columns: ColumnData[]) {
        this.columns = columns
    }

    findColumn(id: string) {
        return this.columns.find((column) => column.id === id)
    }
}

export interface ColumnData {
    items: PassageData[]
    id: string
    task: ProjectTask
}

export interface PassageData {
    passage: Passage
    portion: Portion
}

const createColumns = (projectPlan: ProjectPlan) => {
    const columns: ColumnData[] = []
    projectPlan.stages.forEach((stage) =>
        stage.tasks.forEach((task) => {
            const column: ColumnData = {
                items: [],
                id: task._id,
                task
            }
            columns.push(column)
        })
    )
    return columns
}

const placePassagesInColumns = (columns: ColumnData[], portionPassages: FilteredPortionPassages[]) => {
    const updatedColumns = columns
    portionPassages.forEach((portionPassage) => {
        portionPassage.passages.forEach((passage) => {
            const { portion } = portionPassage
            if (!passage.task) {
                updatedColumns[0].items.push({
                    passage,
                    portion
                })
            } else {
                const index = updatedColumns.findIndex((c) => c.task.id === passage.task)
                updatedColumns[index >= 0 ? index : 0].items.push({
                    passage,
                    portion
                })
            }
        })
    })

    return updatedColumns
}

export function createDataModel(portionPassages: FilteredPortionPassages[], projectPlan: ProjectPlan): DragAndDropData {
    const columns = createColumns(projectPlan)
    const updatedColumns = placePassagesInColumns(columns, portionPassages)
    return new DragAndDropData(updatedColumns)
}
