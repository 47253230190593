import { t } from 'i18next'

import { RefRange, refToBookId } from './RefRange'
import { avttVersion } from '../components/app/slttAvtt'
import { nnn } from '../components/utils/Helpers'

export const PERICOPES_MEDIA_PATH = `${process.env.REACT_APP_RESOURCE_URL}/public/SRV/exegeticalResources/media`

export const getExegeticalResourceBasePath = (language: string) =>
    `${process.env.REACT_APP_RESOURCE_URL}/public/SRV/exegeticalResources/${language}`

export interface BookIndex {
    bookChapterId: string
    audioFiles: string[]
}

export interface ExegeticalResourceBookIndex {
    generatedAt: number
    bookIndexes: BookIndex[]
}

interface Translation {
    languageCode: string
    title: string
    path?: string
}

export interface Medium {
    id: string
    key: string
    type: string
    translations: Translation[]
    path?: string
}

interface PericopeMedia {
    [pericopeId: string]: Medium[]
}

interface PericopeMediaIndex {
    generatedAt: number
    pericopeMedia: PericopeMedia
}

export interface Step {
    id: string
    title: string
    audio?: string
    media?: Medium[]
    html?: string
    json?: string
    number?: number
}

export interface Pericope {
    id: string
    startChapter: number
    startVerse: number
    endChapter: number
    endVerse: number
    verseRange: string
    steps: Step[]
}

const MIN_GENERATED_AT = 0

export const fetchExegeticalResourceBookIndexesResponse = async (language: string) => {
    // force re-caching whenever avttVersion changes
    const url = `${getExegeticalResourceBasePath(language)}/index.json?avttVersion=${avttVersion}`

    try {
        const response = await fetch(url)

        if (!response.ok) {
            console.error(`${response.url}: ${response.statusText}`)
            return
        }

        return response
    } catch (error) {
        console.error(url, error)
    }
}

export const fetchExegeticalResourceBookIndexes = async (language: string): Promise<ExegeticalResourceBookIndex> => {
    const response = await fetchExegeticalResourceBookIndexesResponse(language)
    if (response) {
        const bookIndexes = await response.json()
        if (Array.isArray(bookIndexes)) {
            // old format
            return { generatedAt: MIN_GENERATED_AT, bookIndexes: bookIndexes as BookIndex[] }
        }

        return bookIndexes as ExegeticalResourceBookIndex
    }

    // just swallow error, since language index may not exist
    return { generatedAt: MIN_GENERATED_AT, bookIndexes: [] }
}

export const fetchPericopeAudioFile = async (language: string, audioFile: string) => {
    const url = `${getExegeticalResourceBasePath(language)}/${audioFile}`
    const response = await fetch(url)

    if (!response.ok) {
        throw Error(`${response.url}: ${response.statusText}`)
    }

    // Process blob in following call if necessary. For caching, only fetch is needed.
    return response
}

export const fetchPericopeMediaIndexResponse = async () => {
    const url = `${PERICOPES_MEDIA_PATH}/index.json?avttVersion=${avttVersion}`
    const response = await fetch(url)
    if (!response.ok) {
        throw Error(`${response.url}: ${response.statusText}`)
    }
    return response
}

export const fetchPericopeMediaIndex = async () => {
    const response = await fetchPericopeMediaIndexResponse()
    const index = await response.json()
    return index as PericopeMediaIndex
}

const pericopesInRefRanges = (bbb: string, pericopes: Pericope[], refRanges: RefRange[]) =>
    pericopes.filter(({ startChapter, startVerse, endChapter, endVerse }) => {
        const pericopeRef = new RefRange(
            `${bbb}${nnn(startChapter)}${nnn(startVerse)}`,
            `${bbb}${nnn(endChapter)}${nnn(endVerse)}`
        )
        return pericopeRef.overlaps(refRanges)
    })

export const fetchChapterPericopesResponse = async (language: string, bbbccc: string) => {
    const url = `${getExegeticalResourceBasePath(language)}/${bbbccc}.json`
    const response = await fetch(url)
    if (!response.ok) {
        throw Error(`${response.url}: ${response.statusText}`)
    }
    return response
}

export const fetchChapterPericopes = async (language: string, bbbccc: string) => {
    const response = await fetchChapterPericopesResponse(language, bbbccc)
    const pericopes = await response.json()
    return pericopes as Pericope[]
}

export const fetchPericopes = async (language: string, refs: RefRange[]) => {
    const { pericopeMedia } = await fetchPericopeMediaIndex()
    const allPericopes = new Map<string, Pericope[]>()

    for (const ref of refs) {
        for (const bbbccc of ref.chapterIterator()) {
            const pericopes = await fetchChapterPericopes(language, bbbccc)
            const pericopesInRefs = pericopesInRefRanges(refToBookId(bbbccc), pericopes, refs)

            pericopesInRefs.forEach((pericope) => {
                const mediaItems = pericopeMedia[pericope.id]
                if (mediaItems?.length) {
                    pericope.steps.push({
                        id: `${pericope.id}-media`,
                        title: t('visualResources'),
                        media: mediaItems
                    })
                }
            })

            if (pericopesInRefs.length) {
                allPericopes.set(bbbccc, pericopesInRefs)
            }
        }
    }

    return allPericopes
}
