import React, { FunctionComponent } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { PlayButton, PauseButton } from '../utils/Buttons'

import './VideoTimelinePlayButtons.css'

interface IVideoTimelinePlayButtons {
    isPlaying: boolean
    playAll: () => void
    pause: () => void
}

const VideoTimelinePlayButtons: FunctionComponent<IVideoTimelinePlayButtons> = ({ isPlaying, playAll, pause }) => {
    const { t } = useTranslation()

    if (isPlaying) {
        return (
            <PauseButton
                className="video-timeline-pause-button"
                enabled
                onClick={() => pause()}
                tooltip={t('Pause.')}
            />
        )
    }

    // We originally had two play buttons here.
    // One to play only the citation. One to play everything.
    // I think that is more complicated than it needs to be.
    // Initially set player to start of citation and play.
    // Only provide the 'play from current location function.
    // That makes the play button work like other play buttons.

    // If existing users really miss the 'play range' functionality
    // we could make shift-click do that.
    // We could also make shift-click mean play segmegment the main window.

    return (
        <div>
            {/* <PlayFromBeginningButton 
                className='video-timeline-play-beginning-button'
                onClick={() => playAll()}
            /> */}
            <PlayButton
                tooltip={t('Play')}
                enabled
                selectionPresent={false}
                className="video-timeline-play-button"
                // onClick={playRange}
                onClick={() => playAll()}
            />
        </div>
    )
}

export default observer(VideoTimelinePlayButtons)
