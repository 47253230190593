import { useTranslation } from 'react-i18next'

import SLToolWithCognito from './SLToolWithCognito'

const _window = window as any

function isChrome() {
    const isChromium = _window.chrome !== null && typeof _window.chrome !== 'undefined'
    const winNav = _window.navigator
    const vendorName = winNav.vendor
    const isOpera = typeof _window.opr !== 'undefined'
    const isIEedge = winNav.userAgent.indexOf('Edge') > -1
    const isIOSChrome = winNav.userAgent.match('CriOS')

    return isIOSChrome || (isChromium && vendorName === 'Google Inc.' && isOpera === false && isIEedge === false)
}

function isIOS() {
    if (typeof _window === 'undefined' || typeof navigator === 'undefined') {
        return false
    }

    const userAgent = navigator.userAgent

    return (
        /\b(iPad|iPhone|iPod)\b/.test(userAgent) &&
        /WebKit/.test(userAgent) &&
        !/Edge/.test(userAgent) &&
        !_window.MSStream
    )
}

export const App = () => {
    const { t, i18n } = useTranslation()

    if (isIOS()) {
        return <h2>{t('noIOSSupport')}</h2>
    }

    if (!isChrome()) {
        return <h2>{t('You must use Chrome browser for this website.')}</h2>
    }

    document.body.dir = i18n.dir()
    document.body.lang = i18n.language

    return <SLToolWithCognito />
}
