import { confirmAlert } from 'react-confirm-alert'
import { useTranslation } from 'react-i18next'

import { PassageSegment } from '../../models3/PassageSegment'
import { PassageVideo } from '../../models3/PassageVideo'
import { Root } from '../../models3/Root'
import { DeleteSegmentButton } from '../utils/Buttons'
import { displayError } from '../utils/Errors'

interface ISegmentDeleter {
    rt: Root
    segment: PassageSegment
    passageVideo: PassageVideo
    enabled?: boolean
}

const SegmentDeleter = ({ rt, segment, enabled, passageVideo }: ISegmentDeleter) => {
    const { t } = useTranslation()
    const confirmDeletion = (doDeletion: () => void) => {
        confirmAlert({
            title: t('DELETE this segment?'),
            message: t('recordingSegmentDeleteMessage'),
            buttons: [
                {
                    label: t('Keep'),
                    onClick: () => {}
                },
                {
                    label: t('Delete'),
                    onClick: doDeletion
                }
            ]
        })
    }

    const deleteSegment = () => {
        confirmDeletion(() => {
            passageVideo
                .removeSegment(segment._id)
                .then(() => rt.resetCurrentTime(segment.time))
                .catch((err) => {
                    displayError(err)
                })
        })
    }

    const segments = passageVideo.getVisibleBaseSegments()
    if (segments.length === 0) return null

    let tooltip = t('Delete this segment.')

    if (segment._id === segments[0]._id) {
        tooltip = t('You cannot delete the first segment.')
    }

    return <DeleteSegmentButton enabled={enabled} tooltip={tooltip} onClick={deleteSegment} />
}

export default SegmentDeleter
