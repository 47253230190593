import { engageAppUrl } from '../app/slttAvtt'

const GROUP_PROJECT_SEPARATOR = ':' // not url safe
const REVIEW_GROUP_PROJECT_SEPARATOR = '_' // url safe, and not allowed in project names
const REVIEW_ID_SEPARATOR = '~' // url safe, and not allowed in project names

export const buildEngageUrl = (projectKey: string) =>
    `${engageAppUrl}/projects/rPrj${REVIEW_ID_SEPARATOR}${projectKey.replace(
        GROUP_PROJECT_SEPARATOR,
        REVIEW_GROUP_PROJECT_SEPARATOR
    )}`
