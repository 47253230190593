import { FunctionComponent, createContext, useContext, useEffect, useMemo, useState } from 'react'

import { LocalStorageKeys } from '../app/slttAvtt'

export interface DisplaySettings {
    headers: boolean
    imageLinks: boolean
    termLinks: boolean
}

interface PublishedBibleSettings {
    display: DisplaySettings

    setDisplay: (display: DisplaySettings) => void
}

const defaultDisplay: DisplaySettings = {
    headers: true,
    imageLinks: true,
    termLinks: true
}

export const PublishedBibleSettingsContext = createContext<PublishedBibleSettings>({
    display: defaultDisplay,

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setDisplay: (display: DisplaySettings) => {}
})

const PublishedBibleSettingsProvider: FunctionComponent = ({ children }) => {
    const [display, setDisplay] = useState<DisplaySettings>(() => {
        const value = localStorage.getItem(LocalStorageKeys.PUBLISHED_BIBLE_SETTINGS)

        if (value === null || value === '') {
            return defaultDisplay
        }

        return JSON.parse(value)
    })

    useEffect(() => {
        localStorage.setItem(LocalStorageKeys.PUBLISHED_BIBLE_SETTINGS, JSON.stringify(display))
    }, [display])

    const initialValue = useMemo(() => ({ display, setDisplay }), [display, setDisplay])

    return (
        <PublishedBibleSettingsContext.Provider value={initialValue}>{children}</PublishedBibleSettingsContext.Provider>
    )
}

type PublishedBibleSettingsConsumerProps = {
    children: (props: { context: PublishedBibleSettings }) => JSX.Element
}

const PublishedBibleSettingsConsumer: FunctionComponent<PublishedBibleSettingsConsumerProps> = ({ children }) => {
    return (
        <PublishedBibleSettingsContext.Consumer>
            {(context) => children({ context })}
        </PublishedBibleSettingsContext.Consumer>
    )
}

function usePublishedBibleSettings() {
    return useContext(PublishedBibleSettingsContext)
}

export { PublishedBibleSettingsProvider, PublishedBibleSettingsConsumer, usePublishedBibleSettings }
