import { useTranslation } from 'react-i18next'

import { WarningList } from '../../utils/WarningList'
import { ProgressModalBodyAndFooter } from '../Modals'

interface ResultsPageProps {
    closeModal: () => void
    error: string
    loading: boolean
    hasFileToExport: boolean
    isMissingRecordings: boolean
}

export const ResultsPage = ({ closeModal, error, loading, hasFileToExport, isMissingRecordings }: ResultsPageProps) => {
    const { t } = useTranslation()
    return (
        <ProgressModalBodyAndFooter
            closeModal={closeModal}
            error={error}
            loading={loading}
            successBody={
                <div>
                    {isMissingRecordings && (
                        <div className="modal-warning">
                            <WarningList
                                warnings={[
                                    {
                                        text: navigator.onLine
                                            ? t('exportRecordingsMissing')
                                            : t('exportOfflineWarning'),
                                        key: 'exportWarning'
                                    }
                                ]}
                            />
                        </div>
                    )}
                    <div>{hasFileToExport ? t('fileExported') : t('nothingExported')}</div>
                </div>
            }
        />
    )
}
