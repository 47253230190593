import { t } from 'i18next'

import { VideoCache } from '../../models3/VideoCache'
import { VideoCacheRecord } from '../../models3/VideoCacheRecord'
import { getVideoDuration } from '../../models3/VideoDuration'
import { LocalStorageKeys } from '../app/slttAvtt'
import { fmt } from '../utils/Fmt'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:VideoUploader')

const intest = localStorage.getItem(LocalStorageKeys.INTEST) === 'true'

export interface RecordingDoneParams {
    err: any
    blobsCount: number
    url: string
    duration: number
    mimeType: string
}

export class VideoUploader {
    private blobs: Blob[] = []

    constructor(
        public path: string,
        public onRecordingDone: ({ err, blobsCount, url, duration, mimeType }: Partial<RecordingDoneParams>) => void
    ) {}

    async pushVideoBlob(blob: Blob, lastBlob: boolean) {
        log('pushVideoBlob', fmt({ size: blob.size, lastBlob }))

        this.blobs.push(blob)

        if (lastBlob) {
            await this.finishUpload()
        }
    }

    private async finishUpload() {
        const { path, blobs } = this

        let mimeType = ''
        let blobSize = 0
        for (const blob of blobs) {
            mimeType = blob.type
            blobSize += blob.size
        }

        if (!mimeType || !blobSize) {
            this.onRecordingDone({ err: t('videoUploaderInvalidBlobError') })
            return
        }

        const blob = new Blob(blobs, { type: mimeType })
        const _id = await VideoCache.copyFileToVideoCache(blob, this.path)

        const blobsCount = VideoCacheRecord.get(_id).totalBlobs

        log('finishUpload', fmt({ _id }))
        const duration = intest ? 1.5 : await getVideoDuration(blob)
        log('finishUpload', fmt({ duration }))

        // This will add the resulting item to the model
        this.onRecordingDone({ blobsCount, url: path, duration, mimeType })
    }
}
