import { CachingProgressIndicator } from './CachingProgressIndicator'
import { CacheType, MediaType, PublishedBible } from '../../../types'

import '../ProjectSettings.css'

export const BibleBookProgress = ({
    bookNumber,
    bibleVersion
}: {
    bookNumber: number
    bibleVersion: PublishedBible
}) => {
    return (
        <>
            <td className="project-resource-table-cell project-resource-table-cell-content">
                <CachingProgressIndicator
                    cacheRequest={{ bibleVersion, bookNumber, cacheType: CacheType.BIBLES, mediaType: MediaType.TEXT }}
                />
            </td>
            {bibleVersion.hasAudio && (
                <td className="project-resource-table-cell project-resource-table-cell-content">
                    <CachingProgressIndicator
                        cacheRequest={{
                            bibleVersion,
                            bookNumber,
                            cacheType: CacheType.BIBLES,
                            mediaType: MediaType.AUDIO
                        }}
                    />
                </td>
            )}
        </>
    )
}
