import { Dispatch, useEffect, useMemo, useReducer } from 'react'

import { ReducerAction, SerializedState, initialDropdownState, reducer } from './reducer'
import { Passage } from '../../../models3/Passage'
import { PassageVideo } from '../../../models3/PassageVideo'
import { Portion } from '../../../models3/Portion'
import { Project } from '../../../models3/Project'
import { ReviewProject } from '../../../models3/ReviewProject'
import { ReviewProjectPassageRecording } from '../../../models3/ReviewProjectPassageRecording'
import { Root } from '../../../models3/Root'
import { LocalStorageKeys } from '../../app/slttAvtt'

type State = {
    portion?: Portion
    passage?: Passage
    passageVideo?: PassageVideo
    reviewRecording?: ReviewProjectPassageRecording
}

const deserializeState = (project: Project, reviewProject: ReviewProject, state: SerializedState) => {
    const { selectedPortion, selectedPassage, selectedPassageVideo, selectedReviewRecording } = state

    const portion = project.portions.find((p) => p._id === selectedPortion)
    const passage = portion?.passages.find((p) => p._id === selectedPassage)
    const passageVideo = passage?.videosNotDeleted.find((v) => v._id === selectedPassageVideo)
    const reviewRecording = passageVideo
        ?.getReviewRecordings(reviewProject)
        .find((r) => r._id === selectedReviewRecording)

    return {
        portion,
        passage,
        passageVideo,
        reviewRecording
    }
}

export const useReviewProjectDropdownState: (
    rt: Root,
    reviewProject: ReviewProject
) => [State, Dispatch<ReducerAction>] = (rt: Root, reviewProject: ReviewProject) => {
    const initialState = useMemo(() => {
        const savedState = localStorage.getItem(LocalStorageKeys.REVIEW_PROJECT_CURRENT_REVIEW)
        return savedState ? JSON.parse(savedState) : initialDropdownState
    }, [])
    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {
        localStorage.setItem(LocalStorageKeys.REVIEW_PROJECT_CURRENT_REVIEW, JSON.stringify(state))
    }, [state])

    const { project } = rt
    return [deserializeState(project, reviewProject, state), dispatch]
}
