import { useState } from 'react'

import { t } from 'i18next'
import { observer } from 'mobx-react'
import { confirmAlert } from 'react-confirm-alert'

import { EditableTaskList } from './Task'
import { ProjectStage } from '../../../models3/ProjectStage'
import { Root } from '../../../models3/Root'
import TextInput from '../../utils/TextInput'

import './EditableProjectStage.css'

interface IEditableProjectStage {
    stage: ProjectStage
    deleteStage: (_id: string) => void
    rt: Root
}

export const EditableProjectStage = observer(({ stage, deleteStage, rt }: IEditableProjectStage) => {
    const [editingName, setEditingName] = useState(false)

    const validate = (value: string) => {
        value = value.trim()
        const { plans } = rt.project
        if (!plans.length) {
            return t('No project plan exists')
        }
        const plan = plans[0]
        const otherStages = plan.stages.filter((s) => s._id !== stage._id)
        if (otherStages.find((otherStage) => otherStage.name === value)) {
            return t('Duplicate name')
        }
        if (value === '') {
            return t('Empty name')
        }
        return ''
    }

    const renameStage = (newName: string) => {
        const { plans } = rt.project
        if (!plans.length) {
            return
        }
        const plan = plans[0]
        if (!validate(newName)) {
            stage.setName(plan, newName)
        }
        setEditingName(false)
    }

    const confirmDeletion = (doDeletion: () => void) => {
        confirmAlert({
            title: t('Delete stage?'),
            message: `${stage.name}: ${t('Delete this stage and all the tasks in this stage?')}`,
            buttons: [
                {
                    label: t('Keep stage'),
                    onClick: () => {}
                },
                {
                    label: t('Delete stage'),
                    onClick: doDeletion
                }
            ]
        })
    }

    const onDelete = (e: any) => {
        e.preventDefault()
        confirmDeletion(() => {
            deleteStage(stage._id)
        })
    }

    const { iAmAdmin, useMobileLayout } = rt

    let editableStageName = stage.name
    const parts = stage.name.split(/^(\d+\.?\d*\s*)*/)
    if (parts.length > 0) {
        editableStageName = parts[parts.length - 1]
    }

    return (
        <div>
            <div className="stage-header">
                {editingName ? (
                    <TextInput
                        initialValue={editableStageName}
                        message={t('Type Enter to change name or Esc to cancel.')}
                        _onEscape={() => {
                            setEditingName(false)
                        }}
                        _onEnter={renameStage}
                        validate={validate}
                    />
                ) : (
                    stage.displayedName
                )}
                {iAmAdmin && !useMobileLayout && (
                    <div className="stage-menu">
                        <span
                            className="portion-button"
                            data-toggle="tooltip"
                            data-id={`edit-${stage.name}`}
                            title={t('Rename stage.')}
                            onClick={(e) => {
                                e.preventDefault()
                                setEditingName(true)
                            }}
                        >
                            <i className="fas fa-fw fa-pencil-alt" />
                        </span>
                        <span
                            className="portion-button"
                            data-toggle="tooltip"
                            data-id={`delete-${stage.name}`}
                            title={t('Delete stage')}
                            onClick={onDelete}
                        >
                            <i className="fas fa-fw fa-trash-alt" />
                        </span>
                    </div>
                )}
            </div>
            <div className="plan-editor-task-list">
                <EditableTaskList stage={stage} rt={rt} />
            </div>
        </div>
    )
})

export default EditableProjectStage
