import { Passage } from './Passage'

// Create a passage that omits patches older than cutoffDate
export function createVisiblePassage(passage: Passage, cutoffDate: string) {
    const passageCopy = passage.copy()

    // True if the video for this Id is not newer than creationData of patchVideo
    const notNewerThanNote = (videoId: string) => {
        const _video = passageCopy.findVideo(videoId)
        if (!_video) return false

        /**
         * Using the creation date of the segment rather than the creation
         * date of the parent video BECAUSE for reasons I am not totally
         * clear on (but perhaps having to do with dragging and dropping
         * videos directly onto SLTT from the Telegram app) the patch videos
         * were in some previous cases getting weird dates causing them to
         * appear to be older than a note. Sigh.
         */
        const patchCreationDate = _video.getAllBaseSegments()[0].creationDate

        const notNewer = patchCreationDate <= cutoffDate
        // log('notNewerThanNote', fmt({
        //     notNewer,
        //     videoId,
        //     _video,
        //     noteCreationDate,
        //     videoCreationDate: _video.creationDate,
        // }))

        return notNewer
    }

    for (const video of passageCopy.videos) {
        for (const segment of video.getAllBaseSegments()) {
            segment.videoPatchHistory = segment.videoPatchHistory.filter(notNewerThanNote)
        }
    }

    return passageCopy
}
