import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { CachingProgress } from '../../../types'
import { CacheSingleBookRequest, useTranslationResourceCaching } from '../../app/TranslationResourceCachingContext'
import { CheckIcon, GenericIcon, LoadingIcon, UpdateAvailableIcon } from '../../utils/Icons'

interface Props {
    cacheRequest: Omit<CacheSingleBookRequest, 'language'> // will be supplied here
    showErrorState?: boolean
}

export const CachingProgressIndicator = observer(({ cacheRequest, showErrorState }: Props) => {
    const {
        t,
        i18n: { language }
    } = useTranslation()
    const { getProgress } = useTranslationResourceCaching()

    const cachingProgress = getProgress({ ...cacheRequest, language })
    if (cachingProgress !== undefined) {
        if (cachingProgress === CachingProgress.UPDATE_AVAILABLE) {
            return <UpdateAvailableIcon />
        }
        if (cachingProgress === CachingProgress.IN_PROGRESS) {
            return <LoadingIcon className="book-download-button download-progress-icon" />
        }
        if (cachingProgress === CachingProgress.CACHED) {
            return (
                <CheckIcon
                    className="small-success-icon download-progress-icon"
                    tooltip={t('downloadToDeviceSuccess')}
                />
            )
        }
        if (cachingProgress === CachingProgress.ERROR && showErrorState) {
            return (
                <GenericIcon
                    iconName="fa-circle-exclamation download-progress-icon"
                    className="small-error-icon"
                    tooltip={t('Error')}
                    iconType="fas"
                />
            )
        }
    }

    return null
})
