import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { SlttHelp } from './Buttons'
import { NoInternetIcon } from './Icons'
import { useAppRoot } from '../app/RootContext'

import '../app/SLTool.css'

export const OfflineMessage: FC = () => {
    const appRoot = useAppRoot()
    const { t } = useTranslation()

    const { useMobileLayout } = appRoot

    const offlineMessageId = 'sign-out-info'

    return (
        <SlttHelp id={offlineMessageId} tooltip={t('noInternetMessage')} place="bottom">
            <span id={offlineMessageId}>
                <NoInternetIcon className="no-internet-icon" />
                {!useMobileLayout && <span className="no-internet-text">{t('noInternet')}</span>}
            </span>
        </SlttHelp>
    )
}
