import React, { Component } from 'react'

import { observable } from 'mobx'
import { observer } from 'mobx-react'

interface INumberInput {
    message: string
    initialValue: number
    min?: number
    onEnter: (value: number) => void
    onEscape: () => void
}

class NumberInput extends Component<INumberInput> {
    @observable value = ''

    componentDidMount() {
        const { initialValue } = this.props
        this.value = initialValue.toString()
    }

    onChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.value = e.target.value
    }

    onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        const { onEnter, onEscape } = this.props
        const { value } = this

        e.stopPropagation()

        if (e.key === 'Enter') {
            const _value = parseFloat(value)
            onEnter(_value)
            return
        }

        if (e.key === 'Escape') {
            onEscape()
            this.value = ''
        }
    }

    render() {
        const { min, message, onEnter } = this.props
        const { value } = this
        return (
            <div>
                <input
                    className="difficulty-input"
                    autoFocus
                    required
                    type="number"
                    value={value}
                    min={min}
                    onBlur={() => {
                        onEnter(parseFloat(value))
                    }}
                    onChange={this.onChange.bind(this)}
                    onKeyDown={this.onKeyDown.bind(this)}
                />
                <div className="number-input-text">
                    <span>{message}</span>
                </div>
            </div>
        )
    }
}

export default observer(NumberInput)
