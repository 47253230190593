import { useState } from 'react'

import { observer } from 'mobx-react'
import { FormGroup, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { usePublishedBibleSettings } from './PublishedBibleSettingsContext'
import { CancelButton, OKButton } from '../utils/Buttons'
import { Switch } from '../utils/Switch'

import './PublishedBibleSettings.css'

type PublishedBibleSettingsProps = {
    closeModal: () => void
}

const PublishedBibleSettings = observer(({ closeModal }: PublishedBibleSettingsProps) => {
    const { t } = useTranslation()
    const { display, setDisplay } = usePublishedBibleSettings()

    const [displayHeaders, setDisplayHeaders] = useState(display.headers)
    const [displayImageLinks, setDisplayImageLinks] = useState(display.imageLinks)
    const [displayTermLinks, setDisplayTermLinks] = useState(display.termLinks)

    return (
        <Modal show onHide={closeModal} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t('publishedBibleSettings')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <FormGroup controlId="published-bible-settings">
                        <div className="publishedBibleSettings">
                            {t('publishedBibleSettingsSectionHeaders')}
                            <Switch
                                setValue={setDisplayHeaders}
                                value={displayHeaders}
                                tooltip={t('publishedBibleSettingsSectionHeaders')}
                            />
                        </div>
                        <div className="publishedBibleSettings">
                            {t('publishedBibleSettingsImageLinks')}
                            <Switch
                                setValue={setDisplayImageLinks}
                                value={displayImageLinks}
                                tooltip={t('publishedBibleSettingsImageLinks')}
                            />
                        </div>
                        <div className="publishedBibleSettings">
                            {t('publishedBibleSettingsBiblicalTermLinks')}
                            <Switch
                                setValue={setDisplayTermLinks}
                                value={displayTermLinks}
                                tooltip={t('publishedBibleSettingsBiblicalTermLinks')}
                            />
                        </div>
                    </FormGroup>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <div className="button-row">
                    <OKButton
                        enabled
                        onClick={() => {
                            setDisplay({
                                headers: displayHeaders,
                                imageLinks: displayImageLinks,
                                termLinks: displayTermLinks
                            })
                            closeModal()
                        }}
                        className="modal-footer-button ok-icon"
                        buttonClassName=""
                        tooltip={t('Save')}
                    />
                    <CancelButton
                        enabled
                        onClick={() => {
                            closeModal()
                        }}
                        className="modal-footer-button"
                        tooltip={t('Cancel')}
                    />
                </div>
            </Modal.Footer>
        </Modal>
    )
})

export default PublishedBibleSettings
