import { t } from 'i18next'

import { DateFormat } from './Project'
import { LocalStorageKeys } from '../components/app/slttAvtt'

const intest = localStorage.getItem(LocalStorageKeys.INTEST) === 'true'

export function dateExists(existingDates: Date[], date: Date) {
    const existingDate = existingDates.find((e) => {
        return (
            e.getFullYear() === date.getFullYear() &&
            e.getMonth() === date.getMonth() &&
            e.getHours() === date.getHours() &&
            e.getMinutes() === date.getMinutes()
        )
    })
    return existingDate !== undefined
}

export function adjustForDuplicates(existing: Date[], date: Date) {
    const adjustedDate = new Date(date)
    let minutes = adjustedDate.getMinutes()
    let foundUnusedDate = !dateExists(existing, adjustedDate)

    let numTries = 1
    while (!foundUnusedDate && minutes < 59) {
        adjustedDate.setMinutes(minutes + 1)
        minutes = adjustedDate.getMinutes()
        numTries++
        foundUnusedDate = !dateExists(existing, adjustedDate)
    }

    if (!foundUnusedDate && numTries < 60) {
        adjustedDate.setMinutes(0)
        minutes = adjustedDate.getMinutes()
        foundUnusedDate = !dateExists(existing, adjustedDate)
        numTries++
        while (!foundUnusedDate && numTries !== 60) {
            adjustedDate.setMinutes(minutes + 1)
            minutes = adjustedDate.getMinutes()
            numTries++
            foundUnusedDate = !dateExists(existing, adjustedDate)
        }
    }

    if (!foundUnusedDate) {
        throw Error(
            t('Could not upload file because another file with the same creation date has already been uploaded.')
        )
    }

    return adjustedDate
}

export function isNewerThanOneMonth(date: string) {
    const dateDate = Date.parse(date)
    if (isNaN(dateDate)) {
        return true
    }
    const millisecondsPerMonth = 30 * 24 * 3600 * 1000
    const aMonthAgo = Date.now() - millisecondsPerMonth
    const newerThanOneMonth = dateDate - aMonthAgo > 0
    return newerThanOneMonth
}

export const newerThanCutoffDate = (creationDateString: string, cutoff: Date) => {
    const creationDate = Date.parse(creationDateString)
    if (isNaN(creationDate)) {
        return false
    }

    return creationDate > cutoff.getTime()
}

export function daysAgo(numDays: number) {
    const millisecondsPerDay = 60 * 60 * 24 * 1000
    return Date.now() - millisecondsPerDay * numDays
}

type DateFormatterOptions = {
    showTimeOfDay: boolean
}

export interface IDateFormatter {
    format: (date: Date, options?: DateFormatterOptions) => string
}

class DdmmyyyyFormatter implements IDateFormatter {
    constructor(private locale: string) {
        this.locale = locale
    }

    format(date: Date, options = { showTimeOfDay: true }) {
        const year = date.getFullYear()
        const monthString = (date.getMonth() + 1).toString().padStart(2, '0')
        const day = date.getDate().toString().padStart(2, '0')
        const localizedTimeString = date.toLocaleTimeString(this.locale)
        const formattedDate = `${day}/${monthString}/${year}`
        if (!options.showTimeOfDay) {
            return formattedDate
        }
        return `${formattedDate} ${localizedTimeString}`
    }
}

class MmddyyyyFormatter implements IDateFormatter {
    constructor(private locale: string) {
        this.locale = locale
    }

    format(date: Date, options = { showTimeOfDay: true }) {
        const year = date.getFullYear()
        const monthString = (date.getMonth() + 1).toString().padStart(2, '0')
        const day = date.getDate().toString().padStart(2, '0')
        const localizedTimeString = date.toLocaleTimeString(this.locale)
        const formattedDate = `${monthString}/${day}/${year}`
        if (!options.showTimeOfDay) {
            return formattedDate
        }
        return `${formattedDate} ${localizedTimeString}`
    }
}

class MmmmddyyyyFormatter implements IDateFormatter {
    constructor(private locale: string) {
        this.locale = locale
    }

    format(date: Date, options = { showTimeOfDay: true }) {
        const { showTimeOfDay } = options
        const formatOptions: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: showTimeOfDay ? '2-digit' : undefined,
            minute: showTimeOfDay ? '2-digit' : undefined,
            second: showTimeOfDay ? '2-digit' : undefined
        }
        return date.toLocaleString(this.locale, formatOptions)
    }
}

class Yyyymmddformatter implements IDateFormatter {
    constructor(private locale: string) {
        this.locale = locale
    }

    format(date: Date, options = { showTimeOfDay: true }) {
        const year = date.getFullYear()
        const monthString = (date.getMonth() + 1).toString().padStart(2, '0')
        const day = date.getDate().toString().padStart(2, '0')
        const localizedTimeString = date.toLocaleTimeString(this.locale)
        const formattedDate = `${year}-${monthString}-${day}`
        if (!options.showTimeOfDay) {
            return formattedDate
        }
        return `${formattedDate} ${localizedTimeString}`
    }
}
export class DateFormatterFactory {
    getDateFormatter(dateFormat: DateFormat, locale: string) {
        switch (dateFormat) {
            case DateFormat.ddmmyyyy: {
                return new DdmmyyyyFormatter(locale)
            }
            case DateFormat.mmddyyyy: {
                return new MmddyyyyFormatter(locale)
            }
            case DateFormat.mmmmddyyyy: {
                return new MmmmddyyyyFormatter(locale)
            }
            case DateFormat.yyyymmdd: {
                return new Yyyymmddformatter(locale)
            }
            default: {
                return new Yyyymmddformatter(locale)
            }
        }
    }
}

export class SLTTDateCreator implements IDateCreator {
    // Returns a UMT date with format 2020/10/03 19:01:14.093Z
    // WARNING: we use this date to determine in the back end what change is the latest.
    // Changing the format of this will likely cause data loss due to items being judged outdated
    // and being discarded.
    getDate(date?: Date) {
        if (intest) {
            return '2019/09/10 11:12Z'
        }

        let isoString = date ? date.toISOString() : new Date(Date.now()).toISOString()

        isoString = isoString.replace('-', '/')
        isoString = isoString.replace('-', '/') // replace second occurence of -
        isoString = isoString.replace('T', ' ')

        return isoString
    }
}

export interface IDateCreator {
    getDate: (date?: Date) => string
}
