import { observer } from 'mobx-react'

import { AudioPlayer } from './AudioPlayer'
import { AudioPlayerPlaceholder } from './AudioPlayerPlaceholder'

export const AudioPlayerWithPlaceholder = observer(({ recordedBlobs, url }: { recordedBlobs: Blob[]; url: string }) => {
    const hasRecordedBlobs = recordedBlobs.length > 0

    if (hasRecordedBlobs) {
        return <AudioPlayer audioSrc={recordedBlobs} />
    }

    if (url) {
        return <AudioPlayer audioSrc={url} />
    }

    return <AudioPlayerPlaceholder />
})
