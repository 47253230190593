import { Fragment } from 'react'

import { PlanStructure } from './defaultPlans'

interface PlanPreviewProps {
    plan: PlanStructure
}

export const PlanPreview = ({ plan }: PlanPreviewProps) => {
    return (
        <ol>
            {plan.stages.map((stage) => (
                <Fragment key={stage.name}>
                    <li>{stage.name}</li>
                    <ol>
                        {stage.tasks.map((task) => (
                            <li key={task.name}>{task.name}</li>
                        ))}
                    </ol>
                </Fragment>
            ))}
        </ol>
    )
}
