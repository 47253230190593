import { DBObject } from './DBObject'
import { IDB } from './IDB'
import { PassageDocument } from './PassageDocument'

export const createDocumentObject = ({
    id,
    db,
    title,
    documents
}: {
    id: string
    db: IDB
    title: string
    documents: DBObject[]
}) => {
    const document = new PassageDocument(id, db)
    document.title = title
    document.rank = DBObject.getNextRank(documents)
    return document
}

export const addDocumentObject = async ({
    db,
    documents,
    passageDocument
}: {
    db: IDB
    documents: DBObject[]
    passageDocument: PassageDocument
}) => {
    await db.put(passageDocument.toDocument())

    const addedDocument = documents.find((document) => document._id === passageDocument._id)
    if (!addedDocument) {
        throw new Error('Could not find document we just added')
    }

    return addedDocument
}
