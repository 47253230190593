// Allow user to select which version of the video for the package

import { FC } from 'react'

import { observer } from 'mobx-react'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import _ from 'underscore'

import { Passage } from '../../models3/Passage'
import { PassageVideo } from '../../models3/PassageVideo'
import { ProjectPlan } from '../../models3/ProjectPlan'
import { InternalProjectStatus } from '../../models3/ProjectTask'
import { ReviewProject } from '../../models3/ReviewProject'
import { UnresolvedTextNotification } from '../notifications/Notifications'
import { GenericIcon, TrashIcon } from '../utils/Icons'

interface IPassageVideoSelector {
    enabled: boolean
    videos: PassageVideo[]
    currentVisibleVideo: PassageVideo
    onSelect: (video: PassageVideo) => void
    passageVideoNotification: IPassageVideoNotification
    plan?: ProjectPlan
    reviewProject?: ReviewProject
}

interface IPassageVideoNotification {
    passage: Passage | null
    hardNotificationCutoff: () => Date
    canViewConsultantOnlyFeatures: boolean
}

const PassageVideoSelector: FC<IPassageVideoSelector> = ({
    videos,
    currentVisibleVideo,
    enabled,
    onSelect,
    passageVideoNotification: passageVideoDisplayFormat,
    plan,
    reviewProject
}) => {
    const { t } = useTranslation()
    const { passage, hardNotificationCutoff, canViewConsultantOnlyFeatures } = passageVideoDisplayFormat

    if (!passage) {
        return null
    }

    const removedVideos = videos.filter((v) => v.removed)
    const activeVideos = _.sortBy(
        videos.filter((v) => !v.removed),
        'rank'
    ).reverse()

    const cutoff = hardNotificationCutoff()

    const taskName = (passageVideo: PassageVideo) => {
        const task = plan?.tasks.find((planTask) => planTask.id === passageVideo.status)
        if (!task || task.name === InternalProjectStatus.NOT_STARTED) {
            return ''
        }

        if (task.name === InternalProjectStatus.FINISHED) {
            return ` (${t('Finished')})`
        }

        return ` (${task.name})`
    }

    const label = (passageVideo: PassageVideo) => {
        return `${t('Version')} ${passage.getVersionIndex(passageVideo) + 1}`
    }

    return (
        <div className="passage-video-selector">
            <Dropdown id="dropdown-custom-1" align="end">
                <Dropdown.Toggle className="styled-dropdown-select passage-video-selector-2-toggle" disabled={!enabled}>
                    {currentVisibleVideo.removed && (
                        <TrashIcon className="deleted-video-item-trash" tooltip={t('recordingDeleted')} />
                    )}
                    {label(currentVisibleVideo)}
                    {taskName(currentVisibleVideo)}
                    {reviewProject && currentVisibleVideo.isInReview(reviewProject) && (
                        <GenericIcon
                            iconName="fa-comments"
                            className="dropdown-review-project-icon default-blue-icon"
                            tooltip={t('recordingStartANewReviewSuccess')}
                        />
                    )}
                </Dropdown.Toggle>
                <Dropdown.Menu className="scrollable-dropdown styled-dropdown-select-menu">
                    {activeVideos.map((pv) => {
                        const unresolvedNote = pv.mostRecentUnresolvedNote(
                            passage,
                            cutoff,
                            canViewConsultantOnlyFeatures
                        )
                        return (
                            <Dropdown.Item
                                className="passage-video-selector-2"
                                key={pv._id}
                                onClick={() => onSelect(pv)}
                            >
                                {label(pv)}
                                {taskName(pv)}
                                {unresolvedNote && (
                                    <UnresolvedTextNotification
                                        className="portion-button"
                                        tooltip={t('Unresolved notes exist')}
                                    />
                                )}
                                {reviewProject && pv.isInReview(reviewProject) && (
                                    <GenericIcon
                                        iconName="fa-comments"
                                        className="dropdown-review-project-icon default-blue-icon"
                                        tooltip={t('recordingStartANewReviewSuccess')}
                                    />
                                )}
                            </Dropdown.Item>
                        )
                    })}
                    {removedVideos.map((pv) => {
                        const unresolvedNote = pv.mostRecentUnresolvedNote(
                            passage,
                            cutoff,
                            canViewConsultantOnlyFeatures
                        )
                        return (
                            <Dropdown.Item
                                className="passage-video-selector-2"
                                key={pv._id}
                                onClick={() => onSelect(pv)}
                            >
                                <div className="deleted-video-item">
                                    <TrashIcon
                                        className="deleted-video-item-trash"
                                        tooltip={t('recordingDeletedUndelete')}
                                    />
                                    {label(pv)}
                                    {taskName(pv)}
                                    {unresolvedNote && (
                                        <UnresolvedTextNotification
                                            className="portion-button"
                                            tooltip={t('Unresolved notes exist')}
                                        />
                                    )}
                                </div>
                            </Dropdown.Item>
                        )
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default observer(PassageVideoSelector)
