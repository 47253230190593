import React, { FC, useState, useRef, useEffect } from 'react'

import { observer } from 'mobx-react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import ERTermView from './ERTermView'
import { ProjectReferenceVideoPlayer } from './ProjectReferenceVideoPlayer'
import { ProjectReference } from '../../models3/ProjectReferences'
import { ProjectTerm } from '../../models3/ProjectTerm'
import { Root } from '../../models3/Root'
import { SignVideo } from '../../models3/SignVideo'
import { MarbleLemmas } from '../../resources/Lemmas'
import { AudioPlayerWithPlaceholder } from '../audio/AudioPlayerWithPlaceholder'
import { AudioRecordingRecorder } from '../audio/AudioRecorder'
import { PaneCloseButton, StopButton } from '../utils/Buttons'
import { displayError } from '../utils/Errors'
import { fmt } from '../utils/Fmt'
import Player from '../utils/Player'
import VideoRecorder, { AVTTRecordingState } from '../video/VideoRecorder'
import { RecordingDoneParams, VideoUploader } from '../video/VideoUploader'

import './EnhancedResources.css'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:ERTermModal')

interface IERPlaySign {
    signPlaying: SignVideo
    setSignPlaying: (sign: SignVideo | null) => void
}

const ERPlaySign: FC<IERPlaySign> = ({ signPlaying, setSignPlaying }) => {
    const { t } = useTranslation()
    log(`playSign`)

    if (signPlaying.isAudioOnly()) {
        return (
            <div className="sign-player-audio-container">
                <div className="sign-player-audio-player-wrapper">
                    <AudioPlayerWithPlaceholder url={signPlaying.url} recordedBlobs={[]} />
                </div>
                <PaneCloseButton onClick={() => setSignPlaying(null)} enabled tooltip={t('Close pane')} className="" />
            </div>
        )
    }

    return (
        <div className="sign-player">
            <Player className="sign-player" videoUrl={signPlaying.url} />
            <div className="sign-player-close-button">
                <PaneCloseButton
                    onClick={() => setSignPlaying(null)}
                    enabled
                    tooltip={t('Close pane')}
                    className="sl-pane-close-button"
                />
            </div>
        </div>
    )
}

interface IERRecordSenseSign {
    rt: Root
    signRecording: SignVideo
    setSignRecording: (sign: SignVideo | null) => void
    save: (signRecording: SignVideo) => Promise<void>
}

const ERRecordSenseSign: FC<IERRecordSenseSign> = ({ rt, setSignRecording, signRecording, save }) => {
    const { t } = useTranslation()
    const [recordingState, setRecordingState] = useState<AVTTRecordingState>('NOT_INITIALIZED')
    const videoRecorderRef = useRef<VideoRecorder>(null)

    const { name, recordingCountdown, autoGainControl } = rt.project
    signRecording.url = `${name}/${signRecording._id}`

    log('ERRcordSign', fmt({ _id: signRecording._id, url: signRecording.url }))

    async function onRecordingDone({ err, blobsCount, mimeType }: Partial<RecordingDoneParams>) {
        if (err) {
            setSignRecording(null)
            displayError(err)
            return
        }

        signRecording.url = `${signRecording.url}-${blobsCount}`
        signRecording.mimeType = mimeType ?? ''
        log('onRecordingDone', signRecording.url)

        try {
            await save(signRecording)
            setSignRecording(null)
        } catch (saveError) {
            displayError(saveError)
            setSignRecording(null)
        }
    }

    const videoUploader = new VideoUploader(signRecording.url, onRecordingDone)

    if (rt.project.recordAudioOnly) {
        return (
            <AudioRecordingRecorder
                closeRecorder={() => {
                    setSignRecording(null)
                }}
                onDoneRecording={async (blobs) => {
                    for (let i = 0; i < blobs.length; i++) {
                        await videoUploader.pushVideoBlob(blobs[i], i === blobs.length - 1)
                    }
                }}
            />
        )
    }

    return (
        <div className="er-record-sense-sign">
            <StopButton
                enabled={recordingState !== 'NOT_INITIALIZED'}
                onClick={() => {
                    videoRecorderRef.current?.stop()
                }}
                className="er-video-stop-button"
                tooltip={t('Stop recording.')}
            />
            <div className="er-record-sense-sign-video-player">
                <VideoRecorder
                    {...{
                        videoUploader,
                        ref: videoRecorderRef,
                        setRecordingState,
                        recordingCountdown,
                        autoGainControl
                    }}
                />
            </div>
        </div>
    )
}

interface IERTermModal {
    rt: Root
    termId: string
    setTermId: (resource: string) => void
}

const ERTermModal: FC<IERTermModal> = observer(({ rt, termId, setTermId }) => {
    const { t } = useTranslation()
    const [signPlaying, setSignPlaying] = useState<SignVideo | null>(null)
    const [signRecording, setSignRecording] = useState<SignVideo | null>(null)
    const [projectReference, setProjectReference] = useState<ProjectReference | null>(null)
    const [projectTerm, setProjectTerm] = useState<ProjectTerm | null>(null)
    const recordSignRef = useRef<any>(null)

    function _setSignRecording(sign: SignVideo | null) {
        if (!sign) {
            setProjectTerm(null)
        }
        setSignRecording(sign)
    }

    async function saveSign(sign: SignVideo) {
        return projectTerm?.addRendering(sign)
    }

    const lemma = MarbleLemmas.get(termId)
    log('ERTermModal', fmt({ termId, lemma }))

    useEffect(() => {
        if (lemma) {
            rt.project.migrateLemma(lemma)
        }
    }, [lemma, rt])

    if (!lemma) {
        return null
    }

    rt.projectReferences.update(rt) // expensive, memoize?

    return (
        <Modal
            style={{ top: '1%' }}
            size="lg"
            show
            onEnter={() => (rt.termModalOpen = true)}
            onHide={() => {
                setTermId('')
                rt.termModalOpen = false
            }}
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('Biblical Term Viewer')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="er-term-modal-body">
                    {signRecording && (
                        <ERRecordSenseSign
                            {...{
                                rt,
                                setSignRecording: _setSignRecording,
                                signRecording,
                                save: saveSign,
                                recordSignRef
                            }}
                        />
                    )}
                    {signPlaying && <ERPlaySign {...{ signPlaying, setSignPlaying }} />}
                    {projectReference && (
                        <ProjectReferenceVideoPlayer {...{ rt, projectReference, setProjectReference, termId }} />
                    )}
                    {!signPlaying && !signRecording && !projectReference && (
                        <ERTermView
                            {...{
                                rt,
                                lemma,
                                setProjectReference,
                                termId,
                                setTermId,
                                setSignPlaying,
                                startRecording: (term) => {
                                    setProjectTerm(term)
                                    const signVideo = term.createRendering()
                                    _setSignRecording(signVideo)
                                }
                            }}
                        />
                    )}
                </div>
            </Modal.Body>
        </Modal>
    )
})

export default ERTermModal
