// A range consisting of a starting and ending bbbcccvvv
// Allow iterating over all verses in range.
// The range may span chapter boundaries but not book boundaries

import { displayableBookNames, ptxBookIds } from './bookNames'
import { referenceParser, referenceSuggestionParser } from './ReferenceParsers'
import { versificationConverter } from './Versifications'
import { nnn } from '../components/utils/Helpers'
import { Project } from '../models3/Project'

export const MAX_VERSE_COUNT = 200

const CHAPTER_VERSE_SEPARATOR = ':'

export const refToBookId = (ref: string) => ref.slice(0, 3)
export const refToChapterId = (ref: string) => ref.slice(3, 6)
export const refToVerseId = (ref: string) => ref.slice(6, 9)

export const padToVerseId = (ref: string) => ref.toString().padEnd(9, '001')

export const refToPtxBookId = (ref: string) => {
    const bookNumber = Number(refToBookId(ref)) - 1
    return ptxBookIds[bookNumber]
}

// Convert array of RefRange to an array of human readable strings
// where each array element corresponds to an input RefRange
export const refRangesToDisplayParts = (refs: Array<RefRange>, project?: Project) => {
    const parts: Array<string> = refs.map((rr, i) => {
        const book = refToBookId(rr.startRef)
        const prevBook = i > 0 ? refToBookId(refs[i - 1].startRef) : ''

        let bookName = ''
        if (book !== prevBook) {
            bookName = `${displayableBookNames(project)[parseInt(book, 10) - 1]} `
        }

        if (rr.isBBBOnly()) {
            return bookName
        }

        const c1 = refToChapterId(rr.startRef).replace(/^0+/, '')
        const v1 = refToVerseId(rr.startRef).replace(/^0+/, '')
        const c2 = refToChapterId(rr.endRef).replace(/^0+/, '')
        const v2 = refToVerseId(rr.endRef).replace(/^0+/, '')

        const verse = (v: string) => (v ? `${CHAPTER_VERSE_SEPARATOR}${v}` : '')
        if (c1 !== c2) return `${bookName}${c1}${verse(v1)}-${c2}${verse(v2)}`
        if (v1 !== v2) return `${bookName}${c1}${CHAPTER_VERSE_SEPARATOR}${v1}-${v2}`
        return `${bookName}${c1}${verse(v1)}`
    })

    return parts
}

// Convert array of RefRange to human readable string
export const refRangesToDisplay = (refs: RefRange[], project?: Project) =>
    refRangesToDisplayParts(refs, project).join('; ')

export const refRangesMinMax = (references: RefRange[]) => {
    if (!references.length) {
        return { startRef: '', endRef: '' }
    }

    // Find the minimum start reference and maximum end reference directly
    let minStartVerseRef = padToVerseId(references[0].startRef)
    let maxEndVerseRef = padToVerseId(references[0].endRef)

    for (let i = 1; i < references.length; i++) {
        const { startRef, endRef } = references[i]
        const startVerseRef = padToVerseId(startRef)
        const endVersedRef = padToVerseId(endRef)

        if (startVerseRef < minStartVerseRef) {
            minStartVerseRef = startVerseRef
        }

        if (endVersedRef > maxEndVerseRef) {
            maxEndVerseRef = endVersedRef
        }
    }

    return { startRef: minStartVerseRef, endRef: maxEndVerseRef }
}

export class RefRange {
    constructor(
        public startRef: string, // bbbcccvvv or bbbccc or bbb
        public endRef: string // ditto
    ) {}

    copy() {
        return new RefRange(this.startRef, this.endRef)
    }

    isBBBOnly() {
        return this.startRef.length === 3 // just bbb
    }

    getStartBookNumber() {
        return Number(refToBookId(this.startRef))
    }

    // Return RefRange containing all the chapters in the current book.
    fullBook(versification = 'English') {
        const bbb = refToBookId(this.startRef)
        const book = parseInt(bbb)
        const numberOfChapters = versificationConverter.getNumberOfChaptersInBook(book, versification)
        if (!numberOfChapters) {
            throw new Error(`No chapters in book ${book}`)
        }
        return new RefRange(`${bbb}001`, `${bbb}${nnn(numberOfChapters)}`)
    }

    fullChapter(versification = 'English') {
        const bbbccc = this.startRef.slice(0, 6)
        const numberOfChapters = versificationConverter.getNumberOfVersesPerChapter(versification)
        const versesInChapter = numberOfChapters.get(bbbccc)
        if (!versesInChapter) {
            throw new Error(`No verses in chapter ${bbbccc}`)
        }
        return new RefRange(`${bbbccc}001`, `${bbbccc}${nnn(versesInChapter)}`)
    }

    // Iterate over all the bbbcccvvv strings in a RefRange.
    // If we don't have any information about chapter length just assume
    // there are a lot (180) verses in the chapter.
    // This is ok because our primary use case for this function is to try to determine
    // whether something is present in a range of verses. Looking at extra verses
    // that don't exist will not affect this. (Looking at too few verses would break
    // that however)

    *iterator(bbbcccToMaxV?: { [bbbccc: string]: number } /* number of verses in bbbccc */) {
        const { startRef, endRef } = this

        if (refToBookId(startRef) !== refToBookId(endRef)) throw Error('RefRange cannot iterate over book boundaries')

        let bbbccc = startRef.slice(0, 6)
        while (true) {
            let v = startRef.startsWith(bbbccc) && startRef.length === 9 ? parseInt(refToVerseId(startRef), 10) : 1

            const maxVInChapter = bbbcccToMaxV ? bbbcccToMaxV[bbbccc] || 0 : 180

            const maxV =
                endRef.startsWith(bbbccc) && endRef.length === 9 ? parseInt(refToVerseId(endRef), 10) : maxVInChapter

            for (; v <= maxV; ++v) {
                yield bbbccc + nnn(v)
            }

            if (bbbccc === endRef.slice(0, 6)) break
            bbbccc = this.nextChapter(bbbccc)
        }
    }

    // Iterate over all bbbccc in range
    *chapterIterator() {
        const { startRef, endRef } = this

        if (refToBookId(startRef) !== refToBookId(endRef)) throw Error('RefRange cannot iterate over book boundaries')

        let bbbccc = startRef.slice(0, 6)
        while (true) {
            yield bbbccc

            if (bbbccc === endRef.slice(0, 6)) break
            bbbccc = this.nextChapter(bbbccc)
        }
    }

    nextChapter(bbbccc: string) {
        const ccc = parseInt(refToChapterId(bbbccc), 10)
        if (ccc > 150) throw Error('nextChapter limit exceeded')
        return refToBookId(bbbccc) + nnn(ccc + 1)
    }

    static bcvToRefRange(bcv: string) {
        if (bcv.length === 3) {
            // only bbb
            return new RefRange(bcv, bcv)
        }

        const parts = bcv.split('-')
        return new RefRange(parts[0], parts[1])
    }

    static nextVerse(bbbcccvvv: string, versification: string) {
        try {
            const allVerses = versificationConverter.getValidVerses(versification)
            const verseIndex = allVerses.findIndex((verse) => verse === bbbcccvvv)
            if (verseIndex < 0 || verseIndex === allVerses.length - 1) {
                return bbbcccvvv
            }

            return allVerses[verseIndex + 1]
        } catch (error) {
            return bbbcccvvv
        }
    }

    /** References can use the book names defined by the current project,
     * the UI language, or English.
     * When we persist them in the DB we always use English.
     * @throws Throws if parse fails
     */
    static parseReferences(refs: string, uiLanguageCode: string, projectBookNames: string[]) {
        if (!refs.trim()) return []

        return referenceParser(uiLanguageCode, projectBookNames)
            .map((bcvs) => bcvs.map(RefRange.bcvToRefRange))
            .tryParse(refs)
    }

    /** References can use the book names defined by the current project,
     * the UI language, or English.
     */
    static getSuggestions(refs: string, uiLanguageCode: string, projectBookNames: string[]) {
        if (!refs.trim()) {
            return []
        }

        try {
            return referenceSuggestionParser(uiLanguageCode, projectBookNames)
                .map((bcv) => {
                    if (refToBookId(bcv) === bcv) {
                        return [new RefRange(bcv, bcv).fullBook()]
                    }
                    if (bcv.slice(0, 6) === bcv) {
                        return [new RefRange(bcv, bcv).fullChapter()]
                    }

                    const parts = bcv.split('-')
                    if (parts.length === 2) {
                        const [beginningChapter, endingChapter] = parts
                        const fullEndingChapter = new RefRange(endingChapter, endingChapter).fullChapter()
                        return [new RefRange(beginningChapter, fullEndingChapter.endRef)]
                    }

                    return []
                })
                .tryParse(refs)
        } catch (error) {
            return []
        }
    }

    // Convert array of bbbcccvvv refs to an array of RefRange collapsing
    // consecutive verses into a single RefRange
    static refsToRefRanges(refs: string[]) {
        const rrs: RefRange[] = []

        const isNextVerse = function (bcv1: string, bcv2: string) {
            return (
                bcv1.slice(0, 6) === bcv2.slice(0, 6) &&
                parseInt(refToVerseId(bcv1)) + 1 === parseInt(refToVerseId(bcv2))
            )
        }

        refs.forEach((ref) => {
            if (rrs.length === 0 || !isNextVerse(rrs.slice(-1)[0].endRef, ref)) {
                rrs.push(new RefRange(ref, ref))
            } else {
                rrs.slice(-1)[0].endRef = ref
            }
        })

        return rrs
    }

    // Returns true iff this overlaps with anything in refRanges
    overlaps(refRanges: RefRange[]) {
        const min = (x: string, y: string) => (x < y ? x : y)
        const max = (x: string, y: string) => (x < y ? y : x)

        const convertStartToBBBCCCVVV = (ref: string) => (ref.length > 6 ? ref : `${ref}001`)
        const convertEndToBBBCCCVVV = (ref: string) => (ref.length > 6 ? ref : `${ref}999`)

        const x1 = convertStartToBBBCCCVVV(this.startRef)
        const x2 = convertEndToBBBCCCVVV(this.endRef)

        // https://stackoverflow.com/questions/3269434/whats-the-most-efficient-way-to-test-two-integer-ranges-for-overlap
        return refRanges.some(
            (rr) => max(x1, convertStartToBBBCCCVVV(rr.startRef)) <= min(x2, convertEndToBBBCCCVVV(rr.endRef))
        )
    }

    getAllVerses(versification: string) {
        try {
            const versesPerChapter = Object.fromEntries(
                versificationConverter.getNumberOfVersesPerChapter(versification)
            )
            const verses = Array.from(this.iterator(versesPerChapter))
            return verses
        } catch (error) {
            return []
        }
    }

    static getAllChaptersInBook(bookNumber: number, versification: string) {
        const ref = new RefRange(nnn(bookNumber), nnn(bookNumber))
        const fullBook = ref.fullBook(versification)
        return Array.from(fullBook.chapterIterator())
    }

    static getAllVersesInRefRanges(references: RefRange[], versification: string) {
        const verses = references.flatMap((reference) => reference.getAllVerses(versification))
        const versesSet = new Set<string>(verses)
        const uniqueVerses = Array.from(versesSet)
        return uniqueVerses
    }

    spread() {
        function _spread(bcv: string) {
            const book = parseInt(refToBookId(bcv))
            const chapter = parseInt(refToChapterId(bcv))
            const verse = parseInt(refToVerseId(bcv))
            return { book, chapter, verse }
        }

        const getFullStartRef = () => {
            const { startRef } = this
            const vvv = '001'
            const ccc = '001'
            if (startRef.length === 3) {
                return `${startRef}${ccc}${vvv}`
            }
            if (startRef.length === 6) {
                return `${startRef}${vvv}`
            }
            return startRef
        }

        const getFullEndRef = () => {
            const { endRef } = this
            const vvv = nnn(MAX_VERSE_COUNT)
            const ccc = vvv
            if (endRef.length === 3) {
                return `${endRef}${ccc}${vvv}`
            }
            if (endRef.length === 6) {
                return `${endRef}${vvv}`
            }
            return endRef
        }

        const { book: startBook, chapter: startChapter, verse: startVerse } = _spread(getFullStartRef())
        const { book: endBook, chapter: endChapter, verse: endVerse } = _spread(getFullEndRef())

        return { startBook, startChapter, startVerse, endBook, endChapter, endVerse }
    }
}
