import { openDB } from 'idb'

const DATABASE_NAME = 'EngageApp'
const STORE_NAME = 'blobs'

const dbPromise = openDB(DATABASE_NAME, 1, {
    upgrade(database) {
        database.createObjectStore(STORE_NAME)
    }
})

const engageAppCacheKey = (avttProjectName: string, fileName: string) => `${avttProjectName}/${fileName}`

export const getFromCache = async (avttProjectName: string, fileName: string) => {
    const db = await dbPromise
    const result = await db.get(STORE_NAME, engageAppCacheKey(avttProjectName, fileName))
    if (result) {
        return result as Blob
    }
    return undefined
}

export const putIntoCache = async (avttProjectName: string, fileName: string, data: Blob) => {
    const db = await dbPromise
    return db.put(STORE_NAME, data, engageAppCacheKey(avttProjectName, fileName))
}
