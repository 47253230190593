import { Component, useContext, useEffect, useState } from 'react'

import { t } from 'i18next'
import { observable } from 'mobx'
import { observer } from 'mobx-react'

import { BiblicalTermMarker } from '../../models3/BiblicalTermMarker'
import { Passage } from '../../models3/Passage'
import { PassageVideo } from '../../models3/PassageVideo'
import { ProjectReference } from '../../models3/ProjectReferences'
import { Root } from '../../models3/Root'
import { RootContext } from '../app/RootContext'
import { WaveSurferPassageAudioPlayer } from '../audio/WaveSurferPassageAudioPlayer'
import { PaneCloseButton } from '../utils/Buttons'
import { displayError } from '../utils/Errors'
import RangeVideoPlayer from '../video/RangeVideoPlayer'
import { PositionSetter, VideoTimeline } from '../video/VideoTimeline'
import VideoTimelinePlayButtons from '../video/VideoTimelinePlayButtons'
import { ViewableVideoCollection } from '../video/ViewableVideoCollection'
import { AudioWaveformCreator, WaveformVisualizer } from '../video/WaveformVisualizer'

import '../video/NoteLocationEditor.css'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:ProjectReferenceVideoPlayer')

type SimpleWaveformVisualizerProps = {
    video: PassageVideo
    passage: Passage
    startTime: number
    endTime: number
}

export const SimpleWaveformVisualizer = observer(
    ({ video, passage, startTime, endTime }: SimpleWaveformVisualizerProps) => {
        const [waveformData, setWaveformData] = useState<number[]>([])

        const rt = useContext(RootContext)
        if (!rt) {
            return null
        }

        useEffect(() => {
            let mounted = true

            async function getTheData() {
                if (!rt) {
                    return
                }
                const samplesOnScreen = 300
                const waveformCreator = new AudioWaveformCreator()
                const vvc = new ViewableVideoCollection()
                const audioSlices = await video.getPlayableSlices(passage, vvc)
                const waveform = await waveformCreator.getNormalizedPCMValues(
                    audioSlices,
                    samplesOnScreen,
                    startTime,
                    endTime
                )
                if (mounted) {
                    setWaveformData(waveform.normalizedPCMValues)
                }
            }
            getTheData()

            return () => {
                mounted = false
            }
        }, [rt, passage, video, startTime, endTime])

        return <WaveformVisualizer waveformData={waveformData} className="note-audio-waveform-visualizer" />
    }
)
interface IProjectReferenceVideoPlayer {
    rt: Root
    projectReference: ProjectReference
    setProjectReference: (projectReference: ProjectReference | null) => void
    termId: string
}

@observer
export class ProjectReferenceVideoPlayer extends Component<IProjectReferenceVideoPlayer> {
    @observable isPlaying = false

    @observable currentTime = 0

    rangeVideoPlayer: RangeVideoPlayer | null = null

    setters: PositionSetter[] = [] // passed to VideoTimeline to allow updating of positions relating to note
    // in order the are note.startPosition, note.position, note.endPosition, currentTime

    startingTime = 0

    endingTime = 0

    currentVideos: ViewableVideoCollection | null = null

    constructor(props: IProjectReferenceVideoPlayer) {
        super(props)

        this.setupSetters()
        this.setupVideo()
    }

    // Setup noteVideo to contain only the patches were available
    // at the time the note was originally created.
    //
    // Start download process
    setupVideo() {
        const { rt, projectReference } = this.props
        const { passage } = projectReference.info(rt)
        if (!passage) {
            return
        }

        this.startingTime = projectReference.startingTime
        this.currentTime = this.startingTime
        this.endingTime = projectReference.endingTime

        log('setupVideo', this.startingTime.toFixed(2), this.endingTime.toFixed(2))

        this.currentVideos = new ViewableVideoCollection()
        this.currentVideos.setup(passage, projectReference.passageVideo)
        this.currentVideos.download().catch(displayError)
    }

    setupSetters() {
        this.setters = [
            // set currentTime
            new PositionSetter(
                '3',
                () => 0,
                () => this.endingTime - this.startingTime,
                (value: number) => {
                    this.setCurrentTime(value + this.startingTime)
                }
            )
        ]
    }

    // Convert relative position to time and pass to player
    setCurrentTime(currentTime: number) {
        this.currentTime = currentTime
        // log('setCurrentTimePosition', fx(this.currentTime))

        if (!this.isPlaying) {
            // if we are not playing move the player to the selected point in time
            this.rangeVideoPlayer?.setCurrentTime(this.currentTime)
        }
    }

    playAll = async () => {
        this.isPlaying = true
        const { rangeVideoPlayer } = this
        if (!rangeVideoPlayer) return

        await rangeVideoPlayer.playRange(this.currentTime, this.endingTime)
    }

    pause = () => {
        const { rangeVideoPlayer } = this
        if (rangeVideoPlayer) {
            rangeVideoPlayer.stop()
        }
    }

    render() {
        const { rt, projectReference, setProjectReference, termId } = this.props
        const { passageVideo } = projectReference
        const { startingTime, endingTime, currentVideos } = this

        const { passage } = projectReference.info(rt)
        const { playbackRate, setPlaybackRate } = rt

        // to force re-rendering of toolbar
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { isPlaying } = this

        return (
            <div className="project-reference-video-player-content">
                <div className="note-location-editor-video">
                    <div className="project-reference-video-player-title">{projectReference.info(rt).tooltip}</div>
                    {passageVideo.isAudioOnly() && passage && rt.portion && (
                        <WaveSurferPassageAudioPlayer
                            rt={rt}
                            passage={passage}
                            passageVideo={passageVideo}
                            termId={termId}
                        />
                    )}
                    {!passageVideo.isAudioOnly() && passage && currentVideos && (
                        <>
                            <RangeVideoPlayer
                                className="project-reference-video-player-player"
                                ref={(rvp: any) => (this.rangeVideoPlayer = rvp)}
                                passage={passage}
                                playbackRate={playbackRate}
                                setPlaybackRate={setPlaybackRate}
                                video={passageVideo}
                                currentVideos={currentVideos}
                                startTime={startingTime}
                                endTime={endingTime}
                                onPlayingStatus={(playingStatus: boolean) => (this.isPlaying = playingStatus)}
                                onTick={(time: number) => {
                                    this.setters[0].setValue(time - this.startingTime)
                                }}
                                enableSpeedSlider
                            />
                            <div className="video-timeline-area">
                                <VideoTimelinePlayButtons
                                    isPlaying={this.isPlaying}
                                    playAll={this.playAll}
                                    pause={this.pause}
                                />
                                <div className="video-timeline">
                                    {passage && (
                                        <SimpleWaveformVisualizer
                                            video={passageVideo}
                                            passage={passage}
                                            startTime={startingTime}
                                            endTime={endingTime}
                                        />
                                    )}
                                    <VideoTimeline
                                        setters={this.setters}
                                        domainStartPosition={0}
                                        domainEndPosition={endingTime - startingTime}
                                        adjustTime={(time) => {
                                            this.setters[0].setValue(time)
                                        }}
                                        enabled
                                        allowAdjustingPositions
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div>
                    <PaneCloseButton
                        onClick={() => setProjectReference(null)}
                        enabled
                        tooltip={t('Close pane')}
                        className="sl-pane-close-button"
                    />
                </div>
            </div>
        )
    }
}

export class GlossReference {
    constructor(public passage: Passage, public gloss: BiblicalTermMarker, public passageVideo: PassageVideo) {}

    info(rt: Root) {
        const { passageVideo, gloss } = this

        const portion = rt.project.portions.find((_portion) => passageVideo._id.startsWith(_portion._id))
        const passage = portion?.passages.find((_passage) => passageVideo._id.startsWith(_passage._id))

        const parts = [portion?.name ?? '', passage?.name ?? '']

        // Remove redundant parts of reference name if present
        if (parts.length >= 2 && parts[1].startsWith(parts[0])) parts.splice(0, 1)
        if (parts.length >= 2 && parts[1].startsWith(parts[0])) parts.splice(0, 1)
        if (parts.length >= 3 && parts[2].startsWith(parts[1])) parts.splice(1, 1)

        const bufferSeconds = 5

        const startingTime = Math.max(gloss.time - bufferSeconds, 0)
        const endingTime = startingTime + 2 * bufferSeconds

        const timestamp = startingTime > 0 ? ` (${startingTime.toFixed(2)}...${endingTime.toFixed(2)})` : ''

        const tooltip = parts.join(' / ') + timestamp

        return { portion, passage, tooltip }
    }
}
