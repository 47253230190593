import { PassageDocument } from './PassageDocument'
import { Project } from './Project'
import { RefRange } from '../resources/RefRange'

function createRefString(refs: RefRange[]) {
    return refs
        .map((ref) => `${ref.startRef}-${ref.endRef}`)
        .sort()
        .join(',')
}
export class DocumentCollection {
    private map: Map<string, PassageDocument[]> = new Map()

    initialize = (project: Project) => {
        const newMap: Map<string, PassageDocument[]> = new Map()
        for (const portion of project.portions) {
            for (const passage of portion.passages) {
                if (passage.references.length && passage.documents.length) {
                    const refString = createRefString(passage.references)
                    const existing = newMap.get(refString)
                    if (existing) {
                        newMap.set(refString, [...existing, ...passage.documents])
                    } else {
                        newMap.set(refString, [...passage.documents])
                    }
                }
            }
        }
        this.map = newMap
    }

    get = (refs: RefRange[]) => {
        return this.map.get(createRefString(refs)) ?? []
    }
}
