import { useTranslation } from 'react-i18next'

export const NoPortionsMessage = ({ allowedToEdit }: { allowedToEdit: boolean }) => {
    const { t } = useTranslation()
    if (allowedToEdit) {
        return (
            <>
                <p>{t('portionDescription')}</p>
                <p>{t('portionExample')}</p>
                <p>{t('portionAddDescription')}</p>
            </>
        )
    }

    return <p>{t('No portions present yet for this project')}</p>
}
