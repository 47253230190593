/* eslint-disable react/no-array-index-key */

import { FC } from 'react'

import { observer } from 'mobx-react'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { getAllNoteMarkers } from './NoteMarkers'
import { PassageNote } from '../../models3/PassageNote'
import { Project } from '../../models3/Project'
import { SlttHelp } from '../utils/Buttons'

import './Note.css'

interface INoteMarkerDropdown {
    options: JSX.Element[]
    currentOption: JSX.Element
    onSelect: (option: number) => void
}

const NoteMarkerDropdown = (props: INoteMarkerDropdown) => {
    const { t } = useTranslation()
    const { options, currentOption, onSelect } = props

    return (
        <div className="note-marker-dropdown" data-tip data-for="notes" data-place="right">
            <SlttHelp id="notes" tooltip={t('Set shape and color of note marker.')} place="right">
                <Dropdown id="note-marker-dropdown">
                    <Dropdown.Toggle className="note-marker-dropdown-item styled-dropdown-select">
                        {currentOption}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="styled-dropdown-select-menu">
                        {options.map((type, i) => {
                            return (
                                <Dropdown.Item
                                    key={i}
                                    className="note-marker-dropdown-item"
                                    onClick={() => onSelect(i)}
                                >
                                    {type}
                                </Dropdown.Item>
                            )
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </SlttHelp>
        </div>
    )
}

interface INoteMarkerControl {
    note: PassageNote
    project: Project
    allowEditing: boolean
    selectNoteType: (note: PassageNote, type: string) => void
}

export const NoteMarkerControl: FC<INoteMarkerControl> = observer(({ note, project, allowEditing, selectNoteType }) => {
    const { type } = note
    const { allNoteColors, noteLabels } = project

    const selectType = (passageNote: PassageNote, option: number) => {
        selectNoteType(passageNote, option.toString())
    }

    const markerShapes = getAllNoteMarkers({ className: 'note-bar-marker fa-fw' })
    const noteMarkers: JSX.Element[] = markerShapes.map((shape, i) => (
        <>
            <span className="note-marker-dropdown-marker" style={{ color: allNoteColors[i] }}>
                {shape.component}
            </span>
            {noteLabels[i]}
        </>
    ))

    const typeIndex = parseInt(type)

    if (allowEditing) {
        return (
            <NoteMarkerDropdown
                options={noteMarkers}
                currentOption={noteMarkers[typeIndex]}
                onSelect={(option) => selectType(note, option)}
            />
        )
    }

    return <span className="note-marker-placeholder">{noteMarkers[typeIndex]}</span>
})
