import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { ReferenceInput } from './ReferenceInput'
import { ReferenceInputPropsBase } from './types'
import { RefRange } from '../../resources/RefRange'
import { GotoReferenceIcon } from '../utils/Icons'

import './ReferenceInput.css'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:ReferenceSearchInput')

interface ReferenceSearchInputProps extends ReferenceInputPropsBase {
    stickyReferenceId?: string
    passageReferences: RefRange[]
}

export const ReferenceSearchInput = ({
    refInput,
    setRefs,
    allowBookNameOnly,
    onEnter,
    onEscape,
    errored,
    setErrored,
    autoFocus,
    stickyReferenceId,
    passageReferences
}: ReferenceSearchInputProps) => {
    const { i18n } = useTranslation()

    const getInitialStickyReferences = () => {
        const serializedReferences = (stickyReferenceId && refInput.getDefault(stickyReferenceId)) ?? ''
        log('defaultReference', serializedReferences)
        if (serializedReferences) {
            const defaultReferences = JSON.parse(serializedReferences).map(
                (r: any) => new RefRange(r.startRef, r.endRef)
            ) as RefRange[]
            log('parseDefault', defaultReferences)
            return defaultReferences
        }
        return []
    }

    const [stickyReferences, setReferences] = useState(getInitialStickyReferences)
    const [isSticky, setIsSticky] = useState(stickyReferences.length > 0)

    const setStickyReferences = (refRanges: RefRange[]) => {
        setIsSticky(true)
        setReferences(refRanges)
        if (stickyReferenceId) {
            const referencesString = JSON.stringify(refRanges)
            log('setStickyReferences', referencesString)
            refInput.setDefault(stickyReferenceId, referencesString)
        }
    }

    const disableStickyReferences = () => {
        setIsSticky(false)
        setReferences([])
        if (stickyReferenceId) {
            log('disableStickyReferences')
            refInput.setDefault(stickyReferenceId, '')
        }
    }

    const refsToShow = isSticky ? stickyReferences : passageReferences
    useEffect(() => {
        setRefs(refsToShow)
    }, [refsToShow, setRefs])

    const displayablePassageReferences = refInput.displayableReferences(passageReferences)

    return (
        <div className="reference-input">
            <button
                type="button"
                className="wraparound-button"
                onClick={() => {
                    setErrored(false)
                    disableStickyReferences()
                }}
            >
                <GotoReferenceIcon
                    className="reference-input-goto-reference-icon"
                    tooltip={displayablePassageReferences}
                    dir={i18n.dir()}
                />
            </button>
            <ReferenceInput
                refInput={refInput}
                refs={refsToShow}
                setRefs={setStickyReferences}
                allowBookNameOnly={allowBookNameOnly}
                onEnter={onEnter}
                onEscape={onEscape}
                errored={errored}
                setErrored={setErrored}
                autoFocus={autoFocus}
                required
            />
        </div>
    )
}
