import { useEffect, useState } from 'react'

import { OralTranscriber } from './OralTranscriber'
import { useOnlineStatus } from '../../app/OnlineStatusContext'
import { useAppRoot } from '../../app/RootContext'
import { MultiSelect, Option } from '../../projectSettings/projectResources/MultiSelect'

export const OralTranscriberMultiSelect = () => {
    const { isOnline } = useOnlineStatus()
    const { rt } = useAppRoot()

    const defaultTranscriberLanguage = rt?.getDefault('oralBackTranslationLanguage')
    const [transcriberLanguage, setTranscriberLanguage] = useState<Option<string>[]>([])

    useEffect(() => {
        const supportedLanguages = OralTranscriber.supportedLanguages

        const initialLanguage = isOnline
            ? supportedLanguages.find((option) => option.value === defaultTranscriberLanguage) || supportedLanguages[0]
            : supportedLanguages[0]

        setTranscriberLanguage([initialLanguage])
    }, [isOnline, defaultTranscriberLanguage])

    const saveTranscriberLanguage = (selected: Option<string>[]) => {
        rt?.setDefault('oralBackTranslationLanguage', selected[0].value)
        setTranscriberLanguage(selected)
    }

    return (
        <MultiSelect
            singleSelect
            disabled={!isOnline}
            options={OralTranscriber.supportedLanguages}
            selected={isOnline ? transcriberLanguage : [OralTranscriber.supportedLanguages[0]]}
            setSelected={saveTranscriberLanguage}
        />
    )
}
