import { useCallback } from 'react'

import { AudioFetchPlayer } from './AudioFetchPlayer'
import API from '../../models3/API'

interface ReviewRecordingAudioPlayerProps {
    url: string
    recordedBlobs: Blob[]
    avttProjectName: string
}

export const ReviewRecordingAudioPlayer = ({
    avttProjectName,
    url,
    recordedBlobs
}: ReviewRecordingAudioPlayerProps) => {
    const fetchBlob = useCallback(() => {
        if (recordedBlobs.length) {
            return Promise.resolve(new Blob(recordedBlobs, { type: recordedBlobs[0].type }))
        }
        return API.getReviewRecordingBlob(avttProjectName, url)
    }, [avttProjectName, url, recordedBlobs])

    return <AudioFetchPlayer fetchBlob={fetchBlob} />
}
