import React, { Component } from 'react'

import { observable } from 'mobx'
import { observer } from 'mobx-react'

import './DropTarget.css'

const isFileTransfer = (dataTransfer: DataTransfer) => {
    if (dataTransfer.items.length === 0) {
        return false
    }
    for (const item of dataTransfer.items) {
        if (item.kind === 'file') {
            return true
        }
    }

    return false
}

interface IDropTarget {
    upload: (files: FileList) => Promise<void>
    dropTargetView: JSX.Element
}

class DropTarget extends Component<IDropTarget> {
    @observable active = false

    dragEnterCount = 0

    onDrop = (e: React.DragEvent) => {
        const { upload } = this.props

        e.stopPropagation()
        e.preventDefault()

        const { files } = e.dataTransfer

        if (!files || files.length === 0) {
            this.resetDropTarget()
            return
        }

        upload(files).finally(this.resetDropTarget)
    }

    // In order for the onDrop event to be called, we must prevent the default
    // behavior of the dragEnter and dragOver events
    onDragOver = (e: React.DragEvent) => {
        e.stopPropagation()
        e.preventDefault()
        e.dataTransfer.dropEffect = 'copy'
    }

    onDragEnter = (e: React.DragEvent) => {
        e.stopPropagation()
        e.preventDefault()
        if (!isFileTransfer(e.dataTransfer)) {
            return
        }
        this.dragEnterCount += 1
        if (this.dragEnterCount === 1) {
            this.active = true
        }
    }

    onDragLeave = (e: React.DragEvent) => {
        e.stopPropagation()
        e.preventDefault()
        this.dragEnterCount -= 1
        if (this.dragEnterCount === 0) {
            this.active = false
        }
    }

    resetDropTarget = () => {
        this.active = false
        this.dragEnterCount = 0
    }

    render() {
        const { onDrop, onDragOver, onDragLeave, onDragEnter, active } = this
        const { dropTargetView, children } = this.props

        const dropTargetChildrenClassName = `full-size ${active ? 'ignore-pointer-events' : ''}`

        return (
            <div
                className="drop-target"
                onDrop={onDrop}
                onDragEnter={onDragEnter}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
            >
                <div className={dropTargetChildrenClassName}>{children}</div>
                {active && dropTargetView}
            </div>
        )
    }
}

export default observer(DropTarget)
