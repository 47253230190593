import { useTranslation } from 'react-i18next'

interface SecondsOfSilenceProps {
    secondsOfSilence: number
    setSecondsOfSilence: (value: number) => void
}

export const SecondsOfSilence = ({ secondsOfSilence, setSecondsOfSilence }: SecondsOfSilenceProps) => {
    const { t } = useTranslation()
    const MIN_SPACER_SECONDS = 0
    const MAX_SPACER_SECONDS = 10

    return (
        <>
            <div className="form-label">{t('Seconds of silence between passages')}</div>
            <input
                className="difficulty-input"
                required
                type="number"
                value={secondsOfSilence}
                step={0.1}
                onChange={(e) => {
                    // the min and max attributes of the input field don't always work
                    const silence = Math.max(MIN_SPACER_SECONDS, Math.min(MAX_SPACER_SECONDS, Number(e.target.value)))
                    setSecondsOfSilence(silence)
                }}
            />
        </>
    )
}
