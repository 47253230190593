import { observable } from 'mobx'

export class FfmpegParameters {
    @observable inputOptions: string[] = []

    @observable audioFilters: string[] = []

    @observable videoFilters: string[] = []

    @observable complexFilter: string[] = []

    @observable complexFilterOutputMapping: string[] = []

    @observable outputOptions: string[] = []

    copy() {
        const copy = new FfmpegParameters()
        copy.inputOptions = Array.from(this.inputOptions)
        copy.audioFilters = Array.from(this.audioFilters)
        copy.videoFilters = Array.from(this.videoFilters)
        copy.complexFilter = Array.from(this.complexFilter)
        copy.complexFilterOutputMapping = Array.from(this.complexFilterOutputMapping)
        copy.outputOptions = Array.from(this.outputOptions)
        return copy
    }
}
