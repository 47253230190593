import React, { FC, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { EditableStageList } from './Stage'
import { Root } from '../../../models3/Root'
import { useOnlineStatus } from '../../app/OnlineStatusContext'
import { CopyProjectDataModal } from '../../modals/project/CopyDataModal'
import { CopyButton } from '../../utils/Buttons'

import './ProjectPlanEditor.css'

interface IProjectPlanEditor {
    rt: Root
}

export const ProjectPlanEditor: FC<IProjectPlanEditor> = observer(({ rt }) => {
    const { t } = useTranslation()

    const [openCopyPlanModal, setOpenCopyPlanModal] = useState(false)
    const { isOnline } = useOnlineStatus()
    const { project, iAmAdmin } = rt

    return (
        <div>
            {project.plans.length > 0 && project.plans[0].viewableStages.length > 0 && (
                <div>
                    {openCopyPlanModal && (
                        <CopyProjectDataModal
                            setOpenModal={setOpenCopyPlanModal}
                            source={project}
                            copyDataType="plan"
                        />
                    )}
                    <CopyButton
                        onClick={() => setOpenCopyPlanModal(true)}
                        buttonClassName=""
                        className="project-plan-copy-button"
                        tooltip={isOnline ? t('Copy plan') : t('noInternet')}
                        enabled={isOnline}
                    />
                    {iAmAdmin && <div>{t('projectPlanDescription')}</div>}
                </div>
            )}
            <EditableStageList rt={rt} />
        </div>
    )
})
