import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { CancelButton, HelpLinkWithMessage, OKButton, GenericIconButton } from '../utils/Buttons'
import { LoadingIcon } from '../utils/Icons'

import './Modals.css'

interface ModalFooterProps {
    enabledOK?: boolean
    enabledCancel?: boolean
    onOK: (e?: any) => void
    onCancel: (e?: any) => void
    okTooltip?: string
    cancelTooltip?: string
}

export const ModalFooter = ({
    enabledOK = true,
    enabledCancel = true,
    onOK,
    onCancel,
    okTooltip,
    cancelTooltip
}: ModalFooterProps) => {
    const { t } = useTranslation()

    return (
        <Modal.Footer>
            <div className="modal-footer-buttons button-row">
                <OKButton
                    enabled={enabledOK}
                    onClick={onOK}
                    buttonClassName=""
                    className="ok-button"
                    tooltip={okTooltip || t('OK')}
                />
                <CancelButton
                    enabled={enabledCancel}
                    onClick={onCancel}
                    className="cancel-button"
                    tooltip={cancelTooltip || t('Cancel')}
                />
            </div>
        </Modal.Footer>
    )
}

interface ProgressModalBodyAndFooterProps {
    closeModal: () => void
    error: string
    helpId?: string
    loading: boolean
    loadingMessage?: string
    successBody: JSX.Element
    resetModal?: () => void
}

export const ProgressModalBodyAndFooter = ({
    closeModal,
    error,
    helpId,
    loading,
    loadingMessage,
    successBody,
    resetModal
}: ProgressModalBodyAndFooterProps) => {
    const { t } = useTranslation()

    if (error) {
        return (
            <>
                <Modal.Body>
                    {helpId ? <HelpLinkWithMessage message={error} id={helpId} /> : <div>{error}</div>}
                </Modal.Body>
                <Modal.Footer>
                    <OKButton enabled onClick={closeModal} buttonClassName="" className="ok-button" tooltip={t('OK')} />
                </Modal.Footer>
            </>
        )
    }

    if (loading) {
        return (
            <Modal.Body>
                <div className="modal-loading-message-parent">
                    <LoadingIcon className="" />
                    <span className="modal-loading-message">
                        {loadingMessage ?? t('Preparing file. This may take several minutes...')}
                    </span>
                </div>
            </Modal.Body>
        )
    }

    return (
        <>
            <Modal.Body>{successBody}</Modal.Body>
            <Modal.Footer className="flex-centered">
                <div className="button-row">
                    <OKButton enabled onClick={closeModal} buttonClassName="" className="ok-button" tooltip={t('OK')} />
                    {resetModal && (
                        <GenericIconButton
                            iconName="fa-circle-left"
                            className="modal-go-back-icon"
                            tooltip={t('goBackToDownloadResources')}
                            onClick={resetModal}
                            enabled
                        />
                    )}
                </div>
            </Modal.Footer>
        </>
    )
}
