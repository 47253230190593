import _ from 'underscore'

import { AudioClip } from './AudioClip'
import { BiblicalTermMarker } from './BiblicalTermMarker'
import { Passage } from './Passage'
import { PassageDocument } from './PassageDocument'
import { PassageGloss } from './PassageGloss'
import { PassageHighlight } from './PassageHighlight'
import { PassageNote } from './PassageNote'
import { PassageNoteItem } from './PassageNoteItem'
import { PassageSegment } from './PassageSegment'
import { PassageSegmentDocument } from './PassageSegmentDocument'
import { PassageSegmentTranscription } from './PassageSegmentTranscription'
import { PassageVideo } from './PassageVideo'
import { Portion } from './Portion'
import { Project } from './Project'
import { ReferenceMarker } from './ReferenceMarker'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('avtt:PassageCopier')

// Copy a passage and its components and store them in the database.
export class PassageCopyVisitor {
    private includeResources: boolean

    private newProject: Project

    private oldProject: Project

    private newPassage?: Passage

    private newPortion: Portion

    private newSegment?: PassageSegment

    private newNote?: PassageNote

    private newVideo?: PassageVideo

    private oldVideoToNewMap = new Map<string, PassageVideo>()

    constructor(newProject: Project, oldProject: Project, newPortion: Portion, includeResources: boolean) {
        this.newProject = newProject
        this.oldProject = oldProject
        this.newPortion = newPortion
        this.includeResources = includeResources
    }

    get passage() {
        return this.newPassage
    }

    // passage is the passage being copied
    async visitPassage(passage: Passage, latestDraftOnly: boolean) {
        const { oldProject, newPortion, includeResources } = this
        if (passage.removed) {
            return
        }

        const newPassage = newPortion.createPassageFromExisting(passage)
        newPassage.copiedFromId = `${oldProject.name}/${passage._id}`
        this.newPassage = await newPortion.addPassageFromExisting(newPassage)

        await Promise.all([
            this.newPassage.setReferences(passage.references.map((ref) => ref.copy())),
            this.copyVideos(passage, latestDraftOnly)
        ])

        if (includeResources) {
            await Promise.all([passage.documents.map((doc) => this.visitPassageDocument(doc))].flat())
        }
    }

    async copyVideos(passage: Passage, latestDraftOnly: boolean) {
        const { newPassage } = this
        if (!newPassage || passage.removed) {
            return
        }

        let oldVideos
        let oldPatches
        let oldNonPatches
        if (latestDraftOnly) {
            oldNonPatches = passage.videosNotDeleted.filter((video) => !video.isPatch).slice(-1) ?? []
            if (!oldNonPatches.length) {
                return
            }

            oldPatches = [...oldNonPatches[0].patchVideos(passage)].map((v) => v.patchVideo)
            oldVideos = oldNonPatches.concat(oldPatches)
        } else {
            oldVideos = passage.videos
            ;[oldPatches, oldNonPatches] = _.partition(oldVideos, (v) => v.isPatch)
        }

        this.oldVideoToNewMap = new Map()

        // copy patches before anything else, because other segments reference them
        for (const video of oldPatches) {
            const newVideo = await this.visitPassageVideoAndSegments(video)
            if (newVideo) {
                this.oldVideoToNewMap.set(video._id, newVideo)
            }
        }

        // copy all other videos
        for (const video of oldNonPatches) {
            const newVideo = await this.visitPassageVideoAndSegments(video)
            if (newVideo) {
                this.oldVideoToNewMap.set(video._id, newVideo)
            }
        }

        // Now that all segments have been copied, we can copy things that depend on them existing
        for (const oldVideo of oldVideos) {
            const newVideo = this.oldVideoToNewMap.get(oldVideo._id)
            if (!newVideo) {
                continue
            }

            this.newVideo = newVideo

            const baseVideo = newVideo.baseVideo(newPassage) || newVideo
            if (baseVideo.isPatch) {
                log('orphaned patch?', baseVideo._id)
                continue
            }

            // Copying certain items depends on certain fields being set
            baseVideo.setSegmentTimes(newPassage)

            for (const marker of oldVideo.biblicalTermMarkers) {
                await this.visitBiblicalTermMarker(marker)
            }

            for (const note of oldVideo.notes) {
                await this.visitPassageNote(note)
            }

            for (const gloss of oldVideo.glosses) {
                await this.visitPassageGloss(gloss)
            }

            for (const highlight of oldVideo.highlights) {
                await this.visitPassageHighlight(highlight)
            }

            for (const reference of oldVideo.references) {
                await this.visitPassageVideoReference(reference)
            }
        }
    }

    private async visitPassageVideoAndSegments(video: PassageVideo) {
        const { newPassage, oldProject } = this
        if (!newPassage) {
            return null
        }

        // We want to add removed passage videos. Unlike other objects, removed passage
        // videos stay around in the model.
        const newVideo = newPassage.createVideoFromExisting(video, oldProject.name)
        const _video = await newPassage.addVideo(newVideo)

        // DBAcceptor does not accept removed objects, so we have to mark them as
        // removed after they have already been added.
        if (video.removed) {
            await newPassage.removeVideo(_video._id)
        }

        this.newVideo = _video

        for (const oldSegment of video.segments) {
            await this.visitPassageSegment(oldSegment)
        }

        return _video
    }

    private async visitBiblicalTermMarker(marker: BiblicalTermMarker) {
        const { newVideo } = this

        // Only copy biblical term markers within the same project
        if (!newVideo || marker.removed || this.newProject.name !== this.oldProject.name) {
            return null
        }

        const newMarker = newVideo.createMarkerFromExisting(marker)
        if (newMarker) {
            await newVideo.addMarkerFromExisting(newMarker)
        }
    }

    async visitPassageDocument(doc: PassageDocument) {
        const { newPassage } = this
        if (!newPassage || doc.removed) {
            return
        }
        const copy = newPassage.createDocumentFromExisting(doc)
        if (copy) {
            await newPassage.addDocument(copy)
        }
    }

    async visitPassageSegment(segment: PassageSegment) {
        const { newVideo, oldVideoToNewMap } = this
        if (!newVideo || segment.removed) {
            return
        }

        const copy = newVideo.createSegmentFromExisting(segment)
        if (!copy) {
            return
        }

        copy.videoPatchHistory = segment.videoPatchHistory
            .map((entry) => {
                return oldVideoToNewMap.get(entry)?._id ?? ''
            })
            .filter((s) => s.trim() !== '')

        const _segment = await newVideo.addSegmentFromExisting(copy)
        this.newSegment = _segment
        for (const oldDocument of segment.documents) {
            await this.visitPassageSegmentDocument(oldDocument)
        }

        for (const oldClip of segment.audioClips) {
            await this.visitAudioClip(oldClip)
        }

        for (const oldTranscription of segment.transcriptions) {
            await this.visitPassageSegmentTranscription(oldTranscription)
        }
    }

    async visitPassageSegmentDocument(segmentDocument: PassageSegmentDocument) {
        const { newSegment } = this
        if (!newSegment || segmentDocument.removed) {
            return
        }
        const copy = newSegment.createPassageSegmentDocumentFromExisting(segmentDocument)
        if (copy) {
            await newSegment.addPassageSegmentDocument(copy)
        }
    }

    async visitAudioClip(audioClip: AudioClip) {
        const { newSegment } = this
        if (!newSegment || audioClip.removed) {
            return
        }
        const newClip = newSegment.createAudioClipFromExisting(audioClip, '')
        if (newClip) {
            await newSegment.addAudioClip(newClip)
        }
    }

    async visitPassageSegmentTranscription(transcription: PassageSegmentTranscription) {
        const { newSegment } = this
        if (!newSegment || transcription.removed) {
            return
        }
        const copy = newSegment.createTranscriptionFromExisting(transcription)
        if (copy) {
            await newSegment.addTranscription(copy)
        }
    }

    async visitPassageNote(note: PassageNote) {
        const { newVideo } = this
        if (!newVideo || note.removed) {
            return
        }

        const copy = newVideo.createNoteFromExisting(note)
        if (!copy) {
            return
        }
        const updatedVideo = await newVideo.addNote(copy)
        const _note = updatedVideo.notes.find((n) => n._id === copy?._id)
        if (!_note) {
            throw new Error('Could not find new note we just created')
        }
        this.newNote = _note
        for (const item of note.items) {
            await this.visitPassageNoteItem(item)
        }
    }

    async visitPassageNoteItem(item: PassageNoteItem) {
        const { newNote, newPassage } = this
        if (!newNote || !newPassage || item.removed) {
            return
        }

        const copy = newNote.createItemFromExisting(item)
        if (copy) {
            await newNote.addItem(copy, newPassage)
        }
    }

    async visitPassageGloss(gloss: PassageGloss) {
        const { newVideo } = this
        if (!newVideo || gloss.removed) {
            return
        }

        const pg = newVideo.createGlossFromExisting(gloss)
        if (pg) {
            await newVideo.addGlossFromExisting(pg)
        }
    }

    async visitPassageHighlight(highlight: PassageHighlight) {
        const { newVideo } = this
        if (!newVideo || highlight.removed) {
            return
        }

        const copy = newVideo.createHighlightFromExisting(highlight)
        if (copy) {
            await newVideo.addHighlightFromExisting(copy)
        }
    }

    async visitPassageVideoReference(reference: ReferenceMarker) {
        const { newVideo } = this
        if (!newVideo || reference.removed) {
            return
        }

        const copy = newVideo.createMarkerFromExisting(reference)
        if (copy) {
            await newVideo.addMarkerFromExisting(copy)
        }
    }
}
