import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import { isDev } from './components/app/slttAvtt'
import { DEFAULT_LANGUAGE_CODE, LocaleLanguages } from './components/utils/Languages'

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        defaultNS: 'translation',
        detection: { order: ['localStorage', 'navigator'], caches: ['localStorage'], lookupLocalStorage: 'lang' },
        fallbackLng: DEFAULT_LANGUAGE_CODE,
        supportedLngs: LocaleLanguages.map((lng) => lng.code),
        load: 'currentOnly',
        interpolation: { escapeValue: false },
        debug: isDev,
        react: {
            useSuspense: true
        }
    })

export default i18n
