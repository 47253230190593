type LocaleLanguage = {
    code: string
    name: string
}

// Note the order of the languages here, which is the order they appear in the language picker
export const LocaleLanguages: LocaleLanguage[] = [
    { code: 'en', name: 'English' },
    { code: 'bi', name: 'Bislama' },
    { code: 'es', name: 'Español' },
    { code: 'fr', name: 'Français' },
    { code: 'ha', name: 'Hausa' },
    { code: 'sw', name: 'Kiswahili' },
    { code: 'pt', name: 'Português' },
    { code: 'tpi', name: 'Tok Pisin' },
    { code: 'ru', name: 'Русский' },
    { code: 'zh-CN', name: '中文简体' },
    { code: 'ar', name: 'العربية' }
]

export const DEFAULT_LANGUAGE_CODE = LocaleLanguages[0].code
