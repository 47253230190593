import { useTranslation } from 'react-i18next'

import { GenericIconButton } from '../utils/Buttons'

interface IPortionAdder {
    onClick: () => void
}

const PortionAdder = ({ onClick }: IPortionAdder) => {
    const { t } = useTranslation()
    return (
        <GenericIconButton
            enabled
            onClick={onClick}
            className="portion-add-button"
            tooltip={t('Add portion.')}
            iconName="fa-plus-square"
            iconType="far"
        />
    )
}

export default PortionAdder
