import { CacheType, MediaType, PublishedBible } from '../../../types'
import { CachingProgressIndicator } from '../../projectSettings/projectResources/CachingProgressIndicator'
import { Option } from '../../projectSettings/projectResources/MultiSelect'

interface ResultsRowProps {
    option: Option<number>
    cacheType: CacheType
    mediaType?: MediaType
    bibleVersion?: PublishedBible
}

export const ResultsRow = ({ option, cacheType, mediaType, bibleVersion }: ResultsRowProps) => {
    const { value, label } = option
    return (
        <tr className="modal-table-row">
            <td className="modal-table-cell">{label}</td>
            <td className="modal-progress-column modal-table-cell">
                <CachingProgressIndicator
                    cacheRequest={{ cacheType, mediaType, bibleVersion, bookNumber: Number(value) }}
                    showErrorState
                />
            </td>
        </tr>
    )
}
