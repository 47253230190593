/* eslint-disable react/no-array-index-key */

// Display a item of a note so that the user can play
// or delete[last item only]

import React, { FunctionComponent, useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { INoteRoot } from './NoteDialog'
import { NoteTextEditor } from './NoteTextEditor'
import { Member } from '../../models3/Member'
import { PassageNoteItem } from '../../models3/PassageNoteItem'
import { VideoCache } from '../../models3/VideoCache'
import { TextNotification } from '../notifications/Notifications'
import { PencilButton, DeleteButton, DownloadButton, PaneCloseButton } from '../utils/Buttons'
import { systemError, displayError } from '../utils/Errors'
import { downloadWithFilename } from '../utils/Helpers'
import MediaThumbnail from '../utils/MediaThumbnail'
import { MemberDisplay } from '../utils/MemberDisplay'
import Player from '../utils/Player'
import { EditableRichText } from '../utils/RichTextEditor'
import { BlobDownloading } from '../utils/VideoDownloading'

async function downloadUrl(url: string, filename?: string) {
    const blob = await VideoCache.getVideoBlob(url)
    if (!blob) return
    const href = window.URL.createObjectURL(blob)
    downloadWithFilename(href, filename || `note_${url.split('/').slice(-1)[0].split('-')[0]}.webm`)
}
interface INoteItemControls {
    item: PassageNoteItem
    noteRoot: INoteRoot
    setEditing?: (editing: boolean) => void
    // we assume setEditing passed only if the user has permission to edit
    url?: string
}

const NoteItemControls: FunctionComponent<INoteItemControls> = ({ noteRoot, item, setEditing, url }) => {
    const { t } = useTranslation()
    const { iAmInterpreter, note, createNoteIfNonexistent, hardNotificationCutoff, username, useMobileLayout } =
        noteRoot
    const deleteable = !note?.resolved && iAmInterpreter

    const removeItem = async () => {
        try {
            await createNoteIfNonexistent() // ???
            await note.removeItem(item._id)
        } catch (err) {
            systemError(err)
        }
    }
    const cutoff = hardNotificationCutoff()
    const shouldDisplay = item.isUnviewedAfterDate(username, cutoff)

    return (
        <div className="note-item-controls">
            {shouldDisplay && (
                <TextNotification
                    className="note-item-button note-item-notification"
                    tooltip={t('This item is unviewed')}
                />
            )}
            {setEditing && !useMobileLayout && (
                <span className="note-edit-button note-item-button">
                    <PencilButton
                        enabled
                        className="note-item-edit-button"
                        onClick={() => setEditing(true)}
                        tooltip={t('Edit note item')}
                    />
                </span>
            )}
            {!useMobileLayout && url && (
                <DownloadButton
                    className="note-item-download-button"
                    tooltip={t('Export')}
                    onClick={() => downloadUrl(url)}
                    enabled
                />
            )}
            {deleteable && !useMobileLayout && (
                <span className="note-item-button">
                    <DeleteButton className="note-delete-button" tooltip={t('Delete')} enabled onClick={removeItem} />
                </span>
            )}
        </div>
    )
}

const NoteImageItem: FunctionComponent<INoteItem> = ({ noteRoot, item }) => {
    const [open, setOpen] = useState(false)

    const imageClassName = open ? 'note-image-thumbnail-3x' : 'note-image-thumbnail'
    return (
        <>
            <div className={`note-item-main ${imageClassName}`}>
                <MediaThumbnail
                    url={item.url}
                    creator={item.creator}
                    isImage
                    currentTime={0}
                    onClickVideo={() => {}}
                    onClickImage={() => setOpen(!open)}
                />
            </div>
            <NoteItemControls {...{ noteRoot, item }} />
        </>
    )
}

const NoteTextItem: FunctionComponent<INoteItem> = ({ noteRoot, item, setEditing, allowEditing }) => {
    const [editing, setLocalEditing] = useState(false)

    const { iAmAdmin, username, note } = noteRoot

    const editable = allowEditing && !note.resolved && (iAmAdmin || item.creator === username)

    const prefix = 'note-text-editor'

    function _setEditing(value: boolean) {
        setLocalEditing(value)
        setEditing(value)
    }

    // For now, use the old text editor. We don't do this for text that has
    // already been edited in the new editor. If we did, the old text editor
    // would display HTML strings, which would be surprising for users.
    const useNextTextEditor = item.text.trim().startsWith('<') && item.text.trim().endsWith('>')
    if (useNextTextEditor) {
        return (
            <>
                <EditableRichText
                    savedText={item.text}
                    save={(text) => item.updateText(text)}
                    cancel={() => {}}
                    editorOpen={editing}
                    setEditorOpen={_setEditing}
                    prefix={prefix}
                />
                {!editing && (
                    <NoteItemControls {...{ noteRoot, item }} setEditing={editable ? _setEditing : undefined} />
                )}
            </>
        )
    }

    if (!editing) {
        return (
            <>
                <div className="note-item-main note-text">
                    {item.text.split('\n').map((para, i) => {
                        return (
                            <div key={i} className="note-text-paragraph">
                                {' '}
                                {para}{' '}
                            </div>
                        )
                    })}
                </div>
                <NoteItemControls {...{ noteRoot, item }} setEditing={editable ? _setEditing : undefined} />
            </>
        )
    }

    return (
        <NoteTextEditor
            initialText={item.text}
            onSave={async (text: string) => {
                await item.updateText(text)
                _setEditing(false)
            }}
            onCancel={() => _setEditing(false)}
        />
    )
}

const NoteAudioItem: FunctionComponent<INoteItem> = ({ noteRoot, item }) => {
    const [recordedBlob, setRecordedBlob] = useState<Blob>()
    const [recordedBlobSrc, setRecordedBlobSrc] = useState('')

    useEffect(() => {
        let src = ''
        if (recordedBlob) {
            src = window.URL.createObjectURL(recordedBlob)
            setRecordedBlobSrc(src)
        }

        return () => {
            if (src.trim() !== '') {
                window.URL.revokeObjectURL(src)
            }
        }
    }, [recordedBlob])

    useEffect(() => {
        setRecordedBlob(undefined)
        setRecordedBlobSrc('')
    }, [item.url])

    if (item.url.trim() !== '' && recordedBlobSrc.trim() === '') {
        return <BlobDownloading {...{ url: item.url, onEnded: setRecordedBlob }} />
    }

    return (
        <>
            <audio src={recordedBlobSrc} controls className="audio-note-item" />
            <NoteItemControls {...{ item, noteRoot, url: item.url }} />
        </>
    )
}

const NoteVideoItem: FunctionComponent<INoteItem> = ({ noteRoot, item }) => {
    const { t } = useTranslation()
    const [playing, setPlaying] = useState(false)

    const { username } = noteRoot
    if (!playing) {
        return (
            <>
                <div className="note-item-main">
                    <MediaThumbnail
                        url={item.url}
                        creator={item.creator}
                        isImage={false}
                        currentTime={0.2}
                        onClickVideo={() => {
                            setPlaying(true)
                        }}
                        onClickImage={() => {}}
                    />
                </div>
                <NoteItemControls {...{ item, noteRoot, url: item.url }} />
            </>
        )
    }

    item.addViewedBy(username).catch(displayError)

    return (
        <div>
            <div className="note-item-player">
                <Player className="note-item-player" videoUrl={item.url} />
                <div>
                    <PaneCloseButton
                        onClick={() => setPlaying(false)}
                        enabled
                        tooltip={t('Close pane')}
                        className="sl-pane-close-button"
                    />
                </div>
            </div>
        </div>
    )
}

const NoteItemBody: FunctionComponent<INoteItem> = observer(
    ({ item, noteRoot, closeNoteDialog, setEditing, allowEditing }) => {
        const { t } = useTranslation()
        if (item.resolved) return <span>{t('RESOLVED!')}</span>

        if (item.unresolved) return <span>{t('UNRESOLVED!')}</span>

        if (item.isTextItem()) return <NoteTextItem {...{ noteRoot, item, setEditing, allowEditing }} />

        if (item.isAudioItem()) return <NoteAudioItem {...{ noteRoot, item, setEditing, allowEditing }} />

        if (item.isImageItem()) return <NoteImageItem {...{ noteRoot, item, setEditing, allowEditing }} />

        return <NoteVideoItem {...{ noteRoot, item, closeNoteDialog, setEditing, allowEditing }} />
    }
)
interface INoteItem {
    item: PassageNoteItem
    noteRoot: INoteRoot
    closeNoteDialog?: () => void
    setEditing: (value: boolean) => void
    allowEditing: boolean
}

export const NoteItem: FunctionComponent<INoteItem> = observer(
    ({ noteRoot, item, closeNoteDialog, setEditing, allowEditing }) => {
        const { dateFormatter, project } = noteRoot
        let member = project.members.find((mem) => mem.email === item.creator)
        if (!member) {
            member = new Member(item.creator) //  Create fake member
        }
        const creationDate = item.displayedCreationDate(dateFormatter)

        return (
            <div className="note-item">
                <div className="note-item-header">
                    <div className="note-item-creation-date">{creationDate}</div>
                    <i>
                        <MemberDisplay member={member} imageSize="small" />
                    </i>
                </div>
                <NoteItemBody {...{ noteRoot, item, closeNoteDialog, setEditing, allowEditing }} />
            </div>
        )
    }
)
