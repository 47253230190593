import { useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { CommentsTable } from './CommentsTable'
import { PassageSelector, PassageVersionSelector, PortionSelector, ReviewRecordingSelector } from './Dropdowns'
import { useReviewProjectDropdownState } from './useReviewProjectDropdownState'
import { ReviewProject } from '../../../models3/ReviewProject'
import { Root } from '../../../models3/Root'

import './ReviewResponses.css'

type ResponsesProps = {
    rt: Root
    reviewProject: ReviewProject
}

const Responses = observer(({ rt, reviewProject }: ResponsesProps) => {
    const { t } = useTranslation()
    const [state, dispatch] = useReviewProjectDropdownState(rt, reviewProject)
    const [lastFetchTime, setLastFetchTime] = useState(Date.now())
    const { portion, passage, passageVideo, reviewRecording } = state
    const { project, dateFormatter } = rt

    const projectName = project.name
    const reviewPassageId = reviewRecording?._id
    const reviewQuestions = reviewProject.generalQuestions

    useEffect(() => {
        if (reviewPassageId) {
            setLastFetchTime(Date.now())
        }
    }, [reviewPassageId])

    return (
        <>
            <div className="review-response-choosers">
                <PortionSelector
                    portion={portion}
                    setPortion={(p) =>
                        dispatch({
                            type: 'SET_PORTION',
                            payload: p._id
                        })
                    }
                    project={project}
                />
                {portion && (
                    <PassageSelector
                        setPassage={(p) =>
                            dispatch({
                                type: 'SET_PASSAGE',
                                payload: p._id
                            })
                        }
                        passage={passage}
                        project={project}
                        portion={portion}
                    />
                )}
                {passage && (
                    <PassageVersionSelector
                        project={project}
                        passage={passage}
                        passageVideo={passageVideo}
                        setPassageVideo={(p) =>
                            dispatch({
                                type: 'SET_PASSAGE_VIDEO',
                                payload: p._id
                            })
                        }
                    />
                )}
                {passageVideo && (
                    <ReviewRecordingSelector
                        reviewRecording={reviewRecording}
                        setReviewRecording={(r) =>
                            dispatch({
                                type: 'SET_REVIEW_RECORDING',
                                payload: r._id
                            })
                        }
                        passageVideo={passageVideo}
                        reviewProject={reviewProject}
                        dateFormatter={dateFormatter}
                    />
                )}
                {reviewPassageId && (
                    <div>
                        {t('projectReviewResponseRetrievalTime')} {dateFormatter.format(new Date(lastFetchTime))}
                        <div>
                            <button
                                type="button"
                                className="get-responses-button"
                                onClick={() => setLastFetchTime(Date.now())}
                            >
                                {t('projectReviewReloadResponses')}
                            </button>
                        </div>
                    </div>
                )}
            </div>
            {projectName && reviewPassageId && (
                <>
                    <div className="review-response-divider" />
                    <CommentsTable
                        key={lastFetchTime}
                        projectName={projectName}
                        reviewPassageId={reviewPassageId}
                        reviewQuestions={reviewQuestions}
                        dateFormatter={dateFormatter}
                        reviewProject={reviewProject}
                    />
                </>
            )}
        </>
    )
})

type ReviewResponsesProps = {
    rt: Root
    reviewProject: ReviewProject
}

export const ReviewResponses = observer(({ rt, reviewProject }: ReviewResponsesProps) => {
    const { t } = useTranslation()
    const { project } = rt

    const allPassageRecordings = project.portions.flatMap((portion) =>
        portion.passages.flatMap((passage) => passage.videosNotDeleted)
    )
    const reviewsExist = allPassageRecordings.some((recording) => project.recordingsWithReviews.has(recording._id))
    if (!reviewsExist) {
        return <div>{t('reviewNone')}</div>
    }

    return <Responses rt={rt} reviewProject={reviewProject} />
})
