import { FC, useState } from 'react'

import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { Portion } from '../../models3/Portion'
import { Root } from '../../models3/Root'
import { RefRange } from '../../resources/RefRange'
import { ReferenceInput } from '../referenceInput/ReferenceInput'
import { CancelEditSegmentButton, OkEditSegmentButton } from '../utils/Buttons'
import LabeledFieldWithIcon from '../utils/LabeledFieldWithIcon'
import { SimpleTextInput } from '../utils/TextInput'

interface IPortionEditor {
    rt: Root
    portion?: Portion
    setEditing: (editing: boolean) => void
    save: (name: string, references: RefRange[]) => void
}

const PortionEditor: FC<IPortionEditor> = ({ rt, portion, setEditing, save }) => {
    const { t } = useTranslation()
    const [portionName, setPortionName] = useState(portion?.name ?? '')
    const [nameError, setNameError] = useState('')
    const [references, setReferences] = useState(portion?.references ?? [])
    const [referencesError, setReferencesError] = useState(false)

    const validateName = (name: string) => {
        const newName = name.trim()

        if (newName === '') {
            return t('Empty name')
        }

        if (newName === portion?.name) {
            return ''
        }

        if (rt.project.portions.some((projectPortion) => projectPortion.name === newName)) {
            return t('Duplicate name')
        }

        return ''
    }

    const areValidInputs = () => {
        return validateName(portionName) === '' && !referencesError
    }

    const setName = (value: string) => {
        setNameError(validateName(value))
        setPortionName(value)

        try {
            const parsedNewReferences = rt.parseReferences(value.trim(), true)
            if (parsedNewReferences.length > 0) {
                // Continue to show old references until the user types a valid reference
                setReferences(parsedNewReferences)
                setReferencesError(false)
            }
        } catch (error) {
            // Ignore, since we just want to know whether the string can be parsed as a reference
        }
    }

    return (
        <Modal show onHide={() => setEditing(false)} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t('Portion')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LabeledFieldWithIcon
                    iconName="fa-tag"
                    title={t('Title')}
                    field={
                        <SimpleTextInput autoFocus value={portionName} setValue={setName} errorMessage={nameError} />
                    }
                />
                <LabeledFieldWithIcon
                    iconName="fa-book"
                    title={t('References')}
                    field={
                        <ReferenceInput
                            refInput={rt}
                            refs={references}
                            setRefs={setReferences}
                            errored={referencesError}
                            setErrored={setReferencesError}
                            showSuggestions
                            autoComplete
                        />
                    }
                />
            </Modal.Body>
            <Modal.Footer>
                <div className="button-row">
                    <OkEditSegmentButton
                        enabled={areValidInputs()}
                        onClick={() => {
                            save(portionName, references)
                            setEditing(false)
                        }}
                    />
                    <CancelEditSegmentButton enabled onClick={() => setEditing(false)} />
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default PortionEditor
