import { useState } from 'react'

import { Dropdown, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { PlanStructure, plans } from './defaultPlans'
import { PlanPreview } from './PlanPreview'
import { CancelButton, OKButton } from '../../utils/Buttons'

const choices = Object.keys(plans) as (keyof typeof plans)[]

interface PlanAddModalProps {
    closeModal: () => void
    addPlan: (plan: PlanStructure) => void
}

export const PlanAddModal = ({ closeModal, addPlan }: PlanAddModalProps) => {
    const { t } = useTranslation()
    const [selectedOption, setSelectedOption] = useState<keyof typeof plans>()

    const selectedPlan = selectedOption ? plans[selectedOption] : undefined

    return (
        <Modal show onHide={closeModal} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t('projectPlanHeading')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {t('initialProjectPlan')}
                <div>
                    <Dropdown id="project-plan-choice">
                        <Dropdown.Toggle className="sl-dropdown styled-dropdown-select">
                            {selectedOption ? t(selectedOption) : t('Choose one')}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="styled-dropdown-select-menu">
                            {choices.map((choice) => (
                                <Dropdown.Item key={choice} onClick={() => setSelectedOption(choice)}>
                                    {t(choice)}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    {selectedPlan && (
                        <>
                            <hr />
                            <PlanPreview plan={selectedPlan} />
                        </>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="button-row">
                    <OKButton
                        enabled={Boolean(selectedPlan)}
                        onClick={() => {
                            if (selectedPlan) {
                                addPlan(selectedPlan)
                            }
                            closeModal()
                        }}
                        className="modal-footer-button ok-icon"
                        buttonClassName=""
                        tooltip={t('selectThisProjectPlan')}
                    />
                    <CancelButton
                        enabled
                        onClick={() => {
                            closeModal()
                        }}
                        className="modal-footer-button"
                        tooltip={t('Cancel')}
                    />
                </div>
            </Modal.Footer>
        </Modal>
    )
}
