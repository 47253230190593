import React, { FC, useContext } from 'react'

import { observer } from 'mobx-react'
import { confirmAlert } from 'react-confirm-alert'
import { useTranslation } from 'react-i18next'

import { AppRoot } from '../../../models3/AppRoot'
import { RootContext } from '../../app/RootContext'

interface IRefetchLocalDatabase {
    appRoot: AppRoot
}

const RefetchLocalDatabase: FC<IRefetchLocalDatabase> = observer(({ appRoot }) => {
    const { t } = useTranslation()
    const rt = useContext(RootContext)

    const confirmDelete = () => {
        const onConfirm = async () => {
            if (rt) {
                await rt.project.db.deleteDB()
            }
            appRoot.reinitializeProjects()
        }

        confirmAlert({
            title: t('Refetch Local Database?'),
            message: t(
                'This will delete the local database for this project and download a new copy from the remote server.'
            ),
            buttons: [
                {
                    label: t('Cancel'),
                    onClick: () => {}
                },
                {
                    label: t('Accept'),
                    onClick: onConfirm
                }
            ]
        })
    }

    return (
        <button type="button" className="btn btn-default" onClick={confirmDelete}>
            {t('Refetch local database')}
        </button>
    )
})

export default RefetchLocalDatabase
