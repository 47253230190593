import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { VisibleDocument } from './PassageDocuments'
import { GenericIcon } from '../utils/Icons'

// legacy passage docs allowed empty titles and generated titles based on index
const documentTitle = (title: string, index: number) => title || `#${index + 1}`

interface DropdownItem {
    id: string
    title: string
    isGlobal: boolean
    isGroup: boolean
}
const dropdownItems = (visibleDocuments: VisibleDocument[]): DropdownItem[] => {
    const items: DropdownItem[] = []
    let foundGroup = false

    visibleDocuments.forEach(({ group, document: { _id: id, title, isGlobal } }, index) => {
        if (!foundGroup && group) {
            foundGroup = true
            items.push({ id: '', title: group, isGlobal: false, isGroup: false })
        }
        items.push({ id, title: documentTitle(title, index), isGlobal, isGroup: Boolean(group) })
    })

    return items
}

const RenderDocumentTitle = ({ title, isGlobal }: { title: string; isGlobal: boolean }) => {
    const { t } = useTranslation()

    return (
        <>
            {title}
            {isGlobal && (
                <GenericIcon
                    className="passage-document-global-icon"
                    iconName="fa-globe"
                    iconType="fas"
                    tooltip={t('resourceVisibilityGlobal')}
                />
            )}
        </>
    )
}

interface PassageDocumentSelectorProps {
    visibleDocuments: VisibleDocument[]
    documentId: string
    setDocumentId: (value: string) => void
    idSuffix: string
    disabled?: boolean
}

export const PassageDocumentSelector = ({
    visibleDocuments,
    documentId,
    setDocumentId,
    idSuffix,
    disabled
}: PassageDocumentSelectorProps) => {
    const items = dropdownItems(visibleDocuments)
    const selectedItem = items.find((item) => documentId === item.id) || (items.length > 0 && items[0])

    return (
        <Dropdown id={`resources-dropdown-${idSuffix}`} className="resources-select" align="start">
            <Dropdown.Toggle className="sl-dropdown styled-dropdown-select" disabled={disabled}>
                {selectedItem && <RenderDocumentTitle title={selectedItem.title} isGlobal={selectedItem.isGlobal} />}
            </Dropdown.Toggle>
            <Dropdown.Menu className="scrollable-dropdown styled-dropdown-select-menu">
                {items.map(({ id, title, isGroup, isGlobal }) =>
                    id ? (
                        <Dropdown.Item key={id} onClick={() => setDocumentId(id)} active={id === documentId}>
                            <div className={`${isGroup ? 'resources-dropdown-group-item' : 'resources-dropdown-item'}`}>
                                <div className="resources-dropdown-header">
                                    <RenderDocumentTitle title={title} isGlobal={isGlobal} />
                                </div>
                            </div>
                        </Dropdown.Item>
                    ) : (
                        <Dropdown.Header key={title}>{title}</Dropdown.Header>
                    )
                )}
            </Dropdown.Menu>
        </Dropdown>
    )
}
