import { useEffect, useRef } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import OldSegmentPlayer from './OldSegmentPlayer'
import { SegmentDocumentEditor } from './SegmentDocumentEditor'
import { panelOrder, panelVisibility } from './SegmentPanelOrder'
import { SegmentTimestamp } from './SegmentTimestamp'
import { Passage } from '../../models3/Passage'
import { PassageSegment } from '../../models3/PassageSegment'
import { PassageVideo } from '../../models3/PassageVideo'
import { Root } from '../../models3/Root'
import { PaneCloseButton } from '../utils/Buttons'

interface OldSegmentViewerProps {
    rt: Root
    originalVideo: PassageVideo
    originalSegment: PassageSegment
    video: PassageVideo
    segment: PassageSegment
    passage: Passage
    onDone: () => void
}

export const OldSegmentViewer = observer(
    ({ rt, originalVideo, originalSegment, video, segment, passage, onDone }: OldSegmentViewerProps) => {
        const { t } = useTranslation()
        const originalVideoRef = useRef(originalVideo)
        const originalSegmentRef = useRef(originalSegment)
        const { project } = rt

        useEffect(() => {
            if (
                originalVideoRef.current._id !== originalVideo._id ||
                originalSegmentRef.current._id !== originalSegment._id
            ) {
                onDone()
            }
        }, [originalVideo._id, originalSegment._id, onDone])

        useEffect(() => {
            originalSegmentRef.current = originalSegment
            originalVideoRef.current = originalVideo
        })

        return (
            <div className="segment-dialog">
                <div className="old-segment-viewer-heading">
                    <div className="segment-dialog-heading flex-grow-1">{t('Older Segment Version')}</div>
                    <PaneCloseButton
                        onClick={onDone}
                        enabled
                        className="sl-segment-toolbar-button"
                        tooltip={t('Close pane')}
                    />
                </div>
                <SegmentTimestamp rt={rt} passage={passage} passageSegment={originalSegment} currentSegment={segment} />
                <OldSegmentPlayer video={video} segment={segment} passage={passage} />
                {segment.transcriptions.length > 0 && panelVisibility(project, 'transcriptionPanel') && (
                    <SegmentDocumentEditor
                        segment={segment}
                        documentType="transcription"
                        className={panelOrder(project, 'transcriptionPanel')}
                        showMessage={false}
                        readOnly
                        isEditingSegment={false}
                        setIsEditingSegment={() => {}}
                    />
                )}
                {Boolean(segment.documents.length || segment.audioClips.length) &&
                    panelVisibility(project, 'backTranslationPanel') && (
                        <SegmentDocumentEditor
                            segment={segment}
                            documentType="backTranslationText"
                            className={panelOrder(project, 'backTranslationPanel')}
                            showMessage={false}
                            readOnly
                            isEditingSegment={false}
                            setIsEditingSegment={() => {}}
                        />
                    )}
            </div>
        )
    }
)
