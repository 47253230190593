import { FC, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { ReferenceInputPropsBase } from './types'
import { displayableBookNames } from '../../resources/bookNames'
import { RefRange } from '../../resources/RefRange'
import { SearchBox } from '../utils/SearchBox'

import './ReferenceInput.css'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:ReferenceInput')

interface ReferenceInputProps extends ReferenceInputPropsBase {
    refs: RefRange[]
    showSuggestions?: boolean
}

export const ReferenceInput: FC<ReferenceInputProps> = ({
    refInput,
    refs,
    setRefs,
    allowBookNameOnly,
    onEnter,
    onEscape,
    errored,
    setErrored,
    autoFocus,
    disabled,
    showSuggestions,
    required = false,
    autoComplete = false
}) => {
    const { t } = useTranslation()

    const [references, setReferences] = useState(refInput.displayableReferences(refs))
    const [focused, setFocused] = useState(false)
    const [suggestions, setSuggestions] = useState<RefRange[]>([])

    useEffect(() => {
        if (focused) {
            return
        }

        if (required || autoComplete) {
            setReferences(refInput.displayableReferences(refs))
        }
        setErrored(false)
    }, [autoComplete, focused, refInput, refs, required, setErrored])

    const searchParameterChanged = (newReferences: string) => {
        setReferences(newReferences)
        try {
            if (showSuggestions) {
                const newSuggestions = refInput.getSuggestions(newReferences)
                if (newSuggestions.length > 0) {
                    // Continue to show old suggestions until new ones are available
                    setSuggestions(newSuggestions)
                }
            }

            const parsedNewReferences = refInput.parseReferences(newReferences.trim(), allowBookNameOnly ?? false)
            log('setRefs', refInput.displayableReferences(parsedNewReferences))

            if (parsedNewReferences.length > 0 || !required) {
                setRefs(parsedNewReferences)
                setErrored(false)
            }
        } catch (error) {
            setErrored(true)
        }
    }

    const bookNames = displayableBookNames(refInput.project)

    return (
        <div className="reference-input">
            <div className={`reference-input-searchbox ${errored ? 'reference-input-error' : ''}`}>
                <SearchBox
                    searchParameter={references}
                    options={bookNames}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    onEnter={() => {
                        if (!errored && onEnter) {
                            onEnter()
                        }
                    }}
                    onEscape={() => {
                        if (onEscape) {
                            onEscape()
                        }
                    }}
                    searchParameterChanged={searchParameterChanged}
                    tooltip={t('Show book name suggestions')}
                    autoFocus={autoFocus}
                    disabled={disabled}
                />
            </div>
            {suggestions.length > 0 && refInput.displayableReferences(suggestions)}
        </div>
    )
}
