import { useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { ProjectPlan } from '../../../models3/ProjectPlan'
import { useAppRoot } from '../../app/RootContext'
import { GenericIconButton } from '../../utils/Buttons'
import TextInput from '../../utils/TextInput'

interface IProjectStageAdder {
    addStage: (name: string) => void
    plan: ProjectPlan
}

const ProjectStageAdder = observer(({ addStage, plan }: IProjectStageAdder) => {
    const { rt } = useAppRoot()
    const { t } = useTranslation()

    const [adding, setAdding] = useState(false)

    const validate = (value: string) => {
        value = value.trim()
        if (plan.stages.find((stage) => stage.name === value)) {
            return t('Duplicate name')
        }
        if (value === '') {
            return t('Empty name')
        }
        return ''
    }

    const onEnter = (newStageName: string) => {
        if (!validate(newStageName)) {
            addStage(newStageName)
        }
        setAdding(false)
    }

    if (!rt) {
        return null
    }

    const addStageText = t('Add stage.')

    if (adding) {
        return (
            <TextInput
                placeholder={addStageText}
                message={t('Type Enter to add new stage or Esc to cancel.')}
                validate={validate}
                _onEscape={() => setAdding(false)}
                _onEnter={onEnter}
            />
        )
    }

    return (
        <GenericIconButton
            onClick={() => setAdding(true)}
            className="stage-adder"
            tooltip={addStageText}
            enabled
            iconName="fa-plus-square"
            iconType="far"
        />
    )
})

export default ProjectStageAdder
