import React, { useRef, FC } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { GenericIconButton } from '../utils/Buttons'

interface FilePickerProps {
    enabled: boolean
    setSelectedFiles: (file: FileList) => void
    multiple?: boolean
    className: string
    tooltip?: string
    iconName?: string
    iconType?: 'fas' | 'far'
    accept?: string
}

const FilePicker: FC<FilePickerProps> = observer(
    ({ enabled, setSelectedFiles, multiple, className, accept, iconName, iconType = 'fas', tooltip }) => {
        const { t } = useTranslation()
        const inputRef = useRef<HTMLInputElement>(null)

        return (
            <>
                <input
                    type="file"
                    multiple={multiple}
                    accept={accept}
                    style={{ display: 'none' }}
                    onChange={() => {
                        if (inputRef.current?.files?.length) {
                            setSelectedFiles(inputRef.current.files)
                        }
                    }}
                    ref={inputRef}
                />
                <GenericIconButton
                    onClick={() => {
                        if (inputRef.current) {
                            // Reset input so change event will fire once we've selected a file
                            inputRef.current.value = ''
                            inputRef.current.click()
                        }
                    }}
                    className={className}
                    tooltip={tooltip ?? t('recordingImport')}
                    enabled={enabled}
                    iconName={iconName ?? 'fa-upload'}
                    iconType={iconType}
                />
            </>
        )
    }
)

export default FilePicker
