import React, { FC } from 'react'

import { confirmAlert } from 'react-confirm-alert'
import { useTranslation } from 'react-i18next'

import { Passage } from '../../models3/Passage'
import { PassageSegment } from '../../models3/PassageSegment'
import { PassageVideo } from '../../models3/PassageVideo'
import { Root } from '../../models3/Root'
import { DeletePatchVideoButton } from '../utils/Buttons'
import { systemError } from '../utils/Errors'
import { incrementTime } from '../utils/Helpers'

interface IPatchVideoDeleter {
    rt: Root
    passage: Passage
    passageVideo: PassageVideo
    segment: PassageSegment
}

export async function deletePatch(props: IPatchVideoDeleter) {
    const { rt, passage, passageVideo, segment } = props

    const segmentIndex = passageVideo.findSegmentIndex(segment._id, true)
    const patchVideo = passage.findVideo(segment.videoPatchHistory.slice(-1)[0])

    if (!passage || !patchVideo || !patchVideo.isPatch || segmentIndex < 0) {
        throw Error('Something went wrong')
    }

    await passage.deletePatchVideo(passageVideo, patchVideo, segment)

    // If no patches in the previous (and next) segment,
    // reset the ending (starting) position back to the default value.
    if (segmentIndex > 0) {
        // not first segment
        const segments = passageVideo.getAllBaseSegments()
        const previousSegment = segments[segmentIndex - 1]
        if (!previousSegment.isPatched) {
            const newEndPosition = previousSegment.hardEndPosition(passageVideo)
            await previousSegment.setEndPosition(newEndPosition, passageVideo)
        }
    }
    const updatedSegments = passageVideo.getAllBaseSegments()
    if (segmentIndex < updatedSegments.length - 1) {
        // not last segment
        const nextSegment = updatedSegments[segmentIndex + 1]
        if (!nextSegment.isPatched) {
            const newStartPosition = nextSegment.hardStartPosition(passageVideo)
            await nextSegment.setStartPosition(newStartPosition, passageVideo)
        }
    }

    // Reset times to account for the fact that we removed a patch and thus
    // changed the length of the total video.
    passageVideo.setSegmentTimes(passage, true)

    await rt.setPassageVideo(passageVideo) // Cause view to refresh
    rt.resetCurrentTime(incrementTime(segment.time), passageVideo.computedDuration)
}
interface IPatchVideoDeleterComponent {
    deleter: IPatchVideoDeleter
    enabled: boolean
}

export const PatchVideoDeleter: FC<IPatchVideoDeleterComponent> = ({ deleter, enabled }) => {
    const { t } = useTranslation()

    function confirmDeletion(doDeletion: () => void) {
        confirmAlert({
            title: t('recordingDeleteThisPatch'),
            message: t('recordingDeleteThisPatchMessage'),
            buttons: [
                {
                    label: t('Keep'),
                    onClick: () => {}
                },
                {
                    label: t('Delete'),
                    onClick: doDeletion
                }
            ]
        })
    }

    return (
        <DeletePatchVideoButton
            onClick={() => {
                confirmDeletion(() => {
                    deletePatch(deleter).catch(systemError)
                })
            }}
            enabled={enabled}
        />
    )
}
