import { IDateFormatter } from './DateUtilities'
import { DBObject } from './DBObject'
import { getEmailPrefix } from '../components/utils/Helpers'

interface RecordingTimestampProps {
    object: DBObject
    dateFormatter: IDateFormatter
    showFullName?: boolean
    className: string
    dateBy?: 'creationDate' | 'modDate'
    name?: 'creator' | 'modBy'
}

export const VisibleTimestamp = ({
    object,
    dateFormatter,
    showFullName,
    className,
    dateBy = 'creationDate',
    name = 'creator'
}: RecordingTimestampProps) => {
    const visibleCreator = showFullName ? object[name] : getEmailPrefix(object[name])
    const formattedDate = dateFormatter.format(new Date(object[dateBy]))
    return (
        <div className={className}>
            <div style={{ direction: 'ltr' }}>
                {visibleCreator && <span>{visibleCreator} </span>}
                <span>{formattedDate}</span>
            </div>
        </div>
    )
}
