import { observer } from 'mobx-react'

import { useFetchFromVideoCache } from '../../hooks/useFetchFromVideoCache'
import { DownloadingMessage, VideoDownloadingMessage } from '../video/VideoMessage'

interface IVideoDownloading {
    videoUrl: string
    creator?: string
    onEnded: (blob: Blob) => void
    fontSizePt: number
    className?: string
}

const VideoDownloading = observer(
    ({ videoUrl, creator, onEnded, fontSizePt: fontSize, className }: IVideoDownloading) => {
        useFetchFromVideoCache({ url: videoUrl, onEnded })

        return (
            <div className={className || ''}>
                <VideoDownloadingMessage urls={[videoUrl]} creator={creator} fontSizePt={fontSize} />
            </div>
        )
    }
)

type BlobDownloadingProps = {
    url: string
    onEnded: (blob: Blob) => void
}

export const BlobDownloading = observer(({ url, onEnded }: BlobDownloadingProps) => {
    useFetchFromVideoCache({ url, onEnded })

    return <DownloadingMessage {...{ url }} />
})

export default VideoDownloading
