import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import ReviewDropdown from './ReviewDropdown'
import { IDateFormatter } from '../../../models3/DateUtilities'
import { Passage } from '../../../models3/Passage'
import { PassageVideo } from '../../../models3/PassageVideo'
import { Portion } from '../../../models3/Portion'
import { Project } from '../../../models3/Project'
import { ReviewProject } from '../../../models3/ReviewProject'
import { ReviewProjectPassageRecording } from '../../../models3/ReviewProjectPassageRecording'

import './ReviewResponses.css'

type PortionSelectorProps = {
    portion?: Portion
    setPortion: (portion: Portion) => void
    project: Project
}

export const PortionSelector = observer(({ project, portion, setPortion }: PortionSelectorProps) => {
    const { t } = useTranslation()

    const options = project.portions
        .filter((p) => {
            const passageRecordings = p.passages.flatMap((passage) => passage.videosNotDeleted)
            return passageRecordings.some((recording) => project.recordingsWithReviews.has(recording._id))
        })
        .map((p) => ({
            key: p._id,
            value: p.name,
            onSelect: () => setPortion(p)
        }))

    return (
        <div>
            <div>{t('Portion')}</div>
            <ReviewDropdown
                id="review-responses-portion-selector"
                toggle={portion?.name ?? t('selectOne')}
                options={options}
            />
        </div>
    )
})

type PassageSelectorProps = {
    passage?: Passage
    setPassage: (passage: Passage) => void
    portion: Portion
    project: Project
}

export const PassageSelector = observer(({ portion, passage, setPassage, project }: PassageSelectorProps) => {
    const { t } = useTranslation()

    const options = portion.passages
        .filter((p) => p.videosNotDeleted.some((recording) => project.recordingsWithReviews.has(recording._id)))
        .map((p) => ({
            key: p._id,
            value: p.name,
            onSelect: () => setPassage(p)
        }))

    return (
        <div>
            <div>{t('Passage')}</div>
            <ReviewDropdown
                id="review-responses-passage-selector"
                toggle={passage?.name ?? t('selectOne')}
                options={options}
            />
        </div>
    )
})

type PassageVersionSelectorProps = {
    passage: Passage
    passageVideo?: PassageVideo
    setPassageVideo: (video: PassageVideo) => void
    project: Project
}

export const PassageVersionSelector = observer(
    ({ passage, passageVideo, setPassageVideo, project }: PassageVersionSelectorProps) => {
        const { t } = useTranslation()
        const versionNumber = (pv: PassageVideo) => 1 + passage.getVersionIndex(pv)

        const options = passage.videosNotDeleted
            .filter((recording) => project.recordingsWithReviews.has(recording._id))
            .map((pv) => ({
                key: pv._id,
                value: versionNumber(pv),
                onSelect: () => setPassageVideo(pv)
            }))

        return (
            <div>
                <div>{t('Version')}</div>
                <ReviewDropdown
                    id="review-responses-passage-version-selector"
                    toggle={passageVideo ? versionNumber(passageVideo) : t('selectOne')}
                    options={options}
                />
            </div>
        )
    }
)

type ReviewRecordingSelectorProps = {
    reviewRecording?: ReviewProjectPassageRecording
    setReviewRecording: (recording: ReviewProjectPassageRecording) => void
    passageVideo: PassageVideo
    reviewProject: ReviewProject
    dateFormatter: IDateFormatter
}

export const ReviewRecordingSelector = observer(
    ({
        reviewRecording,
        passageVideo,
        reviewProject,
        setReviewRecording,
        dateFormatter
    }: ReviewRecordingSelectorProps) => {
        const { t } = useTranslation()
        const reviewRecordingValue = (recording: ReviewProjectPassageRecording) => {
            return dateFormatter.format(new Date(recording.creationDate))
        }

        const options = passageVideo
            .getReviewRecordings(reviewProject)
            .reverse()
            .map((r) => ({
                key: r._id,
                value: reviewRecordingValue(r),
                onSelect: () => setReviewRecording(r)
            }))

        return (
            <div>
                <div>{t('projectReviewDate')}</div>
                <ReviewDropdown
                    id="review-responses-review-recording-selector"
                    toggle={reviewRecording ? reviewRecordingValue(reviewRecording) : t('selectOne')}
                    options={options}
                />
            </div>
        )
    }
)
