import { useState, useEffect } from 'react'

import { TFunction } from 'i18next'
import { Dropdown, FormControl, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { MemberRole, memberExistsWithRole } from '../../../models3/Member'
import { Passage } from '../../../models3/Passage'
import { PassageCopyVisitor } from '../../../models3/PassageCopyVisitor'
import { Portion } from '../../../models3/Portion'
import { Project } from '../../../models3/Project'
import { useAppRoot } from '../../app/RootContext'
import { CancelButton, OKButton } from '../../utils/Buttons'
import { systemError } from '../../utils/Errors'
import { LoadingIcon } from '../../utils/Icons'
import { Switch } from '../../utils/Switch'
import { WarningList } from '../../utils/WarningList'

const permissionError = (role: MemberRole, t: TFunction) => `${t('copyPermissionRoleError')} ${role}`

type CopyProjectDataType = 'plan' | 'portions' | 'portion' | 'passage'

interface CopyPassageOptionsProps {
    latestDraftOnly: boolean
    includeResources: boolean
    setLatestDraftOnly: (value: boolean) => void
    setIncludeResources: (value: boolean) => void
}
export const CopyPassageOptions = ({
    latestDraftOnly,
    includeResources,
    setLatestDraftOnly,
    setIncludeResources
}: CopyPassageOptionsProps) => {
    const { t } = useTranslation()

    return (
        <div>
            <h5>{t('copyLatestPassageVersionOnly')}</h5>
            <div>
                <Switch value={latestDraftOnly} setValue={(value) => setLatestDraftOnly(value)} />
            </div>

            <h5>{t('Copy passage resources?')}</h5>
            <div>
                <Switch value={includeResources} setValue={(value) => setIncludeResources(value)} />
            </div>
        </div>
    )
}

interface CopyProjectDataResultProps {
    setOpenModal: (value: boolean) => void
    destinationProjects: Project[]
    loading: boolean
    error: string
}

const CopyProjectDataResult = (props: CopyProjectDataResultProps) => {
    const { t } = useTranslation()

    const { setOpenModal, destinationProjects, loading, error } = props

    if (loading) {
        return (
            <Modal.Body>
                <div className="project-modal-loading-message-parent">
                    <LoadingIcon className="" />
                    <span className="project-modal-loading-message">{t('Copying...')}</span>
                </div>
            </Modal.Body>
        )
    }

    if (error) {
        return <Modal.Body>{error}</Modal.Body>
    }

    const successMessage =
        destinationProjects.length > 1 ? t('Successfully copied to projects: ') : t('Successfully copied to project: ')

    return (
        <>
            <Modal.Body>
                {successMessage + destinationProjects.map((project) => project.getFormattedDisplayName()).join(', ')}
            </Modal.Body>
            <Modal.Footer>
                <div className="button-row">
                    <OKButton
                        enabled
                        onClick={() => setOpenModal(false)}
                        buttonClassName=""
                        className="ok-button"
                        tooltip={t('OK')}
                    />
                </div>
            </Modal.Footer>
        </>
    )
}

interface CopyProjectDataModalProps {
    setOpenModal: (value: boolean) => void
    source: Project
    copyDataType: CopyProjectDataType
    copyData?: Portion | Passage
}

export const CopyProjectDataModal = (props: CopyProjectDataModalProps) => {
    const { t } = useTranslation()

    const { setOpenModal, source, copyDataType, copyData } = props
    const appRoot = useAppRoot()
    const [destinations, setDestinationProjects] = useState<Project[] | undefined>()
    const [selectedProjects, setSelectedProjects] = useState<Project[]>([])
    const [portionsToCopy, setPortionsToCopy] = useState<Portion[]>([])
    const [selectedPortion, setSelectedPortion] = useState<Portion | undefined>()
    const [latestDraftOnly, setLatestDraftOnly] = useState(true)
    const [includeResources, setIncludeResources] = useState(!appRoot?.rt?.groupProjects?.length) // default to include resources unless copying from a group
    const [showCopyPage, setShowCopyPage] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')

    const excludeProject = copyDataType === 'plan' || copyDataType === 'portions' ? source.name : undefined

    const requiredTargetRole: MemberRole =
        copyDataType === 'plan' || copyDataType === 'portions' ? 'admin' : 'translator'

    const warning =
        copyDataType === 'plan'
            ? t('This will overwrite the existing data attached to the destination project.')
            : t('This will not overwrite the existing data attached to the destination project, but will add it.')

    const header = () => {
        switch (copyDataType) {
            case 'passage':
                return `${t('Copy passage')}: ${(copyData as Portion).name}`
            case 'plan':
                return t('Copy plan')
            case 'portion':
                return `${t('Copy portion')}: ${(copyData as Portion).name}`
            case 'portions':
                return t('Copy portions')
            default:
                return ''
        }
    }

    const copy = async () => {
        try {
            setLoading(true)

            const email = appRoot.username
            if (
                !appRoot.iAmRoot &&
                !selectedProjects.every(({ members }) =>
                    memberExistsWithRole({ members, email, requiredRole: requiredTargetRole })
                )
            ) {
                setError(permissionError(requiredTargetRole, t))
                return
            }

            if (copyDataType === 'plan') {
                await Promise.all(
                    selectedProjects.map((project) => project.setViewableStagesFromExisting({ sourceProject: source }))
                )
            } else if (copyDataType === 'passage' && selectedPortion) {
                await Promise.all(
                    selectedProjects.map((project) => {
                        const visitor = new PassageCopyVisitor(project, source, selectedPortion, includeResources)
                        return visitor.visitPassage(copyData as Passage, latestDraftOnly)
                    })
                )
            } else if (copyDataType === 'portion') {
                await Promise.all(
                    selectedProjects.map((sourceProject) =>
                        sourceProject.setPortionFromExisting({
                            sourceProject: source,
                            portion: copyData as Portion,
                            includeResources,
                            latestDraftOnly
                        })
                    )
                )
            } else if (copyDataType === 'portions') {
                await Promise.all(
                    selectedProjects.flatMap((sourceProject) =>
                        sourceProject.setPortionsFromExisting({
                            sourceProject,
                            portions: portionsToCopy,
                            includeResources,
                            latestDraftOnly
                        })
                    )
                )
            }
        } catch (e) {
            console.error(e)
            systemError(e)
            setError(t('Could not copy.'))
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        const initializeRoots = async () => {
            setDestinationProjects(undefined)
            await Promise.all(appRoot.rts.map((rt) => rt.initialize()))

            // NOTE: rts projects do not contain latest rt.iAm permissions, so check members directly
            const sortedProjects = appRoot.getProjectsByRole({ requiredRole: requiredTargetRole, excludeProject })
            setDestinationProjects(sortedProjects)
        }
        initializeRoots()
    }, [appRoot, excludeProject, requiredTargetRole])

    const renderBodyAndFooter = () => {
        if (!destinations) {
            return (
                <Modal.Body>
                    <LoadingIcon className="" />
                </Modal.Body>
            )
        }

        if (showCopyPage && selectedProjects.length) {
            return (
                <CopyProjectDataResult
                    destinationProjects={selectedProjects}
                    setOpenModal={setOpenModal}
                    loading={loading}
                    error={error}
                />
            )
        }

        const hasProjects = destinations.length >= 1

        const renderPortions = (project: Project) => {
            if (project.portions.length === 0) {
                return (
                    <div className="modal-copy-passage-warning-message">
                        <WarningList
                            warnings={[
                                {
                                    key: 'no-portions-warning',
                                    text: t('noPortionToCopy')
                                }
                            ]}
                        />
                    </div>
                )
            }

            return (
                <div>
                    <h5>{t('Destination portion')}</h5>
                    <Dropdown id="copy-passage-modal-portion-dropdown">
                        <Dropdown.Toggle className="sl-dropdown styled-dropdown-select">
                            {selectedPortion?.name ?? t('Choose one')}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="styled-dropdown-select-menu">
                            {project.portions.map((portion) => (
                                <Dropdown.Item key={portion._id} onClick={() => setSelectedPortion(portion)}>
                                    {portion.name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            )
        }

        const _setPortionsToCopy = (portionIds: string[]) => {
            const portions = source.portions.filter((portion) => portionIds.includes(portion._id))
            setPortionsToCopy(portions)
        }

        const _setSelectedProjects = (projectNames: string[]) => {
            const projects = appRoot.rts.filter((rt) => projectNames.includes(rt.name)).map((rt) => rt.project)
            setSelectedProjects(projects)
        }

        const renderPortionsToCopy = () => {
            return (
                <div>
                    <h5>{t('Portions to copy')}</h5>
                    {portionsToCopy.map((portion) => portion.name).join(', ')}
                    <FormControl
                        as="select"
                        multiple
                        onChange={(e: any) => {
                            _setPortionsToCopy(Array.from(e.target.selectedOptions).map((opt: any) => opt.value))
                        }}
                    >
                        {source.portions.map((portion) => (
                            <option key={portion._id} value={portion._id}>
                                {portion.name}
                            </option>
                        ))}
                    </FormControl>
                </div>
            )
        }

        const hasSelectedProject = selectedProjects.length > 0

        const shouldRenderOK = () => {
            if (copyDataType === 'portions') {
                return hasSelectedProject && portionsToCopy.length > 0
            }
            if (copyDataType === 'passage') {
                return hasSelectedProject && !!selectedPortion
            }
            return hasSelectedProject
        }

        return (
            <>
                <Modal.Body>
                    <div className="project-modal-section">
                        {hasProjects && (
                            <>
                                <div>
                                    {copyDataType === 'portions' ? (
                                        <>
                                            <h5>{t('Destination projects')}</h5>
                                            <FormControl
                                                as="select"
                                                multiple
                                                onChange={(e: any) =>
                                                    _setSelectedProjects(
                                                        Array.from(e.target.selectedOptions).map(
                                                            (opt: any) => opt.value
                                                        )
                                                    )
                                                }
                                            >
                                                {destinations.map((project) => (
                                                    <option key={project.name} value={project.name}>
                                                        {project.getFormattedDisplayName()}
                                                    </option>
                                                ))}
                                            </FormControl>
                                        </>
                                    ) : (
                                        <>
                                            <h5>{t('Destination project')}</h5>
                                            <Dropdown id="project-modal-project-dropdown">
                                                <Dropdown.Toggle className="sl-dropdown styled-dropdown-select">
                                                    {selectedProjects.length
                                                        ? selectedProjects[0].getFormattedDisplayName()
                                                        : t('Choose one')}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="styled-dropdown-select-menu">
                                                    {destinations.map((project) => (
                                                        <Dropdown.Item
                                                            key={project.name}
                                                            onClick={() => {
                                                                setSelectedProjects([project])
                                                                setSelectedPortion(undefined)
                                                            }}
                                                        >
                                                            {project.getFormattedDisplayName()}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </>
                                    )}
                                </div>
                                {copyDataType === 'passage' &&
                                    hasSelectedProject &&
                                    renderPortions(selectedProjects[0])}
                                {copyDataType === 'portions' && hasSelectedProject && renderPortionsToCopy()}
                                {copyDataType !== 'plan' && shouldRenderOK() && (
                                    <CopyPassageOptions
                                        latestDraftOnly={latestDraftOnly}
                                        includeResources={includeResources}
                                        setLatestDraftOnly={setLatestDraftOnly}
                                        setIncludeResources={setIncludeResources}
                                    />
                                )}
                            </>
                        )}
                        {!hasProjects && permissionError(requiredTargetRole, t)}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="button-row">
                        {hasProjects && (
                            <OKButton
                                enabled={shouldRenderOK()}
                                onClick={() => {
                                    if (selectedProjects.length) {
                                        setShowCopyPage(true)
                                        copy()
                                    }
                                }}
                                buttonClassName=""
                                className="ok-button"
                                tooltip={t('Copy')}
                            />
                        )}
                        <CancelButton
                            enabled
                            onClick={() => setOpenModal(false)}
                            className="cancel-button"
                            tooltip={t('Cancel')}
                        />
                    </div>
                </Modal.Footer>
            </>
        )
    }

    return (
        <Modal show onHide={() => setOpenModal(false)} backdrop="static" className="project-plan-copy-modal">
            <Modal.Header closeButton>
                <Modal.Title>{header()}</Modal.Title>
            </Modal.Header>
            {warning && <Modal.Body>{warning}</Modal.Body>}
            {renderBodyAndFooter()}
        </Modal>
    )
}
