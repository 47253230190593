import { appendSilenceToEnd, getWavDataWithHeader, HIDDEN_SEGMENT_LENGTH_SECONDS } from './Wav'
import { MimeType } from '../../types'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('AudioProcessing:Opus')

export const fileExtension = (fileName: string) => fileName.split('.')?.pop()?.toLowerCase() ?? ''

// See https://blog.rillke.com/opusenc.js/ WAV, FLAC, OGG, AIFF
export const shouldEncodeOpus = (fileName: string) => ['aiff', 'flac', 'mp3', 'wav'].includes(fileExtension(fileName))

// See full list of args at https://opus-codec.org/docs/opus-tools/opusenc.html
export const encodeOpus = async (input: Blob | File): Promise<File> => {
    log(`Starting opus encoding`)

    const fileName = 'inputFile.wav'
    const outputFileName = 'encoded.opus'
    const outData = { [outputFileName]: { MIME: MimeType.OPUS } }
    const args = ['--downmix-mono', fileName, outputFileName]

    const arrayBuffer = await getWavDataWithHeader(input)
    const fileData = { [fileName]: new Uint8Array(arrayBuffer) }
    const encoderWorker = new Worker('/opusenc.js/EmsWorkerProxy.js')
    encoderWorker.postMessage({ command: 'encode', args, fileData, outData })

    return new Promise((resolve, reject) => {
        encoderWorker.onmessage = async ({ data }) => {
            if (data.reply === 'progress') {
                // future
                // const [completed, total] = data.values
            } else if (data.reply === 'done') {
                const opusBlob = data.values[outputFileName].blob as Blob
                encoderWorker.terminate()

                log(`Completed opus encoding`)

                resolve(new File([opusBlob], outputFileName, { type: opusBlob.type }))
            } else if (data.reply === 'err') {
                encoderWorker.terminate()

                reject(Error(`Error while executing encoderWorker: ${data.values[0]}`))
            }
        }
    })
}

export const appendSilenceToEndOfRecording = async (blob: Blob) => {
    const wavBlob = await appendSilenceToEnd(blob, HIDDEN_SEGMENT_LENGTH_SECONDS)
    return encodeOpus(wavBlob)
}
