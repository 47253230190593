export interface StepJson {
    type: string
    content: any
}

const tagMappings: { [key: string]: string[] } = {
    anchor: ['u'],
    blockquote: ['blockquote'],
    'bring-attention': ['strong'],
    callout: ['div class="callout"'],
    emphasis: ['em'],
    heading: ['h3'],
    idiomatic: ['em'],
    'list-item': ['li'],
    'ordered-list': ['ol'],
    paragraph: ['p'],
    strong: ['strong'],
    'unordered-list': ['ul']
}

export const jsonStepToHtml = (stepJson: StepJson | string): string => {
    if (typeof stepJson === 'string') {
        return stepJson
    }

    const { type, content } = stepJson
    const tagArray = tagMappings[type] || ['p']
    const contentArray = Array.isArray(content) ? content : [content]

    const htmlContent = tagArray.reduceRight(
        (acc, tag) => `<${tag}>${acc}</${tag.split(' ')[0]}>`,
        contentArray.map(jsonStepToHtml).join('')
    )

    return htmlContent
}

export const StepJsonRenderer = ({
    className,
    dir,
    jsonString
}: {
    className: string
    dir: string
    jsonString: string
}) => {
    if (!jsonString) {
        return null
    }

    const parsed: StepJson[] = JSON.parse(jsonString)
    const htmlLines = parsed.map(({ type, content }) => jsonStepToHtml({ type, content }))

    // eslint-disable-next-line react/no-danger
    return <div className={className} dir={dir} dangerouslySetInnerHTML={{ __html: htmlLines.join('') }} />
}
