import { useState } from 'react'

import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { Switch } from '../../utils/Switch'
import { ModalFooter } from '../Modals'
import '../Modals.css'

interface CopyPassageVersionModalProps {
    setOpen: (value: boolean) => void
    onOK: (options: { preserveSegmentation: boolean }) => void
}

export const CopyPassageVersionModal = ({ setOpen, onOK }: CopyPassageVersionModalProps) => {
    const [preserveSegmentation, setPreserveSegmentation] = useState(true)
    const { t } = useTranslation()

    return (
        <Modal show onHide={() => setOpen(false)} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t('recordingCreateConcatenatedDraft')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-toggle">
                    {t('preserveSegmentation')}
                    <Switch
                        value={preserveSegmentation}
                        setValue={setPreserveSegmentation}
                        tooltip={t('preserveSegmentation')}
                    />
                </div>
            </Modal.Body>
            <ModalFooter
                onOK={() => {
                    onOK({ preserveSegmentation })
                    setOpen(false)
                }}
                onCancel={() => setOpen(false)}
            />
        </Modal>
    )
}
