import { useState, useEffect } from 'react'

import { register } from '../serviceWorker'

// Customize build process like this?
// https://karannagupta.com/using-custom-workbox-service-workers-with-create-react-app/
// Eventual this: https://github.com/facebook/create-react-app/issues/5673

// NOTE: In order to use service worker currently you must
//     yarn build      -- build production version of code,
//                     -- autocreates loader based on template
//     serve -s build
export const useRegisterServiceWorker = () => {
    const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null)

    const updateServiceWorker = () => {
        if (!waitingWorker) {
            console.log('Not updating to a new version because waitingWorker is null')
            return
        }
        waitingWorker.postMessage({ type: 'SKIP_WAITING' })
        setWaitingWorker(null)
    }

    const onUpdateAvailable = (registration: ServiceWorkerRegistration) => {
        setWaitingWorker(registration.waiting)
    }

    useEffect(() => {
        register({ onUpdate: onUpdateAvailable })
    }, [])

    const isUpdateAvailable = waitingWorker !== null

    return { isUpdateAvailable, updateServiceWorker }
}
