import { FormGroup, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { ExportTextFormat } from '../../../types'

interface ExportFormatTextChooserProps {
    selected: string
    setSelected: (exportTextFormat: ExportTextFormat) => void
}

export const ExportTextFormatChooser = ({ selected, setSelected }: ExportFormatTextChooserProps) => {
    const { t } = useTranslation()
    return (
        <form>
            <FormGroup controlId="transcription-format-chooser">
                <Form.Label>{t('exportTextFormat')}</Form.Label>
                {Object.values(ExportTextFormat).map((exportTextFormat) => (
                    <Form.Check
                        type="radio"
                        id={exportTextFormat}
                        key={exportTextFormat}
                        onChange={() => {
                            setSelected(exportTextFormat)
                        }}
                        checked={selected === exportTextFormat}
                        label={t(exportTextFormat)}
                    />
                ))}
            </FormGroup>
        </form>
    )
}
