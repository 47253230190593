/* eslint-disable react/no-array-index-key */
import { Component } from 'react'

import { observer } from 'mobx-react'
import Draggable, { DraggableData } from 'react-draggable'

import { PassageSegment } from '../../models3/PassageSegment'
import { PassageSegmentLabel } from '../../models3/PassageSegmentLabel'

import './Segments.css'

// Label positions are measured from the top left corner of the label.
// That is why label.x <= 80, and label.y <= 89.
export const defaultLabels = [
    new PassageSegmentLabel(0, 0),
    new PassageSegmentLabel(80, 0),
    new PassageSegmentLabel(0, 89),
    new PassageSegmentLabel(80, 89)
]

interface ISegmentLabelsPosition {
    segmentLabelsDraft: PassageSegmentLabel[]
    setSegmentLabelsDraft: (labels: PassageSegmentLabel[]) => void
    editingSegmentLabels: boolean
    segment: PassageSegment
    videoWidth: number
}

class SegmentLabelsPosition extends Component<ISegmentLabelsPosition> {
    setLabelsPosition = (data: DraggableData, index: number) => {
        const { segment, segmentLabelsDraft, setSegmentLabelsDraft } = this.props

        const { parentElement } = data.node
        if (parentElement) {
            const originalLabelX = segment.labels[index]?.x ?? defaultLabels[index].x
            const originalLabelY = segment.labels[index]?.y ?? defaultLabels[index].y

            const x = (data.x / parentElement.offsetWidth) * 100 + originalLabelX
            const y = (data.y / parentElement.offsetHeight) * 100 + originalLabelY
            const updatedLabels = segmentLabelsDraft.map(
                (label) => new PassageSegmentLabel(label.x, label.y, label.xText, label.yText, label.text)
            )
            updatedLabels[index].x = x
            updatedLabels[index].y = y

            setSegmentLabelsDraft(updatedLabels)
        }
    }

    segmentLabelStyle = (label: PassageSegmentLabel) => {
        const { videoWidth } = this.props
        const { x, y } = label
        const style = {
            left: `${x}%`,
            top: `${y}%`,
            fontSize: `${Math.min(videoWidth / 35, 18)}px`
        }

        return style
    }

    render() {
        const { segment, segmentLabelsDraft, editingSegmentLabels } = this.props
        const { setLabelsPosition, segmentLabelStyle } = this

        const labels = editingSegmentLabels ? segmentLabelsDraft : segment.labels
        const savedLabels = segment.labels

        return labels.map((label, i) => {
            const { text } = label

            // react-draggable uses CSS transforms to adjust the position of
            // draggable elements. We do not want to change the position of
            // draggable elements ourselves, but let react-draggable apply a
            // transform to the original position. Because we cannot uniquely
            // identify labels, we must assume that they always maintain the
            // same ordering.
            const savedLabel = savedLabels[i] || defaultLabels[i]

            if (text) {
                return editingSegmentLabels ? (
                    <Draggable
                        key={i}
                        bounds="parent"
                        defaultClassName="draggable-label"
                        defaultClassNameDragging="draggable-label-dragging"
                        onStop={(e, data) => setLabelsPosition(data, i)}
                    >
                        <div className="video-label" style={segmentLabelStyle(savedLabel)}>
                            {text}
                        </div>
                    </Draggable>
                ) : (
                    <div key={i} className="video-label" style={segmentLabelStyle(savedLabel)}>
                        {text}
                    </div>
                )
            }

            return <div key={i} />
        })
    }
}

export default observer(SegmentLabelsPosition)
