/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-cycle */
import { observable } from 'mobx'

import { LexMeaning, MarbleLemmas } from './Lemmas'
import { BiblicalTermMarker } from '../models3/BiblicalTermMarker'
import { ProjectTerm } from '../models3/ProjectTerm'
import { TargetGloss } from '../models3/TargetGloss'

class ProjectTermCollection {
    @observable private verseToMeanings: Map<string, Set<LexMeaning>> = new Map()

    @observable private glossToLexicalLinks: Map<string, Set<string>> = new Map()

    @observable private lexicalLinkToProjectTerm: Map<string, ProjectTerm> = new Map()

    @observable private targetGlossToBiblicalTerms: Map<string, string[]> = new Map()

    addProjectTerm(term: ProjectTerm) {
        if (this.lexicalLinkToProjectTerm.has(term.lexicalLink)) {
            return
        }

        const lemma = MarbleLemmas.get(term.lexicalLink)
        if (lemma) {
            const lexMeaning = lemma.meanings.find((meaning) => meaning.lexicalLink === term.lexicalLink)
            if (lexMeaning) {
                this.addMeaning(lexMeaning)
            }
        }

        this.lexicalLinkToProjectTerm.set(term.lexicalLink, term)
    }

    addTargetGloss(gloss: TargetGloss) {
        if (!this.targetGlossToBiblicalTerms.has(gloss._id)) {
            this.targetGlossToBiblicalTerms.set(gloss._id, [])
        }
    }

    private addNewMarker(key: string, markerId: string) {
        const existingMarkerIds = this.targetGlossToBiblicalTerms.get(key)
        if (existingMarkerIds) {
            this.targetGlossToBiblicalTerms.set(key, [...existingMarkerIds, markerId])
        }
    }

    private removeMarker(key: string, markerId: string) {
        const existingMarkerIds = this.targetGlossToBiblicalTerms.get(key)
        if (existingMarkerIds) {
            this.targetGlossToBiblicalTerms.set(
                key,
                existingMarkerIds.filter((id) => id !== markerId)
            )
        }
    }

    private addExistingMarker(existingGlossId: string, marker: BiblicalTermMarker) {
        if (existingGlossId !== marker.targetGlossId) {
            // marker is pointing to a different gloss now
            this.removeMarker(existingGlossId, marker._id)
            this.addNewMarker(marker.targetGlossId, marker._id)
        }
    }

    private getGlossIdFromMarker(markerId: string) {
        const existingEntry = Array.from(this.targetGlossToBiblicalTerms.entries()).find(([, value]) =>
            value.includes(markerId)
        )
        return existingEntry ? existingEntry[0] : ''
    }

    addBiblicalTermMarker(marker: BiblicalTermMarker) {
        const glossId = this.getGlossIdFromMarker(marker._id)
        if (glossId) {
            this.addExistingMarker(glossId, marker)
        } else {
            this.addNewMarker(marker.targetGlossId, marker._id)
        }
    }

    removeBiblicalTermMarker(marker: BiblicalTermMarker) {
        const glossId = this.getGlossIdFromMarker(marker._id)
        if (glossId) {
            this.removeMarker(glossId, marker._id)
        }
    }

    private addMeaning(meaning: LexMeaning) {
        for (const reference of meaning.references) {
            const existingEntry = this.verseToMeanings.get(reference)
            const updatedEntry = new Set<LexMeaning>(existingEntry)
            updatedEntry.add(meaning)
            this.verseToMeanings.set(reference, updatedEntry)
        }
    }

    getProjectTermsByVerse(bbbcccvvv: string) {
        const meanings = this.verseToMeanings.get(bbbcccvvv)
        const terms = new Set<ProjectTerm>()
        if (meanings) {
            for (const meaning of meanings) {
                const term = this.lexicalLinkToProjectTerm.get(meaning.lexicalLink)
                if (term) {
                    terms.add(term)
                }
            }
        }
        return terms
    }

    getProjectTermsByGloss(gloss: string) {
        const lexicalLinks = this.glossToLexicalLinks.get(gloss)
        const terms = new Set<ProjectTerm>()
        if (lexicalLinks) {
            for (const link of lexicalLinks) {
                const term = this.lexicalLinkToProjectTerm.get(link)
                if (term) {
                    terms.add(term)
                }
            }
        }
        return terms
    }

    getProjectTermByLexicalLink(lexicalLink: string) {
        return this.lexicalLinkToProjectTerm.get(lexicalLink)
    }

    removeUnusedTargetGlosses() {
        const unusedTargetGlosses = []
        for (const [targetGloss, biblicalTerms] of this.targetGlossToBiblicalTerms) {
            if (!biblicalTerms.length) {
                this.targetGlossToBiblicalTerms.delete(targetGloss)
                unusedTargetGlosses.push(targetGloss)
            }
        }
        return unusedTargetGlosses
    }
}

export default ProjectTermCollection
