import React, { FunctionComponent, useState, useRef } from 'react'

import { useTranslation } from 'react-i18next'

import { useOnClickOutside } from '../utils/Hooks'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:NoteTextEditor')

interface INoteTextEditor {
    initialText: string
    rows?: number
    onSave: (text: string) => void
    onCancel: () => void
}

// Show text editor with save/cancel buttons.
// Autosave changes if user clicks elsewhere.

export const NoteTextEditor: FunctionComponent<INoteTextEditor> = ({ initialText, rows, onSave, onCancel }) => {
    const { t } = useTranslation()
    const [value, setValue] = useState(initialText)
    const ref = useRef()

    useOnClickOutside(ref, () => {
        log('useOnClickOutside')
        if (value !== initialText) {
            onSave(value)
        } else {
            onCancel()
        }
    })

    return (
        <div className="note-text-editor" ref={ref as any}>
            <div className="note-text-editor-editor">
                <textarea
                    name="story"
                    autoFocus
                    rows={rows || 10}
                    cols={rows === 1 ? 100 : 60}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                />
            </div>
            <div className="note-text-editor-buttons">
                <button
                    type="button"
                    className="btn btn-default btn-primary pull-right note-text-editor-ok styled-primary-button"
                    onClick={() => onSave(value)}
                >
                    {t('OK')}
                </button>
                <button
                    type="button"
                    className="btn btn-primary pull-right note-text-editor-cancel styled-primary-button"
                    onClick={onCancel}
                >
                    {t('Cancel')}
                </button>
            </div>
        </div>
    )
}
