import { useState, useEffect } from 'react'

import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import useDeleteProject from '../../../hooks/useDeleteProject'
import { Root } from '../../../models3/Root'
import MembersByRoleView from '../../projectSettings/members/MembersByRoleView'
import { CancelButton, OKButton } from '../../utils/Buttons'
import { ErrorList } from '../../utils/ErrorList'
import { LoadingIcon } from '../../utils/Icons'
import { Switch } from '../../utils/Switch'

interface ProjectDeleteResultProps {
    deletionInProgress: boolean
    closeModal: () => void
}

const ProjectDeleteResult = ({ deletionInProgress, closeModal }: ProjectDeleteResultProps) => {
    const { t } = useTranslation()

    if (deletionInProgress) {
        return (
            <Modal.Body>
                <div className="project-modal-loading-message-parent">
                    <LoadingIcon className="" />
                    <span className="project-modal-loading-message">{t('Deleting project...')}</span>
                </div>
            </Modal.Body>
        )
    }

    return (
        <>
            <Modal.Body>{t('Successfully deleted project.')}</Modal.Body>
            <Modal.Footer>
                <div className="button-row">
                    <OKButton
                        enabled
                        onClick={() => {
                            closeModal()
                        }}
                        buttonClassName=""
                        className="ok-button"
                        tooltip={t('OK')}
                    />
                </div>
            </Modal.Footer>
        </>
    )
}

interface ProjectDeleteModalProps {
    projectRoot: Root
    closeModal: () => void
}

export const ProjectDeleteModal = ({ projectRoot, closeModal }: ProjectDeleteModalProps) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const [showResultPage, setShowResultPage] = useState(false)
    const [isOk, setIsOk] = useState(false)
    const { deleteProject, error, deletionInProgress } = useDeleteProject()

    useEffect(() => {
        let mounted = true

        async function initializeRoots() {
            setLoading(true)

            await projectRoot.initialize()

            if (mounted) {
                setLoading(false)
                setIsOk(!projectRoot.project.videod())
            }
        }
        initializeRoots()

        return () => {
            mounted = false
        }
    }, [projectRoot])

    const showModalBodyAndFooter = () => {
        if (loading) {
            return (
                <Modal.Body>
                    <div className="project-modal-loading-message-parent">
                        <LoadingIcon className="" />
                        <span className="project-modal-loading-message">{t('Loading project...')}</span>
                    </div>
                </Modal.Body>
            )
        }

        if (showResultPage && !error) {
            return <ProjectDeleteResult closeModal={closeModal} deletionInProgress={deletionInProgress} />
        }

        return (
            <>
                <Modal.Body>
                    {error && <ErrorList errors={[{ text: error, key: '' }]} />}
                    <div className="project-modal-section">
                        <h5>{t('Members')}</h5>
                        <div>
                            <MembersByRoleView project={projectRoot.project} />
                        </div>
                        <h5>{t('Plan')}</h5>
                        <div>
                            <ol>
                                {projectRoot.project.plans.map((plan) =>
                                    plan.viewableStages.map((stage) => <li key={stage._id}>{stage.name}</li>)
                                )}
                            </ol>
                        </div>
                        <h5>{t('Portions')}</h5>
                        <div>
                            <ol>
                                {projectRoot.project.portions.map((portion) => (
                                    <li key={portion._id}>
                                        {portion.name} {portion.videod() && <i className="fas fa-fw fa-video" />}
                                    </li>
                                ))}
                            </ol>
                        </div>
                    </div>
                    <div className="project-modal-warning-message">
                        {projectRoot.project.videod() && (
                            <Switch
                                value={isOk}
                                setValue={(value) => setIsOk(value)}
                                tooltip=""
                                className="project-modal-switch"
                            >
                                <label>{t('Are you sure? This project has recordings!')}</label>
                            </Switch>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="button-row">
                        <OKButton
                            enabled={isOk}
                            onClick={() => {
                                setShowResultPage(true)
                                deleteProject(projectRoot)
                            }}
                            buttonClassName=""
                            className="ok-button"
                            tooltip={t('Delete Project')}
                        />
                        <CancelButton
                            enabled
                            onClick={() => closeModal()}
                            className="cancel-button"
                            tooltip={t('Cancel')}
                        />
                    </div>
                </Modal.Footer>
            </>
        )
    }

    return (
        <Modal show onHide={() => closeModal()} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{`${t('Delete Project')}: ${projectRoot.project.getFormattedDisplayName()}`}</Modal.Title>
            </Modal.Header>
            {showModalBodyAndFooter()}
        </Modal>
    )
}
