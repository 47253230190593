import { ReactNode } from 'react'

import { GenericIcon } from './Icons'

import './LabeledFieldWithIcon.css'

interface LabeledFieldWithIconProps {
    title: string
    iconName: string
    field: ReactNode
}

const LabeledFieldWithIcon = ({ title, iconName, field }: LabeledFieldWithIconProps) => {
    return (
        <div className="labeled-field-with-icon">
            <div className="labeled-field-with-icon-label">{title}</div>
            <GenericIcon iconName={iconName} className="labeled-field-with-icon-icon" tooltip={title} />
            {field}
        </div>
    )
}

export default LabeledFieldWithIcon
