// VideoToolbar allows controlling playing and recording by means of actions and variable of rt

import React, { FC, useEffect, useState } from 'react'

import { t } from 'i18next'
import { observer } from 'mobx-react'
import { Dropdown } from 'react-bootstrap'
import { confirmAlert } from 'react-confirm-alert'

import { createVerseReference } from './VerseReferenceEditor'
import { AVTTRecordingState } from './VideoRecorder'
import { AudioContextFactory } from './WaveformVisualizer'
import API from '../../models3/API'
import { MediaSlice } from '../../models3/MediaSlice'
import { Passage } from '../../models3/Passage'
import { PassageNote } from '../../models3/PassageNote'
import { MIN_SEGMENT_LENGTH } from '../../models3/PassageSegment'
import { PassageVideo } from '../../models3/PassageVideo'
import { Project } from '../../models3/Project'
import { Root } from '../../models3/Root'
import { ExportSourceType, PassageDocumentCopyType, RecordingType } from '../../types'
import { useOnlineStatus } from '../app/OnlineStatusContext'
import { BaseRecordingFilePicker, uploadRecording } from '../filePickers/BaseRecordingFilePicker'
import { ExportModal } from '../modals/export/ExportModal'
import { CopyPassageVersionModal } from '../modals/project/CopyPassageVersionModal'
import { CopyToPassageDocumentModal } from '../modals/project/CopyToPassageDocumentModal'
import { UnresolvedTextNotification } from '../notifications/Notifications'
import PassageVideoSelector from '../passages/PassageVideoSelector'
import {
    RecordButton,
    StopButton,
    CreateNoteButton,
    TrashButton,
    PlayButton,
    PauseButton,
    SlttHelp,
    CutButton,
    KeyTermButton,
    IconButton
} from '../utils/Buttons'
import { AudioEncodeType, getPassageAudio } from '../utils/DownloadPassage'
import { displayError, displayInfo, systemError } from '../utils/Errors'
import {
    safeFileName,
    downloadWithFilename,
    createLink,
    AudioSection,
    decrementTime,
    incrementTime,
    currentTimestampSafeString
} from '../utils/Helpers'
import {
    PassageVideoReferenceIcon,
    DownloadIcon,
    ClipboardIcon,
    CopyIcon,
    FolderIcon,
    EllipsisIcon,
    PassageSegmentsIcon,
    GenericIcon
} from '../utils/Icons'
import { encodeOpus } from '../utils/Opus'
import VideoCompressor from '../utils/VideoCompressor'
import { deleteAudioSection } from '../utils/Wav'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:VideoToolbar')

const getSlicedAudio = async (slice: MediaSlice, sectionsToKeepSeconds: AudioSection[]) => {
    const audioContext = AudioContextFactory.getAudioContext()
    const wavBlob = await deleteAudioSection(audioContext, slice.src, sectionsToKeepSeconds)
    return encodeOpus(wavBlob)
}

const getSlicedVideo = async (passage: Passage, project: Project, rt: Root, sectionsToKeepSeconds: AudioSection[]) => {
    if (!(await VideoCompressor.checkIfServerRunning())) {
        return
    }
    const { currentVideos } = rt
    const setProgressMessage = passage.setCompressionProgressMessage.bind(passage)
    const { maxVideoSizeMB, compressedVideoResolution, compressedVideoQuality } = project
    const compressor = new VideoCompressor(
        {
            crf: compressedVideoQuality,
            resolution: compressedVideoResolution,
            maxFileSizeMB: maxVideoSizeMB,
            rescaleVideo: false
        },
        setProgressMessage
    )
    return compressor.deleteSection(currentVideos, passage, sectionsToKeepSeconds)
}

const deleteRecordingSection = async (rt: Root) => {
    try {
        const { passage, name, passageVideo, passageSegment, timeline } = rt
        if (
            !timeline.selectionPresent() ||
            !passage ||
            !passageVideo ||
            !passageSegment ||
            !passageSegment.actualVideo(passage)
        ) {
            return
        }

        const actualSegment = passageSegment.actualSegment(passage)
        const slices = await actualSegment.getPlayableSlicesForViewablePartOfSegment(passage)
        if (!slices.length) {
            return
        }

        const { selectionStartTime, selectionEndTime } = timeline.getSelectionTimes()
        const slice = slices[0]

        let slicedFile
        if (passageVideo.isAudioOnly()) {
            const sectionsToKeep: AudioSection[] = [
                { start: slice.startPosition, end: actualSegment.timeToPosition(selectionStartTime) },
                { start: actualSegment.timeToPosition(selectionEndTime), end: slice.endPosition }
            ]
            slicedFile = await getSlicedAudio(slice, sectionsToKeep)
        } else {
            // make this a little smaller so we know it is in the current segment and not the next one
            const sectionsToKeep: AudioSection[] = [
                {
                    start: actualSegment.positionToTime(slice.startPosition),
                    end: decrementTime(selectionStartTime)
                },
                {
                    start: selectionEndTime,
                    end: decrementTime(actualSegment.positionToTime(slice.endPosition))
                }
            ]
            slicedFile = await getSlicedVideo(passage, rt.project, rt, sectionsToKeep)
        }
        if (!slicedFile) {
            return
        }
        const patch = await passage.uploadFile(slicedFile, name)
        await passage.addPatchVideo({
            baseRecording: passageVideo,
            patch,
            baseSegment: passageSegment,
            onTopSegment: passageSegment.actualSegment(passage)
        })
        rt.setPassageVideo(passageVideo)
        rt.setPassageSegment(passageSegment)
        rt.resetCurrentTime(incrementTime(passageSegment.actualSegment(passage).time))
    } catch (error) {
        systemError(error)
    }
}

const allowRecordingSectionDeletion = (rt: Root) => {
    if (!rt.timeline.selectionPresent()) {
        return false
    }

    const { passageVideo, passage, passageSegment, iAmTranslator } = rt
    const { reviewProject } = rt.project

    if (
        !(
            iAmTranslator &&
            passageVideo &&
            passage &&
            passageSegment &&
            passageVideo.getVisibleBaseSegments().length > 1
        )
    ) {
        return false
    }

    const isInReview = passageVideo.isInReview(reviewProject)
    if (isInReview) {
        return false
    }

    const { selectionStartTime, selectionEndTime } = rt.timeline.getSelectionTimes()
    const startIndex = passageVideo.timeToSegmentIndex(selectionStartTime)
    const endIndex = passageVideo.timeToSegmentIndex(selectionEndTime)
    if (startIndex === -1 || endIndex === -1 || startIndex !== endIndex) {
        return false
    }

    const actualSegment = passageSegment.actualSegment(passage)
    if (
        actualSegment.endPosition - actualSegment.position - (selectionEndTime - selectionStartTime) <
        MIN_SEGMENT_LENGTH
    ) {
        return false
    }

    return true
}

type RareFunctionsDropdownProps = {
    rt: Root
    enabled?: boolean
    openCopyPassageModal?: (passageToCopy: Passage) => void
}

async function downloadPassageVideo(rt: Root, passage: Passage, setProgressMessage: (message: string) => void) {
    const { currentVideos } = rt
    const { maxVideoSizeMB, compressedVideoResolution, compressedVideoQuality } = rt.project

    if (!(await VideoCompressor.checkIfServerRunning())) {
        setProgressMessage('')
        return
    }

    const compressor = new VideoCompressor(
        {
            crf: compressedVideoQuality,
            resolution: compressedVideoResolution,
            maxFileSizeMB: maxVideoSizeMB
        },
        setProgressMessage
    )
    return compressor.concatenateSelection(currentVideos, passage, -1, -1)
}

async function downloadPassageRecording(rt: Root, passage: Passage) {
    const setProgressMessage = passage.setCompressionProgressMessage.bind(passage)

    try {
        setProgressMessage(t('Initializing...'))
        const blob = await downloadPassageVideo(rt, passage, setProgressMessage)
        setProgressMessage('')
        return { blob }
    } catch (error) {
        setProgressMessage('')
        systemError(error)
        return { blob: undefined }
    }
}

async function createLinkToVideo(rt: Root) {
    const { passageVideo, currentTime, project } = rt
    if (!passageVideo) {
        return
    }
    const text = createLink({ projectName: project.name, itemId: passageVideo._id, time: currentTime })
    try {
        await navigator.clipboard.writeText(text)
        displayInfo(t('Link copied to clipboard. Only your team can view this link.'))
    } catch (error) {
        displayError(error, t('Failed to copy!'))
    }
}

const copyRecordingToNewDraft = async ({
    passage,
    passageVideo,
    rt,
    preserveSegmentation
}: {
    passage: Passage
    passageVideo: PassageVideo
    rt: Root
    preserveSegmentation: boolean
}) => {
    try {
        passage.setCompressionProgressMessage(t('Starting compression...'))
        const saveAsAudio = rt.currentVideos.viewableVideos.every((vv) => vv.video.isAudioOnly())
        const { blob: concatenatedBlob } = saveAsAudio
            ? await getPassageAudio(passage, passageVideo, AudioEncodeType.opus)
            : await downloadPassageRecording(rt, passage)

        const fileName = saveAsAudio ? 'concatenatedBlob.ogg' : 'concatenatedBlob.mp4'
        if (concatenatedBlob) {
            await uploadRecording({
                file: new File([concatenatedBlob], fileName, { type: concatenatedBlob.type }),
                rt,
                passage,
                videoToCopyFrom: passageVideo,
                t,
                options: { preserveSegmentation }
            })
        }
    } catch (err) {
        displayError(t('recordingUnsuccessfulCopy'))
    } finally {
        passage.setCompressionProgressMessage('')
    }
}

const copyAudioRecordingToPassageResource = async ({
    passage,
    passageVideo,
    rt,
    title
}: {
    passage: Passage
    passageVideo: PassageVideo
    rt: Root
    title: string
}) => {
    try {
        passage.setCompressionProgressMessage(t('Starting compression...'))
        const { blob: concatenatedBlob } = await getPassageAudio(passage, passageVideo, AudioEncodeType.opus)
        if (concatenatedBlob) {
            const document = passage.createDocument(title)
            await passage.addDocument(document)
            await document.uploadAudioFile(
                new File([concatenatedBlob], 'concatenatedBlob.ogg', { type: concatenatedBlob.type }),
                rt.name
            )
            displayInfo(t('passageResourceSuccessfulCreation', { title }))
        }
    } catch (err) {
        displayError(t('passageResourceUnsuccessfulCreation'))
    } finally {
        passage.setCompressionProgressMessage('')
    }
}

const copyBackTranslationToPassageResource = async ({
    passage,
    passageVideo,
    rt,
    title
}: {
    passage: Passage
    passageVideo: PassageVideo
    rt: Root
    title: string
}) => {
    try {
        const document = passage.createDocument(title)
        document.text = passageVideo.getBackTranslation({ passage, project: rt.project }).text
        await passage.addDocument(document)
        displayInfo(t('passageResourceSuccessfulCreation', { title }))
    } catch (err) {
        displayError(t('passageResourceUnsuccessfulCreation'))
    }
}

const copyToPassageResource = async ({
    passage,
    passageVideo,
    rt,
    copyType
}: {
    passage: Passage
    passageVideo: PassageVideo
    rt: Root
    copyType: PassageDocumentCopyType
}) => {
    const titlePrefix = copyType === 'passageRecording' ? t('passageRecording') : t('backTranslation')
    const title = `${titlePrefix} (${t('Version')} ${passage.getVersionIndex(passageVideo) + 1})`

    const copyFunction =
        copyType === 'passageRecording' ? copyAudioRecordingToPassageResource : copyBackTranslationToPassageResource
    copyFunction({ passage, passageVideo, rt, title })
}

const designateRecordingForReview = async (
    passage: Passage,
    passageVideo: PassageVideo,
    rt: Root,
    checkIsOnline: () => Promise<boolean>
) => {
    try {
        const isOnline = await checkIsOnline()

        if (!isOnline) {
            displayError(t('recordingStartANewReviewErrorOffline'))
            return
        }

        passage.setCompressionProgressMessage(t('Initializing...'))
        await rt.project.setUpProjectReview()

        const { name, project } = rt
        const { reviewProject } = project

        if (!reviewProject) {
            passage.setCompressionProgressMessage('')
            return
        }

        passage.setCompressionProgressMessage(t('Starting compression...'))
        const { blob: concatenatedBlob } = await getPassageAudio(passage, passageVideo, AudioEncodeType.mp3)
        if (!concatenatedBlob) {
            return
        }

        const blob =
            concatenatedBlob instanceof File
                ? new Blob([concatenatedBlob], { type: concatenatedBlob.type })
                : concatenatedBlob

        const reviewRecording = reviewProject.createReviewRecording(new Date(Date.now()))
        const fileName = `${reviewRecording._id.split('/')[1]}-${currentTimestampSafeString()}.mp3`
        await API.pushReviewRecording(name, fileName, blob)

        reviewRecording.title = passage.name
        reviewRecording.passageRecordingId = passageVideo._id
        reviewRecording.fileName = fileName
        reviewRecording.duration = passageVideo.computedDuration
        reviewRecording.mimeType = passageVideo.mimeType
        reviewRecording.reference = rt.displayableReferences(passage.references)
        reviewRecording.transcription = passageVideo.getTranscription({ passage, project }).text
        reviewRecording.isActive = true
        await reviewProject.addPassageRecording(reviewRecording)
    } catch (err) {
        systemError(err)
    } finally {
        passage.setCompressionProgressMessage('')
    }
}

const RareFunctionsDropdown: FC<RareFunctionsDropdownProps> = observer(({ rt, enabled }) => {
    const [blob, setBlob] = useState<Blob>()
    const [exportModalOpen, setExportModalOpen] = useState(false)
    const [copyModalOpen, setCopyModalOpen] = useState(false)
    const [copyToPassageDocumentModalOpen, setCopyToPassageDocumentModalOpen] = useState(false)
    const { isOnline, checkIsOnline } = useOnlineStatus()

    const { portion, passage, passageVideo, canPlayThrough, useMobileLayout, dateFormatter, iAmTranslator } = rt

    const { isEngageEnabled, reviewProject } = rt.project

    const compressingVideo = passage?.videoBeingCompressed || false
    const compressorAvailable = !useMobileLayout && canPlayThrough && !compressingVideo

    useEffect(() => {
        let blobHref = ''

        if (blob && passage) {
            blobHref = URL.createObjectURL(blob)
            const fileExtension = blob.type.startsWith('audio/')
                ? blob.type.endsWith('opus')
                    ? '.opus'
                    : '.wav'
                : '.mp4'
            let fileName = `${passage.name}-${dateFormatter.format(new Date(Date.now()))}`
            fileName = `${safeFileName(fileName)}${fileExtension}`
            downloadWithFilename(blobHref, fileName)
        }

        return () => {
            if (blobHref) {
                URL.revokeObjectURL(blobHref)
            }
        }
    }, [blob, dateFormatter, passage])

    const activeReviewsExist = Boolean(
        passage && reviewProject && passage.videosNotDeleted.some((pv) => pv.isInReview(reviewProject))
    )

    const isInReview = passageVideo?.isInReview(reviewProject)

    const allowReviews =
        isEngageEnabled && iAmTranslator && passage && passageVideo && passageVideo.isAudioOnly() && reviewProject

    const canStartReview = !activeReviewsExist && isOnline

    return (
        <Dropdown className="video-toolbar-hamburger-menu" id="video-toolbar-hamburger-menu" align="end">
            <Dropdown.Toggle
                className="styled-dropdown-select  video-toolbar-hamburger-menu-toggle"
                disabled={!enabled}
            >
                <EllipsisIcon className="main-video-see-more-icon sl-fa-button" tooltip={t('See more options')} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="styled-dropdown-select-menu">
                {exportModalOpen && portion && (
                    <ExportModal
                        rt={rt}
                        exportSourceType={ExportSourceType.PASSAGE_VIDEO}
                        portion={portion}
                        setOpen={setExportModalOpen}
                    />
                )}
                {copyModalOpen && (
                    <CopyPassageVersionModal
                        setOpen={setCopyModalOpen}
                        onOK={({ preserveSegmentation }) =>
                            passage &&
                            passageVideo &&
                            copyRecordingToNewDraft({ passage, passageVideo, rt, preserveSegmentation })
                        }
                    />
                )}
                {copyToPassageDocumentModalOpen && (
                    <CopyToPassageDocumentModal
                        setOpen={setCopyToPassageDocumentModalOpen}
                        enableCopyingRecording={rt.currentVideos.viewableVideos.every((vv) => vv.video.isAudioOnly())}
                        onOK={({ copyType }) => {
                            if (!passage || !passageVideo) {
                                return
                            }

                            copyToPassageResource({ passage, passageVideo, rt, copyType })
                        }}
                    />
                )}
                <Dropdown.Item
                    className="video-toolbar-hamburger-menu-item"
                    onClick={() => setExportModalOpen(true)}
                    disabled={!passageVideo || compressingVideo}
                >
                    <>
                        <DownloadIcon className="sl-download-full-video-icon fa-fw" tooltip="" />
                        {t('Export')}
                    </>
                </Dropdown.Item>
                {!rt.currentVideos.viewableVideos.some((vv) => vv.video.isAudioOnly()) && (
                    <Dropdown.Item
                        className="video-toolbar-hamburger-menu-item"
                        onClick={() =>
                            passage &&
                            downloadPassageRecording(rt, passage).then((recording) => setBlob(recording.blob))
                        }
                        disabled={!passage || !passageVideo || !compressorAvailable}
                    >
                        <>
                            <DownloadIcon className="sl-download-full-video-icon fa-fw" tooltip="" />
                            {t('Export video')}
                        </>
                    </Dropdown.Item>
                )}
                {!rt.useMobileLayout && iAmTranslator && (
                    <Dropdown.Item
                        className="video-toolbar-hamburger-menu-item"
                        onClick={() => passage && passageVideo && setCopyModalOpen(true)}
                        disabled={!passage || !passageVideo}
                    >
                        <>
                            <CopyIcon className="main-video-clipboard-icon fa-fw" tooltip="" />
                            {t('recordingCreateConcatenatedDraft')}
                        </>
                    </Dropdown.Item>
                )}
                {!rt.useMobileLayout && rt.iAmInterpreter && (
                    <Dropdown.Item
                        className="video-toolbar-hamburger-menu-item"
                        onClick={() => passage && passageVideo && setCopyToPassageDocumentModalOpen(true)}
                        disabled={!passage || !passageVideo}
                    >
                        <>
                            <FolderIcon className="main-video-clipboard-icon fa-fw" tooltip="" />
                            {t('recordingCopyDraftToPassageResource')}
                        </>
                    </Dropdown.Item>
                )}
                <Dropdown.Item
                    className="video-toolbar-hamburger-menu-item"
                    onClick={() => createLinkToVideo(rt)}
                    disabled={!passageVideo}
                >
                    <>
                        <ClipboardIcon className="main-video-clipboard-icon fa-fw" tooltip="" />
                        {t('recordingCopyLinkForRecording')}
                    </>
                </Dropdown.Item>
                {allowReviews && isInReview && (
                    <Dropdown.Item
                        className="video-toolbar-hamburger-menu-item"
                        onClick={() => {
                            confirmAlert({
                                title: t('recordingStopReview'),
                                message: t('recordingStopReviewConfirm'),
                                buttons: [
                                    {
                                        label: t('Cancel'),
                                        onClick: () => {}
                                    },
                                    {
                                        label: t('recordingStopReview'),
                                        onClick: () => {
                                            passageVideo.getLatestReviewRecording(reviewProject)?.setIsActive(false)
                                        }
                                    }
                                ]
                            })
                        }}
                    >
                        <>
                            <GenericIcon
                                iconName="fa-comments"
                                className="main-video-clipboard-icon default-blue-icon fa-fw"
                                tooltip={t('recordingStopReview')}
                            />
                            {t('recordingStopReview')}
                        </>
                    </Dropdown.Item>
                )}
                {allowReviews && !isInReview && (
                    <Dropdown.Item
                        className="video-toolbar-hamburger-menu-item"
                        onClick={() => {
                            confirmAlert({
                                title: t('recordingStartANewReview'),
                                message: t('recordingStartANewReviewConfirm'),
                                buttons: [
                                    {
                                        label: t('Cancel'),
                                        onClick: () => {}
                                    },
                                    {
                                        label: t('recordingStartANewReview'),
                                        onClick: () =>
                                            designateRecordingForReview(passage, passageVideo, rt, checkIsOnline)
                                    }
                                ]
                            })
                        }}
                        disabled={!canStartReview}
                    >
                        <>
                            <GenericIcon
                                iconName="fa-comments"
                                className="main-video-clipboard-icon default-blue-icon fa-fw"
                                tooltip={t('recordingStartANewReview')}
                                enabled={canStartReview}
                            />
                            {t('recordingStartANewReview')}
                        </>
                    </Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    )
})

interface IVideoToolbar {
    rt: Root
    playAllVideos: () => void
    playCurrentVideo: () => void
    pausePlayback: () => void
    pauseRecording: () => void
    record: (recordingType: RecordingType) => void
    stopRecording: () => void
    recordingState: AVTTRecordingState
    resumeRecording: () => void
    openBiblicalTermMarkerEditor: () => void
}

class VideoToolbar extends React.Component<IVideoToolbar> {
    onSelectVideo = async (video: PassageVideo) => {
        if (video.removed) {
            await this.undeleteVideo(video)
        } else {
            await this.makeSelection(video)
        }
    }

    goToNote = async (note: PassageNote) => {
        const { rt } = this.props
        const { passage } = rt
        if (!passage) {
            return
        }
        let video = passage.findVideo(note._id) || null
        video = video?.baseVideo(passage) || video
        await rt.setPassage(passage)
        await rt.setPassageVideo(video)
        rt.setNote(note)
    }

    createPassageSegment = () => {
        const _createPassageSegment = async () => {
            const { rt } = this.props
            const { passage, passageVideo, passageSegment } = rt
            if (!passage || !passageVideo || !passageSegment || passageSegment.isPatched) return
            const position = passageVideo.timeToPosition(passage, rt.currentTime)
            if (position !== undefined) {
                await passageVideo.addSegment({ position })
                rt.resetCurrentTime(rt.currentTime) // will force current segment to be set
            } else {
                throw Error(`${t('System Error')}: PassageVideo, Passage, or PassageSegment should not be null`)
            }
        }

        _createPassageSegment().catch(displayError)
    }

    deleteVideo = async () => {
        const { rt } = this.props
        const { passage, passageVideo } = rt
        const { reviewProject } = rt.project
        if (!passage || !passageVideo) {
            displayError(`${t('System Error')}: Status change failed`)
            return
        }

        this.confirmDeletion(() => {
            if (!passageVideo) {
                displayError(`${t('System Error')}: Status change failed`)
                return
            }

            passage
                .removeVideo(passageVideo._id)
                .then(() => {
                    if (reviewProject) {
                        passageVideo.getLatestReviewRecording(reviewProject)?.setIsActive(false)
                    }
                    const i = passage.videosNotDeleted.length
                    rt.setPassageVideo(i > 0 ? passage.videosNotDeleted[i - 1] : null)
                })
                .catch(displayError)
        })
    }

    confirmDeletion(doDeletion: () => void) {
        confirmAlert({
            title: t('recordingDeleteQuestion'),
            message: t('recordingDeleteWarning'),
            buttons: [
                {
                    label: t('recordingKeep'),
                    onClick: () => {}
                },
                {
                    label: t('recordingDelete'),
                    onClick: doDeletion
                }
            ]
        })
    }

    async undeleteVideo(passageVideo: PassageVideo) {
        const { rt } = this.props
        const { passage, iAmAdmin } = rt
        if (!passage || !iAmAdmin) return

        try {
            await passage.undeleteVideo(passageVideo)
            await this.makeSelection(passageVideo)
        } catch (error) {
            displayError(error)
        }
    }

    async makeSelection(passageVideo: PassageVideo) {
        const { rt } = this.props
        const { passage } = rt
        if (!passage) return

        try {
            await rt.setPassageVideo(passageVideo)
        } catch (error) {
            displayError(error)
        }
    }

    render() {
        const {
            rt,
            playAllVideos,
            playCurrentVideo,
            record,
            stopRecording,
            recordingState,
            resumeRecording,
            pausePlayback,
            pauseRecording,
            openBiblicalTermMarkerEditor
        } = this.props
        const {
            passage,
            passageVideo,
            passageSegment,
            playing,
            recording,
            hardNotificationCutoff,
            iAmInterpreter,
            iAmTranslator,
            iAmConsultant,
            canViewConsultantOnlyFeatures,
            useMobileLayout,
            canPlayThrough,
            project
        } = rt
        const { plans, reviewProject } = project

        const selectionPresent = rt.timeline.selectionPresent()

        const compressingVideo = passage?.videoBeingCompressed || false
        const enabled = !compressingVideo

        const stopEnabled = enabled && (recordingState === 'RECORDING' || recordingState === 'PAUSED')
        const isNotPlayingNorRecording = enabled && !recording && !playing

        const playEnabled = canPlayThrough && !recording && enabled && !!passageVideo

        const recordEnabled =
            (passage && isNotPlayingNorRecording && iAmTranslator) || ['INITIALIZED', 'PAUSED'].includes(recordingState)
        const createNoteEnabled = !!passageVideo && isNotPlayingNorRecording && iAmInterpreter

        const createSegmentEnabled =
            isNotPlayingNorRecording &&
            !!passageVideo &&
            !!passageSegment &&
            !passageSegment.isPatched &&
            iAmInterpreter
        const verseReferenceEnabled = Boolean(passageVideo) && isNotPlayingNorRecording && iAmConsultant
        const cutButtonEnabled = isNotPlayingNorRecording && allowRecordingSectionDeletion(rt)
        const createBiblicalTermMarkerEnabled = Boolean(passageVideo) && isNotPlayingNorRecording && iAmTranslator

        const dropdownVideos = passage
            ? (rt.iAmAdmin ? passage.videos : passage.videosNotDeleted).filter((v) => !v.isPatch)
            : []

        const cutoff = hardNotificationCutoff()
        const activeVideos = dropdownVideos.filter((v) => !v.removed)
        let unresolvedNote: PassageNote | null = null
        if (passage && activeVideos.length) {
            unresolvedNote = activeVideos[activeVideos.length - 1].mostRecentUnresolvedNote(
                passage,
                cutoff,
                canViewConsultantOnlyFeatures
            )
        }

        const displayDeleteVideo = !!passageVideo
        const unresolvedTextNotificationDisplayed = !!passage && unresolvedNote && !!passageVideo
        const unresolvedTextNotificationEnabled = unresolvedTextNotificationDisplayed && enabled
        const displayPassageVideoSelector = !!passage && dropdownVideos.length > 0
        const changeMainVideoEnabled = displayPassageVideoSelector && enabled && isNotPlayingNorRecording

        // If I try to move the following to a .css file it stops working.
        // I have no idea why.
        const passageSegmentIconStyle = {
            verticalAlign: 'middle'
        }

        return (
            <div className="video-toolbar">
                <div className="video-toolbar-left">
                    {playing && (
                        <>
                            <PauseButton
                                className="main-video-pause-button"
                                enabled={enabled}
                                tooltip={t('Pause.')}
                                onClick={() => pausePlayback()}
                            />
                            <SlttHelp id="passages-record" tooltip={t('recordPassage')} place="bottom">
                                <RecordButton
                                    tooltip=""
                                    selectionPresent={false}
                                    enabled={false}
                                    className="sl-record-button sl-record-video-button"
                                    onClick={() => {}}
                                />
                            </SlttHelp>
                        </>
                    )}
                    {!playing && !recording && (
                        <>
                            <PlayButton
                                enabled={playEnabled}
                                selectionPresent={selectionPresent}
                                tooltip={selectionPresent ? t('Play selection.') : t('Play.')}
                                className="main-video-play-button"
                                onClick={async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                                    if (e.shiftKey) {
                                        playAllVideos()
                                    } else {
                                        playCurrentVideo()
                                    }
                                }}
                            />
                            <SlttHelp id="passages-record" tooltip={t('recordPassage')} place="bottom">
                                <RecordButton
                                    tooltip=""
                                    selectionPresent={false}
                                    enabled={recordEnabled}
                                    className="sl-record-button sl-record-video-button"
                                    onClick={() => {
                                        record(RecordingType.BASE)
                                    }}
                                />
                            </SlttHelp>
                        </>
                    )}
                    {recording && recordingState === 'RECORDING' && (
                        <PauseButton
                            className="main-video-pause-button"
                            enabled={enabled}
                            tooltip={t('Pause.')}
                            onClick={() => pauseRecording()}
                        />
                    )}
                    {recording && recordingState !== 'RECORDING' && (
                        <RecordButton
                            tooltip={t('Continue recording')}
                            selectionPresent={false}
                            enabled={recordEnabled}
                            className="sl-record-button sl-record-video-button"
                            onClick={() => resumeRecording()}
                        />
                    )}
                    {recording && (
                        <StopButton
                            className="main-video-stop-button"
                            enabled={stopEnabled}
                            tooltip={t('Stop and save recording.')}
                            onClick={stopRecording}
                        />
                    )}

                    {passage && (
                        <BaseRecordingFilePicker
                            enabled={recordEnabled && !recording}
                            rt={rt}
                            passage={passage}
                            className="main-upload-file-button"
                        />
                    )}

                    {!useMobileLayout && (
                        <>
                            <SlttHelp id="segments-create" tooltip={t('recordingCreateNewSegment')} place="bottom">
                                <IconButton
                                    enabled={iAmInterpreter}
                                    onClick={this.createPassageSegment}
                                    contents={
                                        <PassageSegmentsIcon
                                            className="sl-create-passage-segment"
                                            style={passageSegmentIconStyle}
                                            enabled={createSegmentEnabled}
                                        />
                                    }
                                />
                            </SlttHelp>
                            <CreateNoteButton
                                enabled={createNoteEnabled}
                                onClick={() => {
                                    if (passage && passageVideo) {
                                        const notePosition = passageVideo.timeToPosition(passage, rt.currentTime)
                                        const video = passageVideo.timeToVideo(passage, rt.currentTime)
                                        log('createNote', rt.currentTime, notePosition, video._id)
                                        const note = video.createNote.bind(video)(notePosition)
                                        note.time = rt.currentTime
                                        rt.setNote(note)
                                    }
                                }}
                            />
                            <SlttHelp id="biblical-terms-insert" tooltip={t('createBiblicalTermMarker')} place="bottom">
                                <KeyTermButton
                                    className="video-toolbar-button key-term-marker-button"
                                    onClick={openBiblicalTermMarkerEditor}
                                    enabled={createBiblicalTermMarkerEnabled}
                                />
                            </SlttHelp>
                            <PassageVideoReferenceIcon
                                className="sl-create-passage-segment"
                                style={passageSegmentIconStyle}
                                onClick={() => createVerseReference(rt)}
                                tooltip={t('Create new verse reference')}
                                enabled={verseReferenceEnabled}
                            />
                            <CutButton
                                onClick={() => deleteRecordingSection(rt)}
                                className="video-toolbar-button scissors-icon"
                                tooltip={t('Delete section')}
                                enabled={cutButtonEnabled}
                            />
                        </>
                    )}
                </div>

                <div className="video-toolbar-right">
                    {displayDeleteVideo && !useMobileLayout && (
                        <TrashButton
                            enabled={enabled && iAmTranslator && isNotPlayingNorRecording}
                            buttonClassName=""
                            className="delete-video-button"
                            onClick={this.deleteVideo}
                            tooltip={t('recordingDelete')}
                        />
                    )}
                    {displayPassageVideoSelector && (
                        <PassageVideoSelector
                            enabled={changeMainVideoEnabled}
                            videos={dropdownVideos}
                            currentVisibleVideo={passageVideo || dropdownVideos[dropdownVideos.length - 1]}
                            onSelect={this.onSelectVideo}
                            passageVideoNotification={rt}
                            plan={plans.length ? plans[0] : undefined}
                            reviewProject={reviewProject}
                        />
                    )}
                    {unresolvedTextNotificationDisplayed && !useMobileLayout && (
                        <UnresolvedTextNotification
                            className="video-toolbar-notification sl-fa-button"
                            tooltip={t('Unresolved notes exist')}
                            onClick={async () => {
                                if (unresolvedTextNotificationEnabled && unresolvedNote) {
                                    this.goToNote(unresolvedNote)
                                }
                            }}
                        />
                    )}
                    <RareFunctionsDropdown {...{ rt, enabled: isNotPlayingNorRecording }} />
                </div>
            </div>
        )
    }
}

export default observer(VideoToolbar)
