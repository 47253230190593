import { IDB, IDBObjectWithKey } from './IDB'

// Stub DB which throws if accessed
export class StubDB implements IDB {
    initialize = (): Promise<number> => {
        throw Error('not implemented')
    }

    put = (): Promise<void> => {
        throw Error('not implemented')
    }

    get = (): Promise<IDBObjectWithKey[]> => {
        throw new Error('Method not implemented.')
    }

    doSync = () => {}

    delete = (): Promise<void> => {
        throw Error('not implemented')
    }

    deleteDB = async () => {}

    getRemoteSeq = () => {
        return 0
    }

    getNewId = (): string => {
        throw Error('not implemented')
    }

    username = ''

    getDate = (): string => '0001-01-01 01:01:01.001Z'

    slice = (): any[] => {
        throw Error('not implemented')
    }

    reset = (): void => {
        throw Error('not implemented')
    }

    submitChange = () => {
        throw Error('not implemented')
    }

    unsyncedChangesExist = () => {
        return Promise.resolve(false)
    }
}
