/* eslint-disable react/prefer-stateless-function */
import React, { Component, FC } from 'react'

import { t } from 'i18next'
import { observer } from 'mobx-react'
import { confirmAlert } from 'react-confirm-alert'
import { Link } from 'react-router-dom'

import { newerThanCutoffDate } from '../../models3/DateUtilities'
import { Passage } from '../../models3/Passage'
import { PassageNote } from '../../models3/PassageNote'
import { PassageVideo } from '../../models3/PassageVideo'
import { Portion } from '../../models3/Portion'
import { Root } from '../../models3/Root'
import { ClearNotificationsButton } from '../utils/Buttons'

import './Notifications.css'
import '../utils/Buttons.css'

interface NotificationProps {
    className: string
    onClick?: (event: React.MouseEvent) => Promise<void> | undefined
    tooltip: string
}

export class VideoNotification extends Component<NotificationProps> {
    render() {
        const { className, tooltip, onClick } = this.props
        return (
            <span
                className={className}
                data-id="video-notification"
                data-toggle="tooltip"
                title={tooltip}
                onClick={(e) => onClick && onClick(e)}
            >
                <i className="fas fa-asterisk" />
            </span>
        )
    }
}

export class TextNotification extends Component<NotificationProps> {
    render() {
        const { className, tooltip, onClick } = this.props
        return (
            <span
                className={className}
                data-id="note-notification"
                data-toggle="tooltip"
                title={tooltip}
                onClick={(e) => onClick && onClick(e)}
            >
                <i className="fas fa-fw fa-comment note-notification" />
            </span>
        )
    }
}

export class UnresolvedTextNotification extends Component<NotificationProps> {
    render() {
        const { className, tooltip, onClick } = this.props
        return (
            <span
                className={className}
                data-id="unresolved-note-notification"
                data-toggle="tooltip"
                title={tooltip}
                onClick={(e) => onClick && onClick(e)}
            >
                <i className="fas fa-fw fa-comment unresolved-note" />
            </span>
        )
    }
}

interface IPassageNotificationList {
    rt: Root
    passage: Passage
}

export const PassageNotificationList: FC<IPassageNotificationList> = observer(({ rt, passage }) => {
    const showUnviewedVideo = async (path: string, video: PassageVideo) => {
        rt.selectPage(path)
        await rt.setPassage(passage)
        await rt.setPassageVideo(video)
    }

    const showUnviewedNote = async (path: string, note: PassageNote) => {
        let video = passage.findVideo(note._id) || null
        video = video?.baseVideo(passage) || video
        rt.selectPage(path)
        await rt.setPassage(passage)
        await rt.setPassageVideo(video)
        rt.setNote(note)
    }

    const { hardNotificationCutoff, canViewConsultantOnlyFeatures, username } = rt

    const cutoff = hardNotificationCutoff()

    const unviewedVideo = passage.firstUnviewedVideo(username, cutoff)
    const unviewedNote = passage.firstUnviewedNote(username, cutoff, canViewConsultantOnlyFeatures)
    const unresolvedNote = passage.getUnresolvedNote(cutoff, canViewConsultantOnlyFeatures)

    const mostRecentVideo = passage.videosNotDeleted[passage.videosNotDeleted.length - 1]

    const showUnviewedNoteIcon = !!unviewedNote
    const showUnresolvedNoteIcon = !!unresolvedNote && !showUnviewedNoteIcon

    return (
        <div>
            {unviewedVideo && (
                <Link to="/index.html">
                    <VideoNotification
                        className="portion-button"
                        onClick={() => showUnviewedVideo('/', mostRecentVideo)}
                        tooltip={t('recordingUnwatched')}
                    />
                </Link>
            )}
            {unresolvedNote && showUnresolvedNoteIcon && (
                <Link to="/index.html">
                    <UnresolvedTextNotification
                        className="portion-button"
                        onClick={() => showUnviewedNote('/', unresolvedNote)}
                        tooltip={t('There are unresolved notes.')}
                    />
                </Link>
            )}
            {unviewedNote && showUnviewedNoteIcon && (
                <Link to="/index.html">
                    <TextNotification
                        className="portion-button"
                        onClick={() => showUnviewedNote('/', unviewedNote)}
                        tooltip={t('There are unviewed notes.')}
                    />
                </Link>
            )}
        </div>
    )
})

interface IPortionNotificationList {
    rt: Root
    portion: Portion
}

export const PortionNotificationList: FC<IPortionNotificationList> = observer(({ rt, portion }) => {
    const goToPortion = async (path: string) => {
        rt.selectPage(path)
        await rt.setPassage(portion.passages[0])
    }

    const { hardNotificationCutoff, username, canViewConsultantOnlyFeatures } = rt

    const cutoff = hardNotificationCutoff()

    const isUnviewedVideo = portion.unviewedVideoExists(username, cutoff)
    const isUnviewedNote = portion.unviewedNoteExists(username, cutoff, canViewConsultantOnlyFeatures)
    const isUnresolvedNote = portion.unresolvedNoteExists(cutoff, canViewConsultantOnlyFeatures)

    const showUnviewedNoteIcon = isUnviewedNote
    const showUnresolvedNoteIcon = isUnresolvedNote && !showUnviewedNoteIcon

    return (
        <div>
            {isUnviewedVideo && (
                <Link to="/index.html">
                    <VideoNotification
                        className="portion-button"
                        onClick={() => goToPortion('/')}
                        tooltip={t('recordingUnwatched')}
                    />
                </Link>
            )}
            {isUnresolvedNote && showUnresolvedNoteIcon && (
                <Link to="/index.html">
                    <UnresolvedTextNotification
                        className="portion-button"
                        onClick={() => goToPortion('/')}
                        tooltip={t('There are unresolved notes.')}
                    />
                </Link>
            )}
            {isUnviewedNote && showUnviewedNoteIcon && (
                <Link to="/index.html">
                    <TextNotification
                        className="portion-button"
                        onClick={() => goToPortion('/')}
                        tooltip={t('There are unviewed notes.')}
                    />
                </Link>
            )}
        </div>
    )
})

interface IClearNotifications {
    rt: Root
    passages: Passage[]
}

export const ClearNotifications = ({ rt, passages }: IClearNotifications) => {
    const doDeletion = async () => {
        const { canViewConsultantOnlyFeatures, hardNotificationCutoff } = rt

        const cutoff = hardNotificationCutoff()

        for (const passage of passages) {
            const activeVideos = passage.videosNotDeleted
            const mostRecentVideo = activeVideos[activeVideos.length - 1]
            if (mostRecentVideo && newerThanCutoffDate(mostRecentVideo.creationDate, cutoff)) {
                await mostRecentVideo.addViewedBy(rt.username)
            }

            for (const video of activeVideos) {
                const visibleNotes = video.getVisibleNotes(passage, canViewConsultantOnlyFeatures)
                const noteItems = visibleNotes.flatMap((note) => note.items)
                for (const item of noteItems) {
                    if (newerThanCutoffDate(item.creationDate, cutoff)) {
                        await item.addViewedBy(rt.username)
                    }
                }
            }
        }
    }

    const clearNotifications = () => {
        confirmAlert({
            title: t('Clear Notifications?'),
            message: t(
                'You are about to clear notifications for the displayed portions and/or passages. Are you sure you want to do this?'
            ),
            buttons: [
                {
                    label: t('Cancel'),
                    onClick: () => {}
                },
                {
                    label: t('Clear notifications'),
                    onClick: () => doDeletion()
                }
            ]
        })
    }

    return (
        <ClearNotificationsButton
            className="clear-notifications-button"
            onClick={clearNotifications}
            tooltip={t('Clear notifications for displayed portions and/or passages')}
        />
    )
}
