import { observable } from 'mobx'
import { delay } from 'q'

import { Passage } from '../../models3/Passage'
import { PassageVideo } from '../../models3/PassageVideo'
import { VideoCache } from '../../models3/VideoCache'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:ViewableVideoCollection')

export enum DownloadStatus {
    Cached,
    NotCached,
    NotCached_Error,
    WaitingForResponse
}

export interface ViewableVideo {
    video: PassageVideo
    status: DownloadStatus
    src: string
}

export class ViewableVideoCollection {
    @observable public viewableVideos: ViewableVideo[] = []

    public creator = ''

    @observable public downloadingMessage = '...'

    @observable public downloaded = false

    messages: string[] = []

    protected setupStarted = false

    constructor() {
        this.downloadViewableVideo = this.downloadViewableVideo.bind(this)
        this.downloadVideo = this.downloadVideo.bind(this)
        this.download = this.download.bind(this)
    }

    setup(passage: Passage | null, passageVideo: PassageVideo | null) {
        log('setup', passageVideo && passageVideo._id)

        if (this.setupStarted) {
            return
        }

        this.setupStarted = true
        this.downloadingMessage = '...'
        this.viewableVideos = []
        if (!passage || !passageVideo) {
            log('!!!setup aborted')
            return
        }

        this.creator = passageVideo.creator

        const patchVideos = passageVideo
            .getAllBaseSegments()
            .map((segment) => segment.patchVideo(passage))
            .filter((video) => video)

        this.viewableVideos = [passageVideo, ...patchVideos].map((video) => {
            return { video, src: '', status: DownloadStatus.WaitingForResponse } as ViewableVideo
        })

        log('setup count', this.viewableVideos.length)
    }

    reset() {
        this.viewableVideos = []
        this.creator = ''
        this.downloadingMessage = '...'
        this.downloaded = false
        this.messages = []
        this.setupStarted = false
    }

    // Download main video and all patches
    // When download complete downloadingMessage will be ''
    async download() {
        if (this.downloaded) {
            return
        }

        log('download')
        this.downloaded = false

        this.messages = Array(this.viewableVideos.length).fill('downloading')

        const promises = this.viewableVideos.map((vv: ViewableVideo, i: number) => this.downloadViewableVideo(vv, i))
        Promise.all(promises)
    }

    async waitUntilDownloaded() {
        while (true) {
            if (this.downloaded) return
            await delay(500)
        }
    }

    private async getDownloadStatus() {
        const checkStatus = () => {
            if (this.viewableVideos.some((vv) => vv.status === DownloadStatus.WaitingForResponse)) {
                return DownloadStatus.WaitingForResponse
            }

            if (this.viewableVideos.some((vv) => vv.status === DownloadStatus.NotCached)) {
                return DownloadStatus.NotCached
            }

            return this.viewableVideos.some((vv) => vv.status === DownloadStatus.NotCached_Error)
                ? DownloadStatus.NotCached_Error
                : DownloadStatus.Cached
        }

        let status = checkStatus()
        while (status === DownloadStatus.WaitingForResponse) {
            await delay(500)
            status = checkStatus()
        }
        return status
    }

    async getViewableVideos() {
        let status = await this.getDownloadStatus()
        while (status === DownloadStatus.NotCached) {
            await delay(500)
            status = await this.getDownloadStatus()
        }

        return this.viewableVideos.filter((vv) => vv.status === DownloadStatus.Cached)
    }

    // Download one video.
    downloadViewableVideo = async (vv: ViewableVideo, i: number) => {
        log(`downloadViewableVideo ${vv.video._id}`)

        // Streaming is not ready for general use
        const useStreaming = false
        if (useStreaming) {
            // Use Media source extensions. You need an initialization segment and media
            // segments to feed to a source buffer. Ffmpeg can transcode an mp4 file so
            // that the initialization segment is at the beginning of the video, and the
            // rest of the video is stored as individually decodable fragments
        } else {
            this.downloadVideo(vv, i)
        }
    }

    /** Download all the blobs of a video. Along the way, update the download
     * status of the video. When the video is downloaded completely, create a URL
     * for it. */
    async downloadVideo(vv: ViewableVideo, i: number) {
        const timeout = (ms: number) =>
            new Promise((res) => {
                setTimeout(res, ms)
            })
        const { _id } = vv.video
        while (true) {
            // break if the list of videos to be downloaded has changed
            const { viewableVideos } = this
            if (i >= viewableVideos.length) break
            vv = viewableVideos[i]
            if (vv.video._id !== _id) break

            if (vv.video.url) {
                const response = await VideoCache.queryVideoDownload(vv.video.url)
                if (response.blob) {
                    // got blob from cache, save it and break
                    log(`downloadViewableVideo success ${vv.video._id}`)
                    vv.src = window.URL.createObjectURL(response.blob)
                    vv.status = DownloadStatus.Cached
                    this.messages[i] = ''
                    this.setDownloadingMessage(i, '')

                    if (this.allSourcesPresent) {
                        log(
                            'download complete',
                            this.viewableVideos.map((video) => video.src)
                        )
                        this.downloaded = true
                    }

                    break
                }
                this.setDownloadingMessage(i, response.message)

                if (response.isError || !navigator.onLine) {
                    vv.status = DownloadStatus.NotCached_Error
                    break
                }

                vv.status = DownloadStatus.NotCached
            } else {
                log('waiting for url')
            }

            await timeout(1000)
        }
    }

    get allSourcesPresent() {
        return this.viewableVideos.every((vv) => vv.src !== '')
    }

    setDownloadingMessage(i: number, message: string) {
        this.messages[i] = message
        const messages = this.messages.filter((m) => m)

        if (messages.length === 0) {
            this.downloadingMessage = ''
        } else if (messages.length === 1) {
            this.downloadingMessage = messages[0]
        } else {
            this.downloadingMessage = `${messages[0]} (+${messages.length - 1})`
        }
    }

    async getBlob(video: PassageVideo) {
        const src = this.viewableVideos.find((vv) => vv.video._id === video._id)?.src
        if (src === undefined) {
            throw new Error('Video not downloaded from VideoCache yet')
        }

        const response = await fetch(src)
        const blob = await response.blob()
        return blob
    }

    dbg() {
        return this.viewableVideos.map((vv) => {
            return { src: vv.src, video: vv.video._id }
        })
    }
}

export class SingleVideoViewableVideoCollection extends ViewableVideoCollection {
    setup(passage: Passage | null, passageVideo: PassageVideo | null) {
        log('setup', passageVideo && passageVideo._id)

        if (this.setupStarted) {
            return
        }

        this.setupStarted = true
        this.downloadingMessage = '...'
        this.viewableVideos = []
        if (!passage || !passageVideo) {
            log('!!!setup aborted')
            return
        }

        this.creator = passageVideo.creator

        this.viewableVideos = [passageVideo].map((video) => {
            return { video, src: '', status: DownloadStatus.WaitingForResponse } as ViewableVideo
        })

        log('setup count', this.viewableVideos.length)
    }
}
