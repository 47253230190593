import { useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { ProjectResourcesTable } from './ProjectResourcesTable'
import { ResourcesSettingsModal } from './ResourcesSettingsModal'
import { Root } from '../../../models3/Root'
import { DownloadModal } from '../../modals/resources/DownloadModal'
import { DownloadButton, SettingsButton } from '../../utils/Buttons'

import '../ProjectSettings.css'

export const ProjectResources = observer(({ rt }: { rt: Root }) => {
    const { t } = useTranslation()
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
    const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false)

    return (
        <div>
            {isSettingsModalOpen && <ResourcesSettingsModal rt={rt} closeModal={() => setIsSettingsModalOpen(false)} />}
            {isDownloadModalOpen && <DownloadModal rt={rt} closeModal={() => setIsDownloadModalOpen(false)} />}
            <h3>{t('projectResources')}</h3>
            <div className="resource-download-button-and-label">
                <SettingsButton
                    tooltip={t('showOrHideBibles')}
                    enabled
                    onClick={() => setIsSettingsModalOpen(true)}
                    className="book-download-button"
                />
                <span>{t('showOrHideBibles')}</span>
            </div>
            <div className="resource-download-button-and-label">
                <DownloadButton
                    tooltip={t('downloadToDevice')}
                    enabled
                    onClick={() => setIsDownloadModalOpen(true)}
                    className="book-download-button"
                />
                <span>{t('downloadToDevice')}</span>
            </div>
            <h4>{t('downloadedResources')}</h4>
            <ProjectResourcesTable rt={rt} />
        </div>
    )
})
