import React, { FC } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { LineChart, Line, XAxis, YAxis } from 'recharts'

import { expectedCompletionDate, getGraphData } from './ProgressUtils'
import { Passage } from '../../../models3/Passage'
import { Portion } from '../../../models3/Portion'
import { Project } from '../../../models3/Project'
import { Root } from '../../../models3/Root'
import ProjectDataFilter from '../../utils/ProjectDataFilter'
import { FilteredPortionPassages } from '../StatusEditor'

import './ProgressGraph.css'

interface IProgressGraphHeader {
    iAmTranslator: boolean
    project: Project
    currentValue: string
    setCurrentPortion: (value: Portion) => void
    setCurrentPassage: (portion: Portion, passage: Passage) => void
    setShowAll: () => void
    setShowAssignedPassages: () => void
    setShowCurrentPortion: () => void
    setShowCurrentPassage: () => void
}

const ProgressGraphHeader: FC<IProgressGraphHeader> = ({
    setCurrentPortion,
    setCurrentPassage,
    setShowCurrentPortion,
    setShowCurrentPassage,
    setShowAll,
    setShowAssignedPassages,
    currentValue,
    project,
    iAmTranslator
}) => {
    const { t } = useTranslation()

    return (
        <div className="progress-graph-header">
            <div className="progress-graph-header-item">
                {t('Report of')}
                <ProjectDataFilter
                    iAmTranslator={iAmTranslator}
                    portions={project.portions}
                    currentValue={currentValue}
                    setShowAll={setShowAll}
                    setShowAssignedPassages={setShowAssignedPassages}
                    setShowCurrentPassage={setShowCurrentPassage}
                    setShowCurrentPortion={setShowCurrentPortion}
                    setPortion={setCurrentPortion}
                    setPassage={setCurrentPassage}
                />
            </div>
        </div>
    )
}

interface IProgressGraphFooter {
    rt: Root
    data: { name: string; date: Date; percent: number }[]
}

const ProgressGraphFooter: FC<IProgressGraphFooter> = ({ rt, data }) => {
    const { t } = useTranslation()

    const entries = data.map((entry) => {
        const { date, percent } = entry
        return {
            date,
            percent
        }
    })
    const sortedEntries = [...entries].sort((a, b) => a.date.getTime() - b.date.getTime())
    if (sortedEntries[sortedEntries.length - 1].percent === 100) {
        return <div>{t('Already completed')}</div>
    }

    const completionDate = expectedCompletionDate(sortedEntries, new Date())

    return (
        <div>{completionDate && `${t('Projected completion date')}: ${rt.dateFormatter.format(completionDate)}`}</div>
    )
}

interface IProgressGraph {
    data: { name: string; date: Date; percent: number }[]
}

export const ProgressGraph: FC<IProgressGraph> = ({ data }) => {
    const entries = data.map((entry) => {
        const { name, percent } = entry
        return {
            name,
            percent
        }
    })
    return (
        <div>
            <LineChart width={400} height={400} data={entries}>
                <Line type="linear" dataKey="percent" stroke="#8884d8" isAnimationActive={false} />
                <XAxis dataKey="name" />
                <YAxis domain={[0, 100]} />
            </LineChart>
        </div>
    )
}

interface IProgressGraphPage {
    rt: Root
    currentSelectedOption: string
    portionPassages: FilteredPortionPassages[]
    setCurrentPortion: (value: Portion) => void
    setCurrentPassage: (portion: Portion, passage: Passage) => void
    setShowAll: () => void
    setShowAssignedPassages: () => void
    setShowCurrentPortion: () => void
    setShowCurrentPassage: () => void
}

export const ProgressGraphPage: FC<IProgressGraphPage> = observer(
    ({
        rt,
        currentSelectedOption,
        portionPassages,
        setShowAll,
        setShowAssignedPassages,
        setShowCurrentPassage,
        setShowCurrentPortion,
        setCurrentPassage,
        setCurrentPortion
    }) => {
        const { t, i18n } = useTranslation()

        const { language } = i18n

        const { project, iAmTranslator } = rt

        if (!project.plans.length) {
            return null
        }

        const passages = portionPassages.flatMap((pp) => pp.passages)
        const plan = project.plans[0]
        const data = plan ? getGraphData(passages, plan, language) : []

        return (
            <div>
                <ProgressGraphHeader
                    iAmTranslator={iAmTranslator}
                    project={project}
                    currentValue={currentSelectedOption}
                    setShowAll={setShowAll}
                    setShowAssignedPassages={setShowAssignedPassages}
                    setShowCurrentPassage={setShowCurrentPassage}
                    setShowCurrentPortion={setShowCurrentPortion}
                    setCurrentPassage={setCurrentPassage}
                    setCurrentPortion={setCurrentPortion}
                />
                <div className="progress-graph-separator" />
                {data.length === 0 && t('No data available')}
                {data.length > 0 && (
                    <>
                        <div className="progress-graph-table">
                            <ProgressGraph data={data} />
                        </div>
                        <div className="progress-graph-separator" />
                        <ProgressGraphFooter {...{ rt, data }} />
                    </>
                )}
            </div>
        )
    }
)
