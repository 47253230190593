import { useMemo, useState, useEffect } from 'react'

import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { RecordingTypeChooser } from './RecordingTypeChooser'
import { pericopesInRef, useBiblePericopes, BiblePericopes } from '../../hooks/useBiblePericopes'
import { Passage, PassageContentTypes } from '../../models3/Passage'
import { Root } from '../../models3/Root'
import { RefRange, refToBookId } from '../../resources/RefRange'
import { RecordingMediaType } from '../../types'
import { MultiSelect, Option } from '../projectSettings/projectResources/MultiSelect'
import { ReferenceInput } from '../referenceInput/ReferenceInput'
import { CancelEditSegmentButton, OkEditSegmentButton } from '../utils/Buttons'
import { displayError } from '../utils/Errors'
import { LoadingIcon } from '../utils/Icons'
import LabeledFieldWithIcon from '../utils/LabeledFieldWithIcon'
import { Switch } from '../utils/Switch'
import { SimpleTextInput } from '../utils/TextInput'

const DifficultyInput = ({
    difficulty,
    setDifficulty
}: {
    difficulty: number
    setDifficulty: (difficulty: number) => void
}) => {
    const [value, setValue] = useState(difficulty.toString())

    return (
        <input
            className="difficulty-input"
            required
            type="number"
            value={value}
            min={0}
            step={0.1}
            onChange={(e) => {
                setDifficulty(parseFloat(e.target.value))
                setValue(e.target.value)
            }}
        />
    )
}

const getPericopes = ({
    getBiblePericopes,
    getSelectedPericopeType,
    getRefBookId,
    getReferences,
    getPassageName
}: {
    getBiblePericopes: BiblePericopes | undefined
    getSelectedPericopeType: Option<string>[]
    getRefBookId: string
    getReferences: RefRange[]
    getPassageName: string
}) => {
    if (
        getBiblePericopes &&
        getSelectedPericopeType.length &&
        getBiblePericopes[getSelectedPericopeType[0].value][getRefBookId]
    ) {
        return pericopesInRef({
            pericopes: getBiblePericopes[getSelectedPericopeType[0].value][getRefBookId],
            startRef: getReferences[0].startRef,
            endRef: getReferences[0].endRef
        }).map(({ title, startRef, endRef }) => {
            const label = getPassageName ? `${getPassageName} ${title}` : title
            const value = `${startRef}-${endRef}`
            return { label, value }
        })
    }
    return []
}

const MultipleAddFields = ({
    references,
    passageName,
    selectedPericopes,
    setSelectedPericopes,
    selectedPericopeType,
    setSelectedPericopeType
}: {
    references: RefRange[]
    passageName: string
    selectedPericopes: Option<string>[]
    setSelectedPericopes: (selected: Option<string>[]) => void
    selectedPericopeType: Option<string>[]
    setSelectedPericopeType: (selected: Option<string>[]) => void
}) => {
    const { t } = useTranslation()
    const [srvPericopes, setSrvPericopes] = useState<Option<string>[]>([])
    const { biblePericopes, pericopeTypes, isLoading } = useBiblePericopes()

    const refBookId = useMemo(() => (references.length ? refToBookId(references[0].startRef) : ''), [references])

    const memoizedPericopes = useMemo(() => {
        return getPericopes({
            getBiblePericopes: biblePericopes,
            getSelectedPericopeType: selectedPericopeType,
            getRefBookId: refBookId,
            getReferences: references,
            getPassageName: passageName
        })
    }, [biblePericopes, selectedPericopeType, references, passageName, refBookId])

    useEffect(() => {
        if (biblePericopes && biblePericopes.srvPericopes[refBookId]) {
            setSrvPericopes(
                getPericopes({
                    getBiblePericopes: biblePericopes,
                    getSelectedPericopeType: [{ label: 'passage per Study Guide', value: 'srvPericopes' }],
                    getRefBookId: refBookId,
                    getReferences: references,
                    getPassageName: passageName
                })
            )
        } else {
            setSrvPericopes([])
        }
    }, [biblePericopes, references, passageName, refBookId])

    useEffect(() => {
        if (
            references.length &&
            srvPericopes.length === 0 &&
            selectedPericopeType.some((pericopeType) => pericopeType.value === 'srvPericopes')
        ) {
            setSelectedPericopeType([])
        }
    }, [references, setSelectedPericopeType, srvPericopes, selectedPericopeType])

    const setNewPericopeType = (pericopeType: Option<string>[]) => {
        setSelectedPericopeType(pericopeType)
        setSelectedPericopes([])
    }

    if (isLoading) {
        return <LoadingIcon className="" />
    }

    const passagePericopeTypeOptions = () => {
        if (references.length === 0) {
            return pericopeTypes.map(({ id, title }) => ({
                label: title,
                value: id,
                disabled: true
            }))
        }

        return pericopeTypes.map(({ id, title }) => ({
            label: title,
            value: id,
            disabled: srvPericopes.length === 0 ? id === 'srvPericopes' : false
        }))
    }

    return (
        <>
            <LabeledFieldWithIcon
                iconName="fa-paragraph"
                title={t('passagePericopeType')}
                field={
                    <MultiSelect
                        singleSelect
                        options={passagePericopeTypeOptions()}
                        selected={selectedPericopeType}
                        setSelected={setNewPericopeType}
                    />
                }
            />

            <LabeledFieldWithIcon
                iconName="fa-plus-square"
                title={t('passagesToAdd')}
                field={
                    <MultiSelect
                        options={memoizedPericopes}
                        selected={selectedPericopes}
                        setSelected={setSelectedPericopes}
                        disabled={selectedPericopeType.length === 0}
                    />
                }
            />
        </>
    )
}

const PassageEditor = ({
    rt,
    passage,
    setEditing
}: {
    rt: Root
    passage?: Passage
    setEditing: (editing: boolean) => void
}) => {
    const { t } = useTranslation()

    const getInitialRecordingType = () => {
        if (passage?.latestVideo) {
            return passage?.latestVideo.isAudioOnly() ? RecordingMediaType.AUDIO : RecordingMediaType.VIDEO
        }

        return passage?.recordingMediaType ?? RecordingMediaType.AUDIO
    }

    const [passageName, setPassageName] = useState(passage?.name ?? '')
    const [nameError, setNameError] = useState('')
    const [references, setReferences] = useState(passage?.references ?? [])
    const [referencesError, setReferencesError] = useState(false)
    const [passageRecordingType, setPassageRecordingType] = useState(getInitialRecordingType())
    const [difficulty, setDifficulty] = useState(passage?.difficulty ?? 1.0)

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const passageContentTypes = Object.keys(PassageContentTypes).map((type) => ({ label: t(type), value: type }))
    const initialContentType = passage
        ? [{ label: t(passage.contentType), value: passage.contentType }]
        : passageContentTypes.slice(0, 1)

    const [contentType, setContentType] = useState<Option<string>[]>(initialContentType)

    const [isMultipleAdd, setIsMultipleAdd] = useState(false)
    const [selectedPericopes, setSelectedPericopes] = useState<Option<string>[]>([])
    const [selectedPericopeType, setSelectedPericopeType] = useState<Option<string>[]>([])

    const portion = rt.portion
    const isAdd = !passage

    if (!portion) {
        const error = t('portionNotFound')
        console.error(error)
        displayError(error)
        return null
    }

    const setNewReferences = (newReferences: RefRange[]) => {
        setReferences(newReferences)
        if (isMultipleAdd) {
            setSelectedPericopes([])
        }
    }

    const validatePassageName = (newValue: string) => {
        if (isMultipleAdd || passage?.name === newValue) return ''

        return portion.checkNewPassageName(newValue)
    }

    const areValidInputs = () => {
        if (contentType.length === 0 || isNaN(difficulty) || referencesError) {
            return false
        }

        if (isMultipleAdd) {
            return selectedPericopes.length > 0 && selectedPericopeType.length > 0
        }

        return validatePassageName(passageName) === ''
    }

    const save = async () => {
        const savedContentType = contentType[0].value as PassageContentTypes

        try {
            if (isMultipleAdd) {
                for (const pericope of selectedPericopes) {
                    const [startRef, endRef] = pericope.value.split('-')
                    const newPassage = portion.createPassage(pericope.label, false)
                    newPassage.references = [new RefRange(startRef, endRef)]
                    newPassage.difficulty = difficulty
                    newPassage.recordingMediaType = passageRecordingType
                    newPassage.contentType = savedContentType
                    await portion.addPassageFromExisting(newPassage)
                }
            } else if (isAdd) {
                const newPassage = portion.createPassage(passageName)
                newPassage.references = references
                newPassage.difficulty = difficulty
                newPassage.contentType = savedContentType
                newPassage.recordingMediaType = passageRecordingType
                const savedPassage = await portion.addPassageFromExisting(newPassage)
                await rt.setPassage(savedPassage)
            } else {
                await passage.setName(passageName)
                await passage.setReferences(references)
                await passage.setDifficulty(difficulty)
                await passage.setContentType(savedContentType)
            }

            rt.setDbsRefs(portion)
        } catch (error) {
            console.error(error)
            displayError(error)
        }
    }

    return (
        <Modal show onHide={() => setEditing(false)} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t('Passage')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isAdd && (
                    <div className="passages-modal-toggle">
                        {t('addMultiplePassages')}
                        <Switch
                            value={isMultipleAdd}
                            setValue={(newValue) => {
                                setIsMultipleAdd(newValue)
                                setNameError('')
                                setPassageName('')
                            }}
                            tooltip={isMultipleAdd ? t('addMultiplePassages') : t('addSinglePassage')}
                        />
                    </div>
                )}

                <LabeledFieldWithIcon
                    iconName="fa-tag"
                    title={isAdd && isMultipleAdd ? t('titlePrefixOptional') : t('Title')}
                    field={
                        <SimpleTextInput
                            autoFocus
                            value={passageName}
                            setValue={(value: string) => {
                                setNameError(validatePassageName(value))
                                setPassageName(value)
                                if (isMultipleAdd) {
                                    setSelectedPericopes([])
                                }

                                try {
                                    const parsedNewReferences = rt.parseReferences(value.trim(), isMultipleAdd)
                                    if (parsedNewReferences.length > 0) {
                                        // Continue to show old references until the user types a valid reference
                                        setNewReferences(parsedNewReferences)
                                        setReferencesError(false)
                                    }
                                } catch (error) {
                                    // Ignore, since we just want to know whether the string can be parsed as a reference
                                }
                            }}
                            errorMessage={nameError}
                        />
                    }
                />

                <LabeledFieldWithIcon
                    iconName="fa-book"
                    title={t('References')}
                    field={
                        <ReferenceInput
                            refInput={rt}
                            refs={references}
                            setRefs={setNewReferences}
                            errored={referencesError}
                            setErrored={setReferencesError}
                            showSuggestions
                            autoComplete
                        />
                    }
                />
                {isMultipleAdd && (
                    <MultipleAddFields
                        passageName={passageName}
                        references={references}
                        selectedPericopes={selectedPericopes}
                        setSelectedPericopes={setSelectedPericopes}
                        selectedPericopeType={selectedPericopeType}
                        setSelectedPericopeType={setSelectedPericopeType}
                    />
                )}

                <LabeledFieldWithIcon
                    iconName="fa-certificate"
                    title={t('passageType')}
                    field={
                        <MultiSelect
                            singleSelect
                            options={passageContentTypes}
                            selected={contentType}
                            setSelected={setContentType}
                        />
                    }
                />
                <LabeledFieldWithIcon
                    iconName="fa-circle"
                    title={t('passageRecordingMediaType')}
                    field={
                        <RecordingTypeChooser
                            recordingTypes={[RecordingMediaType.AUDIO, RecordingMediaType.VIDEO]}
                            selected={passageRecordingType}
                            setSelected={(recordingType) => setPassageRecordingType(recordingType)}
                            enabled={isAdd}
                        />
                    }
                />
                <LabeledFieldWithIcon
                    iconName="fa-cog"
                    title={t('difficulty')}
                    field={<DifficultyInput {...{ difficulty, setDifficulty }} />}
                />
            </Modal.Body>
            <Modal.Footer>
                <div className="button-row">
                    <OkEditSegmentButton
                        enabled={areValidInputs()}
                        onClick={() => {
                            save()
                            setEditing(false)
                        }}
                    />
                    <CancelEditSegmentButton enabled onClick={() => setEditing(false)} />
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default PassageEditor
