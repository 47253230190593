import { FC, useState, useContext } from 'react'

import {
    DndContext,
    closestCorners,
    KeyboardSensor,
    PointerSensor,
    useDroppable,
    useSensor,
    useSensors
} from '@dnd-kit/core'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import { SortableContext, arrayMove, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { RecordingLayoutSortableItem } from './RecordingLayoutSortableItem'
import { Project } from '../../../models3/Project'
import { RootContext } from '../../app/RootContext'
import { LayoutContainer, SegmentEditorPanel } from '../../segments/SegmentPanelOrder'
import { TableIcon } from '../../utils/Icons'
import { Switch } from '../../utils/Switch'

type RecordingLayoutPreferencesProps = {
    project: Project
    iconClassName: string
}

const SegmentEditorPanelContainer = ({ id, items }: { id: LayoutContainer; items: SegmentEditorPanel[] }) => {
    const { setNodeRef } = useDroppable({ id })
    const { t } = useTranslation()
    const rt = useContext(RootContext)
    if (!rt) {
        return null
    }

    return (
        <SortableContext id={id} items={items} strategy={verticalListSortingStrategy}>
            <div ref={setNodeRef} className="sortable-container">
                <div style={{ textAlign: 'center' }}>
                    <label>{t('segmentEditor')}</label>
                </div>
                {items.map((item) => (
                    <RecordingLayoutSortableItem rt={rt} key={item} id={item} />
                ))}
            </div>
        </SortableContext>
    )
}

export const RecordingLayoutPreferences: FC<RecordingLayoutPreferencesProps> = observer(
    ({ project, iconClassName }) => {
        const { t } = useTranslation()
        const { overrideRecordingLayout } = project

        const defaultItems: { [key: string]: SegmentEditorPanel[] } = {
            segmentEditor: project.segmentEditorPanelLayout
        }

        const [items, setItems] = useState(defaultItems)

        const sensors = useSensors(
            useSensor(PointerSensor),
            useSensor(KeyboardSensor, {
                coordinateGetter: sortableKeyboardCoordinates
            })
        )

        const findContainer = (id: SegmentEditorPanel) => {
            if (id in items) {
                return id
            }

            return Object.keys(items).find((key: string) => items[key].includes(id))
        }

        const handleDragEnd = async (event: any) => {
            const { active, over } = event
            const { id } = active
            const { id: overId } = over

            const activeContainer = findContainer(id)
            const overContainer = findContainer(overId)

            if (!activeContainer || !overContainer || activeContainer !== overContainer) {
                return
            }

            const activeIndex = items[activeContainer].indexOf(id)
            const overIndex = items[overContainer].indexOf(overId)
            if (activeIndex !== overIndex) {
                const newItems = {
                    ...items,
                    [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex)
                }
                setItems(newItems)
                await project.setSegmentEditorPanelLayout(newItems.segmentEditor)
            }
        }

        return (
            <>
                <div className="project-preferences-switch">
                    <Switch
                        value={overrideRecordingLayout}
                        setValue={(value) => project.setOverrideRecordingLayout(value)}
                        tooltip={t('Override default layout')}
                    >
                        <TableIcon className={`generic-icon ${iconClassName}`} tooltip={t('Recording page layout')} />
                    </Switch>
                </div>
                {overrideRecordingLayout && (
                    <div className="recording-layout-preferences">
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCorners}
                            modifiers={[restrictToParentElement]}
                            onDragEnd={handleDragEnd}
                        >
                            <SegmentEditorPanelContainer id="segmentEditor" items={items.segmentEditor} />
                        </DndContext>
                    </div>
                )}
            </>
        )
    }
)
