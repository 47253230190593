/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react'

import { observable } from 'mobx'
import { observer } from 'mobx-react'
import Autosuggest, { ChangeEvent, SuggestionHighlightedParams } from 'react-autosuggest'

import { WarningIcon } from './Icons'

import './Autosuggest.css'

const getItemValue = (item: string) => item

const renderRegularInput = (inputProps: any) => <input {...inputProps} />

const renderInputWithValidityIndicator = (inputProps: any, valid: boolean, prefix: string, invalidTooltip: string) => (
    <div className={`${prefix}__input-wrapper`}>
        <input {...inputProps} />
        {!valid && <WarningIcon className={`${prefix}__invalid-icon`} tooltip={invalidTooltip} />}
    </div>
)

const renderSuggestion = (suggestion: string) => <div>{suggestion}</div>

interface ISearchBox {
    searchParameter: string
    options: string[]
    onFocus?: () => void
    onBlur?: () => void
    onEnter: () => void
    onEscape: () => void
    searchParameterChanged: (searchParameter: string) => void
    tooltip: string
    autoFocus?: boolean
    isValidInput?: boolean
    invalidTooltip?: string
    keyboardShortcuts?: { key: string; metaKey: boolean; shiftKey: boolean; handler: (event: any) => void }[]
    disableTab?: boolean
    disabled?: boolean
}

@observer
class SearchBox extends Component<ISearchBox> {
    @observable suggestions: string[] = []

    input: any = null

    suggestionHighlighted = false

    onSuggestionsFetchRequested = ({ value }: { value: string }) => {
        this.suggestions = this.getSuggestions(value)
    }

    getSuggestions = (value: string) => {
        const { options } = this.props
        return options.filter((e) => e.toLowerCase().indexOf(value.toLowerCase()) > -1)
    }

    onSuggestionsClearRequested = () => {
        this.suggestions = []
    }

    onSuggestionHighlighted = (params: SuggestionHighlightedParams) => {
        const { suggestion } = params
        this.suggestionHighlighted = suggestion !== null
    }

    onChange = (event: any, params: ChangeEvent) => {
        const { newValue, method } = params
        const { searchParameterChanged } = this.props
        if (method !== 'escape') {
            searchParameterChanged(newValue)
        }
    }

    storeInputReference = (autosuggest: any) => {
        if (autosuggest !== null) {
            this.input = autosuggest.input
        }
    }

    onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const { onEnter, onEscape, keyboardShortcuts, disableTab, searchParameterChanged } = this.props
        const { suggestionHighlighted, input } = this

        event.stopPropagation()

        if (event.key === 'Tab' && !disableTab) {
            event.preventDefault()

            if (suggestionHighlighted) {
                if (input) {
                    input.blur()
                    input.focus()
                }
                this.suggestions = []
            } else if (this.suggestions.length) {
                searchParameterChanged(this.suggestions[0])
            }

            return
        }

        if (event.key === 'Enter') {
            if (!suggestionHighlighted) {
                onEnter()
                if (input) {
                    input.blur()
                }
            }

            return
        }

        if (event.key === 'Escape') {
            onEscape()
            if (input) {
                input.blur()
            }
            return
        }

        // The key is not 'Tab', 'Enter' or 'Escape'
        const shortcutHandler = keyboardShortcuts?.find(
            (shortcut) =>
                shortcut.key === event.key && shortcut.metaKey === event.metaKey && shortcut.shiftKey === event.shiftKey
        )
        if (shortcutHandler) {
            shortcutHandler.handler(event)
        }
    }

    render() {
        const { searchParameter, autoFocus, isValidInput, invalidTooltip, onFocus, onBlur, disabled } = this.props
        const {
            onChange,
            storeInputReference,
            suggestions,
            onSuggestionsFetchRequested,
            onSuggestionsClearRequested,
            onSuggestionHighlighted,
            onKeyDown
        } = this

        // Even though this isn't used in the render method, we must reference it so
        // the component will rerender when keyboardShortcuts changes. This is so that
        // onKeyDown will get access to the updated list of shortcuts.
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { keyboardShortcuts, disableTab } = this.props

        const inputProps = {
            value: searchParameter,
            onChange,
            onKeyDown,
            onFocus,
            onBlur,
            autoFocus: Boolean(autoFocus),
            disabled
        }

        const prefix = 'search-box'

        return (
            <div className="search-box-container">
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    getSuggestionValue={getItemValue}
                    renderSuggestion={renderSuggestion}
                    renderInputComponent={(input) =>
                        isValidInput !== undefined && invalidTooltip !== undefined
                            ? renderInputWithValidityIndicator(input, isValidInput, prefix, invalidTooltip)
                            : renderRegularInput(input)
                    }
                    inputProps={inputProps}
                    shouldRenderSuggestions={() => true}
                    onSuggestionHighlighted={onSuggestionHighlighted}
                    ref={storeInputReference}
                    theme={{
                        input:
                            isValidInput !== undefined ? `${prefix}__input--with-validity` : `${prefix}__input--plain`,
                        suggestionsContainer: `${prefix}__suggestions-container`,
                        suggestionsContainerOpen: `${prefix}__suggestions-container--open`,
                        suggestion: `${prefix}__suggestion`,
                        suggestionHighlighted: `${prefix}__suggestion--highlighted`,
                        suggestionsList: `${prefix}__suggestions-list`
                    }}
                />
                {/* <DownArrowButton
                    onClick={onClick}
                    className='search-box-down-arrow'
                    tooltip={tooltip} /> */}
            </div>
        )
    }
}

export { SearchBox }
