import { FormGroup, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { CacheType } from '../../../types'

interface CacheTypeChooserProps {
    cacheTypes: CacheType[]
    selected: CacheType | null
    setSelected: (cacheType: CacheType) => void
    enabled: boolean
}

export const CacheTypeChooser = ({ cacheTypes, selected, setSelected, enabled }: CacheTypeChooserProps) => {
    const { t } = useTranslation()
    return (
        <form>
            <FormGroup controlId="resource-type-chooser">
                <Form.Label>{t('resourceType')}</Form.Label>
                {cacheTypes.map((cacheType) => (
                    <Form.Check
                        type="radio"
                        id={cacheType}
                        key={cacheType}
                        onChange={() => {
                            setSelected(cacheType)
                        }}
                        checked={selected === cacheType}
                        label={t(cacheType)}
                        disabled={enabled === false}
                    />
                ))}
            </FormGroup>
        </form>
    )
}
