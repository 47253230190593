import { useTranslation } from 'react-i18next'

import { ExportProgress } from '../../../types'
import { LoadingIcon } from '../../utils/Icons'

interface DownloadExportProgressProps {
    exportProgress: ExportProgress
    loading: boolean
}

export const DownloadExportProgress = ({ exportProgress, loading }: DownloadExportProgressProps) => {
    const { t } = useTranslation()

    if (loading) {
        return <LoadingIcon className="" />
    }

    if (exportProgress === ExportProgress.FINISHED) {
        return <div>{t('fileExported')}</div>
    }

    if (exportProgress === ExportProgress.ERROR) {
        return <div>{t('exportError')}</div>
    }

    return null
}
