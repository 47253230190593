// Display note selected by user OR create new note

import { Component, FunctionComponent, useEffect, useState } from 'react'

import { t as tFunction } from 'i18next'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import NavigateAway, { NavigateAwayChildrenProps } from './NavigateAway'
import NoteDropTarget from './NoteDropTarget'
import { NoteMain } from './NoteMain'
import { IDateFormatter } from '../../models3/DateUtilities'
import { Passage } from '../../models3/Passage'
import { PassageNote } from '../../models3/PassageNote'
import { PassageSegment } from '../../models3/PassageSegment'
import { PassageVideo } from '../../models3/PassageVideo'
import { Portion } from '../../models3/Portion'
import { Project } from '../../models3/Project'
import { PreviousSegmentButton, NextSegmentButton, PaneCloseButton, LockButton, UnlockButton } from '../utils/Buttons'
import { DropTargetViewLarge } from '../utils/DropTargetView'
import { systemError, CustomErrorBoundary } from '../utils/Errors'

import './Note.css'
import '../video/Video.css'

//! It would make sense for the goto next/prev  amd selectNoteType routines to be part of
// NoteHeader. It would reduce the size of NoteDialog and reduce the number of arguments
// that need to be passed to NoteHeader.

//! Create a NoteFooter function to reduce size of NoteDialog. Move any action routines
// it calls out of NoteDialog and into NoteFooter.

interface INoteHeader {
    noteRoot: INoteRoot
    notes: PassageNote[]
    goToPreviousNote: () => void
    goToNextNote: () => void
    closeDialog: () => void
}

const NoteHeader: FunctionComponent<INoteHeader> = observer((props) => {
    const { t, i18n } = useTranslation()
    const dir = i18n.dir()
    const [narrowWidth, setNarrowWidth] = useState(false)

    const { noteRoot, notes, goToPreviousNote, goToNextNote, closeDialog } = props
    const { portion, passage, note } = noteRoot

    const i = notes.findIndex((nt) => nt._id === note._id)
    const x = i + 1
    const y = notes.length

    const mediaQueryChangeEvent = (e: any) => {
        setNarrowWidth(e.matches)
    }

    useEffect(() => {
        const mediaQueryList = window.matchMedia('(max-width: 991px)')
        setNarrowWidth(mediaQueryList.matches)
        mediaQueryList.addEventListener('change', mediaQueryChangeEvent)
        return () => mediaQueryList.removeEventListener('change', mediaQueryChangeEvent)
    }, [])

    if (narrowWidth) {
        return (
            <span className="note-header">
                <div className="note-header-left">
                    {portion ? portion.name : `{${t('Portion Name')}}`} /{' '}
                    {passage ? passage.name : `{${t('Passage Name')}}`}
                    <div className="note-header-button-row">
                        {i >= 0 && (
                            <span className="note-navigator">
                                &nbsp;&nbsp;
                                <PreviousSegmentButton
                                    onClick={goToPreviousNote}
                                    tooltip={t('Go to previous note.')}
                                    enabled={i > 0}
                                    dir={dir}
                                />
                                {`${x}/${y}`}
                                <NextSegmentButton
                                    onClick={goToNextNote}
                                    tooltip={t('Go to next note.')}
                                    enabled={i < notes.length - 1}
                                    dir={dir}
                                />
                            </span>
                        )}
                    </div>
                </div>
                <div className="note-header-right">
                    <PaneCloseButton
                        enabled
                        onClick={closeDialog}
                        tooltip={t('Close')}
                        className="note-dialog-close-button"
                    />
                </div>
            </span>
        )
    }

    const notePositionMessage = t('Note {{x}} of {{y}}', { x, y })

    return (
        <span className="note-header">
            {portion ? portion.name : `{${t('Portion Name')}}`} / {passage ? passage.name : `{${t('Passage Name')}}`}:
            {i >= 0 && (
                <span className="note-navigator">
                    &nbsp;&nbsp;
                    <PreviousSegmentButton
                        onClick={goToPreviousNote}
                        tooltip={t('Go to previous note.')}
                        enabled={i > 0}
                        dir={dir}
                    />
                    {notePositionMessage}
                    <NextSegmentButton
                        onClick={goToNextNote}
                        tooltip={t('Go to next note.')}
                        enabled={i < notes.length - 1}
                        dir={dir}
                    />
                </span>
            )}
            <div className="note-header-right">
                <PaneCloseButton
                    enabled
                    onClick={closeDialog}
                    tooltip={t('Close')}
                    className="note-dialog-close-button"
                />
            </div>
        </span>
    )
})

interface INoteDialog {
    noteRoot: INoteRoot
    closeNoteDialog: () => void
}

export interface INoteRoot {
    iAmInterpreter: boolean
    iAmAdmin: boolean
    username: string
    currentTime: number
    duration: number
    resetCurrentTime: (newTime: number, duration?: number) => void
    note: PassageNote
    setNote: (note: PassageNote) => void
    createNoteIfNonexistent: () => Promise<void>
    project: Project
    portion: Portion | null
    passage: Passage | null
    passageSegment: PassageSegment | null
    passageVideo: PassageVideo | null
    name: string
    hardNotificationCutoff: () => Date
    dateFormatter: IDateFormatter
    useMobileLayout: boolean
    canViewConsultantOnlyFeatures: boolean
    playbackRate: number
    setPlaybackRate: (rate: number) => void
    setPassageVideo: (passageVideo: PassageVideo) => void
    setPassageSegment: (passageSegment: PassageSegment) => void
}

class NoteDialog extends Component<INoteDialog> {
    @observable editing = false

    constructor(props: INoteDialog) {
        super(props)
        this.resolveNote = this.resolveNote.bind(this)
        this.unresolveNote = this.unresolveNote.bind(this)
        this.closeNoteDialog = this.closeNoteDialog.bind(this)
        this.userCanResolve = this.userCanResolve.bind(this)
        this.userCanUnresolve = this.userCanUnresolve.bind(this)
        this.canModifyResolveAccess = this.canModifyResolveAccess.bind(this)
        this.selectNoteType = this.selectNoteType.bind(this)
        this.goToPreviousNote = this.goToPreviousNote.bind(this)
        this.goToNextNote = this.goToNextNote.bind(this)
    }

    async setUserHasViewed(note: PassageNote, username: string) {
        const { items } = note

        // Treat text items or images as viewed as soon as the user has opened
        // the note. Videos must be played before we mark them as viewed.
        const viewedItems = items.filter(
            (item) => item.isTextItem() || item.isImageItem() || item.resolved || item.unresolved
        )
        for (const item of viewedItems) {
            await item.addViewedBy(username)
        }
    }

    continueAfterConfirmation = async (confirm: () => Promise<boolean>, action: () => void) => {
        const canContinue = await confirm()
        if (canContinue) {
            action()
        }
    }

    userCanResolve() {
        const { noteRoot } = this.props
        return this.canModifyResolveAccess() || (noteRoot.iAmInterpreter && noteRoot.note.canResolve)
    }

    userCanUnresolve() {
        const { noteRoot } = this.props

        return noteRoot.iAmInterpreter
    }

    canModifyResolveAccess() {
        const { noteRoot } = this.props
        const { iAmAdmin, username, note } = noteRoot
        return iAmAdmin || note?.creator === username
    }

    async goToPreviousNote(notes: PassageNote[], currentNote: PassageNote) {
        const { noteRoot } = this.props
        const { passage, username, setNote } = noteRoot
        if (!passage) {
            return
        }

        await this.setUserHasViewed(currentNote, username)

        const i = notes.findIndex((nt) => nt._id === currentNote._id)
        const previousIndex = Math.max(i - 1, 0)
        const previousNote = notes[previousIndex]
        setNote(previousNote)
    }

    async goToNextNote(notes: PassageNote[], currentNote: PassageNote) {
        const { noteRoot } = this.props
        const { passage, username, setNote } = noteRoot
        if (!passage) {
            return
        }

        await this.setUserHasViewed(currentNote, username)

        const i = notes.findIndex((nt) => nt._id === currentNote._id)
        const nextIndex = Math.min(i + 1, notes.length - 1)
        const nextNote = notes[nextIndex]
        setNote(nextNote)
    }

    async modifyResolveAccess(note: PassageNote, newValue: boolean) {
        if (!this.canModifyResolveAccess()) {
            return
        }

        const { noteRoot } = this.props
        try {
            await noteRoot.createNoteIfNonexistent()
            await note.setCanResolve(newValue)
        } catch (err) {
            systemError(err)
        }
    }

    async selectNoteType(note: PassageNote, type: string) {
        const { noteRoot } = this.props

        try {
            await noteRoot.createNoteIfNonexistent()
            await note.setType(type)
        } catch (err) {
            systemError(err)
        }
    }

    async resolveNote(note: PassageNote) {
        const { noteRoot } = this.props
        const { passage, createNoteIfNonexistent } = noteRoot

        if (!passage) {
            return
        }

        try {
            await createNoteIfNonexistent()
            await note.resolve(passage)
        } catch (err) {
            systemError(err)
        }
    }

    async unresolveNote(note: PassageNote) {
        const { noteRoot } = this.props
        const { passage, createNoteIfNonexistent } = noteRoot
        if (!passage) {
            return
        }

        try {
            await createNoteIfNonexistent()
            await note.unresolve(passage)
        } catch (err) {
            systemError(err)
        }
    }

    async closeNoteDialog() {
        const { closeNoteDialog, noteRoot } = this.props
        const { username, note } = noteRoot
        if (!note) return

        await this.setUserHasViewed(note, username)
        closeNoteDialog()
    }

    render() {
        const { editing, closeNoteDialog } = this
        const { noteRoot } = this.props
        const { note, passage, passageVideo, currentTime, canViewConsultantOnlyFeatures } = noteRoot
        const { resolved } = note
        if (!passage) {
            return null
        }

        let video: PassageVideo | null = null
        if (note && passage && passageVideo) {
            video = note.toVideo(passage)

            // If no current video, assume the note has not been created but not added to the database yet
            // The video can be determined by looking at the current view time
            if (!video) {
                video = passageVideo.timeToVideo(passage, currentTime)
            }
        }

        const notes =
            passageVideo
                ?.getVisibleNotes(passage, canViewConsultantOnlyFeatures)
                .filter((visibleNote) => resolved === visibleNote.resolved) || []

        const {
            goToNextNote,
            goToPreviousNote,
            userCanResolve,
            userCanUnresolve,
            canModifyResolveAccess,
            selectNoteType
        } = this

        const isSaved = notes.some((n) => n._id === note._id)

        const message = <div>{tFunction('noteDropFile')}</div>
        const dropTargetView = <DropTargetViewLarge message={message} />

        const setEditing = (value: boolean) => (this.editing = value)

        return (
            <NavigateAway>
                {(props: NavigateAwayChildrenProps) => (
                    <Modal
                        backdrop="static"
                        keyboard={!editing}
                        show
                        onHide={() => {
                            this.continueAfterConfirmation(props.confirm, closeNoteDialog)
                        }}
                        size="lg"
                    >
                        <CustomErrorBoundary
                            fallbackUI={
                                <>
                                    <Modal.Header>
                                        <Modal.Title>
                                            <NoteHeader
                                                {...{ noteRoot, notes }}
                                                goToPreviousNote={() =>
                                                    this.continueAfterConfirmation(props.confirm, () =>
                                                        goToPreviousNote(notes, note)
                                                    )
                                                }
                                                goToNextNote={() =>
                                                    this.continueAfterConfirmation(props.confirm, () =>
                                                        goToNextNote(notes, note)
                                                    )
                                                }
                                                closeDialog={() =>
                                                    this.continueAfterConfirmation(props.confirm, closeNoteDialog)
                                                }
                                            />
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <h4>{tFunction('Something went wrong...')}</h4>
                                    </Modal.Body>
                                </>
                            }
                        >
                            <Modal.Header>
                                <Modal.Title>
                                    <NoteHeader
                                        {...{ noteRoot, notes }}
                                        goToPreviousNote={() =>
                                            this.continueAfterConfirmation(props.confirm, () =>
                                                goToPreviousNote(notes, note)
                                            )
                                        }
                                        goToNextNote={() =>
                                            this.continueAfterConfirmation(props.confirm, () =>
                                                goToNextNote(notes, note)
                                            )
                                        }
                                        closeDialog={() =>
                                            this.continueAfterConfirmation(props.confirm, closeNoteDialog)
                                        }
                                    />
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <NoteDropTarget
                                    noteRoot={noteRoot}
                                    canUpload={props.confirm}
                                    dropTargetView={dropTargetView}
                                >
                                    <div className="note-container">
                                        <NoteMain
                                            {...{
                                                noteRoot,
                                                closeNoteDialog,
                                                editing,
                                                setEditing,
                                                selectNoteType,
                                                isSaved
                                            }}
                                        />
                                    </div>
                                </NoteDropTarget>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="note-dialog-footer">
                                    {!note.resolved && userCanResolve() && (
                                        <span className="pull-left">
                                            <button
                                                type="button"
                                                disabled={!isSaved}
                                                title={tFunction(
                                                    'Resolve note to show that the discussion is finished.'
                                                )}
                                                className="resolve-note btn btn-primary styled-primary-button"
                                                onClick={() =>
                                                    this.continueAfterConfirmation(props.confirm, async () => {
                                                        await this.resolveNote(note)
                                                        await closeNoteDialog()
                                                    })
                                                }
                                            >
                                                {tFunction('Resolve')}
                                            </button>
                                        </span>
                                    )}
                                    {!note.resolved && !userCanResolve() && (
                                        <span className="pull-left">
                                            <button
                                                type="button"
                                                disabled
                                                title={tFunction(
                                                    'An admin or the creator of this note has locked the resolve button'
                                                )}
                                                className="resolve-note btn btn-primary"
                                            >
                                                {tFunction('Resolve')}
                                            </button>
                                        </span>
                                    )}
                                    {note.resolved && userCanUnresolve() && (
                                        <button
                                            type="button"
                                            disabled={!isSaved}
                                            className="unresolve-note btn pull-left btn-primary styled-primary-button"
                                            onClick={() =>
                                                this.continueAfterConfirmation(props.confirm, async () => {
                                                    await this.unresolveNote(note)
                                                    await closeNoteDialog()
                                                })
                                            }
                                        >
                                            {tFunction('Unresolve')}
                                        </button>
                                    )}
                                    {note.canResolve && canModifyResolveAccess() && (
                                        <UnlockButton
                                            enabled
                                            onClick={() => this.modifyResolveAccess(note, false)}
                                            className="lock-resolve-button pull-left"
                                            tooltip={tFunction('Allow only admin or note creator to resolve')}
                                        />
                                    )}
                                    {!note.canResolve && canModifyResolveAccess() && (
                                        <LockButton
                                            enabled
                                            onClick={() => this.modifyResolveAccess(note, true)}
                                            className="lock-resolve-button pull-left"
                                            tooltip={tFunction('Allow anyone to resolve')}
                                        />
                                    )}
                                    <button
                                        type="button"
                                        className="close-note-dialog btn btn-default btn-primary styled-primary-button"
                                        onClick={() => this.continueAfterConfirmation(props.confirm, closeNoteDialog)}
                                    >
                                        {tFunction('Close')}
                                    </button>
                                </div>
                            </Modal.Footer>
                        </CustomErrorBoundary>
                    </Modal>
                )}
            </NavigateAway>
        )
    }
}

export default observer(NoteDialog)
