/* eslint-disable react/jsx-props-no-spreading */
import { Component } from 'react'

import { observer } from 'mobx-react'
import { Droppable } from 'react-beautiful-dnd'

import { ColumnData } from './DragAndDropData'
import PassageCard from './PassageCard'
import { Root } from '../../models3/Root'

import './TaskColumn.css'

interface ITaskColumn extends ColumnData {
    rt: Root
    updateDifficulty: (difficulty: number) => void
}

class TaskColumn extends Component<ITaskColumn> {
    render() {
        const { task, id, items, rt } = this.props

        return (
            <div className="column-container">
                <div className="task-column-header">
                    <h6>{task.displayedName}</h6>
                    <div className="task-details">{task.details}</div>
                </div>
                <Droppable droppableId={id}>
                    {(provided) => (
                        <div className="task-list" ref={provided.innerRef} {...provided.droppableProps}>
                            {items.map((item, index) => (
                                <PassageCard
                                    key={item.passage._id}
                                    passageData={item}
                                    index={index}
                                    rt={rt}
                                    updateDifficulty={item.passage.setDifficulty.bind(item.passage)}
                                />
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        )
    }
}

export default observer(TaskColumn)
