import { Fragment } from 'react'

import { GenericIcon } from './Icons'

import './ErrorList.css'

type ErrorListItem = {
    text: string
    key: string
}

type ErrorListProps = {
    errors: ErrorListItem[]
}

export const ErrorList = ({ errors }: ErrorListProps) => {
    return (
        <div className="error-list">
            {errors.map(({ text, key }) => (
                <Fragment key={key}>
                    <GenericIcon iconName="fa-exclamation-triangle" className="error-list-error-icon" tooltip={text} />
                    {text}
                </Fragment>
            ))}
        </div>
    )
}
