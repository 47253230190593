import { useState } from 'react'

import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { AudioEncodeType, DEFAULT_AUDIO_ENCODE_TYPE } from '../../utils/DownloadPassage'
import { Switch } from '../../utils/Switch'

export const AudioEncodingSwitch = ({
    setAudioEncodeType,
    defaultAudioEncodeType = DEFAULT_AUDIO_ENCODE_TYPE
}: {
    setAudioEncodeType: (type: AudioEncodeType) => void
    defaultAudioEncodeType?: AudioEncodeType
}) => {
    const { t } = useTranslation()
    const [toggle, setToggle] = useState(defaultAudioEncodeType !== 'wav')
    const [radio, setRadio] = useState(defaultAudioEncodeType)

    return (
        <>
            <div className="form-label">{t('Compress recording?')}</div>
            <Switch
                value={toggle}
                setValue={(newValue) => {
                    setAudioEncodeType(newValue ? radio : AudioEncodeType.wav)
                    setToggle(newValue)
                }}
                tooltip={t('Compress the recording before exporting it?')}
            />
            {toggle &&
                Object.keys(AudioEncodeType)
                    .filter((type) => type !== 'wav')
                    .map((type) => (
                        <Form.Check
                            type="radio"
                            id={type}
                            key={type}
                            name="audio-encoding-radio-group"
                            onChange={() => {
                                setAudioEncodeType(type as AudioEncodeType)
                                setRadio(type as AudioEncodeType)
                            }}
                            checked={radio === type}
                            label={type}
                        />
                    ))}
        </>
    )
}
