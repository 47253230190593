export type SerializedState = {
    selectedPortion?: string
    selectedPassage?: string
    selectedPassageVideo?: string
    selectedReviewRecording?: string
}

export const initialDropdownState: SerializedState = {
    selectedPortion: '',
    selectedPassage: '',
    selectedPassageVideo: '',
    selectedReviewRecording: ''
}

export type ReducerAction = {
    type: 'SET_PORTION' | 'SET_PASSAGE' | 'SET_PASSAGE_VIDEO' | 'SET_REVIEW_RECORDING'
    payload: string
}

export const reducer = (state: SerializedState, action: ReducerAction) => {
    switch (action.type) {
        case 'SET_PORTION': {
            return {
                selectedPortion: action.payload
            }
        }
        case 'SET_PASSAGE': {
            return {
                selectedPortion: state.selectedPortion,
                selectedPassage: action.payload
            }
        }
        case 'SET_PASSAGE_VIDEO': {
            return {
                selectedPortion: state.selectedPortion,
                selectedPassage: state.selectedPassage,
                selectedPassageVideo: action.payload
            }
        }
        case 'SET_REVIEW_RECORDING': {
            return {
                selectedPortion: state.selectedPortion,
                selectedPassage: state.selectedPassage,
                selectedPassageVideo: state.selectedPassageVideo,
                selectedReviewRecording: action.payload
            }
        }
        default: {
            return state
        }
    }
}
