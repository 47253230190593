import React from 'react'

import { observer } from 'mobx-react'

import { FetchBlobParams, useFetchBlob } from '../../hooks/useFetchBlob'
import { LoadingIcon } from '../utils/Icons'

import './AudioPlayer.css'

export const AudioFetchPlayer = observer(({ fetchBlob }: { fetchBlob: FetchBlobParams }) => {
    const { blobUrl, isError, isLoading } = useFetchBlob(fetchBlob)

    if (isError) {
        return null
    }

    return (
        <div className="audio-player-wrapper">
            {isLoading ? (
                <LoadingIcon className="audio-player" />
            ) : (
                <audio src={blobUrl} className="audio-player" controls />
            )}
        </div>
    )
})
