import { FunctionComponent, useContext } from 'react'

import { observer } from 'mobx-react'
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { getAllSegmentStatusMarkers } from './SegmentStatus'
import { Member } from '../../models3/Member'
import { PassageSegment, PassageSegmentApproval } from '../../models3/PassageSegment'
import { RootContext } from '../app/RootContext'

interface ISegmentApproval {
    segment: PassageSegment
    enabled: boolean
}

export const SegmentApproval: FunctionComponent<ISegmentApproval> = observer(({ segment, enabled }) => {
    const { t } = useTranslation()
    const rt = useContext(RootContext)
    if (!rt) return null

    const { iAmAdmin, iAmInterpreter, username, dateFormatter, project } = rt
    const { segmentStatusLabels } = project
    const { approved, approvalDate, approvedBy } = segment
    const isApproved = approved !== PassageSegmentApproval.State0

    const markerShapes = getAllSegmentStatusMarkers({ className: 'fa-fw' })

    const markers: JSX.Element[] = markerShapes.map((shape, i) => (
        <span className="segment-status-with-label">
            <span className="segment-status-icon">{shape}</span>
            {segmentStatusLabels[i]}
        </span>
    ))

    const markersWithoutLabels: JSX.Element[] = markerShapes.map((shape) => (
        <span className="segment-status-icon segment-status-icon-no-label">{shape}</span>
    ))

    const noApproval = <span className="dash-icon sl-fa-button">&mdash;</span>

    const options = [noApproval, ...markers]
    const currentOptionWithLabel = options[approved]

    const optionsWithoutLabels = [noApproval, ...markersWithoutLabels]
    const currentOptionWithoutLabel = optionsWithoutLabels[approved]
    const hasCorrectRole = iAmAdmin || iAmInterpreter
    const allowedToEdit = hasCorrectRole && enabled

    let member = project.members.find((mem) => mem.email === approvedBy)
    if (!member) {
        member = new Member(username)
    }

    // example: "Segment approved by email@gmail.com on 5-24-21 10:00"
    const approvedMessage = t('Segment approved by {{userEmail}} on {{dateString}}', {
        userEmail: member.email,
        dateString: dateFormatter.format(new Date(approvalDate))
    })

    const tooltip = <Tooltip id="segment-approval-tooltip">{isApproved ? approvedMessage : t('Approved?')}</Tooltip>

    return (
        <form className={`${allowedToEdit ? '' : 'segment-approval-not-allowed'} segment-approval-form-container`}>
            <OverlayTrigger overlay={tooltip} placement="bottom">
                <div>
                    {currentOptionWithoutLabel}
                    {currentOptionWithLabel.props.children[1]}
                </div>
            </OverlayTrigger>
            {hasCorrectRole && (
                <Dropdown>
                    <Dropdown.Toggle
                        title=""
                        id="segment-approval-dropdown"
                        className="sl-dropdown segment-approval-dropdown styled-dropdown-select"
                        disabled={!enabled}
                    />
                    <Dropdown.Menu className="styled-dropdown-select-menu">
                        {options.map((icon, i) => (
                            <Dropdown.Item
                                /* eslint-disable-next-line react/no-array-index-key */
                                key={i}
                                onClick={() => {
                                    if (allowedToEdit) {
                                        segment.setApproved(i, username)
                                    }
                                }}
                            >
                                <div className="segment-approval-option">{icon}</div>
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </form>
    )
})
