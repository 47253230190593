import React, { useEffect, useRef, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { CanvasDragAndClickDetector } from './VideoPositionBar'
import { AVTTRecordingState } from './VideoRecorder'
import { MediaSlice } from '../../models3/MediaSlice'
import { useResizeObserver } from '../utils/Hooks'
import { LoadingIcon } from '../utils/Icons'

function useGetCanvasSize(ref: React.RefObject<HTMLCanvasElement>) {
    const canvasSizeObservers = useResizeObserver(ref.current)
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)

    useEffect(() => {
        if (canvasSizeObservers.length > 0) {
            const content = canvasSizeObservers[0].contentRect
            setWidth(content.width)
            setHeight(content.height)
        }
    }, [canvasSizeObservers])

    return { width, height }
}

type SelectionLayerProps = {
    onClick: (x: number, y: number) => void
    onDrag: (startX: number, startY: number, currentX: number, currentY: number) => void
    onDragEnd: (startX: number, startY: number, endX: number, endY: number) => void
    className: string
}

export const SelectionLayer = observer(({ onClick, onDrag, onDragEnd, className }: SelectionLayerProps) => {
    const ref = useRef<HTMLCanvasElement>(null)
    const { width, height } = useGetCanvasSize(ref)

    useEffect(() => {
        const context = ref.current?.getContext('2d')
        if (!context) {
            return
        }

        const { canvas } = context
        canvas.width = width
        canvas.height = height
    })

    useEffect(() => {
        const canvasDragAndClickDetector = new CanvasDragAndClickDetector(ref, onClick, onDrag, onDragEnd)

        return () => {
            canvasDragAndClickDetector.dispose()
        }
    }, [width, height, onClick, onDrag, onDragEnd])

    return <canvas ref={ref} className={className} />
})

type CurrentPositionMarkerVisualizationProps = {
    currentPosition: number
    className: string
}

export const CurrentPositionMarkerVisualization = observer(
    ({ currentPosition, className }: CurrentPositionMarkerVisualizationProps) => {
        const ref = useRef<HTMLCanvasElement>(null)
        const { width, height } = useGetCanvasSize(ref)

        useEffect(() => {
            const context = ref.current?.getContext('2d')
            if (!context) {
                return
            }

            const { canvas } = context
            canvas.width = width
            canvas.height = height
            context.resetTransform()
            context.clearRect(0, 0, width, height)
            context.translate(0, height / 2) // make certain coordinate calculations easier
            context.lineWidth = 2
            context.strokeStyle = 'black'
            context.beginPath()
            context.moveTo(currentPosition, -height / 2)
            context.lineTo(currentPosition, height / 2)
            context.stroke()
        })

        return <canvas ref={ref} className={className} />
    }
)

type WaveformVisualizerProps = {
    waveformData: number[]
    className: string
    setWidth?: (width: number) => void
    setHeight?: (height: number) => void
}

export const WaveformVisualizer = observer(
    ({ waveformData, className, setWidth, setHeight }: WaveformVisualizerProps) => {
        const ref = useRef<HTMLCanvasElement>(null)
        const { width, height } = useGetCanvasSize(ref)

        useEffect(() => {
            setWidth?.(width)
        }, [width, setWidth])

        useEffect(() => {
            setHeight?.(height)
        }, [height, setHeight])

        useEffect(() => {
            function drawLineSegment(context: CanvasRenderingContext2D, x: number, y: number, lineWidth: number) {
                context.lineWidth = lineWidth
                context.strokeStyle = '#337ab7'
                context.beginPath()
                context.moveTo(x, y)
                context.lineTo(x, -y)
                context.stroke()
            }

            function drawWaveform(context: CanvasRenderingContext2D) {
                const barWidth = width / waveformData.length
                for (let i = 0; i < waveformData.length; i++) {
                    const x = barWidth * i
                    const barHeight = (waveformData[i] * height) / 2
                    drawLineSegment(context, x, barHeight, barWidth)
                }
            }

            function drawCenterLine(context: CanvasRenderingContext2D) {
                context.lineWidth = 1
                context.strokeStyle = '#337ab7'
                context.beginPath()
                context.moveTo(0, 0)
                context.lineTo(width, 0)
                context.stroke()
            }

            function draw() {
                const context = ref.current?.getContext('2d')
                if (!context) {
                    return
                }

                const { canvas } = context
                canvas.width = width
                canvas.height = height
                context.resetTransform()
                context.clearRect(0, 0, width, height)
                context.translate(0, height / 2) // make certain coordinate calculations easier
                drawCenterLine(context)
                drawWaveform(context)
            }

            draw()
        })

        return <canvas ref={ref} className={className} />
    }
)

type WatermarkProps = {
    className: string
    text: string
    blink?: boolean
}

// Memoize to prevent re-rendering unnecessarily when parent rerenders
const Watermark = React.memo(({ className, text, blink }: WatermarkProps) => {
    const ref = useRef<HTMLCanvasElement>(null)
    const { width, height } = useGetCanvasSize(ref)

    useEffect(() => {
        const context = ref.current?.getContext('2d')
        if (!context) {
            return
        }

        const { canvas } = context
        canvas.width = width
        canvas.height = height
        context.resetTransform()
        context.clearRect(0, 0, width, height)
        context.translate(0, height / 4) // make certain coordinate calculations easier

        context.font = '16px sans-serif'
        context.textBaseline = 'middle'
        if (blink) {
            context.fillStyle = '#953b3b' // same color as the red in the waveform visualizer
        }

        const measureText = context.measureText(text)
        const textWidth = measureText.actualBoundingBoxLeft + measureText.actualBoundingBoxRight
        const textStartX = (width - textWidth) / 2
        context.fillText(text, textStartX, 0)
    }, [height, text, width, blink])

    return <canvas ref={ref} className={`${className} ${blink ? 'blink' : ''}`} />
})

type LiveAudioWaveformProps = {
    mediaStream?: MediaStream
    className: string
    watermarkText?: string
    watermarkBlink?: boolean
}

export const LiveWaveformVisualizer = observer(
    ({ mediaStream, className, watermarkText, watermarkBlink }: LiveAudioWaveformProps) => {
        const dataArrayRef = useRef(new Uint8Array(0))
        const [analyzer, setAnaylzer] = useState<AnalyserNode>()
        const [waveformData, setWaveformData] = useState<number[]>([])

        // setup audio graph
        useEffect(() => {
            if (mediaStream) {
                const audioCtx = new AudioContext()
                const _analyzer = audioCtx.createAnalyser()
                const source = audioCtx.createMediaStreamSource(mediaStream)
                source.connect(_analyzer)

                _analyzer.fftSize = 2048
                const bufferLength = _analyzer.frequencyBinCount
                const _dataArray = new Uint8Array(bufferLength)

                setAnaylzer(_analyzer)
                dataArrayRef.current = _dataArray
            }
        }, [mediaStream])

        useEffect(() => {
            let requestAnimationFrameId = 0
            requestAnimationFrameId = requestAnimationFrame(() => {
                const data = []
                if (dataArrayRef.current) {
                    for (let i = 0; i < dataArrayRef.current.length; i++) {
                        // The byte time domain data has the range [0,255]. 0 is equivalent
                        // to -1 volts, 255 is equivalent to +1 volts, and 128 is 0 volts.
                        // Our waveform visualizer expects data in the range [0,1].
                        data.push(Math.abs((dataArrayRef.current[i] - 128.0) / 128.0))
                    }
                }

                setWaveformData(data)
            })

            analyzer?.getByteTimeDomainData(dataArrayRef.current)

            return () => {
                cancelAnimationFrame(requestAnimationFrameId)
            }
        })

        return (
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                <WaveformVisualizer waveformData={waveformData} className={className} />
                {watermarkText && <Watermark className={className} text={watermarkText} blink={watermarkBlink} />}
            </div>
        )
    }
)

interface LiveWaveformVisualizerWrapperProps {
    mediaStream?: MediaStream
    recordingState: AVTTRecordingState
    isAudioOnly: boolean
    className: string
}

export const LiveWaveformVisualizerWrapper = observer(
    ({ mediaStream, recordingState, isAudioOnly, className }: LiveWaveformVisualizerWrapperProps) => {
        const { t } = useTranslation()
        if (!isAudioOnly && !['RECORDING', 'PAUSED'].includes(recordingState)) {
            return null
        }

        if (recordingState === 'STOPPED') {
            return (
                <div className="video-message">
                    <LoadingIcon className="passage-recording-loading-icon" />
                    {t('Uploading...')}
                </div>
            )
        }

        let countdown = ''
        if (recordingState === 'RECORDING_IN_THREE_SECONDS') {
            countdown = '3'
        } else if (recordingState === 'RECORDING_IN_TWO_SECONDS') {
            countdown = '2'
        } else if (recordingState === 'RECORDING_IN_ONE_SECOND') {
            countdown = '1'
        }

        if (countdown) {
            return <div className="countdown">{countdown}</div>
        }

        // const watermarkText = isAudioOnly && recordingState === 'PAUSED' ? t('Paused') : ''

        let watermarkText
        let watermarkBlink = false
        if (isAudioOnly) {
            if (recordingState === 'PAUSED') {
                watermarkText = t('Paused')
            } else if (recordingState === 'RECORDING') {
                watermarkText = t('Recording...')
                watermarkBlink = true
            }
        }
        return <LiveWaveformVisualizer {...{ mediaStream, className, watermarkText, watermarkBlink }} />
    }
)

export class AudioContextFactory {
    private static audioContext?: AudioContext

    static getAudioContext() {
        if (!this.audioContext) {
            this.audioContext = new AudioContext({
                sampleRate: 44100
            })
        }
        return this.audioContext
    }
}
export class AudioWaveformCreator {
    numberOfSamples = 0

    private audioBufferCache = new Map<string, AudioBuffer>()

    async getNormalizedPCMValues(slices: MediaSlice[], numberOfSamples: number, startTime?: number, endTime?: number) {
        this.numberOfSamples = numberOfSamples
        let pcmValues = await this.getPCMValues(slices)
        if (pcmValues.length === 0 || slices.length === 0) {
            return { normalizedPCMValues: [], samplesPerSecond: 0 }
        }
        const sampleRate = await this.getSampleRate(slices[0].src)
        if (startTime !== undefined && endTime !== undefined) {
            pcmValues = pcmValues.slice(startTime * sampleRate, endTime * sampleRate)
        }
        const samplesPerSecond = numberOfSamples / (pcmValues.length / sampleRate)
        const filteredData = this.getAveragePCMValues(pcmValues)
        const normalizedPCMValues = this.normalizeData(filteredData)
        return { normalizedPCMValues, samplesPerSecond }
    }

    async getPCMValues(slices: MediaSlice[]) {
        const pcmValueSlices: Float32Array[] = []
        for (const slice of slices) {
            const data = await this.getPCM(slice.src)
            const sampleRate = await this.getSampleRate(slice.src)
            pcmValueSlices.push(data.slice(slice.startPosition * sampleRate, slice.endPosition * sampleRate))
        }
        return this.concatenateFloat32Arrays(pcmValueSlices)
    }

    private async getPCM(src: string) {
        try {
            const audioBuffer = await this.getAudioBuffer(src)
            const pcmValues = audioBuffer.getChannelData(0)
            return pcmValues
        } catch (error) {
            return new Float32Array([])
        }
    }

    private async getSampleRate(src: string) {
        try {
            const audioBuffer = await this.getAudioBuffer(src)
            return audioBuffer.sampleRate
        } catch (error) {
            return 1
        }
    }

    private async getAudioBuffer(src: string) {
        if (src.trim() === '') {
            throw new Error('Empty src')
        }

        if (this.audioBufferCache.has(src)) {
            const cachedCopy = this.audioBufferCache.get(src)
            if (cachedCopy) {
                return cachedCopy
            }
        }

        // Creating an AudioContext here causes a warning to be displayed in the console.
        // https://developers.google.com/web/updates/2017/09/autoplay-policy-changes#webaudio
        // Since we aren't using the AudioContext to play audio, we should be able
        // to ignore the warning.
        const audioContext = AudioContextFactory.getAudioContext()
        const response = await fetch(src)
        const arrayBuffer = await response.arrayBuffer()
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer)
        this.audioBufferCache.set(src, audioBuffer)
        return audioBuffer
    }

    private concatenateFloat32Arrays(arrays: Float32Array[]) {
        let fullArrayLength = 0
        for (const array of arrays) {
            fullArrayLength += array.length
        }

        const pcmValues = new Float32Array(fullArrayLength)
        let currentIndex = 0
        for (const array of arrays) {
            pcmValues.set(array, currentIndex)
            currentIndex += array.length
        }
        return pcmValues
    }

    private getAveragePCMValues(pcmValues: Float32Array) {
        const { numberOfSamples } = this
        const blockSize = pcmValues.length / numberOfSamples
        const filteredData = []
        for (let i = 0; i < numberOfSamples; i++) {
            const blockStart = Math.round(blockSize * i)
            let sum = 0
            for (let j = 0; j < blockSize; j++) {
                sum += Math.abs(pcmValues[blockStart + j])
            }
            let average = sum / blockSize
            if (!isFinite(average) || isNaN(average)) {
                average = 0
            }
            filteredData.push(average)
        }
        return filteredData
    }

    private normalizeData(array: number[]) {
        const multiplier = Math.max(...array) ** -1
        return array.map((n) => n * multiplier)
    }
}
