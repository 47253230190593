import { FC, createContext, useContext, useEffect, useState } from 'react'

import { IDBPDatabase, openDB } from 'idb'

import { useExegeticalResources } from './ExegeticalResourcesContext'
import {
    cacheExegeticalResourceInBook,
    cacheImagesInBook,
    cachePublishedBibleBook
} from '../../resources/cacheResources'
import { ExegeticalResourceBookIndex } from '../../resources/ExegeticalResources'
import { CacheIndexEntry, CacheRequest, CacheType, CachingProgress, ExportProgress } from '../../types'
import { exportTranslationResources } from '../projectSettings/projectResources/ResourceExporter'
import { nnn } from '../utils/Helpers'

export const TRANSLATION_RESOURCES_DATABASE_NAME = 'TranslationResources'

// Old clients may have cached resources that used "true" instead of a number to represent
// a version. True coerces to 1, which means they will think that the resource is up to date.
const MIN_RESOURCE_VERSION = 0

type TranslationResources = {
    getProgress: (request: CacheSingleBookRequest) => CachingProgress | undefined
    progress: Map<string, CachingProgress>
    requestResources: (request: CacheMultipleBooksRequest) => Promise<void>
    exportProgress: ExportProgress
}

export type CacheSingleBookRequest = CacheRequest & { bookNumber: number }

type CacheMultipleBooksRequest = CacheRequest & { bookNumbers: number[]; exportResources: boolean }

const getCacheKey = ({ bookNumber, bibleVersion, mediaType, language, cacheType }: CacheSingleBookRequest) =>
    [nnn(bookNumber), mediaType, bibleVersion?.id, cacheType === CacheType.EXEGETICAL_RESOURCES && language]
        .filter(Boolean)
        .join('-')

const getCacheFunction = (
    request: CacheSingleBookRequest,
    exegeticalResourceBookIndexes?: ExegeticalResourceBookIndex
) => {
    const { bookNumber, mediaType, cacheType, bibleVersion, language } = request

    if (cacheType === CacheType.IMAGES) {
        return async () => ({
            generatedAt: Date.now(),
            responses: await cacheImagesInBook({ bookNumber })
        })
    }

    if (!mediaType) {
        return
    }

    if (cacheType === CacheType.EXEGETICAL_RESOURCES && exegeticalResourceBookIndexes) {
        return async () => ({
            generatedAt: exegeticalResourceBookIndexes.generatedAt,
            responses: await cacheExegeticalResourceInBook({ language, bookNumber, mediaType })
        })
    }

    if (bibleVersion && cacheType === CacheType.BIBLES) {
        return async () => ({
            generatedAt: Date.now(),
            responses: await cachePublishedBibleBook({ bookNumber, bibleVersion, mediaType })
        })
    }
}

const TranslationResourceCachingContext = createContext<TranslationResources | undefined>(undefined)

export const TranslationResourceCachingProvider: FC = ({ children }) => {
    const [progress, setProgress] = useState<Map<string, CachingProgress>>(new Map())
    const [exportProgress, setExportProgress] = useState<ExportProgress>(ExportProgress.NOT_STARTED)
    const [db, setDb] = useState<IDBPDatabase<unknown>>()
    const { exegeticalResourceBookIndexes } = useExegeticalResources()

    useEffect(() => {
        if (!exegeticalResourceBookIndexes) {
            return
        }

        const getLatestVersion = (cacheType: CacheType) => {
            // We only version SRV resources for now
            if (cacheType === CacheType.EXEGETICAL_RESOURCES) {
                return exegeticalResourceBookIndexes.generatedAt
            }

            return MIN_RESOURCE_VERSION
        }

        const getItemProgress = (cacheType: CacheType, value: any) => {
            const latestVersion = getLatestVersion(cacheType)
            const cachedVersion = JSON.parse(value)
            return latestVersion > cachedVersion ? CachingProgress.UPDATE_AVAILABLE : CachingProgress.CACHED
        }

        const initialize = async () => {
            const newDb = await openDB(TRANSLATION_RESOURCES_DATABASE_NAME, 1, {
                upgrade(theDb) {
                    Object.values(CacheType).forEach((cache) => theDb.createObjectStore(cache))
                }
            })

            const getCacheProgress = async (cacheType: CacheType) => {
                const [keys, values] = await Promise.all([newDb.getAllKeys(cacheType), newDb.getAll(cacheType)])
                return keys.map((key, index) => {
                    const resourceProgress = getItemProgress(cacheType, values[index])
                    return { key, resourceProgress }
                })
            }

            const docs = (await Promise.all(Object.values(CacheType).map(getCacheProgress))).flat()
            const initialProgress = new Map(docs.map(({ key, resourceProgress }) => [key as string, resourceProgress]))

            setDb(newDb)
            setProgress(initialProgress)
        }
        initialize()
    }, [exegeticalResourceBookIndexes])

    const updateProgress = (request: CacheSingleBookRequest, value: CachingProgress) => {
        setProgress((prev) => new Map(prev.set(getCacheKey(request), value)))
    }

    const getProgress = (request: CacheSingleBookRequest) => progress.get(getCacheKey(request))

    // If this function throws, every valid response will be cached in the background,
    // but nothing will be returned
    const cacheSingleBook = async (request: CacheSingleBookRequest) => {
        if (!db) {
            return
        }

        const updateRequestProgress = (value: CachingProgress) => updateProgress(request, value)

        try {
            const { cacheType } = request
            const key = getCacheKey(request)
            const cacheFunction = getCacheFunction(request, exegeticalResourceBookIndexes)
            if (!cacheFunction) {
                updateRequestProgress(CachingProgress.ERROR)
                return
            }
            updateRequestProgress(CachingProgress.IN_PROGRESS)
            const response = await cacheFunction()
            const { generatedAt } = response
            db.put(cacheType, generatedAt, key)
            updateRequestProgress(CachingProgress.CACHED)
            return { ...response, key }
        } catch (error) {
            updateRequestProgress(CachingProgress.ERROR)
        }
    }

    const requestResources = async (multiRequest: CacheMultipleBooksRequest) => {
        const { cacheType, bookNumbers, exportResources, ...rest } = multiRequest

        if (exportResources) {
            setExportProgress(ExportProgress.NOT_STARTED)
        }

        const bookNumbersToCache = exportResources
            ? bookNumbers
            : bookNumbers.filter((bookNumber) => {
                  const cachingProgress = getProgress({ bookNumber, cacheType, ...rest })
                  return [undefined, CachingProgress.ERROR, CachingProgress.UPDATE_AVAILABLE].includes(cachingProgress)
              })

        const bookRequests = bookNumbersToCache.map((bookNumber) => ({ cacheType, bookNumber, ...rest }))

        for (const request of bookRequests) {
            updateProgress(request, CachingProgress.REQUESTED)
        }

        const cacheIndexEntries: CacheIndexEntry[] = []
        const uniqueResponses: Map<string, Response> = new Map()
        // Do these sequentially to avoid overwhelming the network
        for (const request of bookRequests) {
            const bookResponse = await cacheSingleBook(request)
            if (bookResponse) {
                const { responses, key, generatedAt } = bookResponse
                cacheIndexEntries.push({ key, generatedAt })
                for (const response of responses) {
                    uniqueResponses.set(response.url, response)
                }
            }
        }

        if (exportResources) {
            const responses = Array.from(uniqueResponses.values())
            try {
                setExportProgress(ExportProgress.IN_PROGRESS)
                await exportTranslationResources({ cacheType, responses, cacheIndexEntries, ...rest })
                setExportProgress(ExportProgress.FINISHED)
            } catch (error) {
                setExportProgress(ExportProgress.ERROR)
            }
        }
    }

    return (
        <TranslationResourceCachingContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                getProgress,
                progress,
                requestResources,
                exportProgress
            }}
        >
            {children}
        </TranslationResourceCachingContext.Provider>
    )
}

export const useTranslationResourceCaching = () => {
    const context = useContext(TranslationResourceCachingContext)
    if (!context) {
        throw new Error('useTranslationResourceCaching must be used within a TranslationResourceCachingProvider')
    }
    return context
}
