import { useEffect, useRef, useState } from 'react'

import { Passage } from '../../models3/Passage'
import { useAppRoot } from '../app/RootContext'

export function useOnClickOutside(ref: any, handler: () => void) {
    useEffect(
        () => {
            const listener = (event: any) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return
                }

                handler()
            }

            document.addEventListener('mousedown', listener)
            document.addEventListener('touchstart', listener)

            return () => {
                document.removeEventListener('mousedown', listener)
                document.removeEventListener('touchstart', listener)
            }
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler]
    )
}

export function useHoverDelay(mouseOver: boolean) {
    const [mouseOverTimer, setMouseOverTimer] = useState<number | null>(null)
    const [hideDelayTimer, setHideDelayTimer] = useState<number | null>(null)
    const [doAction, setDoAction] = useState(false)

    useEffect(() => {
        if (mouseOver) {
            if (!doAction && !mouseOverTimer) {
                const timer = window.setTimeout(() => setDoAction(true), 1000)
                setMouseOverTimer(timer)
            }

            if (hideDelayTimer) {
                clearTimeout(hideDelayTimer)
                setHideDelayTimer(null)
            }
        } else {
            if (mouseOverTimer) {
                clearTimeout(mouseOverTimer)
                setMouseOverTimer(null)
            }

            if (doAction && !hideDelayTimer) {
                const timer = window.setTimeout(() => setDoAction(false), 500)
                setHideDelayTimer(timer)
            }
        }
    }, [doAction, hideDelayTimer, mouseOver, mouseOverTimer])

    useEffect(() => {
        return () => {
            if (mouseOverTimer) {
                clearTimeout(mouseOverTimer)
                setMouseOverTimer(null)
            }

            if (hideDelayTimer) {
                clearTimeout(hideDelayTimer)
                setHideDelayTimer(null)
            }
        }
    }, [mouseOverTimer, hideDelayTimer])

    return doAction
}

export function useDebouncedValue(value: any, delayMs: number) {
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDebouncedValue(value)
        }, delayMs)

        return () => {
            clearTimeout(timeout)
        }
    })

    return debouncedValue
}

export function useResizeObserver(target: Element | null, options?: ResizeObserverOptions) {
    const [resizeObserverEntries, setResizeObserverEntries] = useState<readonly ResizeObserverEntry[]>([])
    const resizeObserverRef = useRef<ResizeObserver>()
    const timeoutRef = useRef<number>()

    useEffect(() => {
        let isMounted = true
        resizeObserverRef.current?.disconnect()
        resizeObserverRef.current = new ResizeObserver((entries) => {
            // Normally, we would not need to debounce the callback to a ResizeObserver.
            // For some reason, Cypress tests fail if we don't.
            if (timeoutRef.current !== undefined) {
                window.cancelAnimationFrame(timeoutRef.current)
            }

            if (isMounted) {
                timeoutRef.current = window.requestAnimationFrame(() => {
                    setResizeObserverEntries(entries)
                })
            }
        })

        setResizeObserverEntries([])
        if (target) {
            resizeObserverRef.current?.observe(target, options)
        }

        return () => {
            isMounted = false
            if (timeoutRef.current !== undefined) {
                cancelAnimationFrame(timeoutRef.current)
            }
        }
    }, [target, options])

    return resizeObserverEntries
}

export function useGetTrackedPassage(passage: Passage | null | undefined) {
    const [trackedPassage, setTrackedPassage] = useState<Passage | undefined>(undefined)

    const appRoot = useAppRoot()

    useEffect(() => {
        async function getChanges() {
            if (passage && passage.trackedPassageId.trim() !== '') {
                const trackedRt = appRoot.rts.find((r) => r.name === passage.trackedProjectName)
                await trackedRt?.initialize()
                setTrackedPassage(trackedRt?.project.findPassage(passage.trackedPassageId))
            } else {
                setTrackedPassage(undefined)
            }
        }

        getChanges()
    }, [passage, appRoot])

    return trackedPassage
}
