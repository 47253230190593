import { useTranslation } from 'react-i18next'

import { Switch } from '../../utils/Switch'

export const CombineToggle = ({ toggle, setToggle }: { toggle: boolean; setToggle: (value: boolean) => void }) => {
    const { t } = useTranslation()
    return (
        <>
            <div className="form-label">{t('Combine passages?')}</div>
            <Switch value={toggle} setValue={setToggle} tooltip={t('Combine passages before exporting?')} />
        </>
    )
}
