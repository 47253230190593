// Provides a button to click to clear video cache on next reload

import React, { useState, FC } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { LocalStorageKeys } from '../../app/slttAvtt'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IVideoCacheResetRequest {}

const VideoCacheResetRequest: FC<IVideoCacheResetRequest> = observer(() => {
    const { t } = useTranslation()

    const [showReloadMessage, setShowReloadMessage] = useState(false)

    const requestReset = () => {
        localStorage.setItem(LocalStorageKeys.CACHE_RESET_REQUEST, 'YES')
        setShowReloadMessage(true)
    }

    if (showReloadMessage) {
        return <div>{t('You MUST close and reopen this website or refresh page to make this take effect')}</div>
    }

    return (
        <div>
            <button type="button" className="btn btn-default" onClick={requestReset}>
                {t('recordingClearCache')}
            </button>
        </div>
    )
})

export default VideoCacheResetRequest
