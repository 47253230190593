import { displayableBookNames } from './bookNames'
import { refToBookId, refToChapterId, refToVerseId, refToPtxBookId } from './RefRange'
import { avttVersion } from '../components/app/slttAvtt'
import { Project } from '../models3/Project'

const USFM_VERSION = '3.0.1'
const USFM_CHAPTER_MARKER = '\\c'
const USFM_VERSE_MARKER = '\\v'

export const refToUsfmBookHeader = (ref: string, project: Project) => {
    const bookNumber = Number(refToBookId(ref)) - 1
    const paratextBookId = refToPtxBookId(ref)
    const bookName = displayableBookNames(project)[bookNumber]
    const timestamp = new Date().toISOString()

    const lines = [
        `\\id ${paratextBookId} ${timestamp}`,
        `\\ide UTF-8`,
        `\\usfm ${USFM_VERSION}`,
        `\\rem Exported from AVTT ${avttVersion}`,
        `\\h ${bookName}`,
        ''
    ]

    return lines.join('\n')
}

const usfmLabel = (marker: string, startId: string, endId: string) =>
    `${marker} ${Number(startId)}${startId !== endId ? `-${Number(endId)}` : ''}`

const refRangeToUsfmChapterLabel = (startRef: string, endRef: string) =>
    `${usfmLabel(USFM_CHAPTER_MARKER, refToChapterId(startRef), refToChapterId(endRef))}\n`

const refRangeToUsfmVerseLabel = (startRef: string, endRef: string) =>
    usfmLabel(USFM_VERSE_MARKER, refToVerseId(startRef), refToVerseId(endRef))

// Asssume book and chapter are the same for startRef and endRef
// TODO: Better check and warning messages for exceptions
export const refRangeToUsfmLabel = ({
    startRef,
    endRef,
    lastChapterId
}: {
    startRef: string
    endRef: string
    lastChapterId: string
}) => {
    if (!startRef || !endRef) {
        return ''
    }

    const chapter = refRangeToUsfmChapterLabel(startRef, endRef)
    const verse = refRangeToUsfmVerseLabel(startRef, endRef)

    return lastChapterId === refToChapterId(startRef) ? verse : `${chapter}${verse}`
}
