/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { useCallback, useEffect, useState } from 'react'

import { t } from 'i18next'
import { observer } from 'mobx-react'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'

import { ProjectPlanEditor } from './plan/ProjectPlanEditor'
import { ProgressGraphPage } from './progress/ProgressGraph'
import ProgressReport from './progress/ProgressReport'
import StatusBoardEditor from './StatusBoardEditor'
import { Passage } from '../../models3/Passage'
import { Portion } from '../../models3/Portion'
import { Root } from '../../models3/Root'
import ErrorBoundary from '../utils/Errors'

export enum TabsList {
    STATUS,
    PLAN,
    PROGRESS,
    GRAPH
}

interface IStatusEditor {
    rt: Root
    initialIndex?: TabsList
}

export type FilteredPortionPassages = {
    portion: Portion
    passages: Passage[]
}

const StatusTabs = observer(({ rt, initialIndex }: IStatusEditor) => {
    const [specificPortionSelected, setSpecificPortionSelected] = useState(false) // User has selected a portion, but not *Current Portion*

    const [specificPassageSelected, setSpecificPassageSelected] = useState(false) // User has selected a passage, but not *Current Passage*

    const [selectedPassage, setSelectedPassage] = useState<Passage>()

    const [selectedPortion, setSelectedPortion] = useState<Portion>()

    const [index, setIndex] = useState(initialIndex ?? TabsList.STATUS)

    const setStatusAndReset = useCallback(
        (
            statusKey:
                | 'statusShowAll'
                | 'statusShowAssignedPassages'
                | 'statusShowCurrentPortion'
                | 'statusShowCurrentPassage'
        ) => {
            setSpecificPassageSelected(false)
            setSpecificPortionSelected(false)
            setSelectedPortion(undefined)
            setSelectedPassage(undefined)
            rt.setStatus(statusKey, true)
        },
        [rt]
    )

    const setShowAll = () => setStatusAndReset('statusShowAll')
    const setShowAssignedPassages = () => setStatusAndReset('statusShowAssignedPassages')
    const setShowCurrentPortion = useCallback(() => setStatusAndReset('statusShowCurrentPortion'), [setStatusAndReset])
    const setShowCurrentPassage = () => setStatusAndReset('statusShowCurrentPassage')

    const setCurrentPassage = (portion: Portion, passage: Passage) => {
        setSpecificPassageSelected(true)
        setSpecificPortionSelected(false)
        setSelectedPortion(portion)
        setSelectedPassage(passage)
        rt.resetStatusFilter()
    }

    const setCurrentPortion = (portion: Portion) => {
        setSpecificPassageSelected(false)
        setSpecificPortionSelected(true)
        setSelectedPortion(portion)
        setSelectedPassage(undefined)
        rt.resetStatusFilter()
    }

    useEffect(() => {
        const { statusShowAll, statusShowAssignedPassages, statusShowCurrentPassage, statusShowCurrentPortion } = rt
        if (!statusShowAll && !statusShowAssignedPassages && !statusShowCurrentPassage && !statusShowCurrentPortion) {
            setShowCurrentPortion() // Default to current portion
        }
    }, [rt, setShowCurrentPortion])

    const { project, statusShowAll, statusShowAssignedPassages, statusShowCurrentPassage, statusShowCurrentPortion } =
        rt

    let portionPassages: FilteredPortionPassages[] = []
    let currentValue = ''
    if (statusShowAll) {
        currentValue = `*${t('Show All')}*`
        portionPassages = project.portions.map((portion) => ({ portion, passages: portion.passages }))
    } else if (statusShowAssignedPassages) {
        currentValue = `*${t('myPassages')}*`
        portionPassages = project.portions.map((portion) => ({
            portion,
            passages: portion.passages.filter((passage) => passage.assignee === rt.username)
        }))
    } else if (statusShowCurrentPassage) {
        currentValue = `*${t('Current Passage')}*`
        const portion = rt.portion!
        const passage = rt.passage!
        portionPassages = [
            {
                portion,
                passages: [passage]
            }
        ]
    } else if (statusShowCurrentPortion) {
        currentValue = `*${t('Current Portion')}*`
        const portion = rt.portion!
        portionPassages = [
            {
                portion,
                passages: portion.passages
            }
        ]
    } else if (specificPassageSelected) {
        currentValue = selectedPortion && selectedPassage ? selectedPortion.getLongPassageName(selectedPassage) : ''
        portionPassages = [
            {
                portion: selectedPortion!,
                passages: [selectedPassage!]
            }
        ]
    } else if (specificPortionSelected) {
        currentValue = selectedPortion!.name
        portionPassages = [
            {
                portion: selectedPortion!,
                passages: selectedPortion!.passages
            }
        ]
    }

    return (
        <Tabs selectedIndex={index} onSelect={setIndex}>
            <TabList>
                <Tab>{t('projectStatusHeading')}</Tab>
                <Tab>{t('projectPlanHeading')}</Tab>
                <Tab>{t('Progress')}</Tab>
                <Tab>{t('Graph')}</Tab>
            </TabList>

            <TabPanel>
                <ErrorBoundary>
                    <StatusBoardEditor
                        rt={rt}
                        currentSelectedOption={currentValue}
                        portionPassages={portionPassages}
                        setCurrentPassage={setCurrentPassage}
                        setCurrentPortion={setCurrentPortion}
                        setShowAll={setShowAll}
                        setShowAssignedPassages={setShowAssignedPassages}
                        setShowCurrentPortion={setShowCurrentPortion}
                        setShowCurrentPassage={setShowCurrentPassage}
                    />
                </ErrorBoundary>
            </TabPanel>
            <TabPanel>
                <ErrorBoundary>
                    <ProjectPlanEditor rt={rt} />
                </ErrorBoundary>
            </TabPanel>
            <TabPanel>
                <ErrorBoundary>
                    <ProgressReport rt={rt} />
                </ErrorBoundary>
            </TabPanel>
            <TabPanel>
                <ErrorBoundary>
                    <ProgressGraphPage
                        rt={rt}
                        currentSelectedOption={currentValue}
                        portionPassages={portionPassages}
                        setCurrentPassage={setCurrentPassage}
                        setCurrentPortion={setCurrentPortion}
                        setShowAll={setShowAll}
                        setShowAssignedPassages={setShowAssignedPassages}
                        setShowCurrentPortion={setShowCurrentPortion}
                        setShowCurrentPassage={setShowCurrentPassage}
                    />
                </ErrorBoundary>
            </TabPanel>
        </Tabs>
    )
})

export default StatusTabs
