import { getAllVersesInRange } from './AudioResource'
import { MAX_VERSE_COUNT, RefRange, refToBookId } from './RefRange'
import { inRangeInclusive, nnn } from '../components/utils/Helpers'
import API from '../models3/API'

const ENHANCED_RESOURCE_KEY_PREFIX = 'SLMARBLE/ers'

export class ERSpan {
    public type: 'text' | 'verse' | 'wg' | 'spacer' = 'text'

    public text?: string

    public style?: string

    public attributes?: any

    public book?: string

    public chapter?: string

    public verse?: string

    public id?: string

    constructor(span: any) {
        const { type, text, style, attributes, book, chapter, verse, id } = span
        this.type = type ?? 'text'
        this.text = text
        this.attributes = attributes
        this.book = book
        this.chapter = chapter
        this.verse = verse
        this.id = id

        if (style) {
            this.style = style
        }
    }

    includedIn(ref: RefRange) {
        const { book, chapter, verse } = this
        if (book && chapter && verse) {
            const { startBook, startChapter, startVerse, endChapter, endVerse } = ref.spread()

            const inBook = Number(book) === startBook
            const inChapterRange = inRangeInclusive(Number(chapter), startChapter, endChapter)
            if (!inBook || !inChapterRange) {
                return false
            }

            const versesInRange = getAllVersesInRange(verse)
            if (startChapter === endChapter) {
                return versesInRange.some((rangeVerse) => inRangeInclusive(Number(rangeVerse), startVerse, endVerse))
            }

            const inFirstChapter = Number(chapter) === startChapter
            if (inFirstChapter) {
                return versesInRange.some((rangeVerse) => Number(rangeVerse) >= startVerse)
            }

            const inLastChapter = Number(chapter) === endChapter
            if (inLastChapter) {
                return versesInRange.some((rangeVerse) => Number(rangeVerse) <= endVerse)
            }

            // in middle chapter
            return true
        }

        const startRefVVV = '000'
        const endRefVVV = nnn(MAX_VERSE_COUNT)
        let { startRef, endRef } = ref
        if (startRef.length === 6) startRef += startRefVVV
        if (endRef.length === 6) endRef += endRefVVV

        let { id } = this

        if (!id) return false

        id = id.slice(0, 9) // retain just bbbcccvvv
        return inRangeInclusive(Number(id), Number(startRef), Number(endRef))
    }

    getLexicalLinks() {
        const { attributes } = this
        const lexicalLinks: string = (attributes && attributes.lexical_links) || ''
        return lexicalLinks.split('|')
    }
}

export class ERDiv {
    public style = 'p' // s, p, q (from usx marker)

    public id = ''

    public bbbccc = '' // book number/chapter number for this div

    public chapter = ''

    public spans: ERSpan[] = []

    constructor(div: any) {
        const { style, id, spans, bbbccc, chapter } = div
        this.style = style ?? 'p'
        this.id = id ?? ''
        this.spans = spans ? spans.map((span: any) => new ERSpan(span)) : []
        this.bbbccc = bbbccc
        this.chapter = chapter
    }

    // Note: add more prefixes that indicate the text is a header
    public isHeader = () => ['r'].includes(this.style) || ['ms', 's'].some((prefix) => this.style.startsWith(prefix))
}

// Ensure that if any span in a div has an id that
// all spans in the div had an id.
// Spans at the beginning of the list without ids
// are given the first id found. After that spans
// without ids are given the id of the previous span.
const propagateSpanIds = (div: ERDiv) => {
    const span = div.spans.find((divSpan) => divSpan.id)
    if (!span) return

    let id = span.id || ''
    div.id = id

    div.spans.forEach((divSpan) => {
        if (divSpan.id) {
            id = divSpan.id
        } else {
            divSpan.id = id
        }
    })

    // Verse markers should always get the id of the next span. Example: NRS89 Ps 119:3
    // should get the id for 119:3, not 119:2.
    for (let i = div.spans.length - 2; i >= 0; --i) {
        if (div.spans[i].type === 'verse') {
            div.spans[i].id = div.spans[i + 1].id
        }
    }
}

const propagateChapterAndVerse = (erDivs: ERDiv[]) => {
    const divsWithSpans = erDivs.map((div) => {
        const currentBook = refToBookId(div.bbbccc)
        const currentChapter = div.chapter
        let previousVerse = ''
        const spans = div.spans.map((span) => {
            span.book = currentBook
            span.chapter = currentChapter
            if (span.verse) {
                previousVerse = span.verse
            } else {
                span.verse = previousVerse
            }
            return span
        })
        return { ...div, spans }
    })

    // assign each header's verse to be the same as the next verse that follows it
    return divsWithSpans.map((divWithSpans, divIndex) => {
        if (divWithSpans.isHeader()) {
            const nextNonHeaderDivIndex = divsWithSpans.findIndex(
                (div, i) => i > divIndex && !div.isHeader() && div.spans.length
            )
            if (nextNonHeaderDivIndex >= 0) {
                const verse = divsWithSpans[nextNonHeaderDivIndex].spans[0].verse
                const spans = divWithSpans.spans.map((span) => {
                    span.verse = verse
                    return span
                })
                return { ...divWithSpans, spans }
            }
        }
        return divWithSpans
    })
}

const propagateIds = (divs: ERDiv[]) => {
    divs.forEach((div) => {
        propagateSpanIds(div)

        // Give div the id of the first span
        if (div.spans.length) {
            div.id = div.spans[0].id || ''
        }
    })

    // for divs with empty ids, give them the id of the next div
    for (let i = divs.length - 2; i >= 0; --i) {
        if (!divs[i].id) divs[i].id = divs[i + 1].id
    }
}

export class EnhancedResources {
    static async fetchResponse(
        resource: string, // e.g. RVR60
        bbbccc: string // e.g. 001002 (Gen 2)
    ) {
        const resourceKey = `${ENHANCED_RESOURCE_KEY_PREFIX}/${resource}/${bbbccc}.json`
        const response = await API.getPrivateResource(resourceKey)

        if (!response.ok) {
            throw Error(`${response.url}: ${response.statusText}`)
        }

        return response
    }

    static async fetch(
        resource: string, // e.g. RVR60
        bbbccc: string // e.g. 001002 (Gen 2)
    ): Promise<ERDiv[]> {
        const response = await this.fetchResponse(resource, bbbccc)
        const _divs: any[] = await response.json()
        const divs: ERDiv[] = _divs.map((_div) => new ERDiv({ ..._div, bbbccc }))
        propagateIds(divs)
        propagateChapterAndVerse(divs)
        return divs
    }

    /**
     * Fetch divs matching refs.
     * Filter there spans to be only this included in refs.
     */
    static async fetchRefs(
        resource: string, // e.g. RVR60
        refs: RefRange[]
    ): Promise<ERDiv[]> {
        const divs: ERDiv[] = []

        for (const ref of refs) {
            await this.fetchRef(resource, ref, divs)
        }

        return divs
    }

    /**
     * Fetch all the divs for chapters in ref from the resource.
     * Any spans include in ref range are pused onto divs.
     */
    static async fetchRef(
        resource: string, // e.g. RVR60
        ref: RefRange,
        divs: ERDiv[]
    ): Promise<void> {
        for (const bbbccc of ref.chapterIterator()) {
            const _divs = await this.fetch(resource, bbbccc)

            for (const _div of _divs) {
                const spans = _div.spans.filter((span) => span.includedIn(ref))

                if (spans.length) {
                    divs.push(new ERDiv({ ..._div, spans }))
                }
            }

            if (divs.length) {
                const lastDiv = divs.slice(-1)[0]

                if (lastDiv.spans.length && lastDiv.spans.slice(-1)[0].type === 'verse') {
                    lastDiv.spans.splice(lastDiv.spans.length - 1, 1)
                }
            }
        }
    }
}
