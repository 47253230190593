import { observable } from 'mobx'

import { DBObject } from './DBObject'
import { SignVideo } from './SignVideo'
import { TargetGloss } from './TargetGloss'
import { remove } from './Utils'
import { MarbleLemmas } from '../resources/Lemmas'

export class ProjectTerm extends DBObject {
    lexicalLink = ''

    @observable isKeyTerm = false

    @observable glosses: TargetGloss[] = []

    @observable renderings: SignVideo[] = []

    @observable notes = ''

    private static model = 15

    toDocument() {
        const { lexicalLink, isKeyTerm, notes } = this
        return this._toDocument({ lexicalLink, isKeyTerm, notes, model: ProjectTerm.model })
    }

    async setNotes(notes: string) {
        if (notes.trim() === this.notes.trim()) {
            return
        }

        const doc = this._toDocument({ model: ProjectTerm.model, notes })
        await this.db.put(doc)
    }

    async setIsKeyTerm(isKeyTerm: boolean) {
        if (isKeyTerm === this.isKeyTerm) {
            return
        }

        const doc = this._toDocument({ model: ProjectTerm.model, isKeyTerm })
        await this.db.put(doc)
    }

    async addGloss(gloss: string) {
        if (this.glosses.find((g) => g.text === gloss.trim())) {
            return
        }
        const newId = this.db.getNewId(this.glosses, new Date(Date.now()), 'tgls_')
        const itemId = `${this._id}/${newId}`
        const newGloss = new TargetGloss(itemId, this.db)
        let rank = 100
        if (this.glosses.length) {
            rank = this.glosses.slice(-1)[0].rankAsNumber + 100
        }
        newGloss.rank = DBObject.numberToRank(rank)
        newGloss.lexicalLink = this.lexicalLink
        newGloss.text = gloss
        await this.db.put(newGloss.toDocument())
        return this.glosses.find((g) => g._id === newGloss._id)
    }

    createRendering() {
        const newId = this.db.getNewId(this.renderings, new Date(Date.now()), 'sign_')
        const itemId = `${this._id}/${newId}`
        const sign = new SignVideo(itemId, this.db)

        let rank = 100
        if (this.renderings.length > 0) {
            rank = this.renderings.slice(-1)[0].rankAsNumber + 100
        }
        sign.rank = DBObject.numberToRank(rank)
        return sign
    }

    async addRendering(rendering: SignVideo) {
        for (const _rendering of this.renderings) {
            await _rendering.delete()
        }
        await this.db.put(rendering.toDocument())
    }

    /** Is this Hebrew or Greek term used in a range of verses? It is assumed that bbbcccvvvs are in the "Original" versification */
    doesOccurInVerses(bbbcccvvvs: string[]) {
        const lemma = this.getMarbleLemma()
        if (!lemma) {
            return false
        }

        const lexMeaning = lemma.meanings.find((meaning) => meaning.lexicalLink === this.lexicalLink)
        if (!lexMeaning) {
            return false
        }

        for (let i = 0; i < lexMeaning.references.length; i++) {
            for (let j = 0; j < bbbcccvvvs.length; j++) {
                if (lexMeaning.references[i] === bbbcccvvvs[j]) {
                    return true
                }
            }
        }

        return false
    }

    getMarbleLemma() {
        return MarbleLemmas.get(this.lexicalLink)
    }

    async removeGloss(_id: string) {
        await remove(this.glosses, _id)
    }
}
