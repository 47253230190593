import { useState } from 'react'

import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import API from '../../models3/API'
import { ReviewProjectGeneralQuestion } from '../../models3/ReviewProjectGeneralQuestion'
import { ReviewRecordingPlayerAndRecorder } from '../audio/ReviewRecordingPlayerAndRecorder'
import { CancelButton, OKButton } from '../utils/Buttons'
import { AudioEncodeType, encodeAudio } from '../utils/DownloadPassage'
import { currentTimestampSafeString } from '../utils/Helpers'
import { LoadingIcon } from '../utils/Icons'
import { WarningList } from '../utils/WarningList'

import './ProjectReview.css'

const GeneralQuestionsTextEditor = ({ text, setText }: { text: string; setText: (text: string) => void }) => {
    return <textarea value={text} onChange={(e) => setText(e.target.value)} className="general-questions-text-editor" />
}

export const GeneralQuestionEditor = ({
    avttProjectName,
    question,
    closeModal
}: {
    avttProjectName: string
    question: ReviewProjectGeneralQuestion
    closeModal: () => void
}) => {
    const [text, setText] = useState(question.text)
    const [fileName, setFileName] = useState(question.fileName)
    const [recordedBlobs, setRecordedBlobs] = useState<Blob[]>([])
    const [isSaveError, setIsSaveError] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { t } = useTranslation()

    const okEnabled = Boolean(text.trim() || fileName || recordedBlobs.length)
    const isCreate = question.text === '' && question.fileName === ''

    const saveAndClose = async () => {
        try {
            setIsSubmitting(true)
            if (recordedBlobs.length) {
                const newFileName = `${question._id.split('/')[1]}-${currentTimestampSafeString()}.mp3`
                const encodedRecording = await encodeAudio(
                    new Blob(recordedBlobs, { type: recordedBlobs[0].type }),
                    AudioEncodeType.mp3
                )
                await API.pushReviewRecording(avttProjectName, newFileName, encodedRecording)
                await question.setValues(text, newFileName)
            } else {
                await question.setValues(text, fileName)
            }
            closeModal()
            setIsSubmitting(false)
        } catch (error) {
            setIsSaveError(true)
            setIsSubmitting(false)
        }
    }

    return (
        <Modal show onHide={closeModal} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t(isCreate ? 'createQuestion' : 'editQuestion')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isSubmitting ? (
                    <div className="general-question-submitting">
                        <LoadingIcon className="" />
                        <span className="general-question-submitting-message">
                            {t(isCreate ? 'addingQuestion' : 'savingQuestion')}
                        </span>
                    </div>
                ) : (
                    <>
                        <div className="general-question-text-area-wrapper">
                            <strong>{t('question')}</strong>
                            <GeneralQuestionsTextEditor text={text} setText={setText} />
                        </div>
                        <strong>{t('Audio')}</strong>
                        <div className="audio-player-recorder-wrapper">
                            <ReviewRecordingPlayerAndRecorder
                                url={fileName}
                                setUrl={setFileName}
                                recordedBlobs={recordedBlobs}
                                setRecordedBlobs={setRecordedBlobs}
                                avttProjectName={avttProjectName}
                            />
                        </div>
                    </>
                )}
                {isSaveError && (
                    <div className="general-question-warnings-list-wrapper">
                        <WarningList
                            warnings={[
                                {
                                    text: t('saveErrorNoInternet'),
                                    key: 'saveErrorNoInternet'
                                }
                            ]}
                        />
                    </div>
                )}
            </Modal.Body>
            {!isSubmitting && (
                <Modal.Footer>
                    <div className="project-review-button-row button-row">
                        <OKButton
                            enabled={okEnabled}
                            onClick={async () => {
                                await saveAndClose()
                            }}
                            buttonClassName=""
                            className="ok-button"
                            tooltip={t('OK')}
                        />
                        <CancelButton enabled onClick={closeModal} className="cancel-button" tooltip={t('Cancel')} />
                    </div>
                </Modal.Footer>
            )}
        </Modal>
    )
}
