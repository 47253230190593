import { useEffect, useState } from 'react'

import {
    DndContext,
    closestCorners,
    KeyboardSensor,
    PointerSensor,
    useDroppable,
    useSensor,
    useSensors
} from '@dnd-kit/core'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import { SortableContext, arrayMove, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { observer } from 'mobx-react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { RecordingLayoutSortableItem as ProfileAttributeSortableItem } from './ProfileAttributeSortableItem'
import { ReviewProject } from '../../models3/ReviewProject'
import { ReviewProjectProfileAttribute } from '../../models3/ReviewProjectProfileAttribute'
import { move } from '../../models3/Utils'
import { AddButton, CancelButton, DeleteButton, OKButton } from '../utils/Buttons'
import { GenericIcon } from '../utils/Icons'
import { Switch } from '../utils/Switch'
import { SimpleTextInput } from '../utils/TextInput'

import './ProjectReview.css'

type LayoutContainer = 'reviewProjectProfileAttributes'

interface ProfileAttributeEditorProps {
    attribute: ReviewProjectProfileAttribute
    closeModal: () => void
}

const ProfileAttributeEditor = ({ attribute, closeModal }: ProfileAttributeEditorProps) => {
    const [label, setLabel] = useState(attribute.label)
    const [responseType, setResponseType] = useState(attribute.responseType)
    const [choices, setChoices] = useState(attribute.options)
    const { t } = useTranslation()

    const validate = (text: string) => {
        if (!text.trim()) {
            return t('Empty name')
        }
        return ''
    }

    const labelError = validate(label)

    const choiceErrors = choices.map((choice) => validate(choice.text))

    let okEnabled = true
    if (labelError) {
        okEnabled = false
    }
    if (responseType === 'select') {
        if (choiceErrors.some((error) => error.trim())) {
            okEnabled = false
        }
        if (choices.length < 2) {
            okEnabled = false
        }
    }

    const addChoice = () => {
        setChoices([...choices, attribute.createNewResponseOption()])
    }

    const deleteChoice = (id: string) => {
        setChoices(choices.filter((choice) => choice.id !== id))
    }

    const setChoiceText = (text: string, id: string) => {
        const newChoices = choices.map((choice) => {
            if (choice.id === id) {
                return { ...choice, text }
            }
            return choice
        })
        setChoices(newChoices)
    }

    return (
        <Modal show onHide={closeModal} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t('reviewProjectProfileAttributeEditorHeader')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="pr-text-modal-field">
                    <div className="text-input-with-label">
                        <GenericIcon
                            iconName="fa-tag"
                            className="project-review-button default-blue-icon icon-spacer"
                            tooltip={t('Label')}
                        />
                        <SimpleTextInput value={label} setValue={setLabel} errorMessage={labelError} />
                    </div>
                </div>
                <div className="pr-text-modal-field">
                    <Switch
                        value={responseType === 'select'}
                        setValue={(value) => {
                            if (value) {
                                setResponseType('select')
                            } else {
                                setResponseType('text')
                                setChoices([])
                            }
                        }}
                        className="profile-response-type"
                    >
                        <GenericIcon
                            iconName="fa-list"
                            className="project-review-button default-blue-icon icon-spacer"
                            tooltip={t('reviewProjectProfilePredefinedChoices')}
                        />
                    </Switch>
                </div>
                {responseType === 'select' && (
                    <table className="attribute-choice-table table table-striped">
                        <tbody>
                            {choices.map((choice, index) => (
                                <tr key={choice.id}>
                                    <td>
                                        <SimpleTextInput
                                            value={choice.text}
                                            setValue={(value) => {
                                                setChoiceText(value, choice.id)
                                            }}
                                            errorMessage={choiceErrors[index]}
                                        />
                                    </td>
                                    <td>
                                        <DeleteButton
                                            className="project-review-button"
                                            buttonClassName="choices-table-action-column"
                                            tooltip={t('Delete')}
                                            enabled
                                            onClick={() => {
                                                deleteChoice(choice.id)
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td>
                                    <AddButton
                                        className="default-blue-icon project-review-button no-padding"
                                        enabled
                                        onClick={() => {
                                            addChoice()
                                        }}
                                        tooltip={t('reviewProjectCreateProfileAttributeChoice')}
                                    />
                                </td>
                                <td />
                            </tr>
                        </tbody>
                    </table>
                )}
            </Modal.Body>
            <Modal.Footer>
                <div className="project-review-modal-footer button-row">
                    <OKButton
                        enabled={okEnabled}
                        onClick={() => {
                            attribute.setValues({ label, options: choices, responseType })
                            closeModal()
                        }}
                        buttonClassName=""
                        className="ok-button"
                        tooltip={t('OK')}
                    />
                    <CancelButton enabled onClick={closeModal} className="cancel-button" tooltip={t('Cancel')} />
                </div>
            </Modal.Footer>
        </Modal>
    )
}

const ProfileAttributesDroppableArea = ({
    id,
    items,
    project,
    editAttribute
}: {
    id: LayoutContainer
    items: string[]
    project: ReviewProject
    editAttribute: (attribute: ReviewProjectProfileAttribute) => void
}) => {
    const { setNodeRef } = useDroppable({ id })
    const { t } = useTranslation()
    return (
        <SortableContext id={id} items={items} strategy={verticalListSortingStrategy}>
            <div
                ref={setNodeRef}
                className={`profile-attribute-sortable-container ${
                    !project.profileAttributes.length ? 'profile-attribute-sortable-container-no-items' : ''
                }`}
            >
                {project.profileAttributes.map((attribute) => (
                    <ProfileAttributeSortableItem
                        key={attribute._id}
                        attribute={attribute}
                        editItem={() => editAttribute(attribute)}
                    />
                ))}
                <AddButton
                    className="default-blue-icon project-review-button no-padding"
                    enabled
                    onClick={() => {
                        const attribute = project.createProfileAttribute(new Date(Date.now()))
                        editAttribute(attribute)
                    }}
                    tooltip={t('reviewProjectCreateProfileAttribute')}
                />
                {!project.profileAttributes.length && (
                    <span className="profile-attribute-sortable-help-info">
                        {t('addProjectEngagementProfileAttributes')}
                    </span>
                )}
            </div>
        </SortableContext>
    )
}

interface ProfileAttributesViewProps {
    project: ReviewProject
}

export const ProfileAttributesView = observer(({ project }: ProfileAttributesViewProps) => {
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [currentAttribute, setCurrentAttribute] = useState<ReviewProjectProfileAttribute>()
    const { t } = useTranslation()

    const { profileAttributes } = project

    const defaultItems: { [key: string]: string[] } = {
        profileAttributeEditor: profileAttributes.map((attribute) => attribute._id)
    }

    // I tried to derive this instead of storing it in state. It caused the dragging animations
    // to behave differently.
    const [items, setItems] = useState(defaultItems)

    useEffect(() => {
        setItems({
            profileAttributeEditor: project.profileAttributes.map((attribute) => attribute._id)
        })
    }, [project.profileAttributes])

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    )

    const findContainer = (id: string) => {
        if (id in items) {
            return id
        }

        return Object.keys(items).find((key: string) => items[key].includes(id))
    }

    const handleDragEnd = async (event: any) => {
        const { active, over } = event
        const { id } = active
        const { id: overId } = over

        const activeContainer = findContainer(id)
        const overContainer = findContainer(overId)

        if (!activeContainer || !overContainer || activeContainer !== overContainer) {
            return
        }

        const activeIndex = items[activeContainer].indexOf(id)
        const overIndex = items[overContainer].indexOf(overId)
        if (activeIndex !== overIndex) {
            const newItems = {
                ...items,
                [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex)
            }
            setItems(newItems)
            await move(profileAttributes, activeIndex, overIndex)
        }
    }

    return (
        <div className="profile-attributes-table">
            <GenericIcon
                iconName="fa-users-cog"
                className="default-blue-icon project-review-button icon-spacer fa-fw"
                tooltip={t('reviewProjectProfileAttributes')}
            />
            <div className="profile-attribute-preferences">
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCorners}
                    modifiers={[restrictToParentElement]}
                    onDragEnd={handleDragEnd}
                >
                    <ProfileAttributesDroppableArea
                        id="reviewProjectProfileAttributes"
                        items={items.profileAttributeEditor}
                        project={project}
                        editAttribute={(attribute) => {
                            setCurrentAttribute(attribute)
                            setIsEditModalOpen(true)
                        }}
                    />
                </DndContext>
            </div>
            {isEditModalOpen && currentAttribute && (
                <ProfileAttributeEditor
                    attribute={currentAttribute}
                    closeModal={() => {
                        setIsEditModalOpen(false)
                        setCurrentAttribute(undefined)
                    }}
                />
            )}
        </div>
    )
})
