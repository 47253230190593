const getDataURL = async (file: File): Promise<string> => {
    const reader = new FileReader()
    return new Promise((resolve, reject) => {
        reader.onerror = reject
        reader.onload = (e: any) => resolve(e.target.result)
        reader.readAsDataURL(file)
    })
}

export type Rectangle = {
    width: number
    height: number
}

const getScalingFactor = (existingSize: Rectangle, desiredSize: Rectangle) => {
    const largestDimension = Math.max(existingSize.width, existingSize.height)
    const smallestDesiredDimension = Math.min(desiredSize.width, desiredSize.height)
    return largestDimension / smallestDesiredDimension
}

// Maintain aspect ratio
export const scaleToFit = (existingSize: Rectangle, desiredSize: Rectangle): Rectangle => {
    const scalingFactor = Math.max(1, getScalingFactor(existingSize, desiredSize))
    return { width: existingSize.width / scalingFactor, height: existingSize.height / scalingFactor }
}

export const resizeImage = async (file: File, width: number, height: number): Promise<string> => {
    return new Promise((resolve, reject) => {
        const image = new Image()

        getDataURL(file).then((dataURL: string) => {
            image.src = dataURL
        })

        image.onload = () => {
            const desiredSize: Rectangle = { width, height }
            const existingSize: Rectangle = { width: image.width, height: image.height }
            const newSize = scaleToFit(existingSize, desiredSize)

            const canvas = document.createElement('canvas')
            canvas.width = newSize.width
            canvas.height = newSize.height
            const ctx = canvas.getContext('2d')
            ctx?.drawImage(image, 0, 0, newSize.width, newSize.height)
            const compressedDataURL = ctx?.canvas.toDataURL(file.type)
            resolve(compressedDataURL || '')
        }
        image.onerror = reject
    })
}
