/* eslint-disable max-classes-per-file */
import { fmt } from '../components/utils/Fmt'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:ELANReader')

async function getText(file: File): Promise<string> {
    const reader = new FileReader()
    return new Promise((resolve) => {
        reader.onload = (e: any) => resolve(e.target.result)
        reader.readAsText(file)
    })
}

export class EAFItem {
    constructor(public position: number, public endPosition: number, public text: string) {}
}

export class EAFTier {
    constructor(public id: string, public items: EAFItem[]) {}
}

function elementByName(root: Element, name: string) {
    const elements = [...root.getElementsByTagName(name)]
    if (elements.length !== 1) {
        throw Error()
    }

    return elements[0]
}

function getAttribute(element: Element, name: string) {
    const value = element.getAttribute(name)
    if (value === null) {
        throw Error(`Attribute missing: ${name}`)
    }

    return value
}

type Timeslots = Map<string, number>

function getTimeslots(doc: Document) {
    const timeOrder = elementByName(doc.documentElement, 'TIME_ORDER')
    const slots = [...timeOrder.getElementsByTagName('TIME_SLOT')]
    if (slots.length === 0) {
        throw Error('No TIME_SLOTs present')
    }

    const timeslots = new Map<string, number>()
    slots.forEach((slot) => {
        const id = getAttribute(slot, 'TIME_SLOT_ID')
        const value = getAttribute(slot, 'TIME_VALUE')

        timeslots.set(id, parseInt(value) / 1000)
    })

    return timeslots
}

function convertEAFAnnotation(timeslots: Timeslots, annotation: Element) {
    const alignable = elementByName(annotation, 'ALIGNABLE_ANNOTATION')
    const ts1 = getAttribute(alignable, 'TIME_SLOT_REF1')
    const ts2 = getAttribute(alignable, 'TIME_SLOT_REF2')

    const position = timeslots.get(ts1)
    if (position === undefined) throw Error(`Timeslot missing: ${ts1}`)
    const endPosition = timeslots.get(ts2)
    if (endPosition === undefined) throw Error(`Timeslot missing: ${ts2}`)

    const text = elementByName(annotation, 'ANNOTATION_VALUE').textContent ?? ''

    // log('convertEAFAnnotation', fmt({position, endPosition, text}))
    return new EAFItem(position, endPosition, text)
}

function convertEAFTier(timeslots: Timeslots, tier: Element) {
    const id = getAttribute(tier, 'TIER_ID')

    const annotations = [...tier.getElementsByTagName('ANNOTATION')]
    log('convertEAFTier', fmt({ id, annnotations: annotations.length }))

    return new EAFTier(
        id,
        annotations.map((annotation) => convertEAFAnnotation(timeslots, annotation))
    )
}

function convertEAFDoc(doc: Document) {
    const timeslots = getTimeslots(doc)

    const tiers = [...doc.getElementsByTagName('TIER')]

    return tiers.map((tier) => convertEAFTier(timeslots, tier))
}

/*
 * Read .eaf file and convert it to tier objects.
 * Will throw an Error if file cannot be read and parsed.
 */
export async function readEAFFile(file: File): Promise<EAFTier[]> {
    log('readEAFFile', file.name)

    const xmlText = await getText(file)

    const domParser = new DOMParser()
    const doc = domParser.parseFromString(xmlText, 'text/xml')

    return convertEAFDoc(doc)
}
