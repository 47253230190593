import { useState, useEffect } from 'react'

const getBlobFromUrl = async (url: string) => {
    const response = await fetch(url)
    if (!response.ok) {
        throw Error('Network response was not ok')
    }
    return response.blob()
}

export type FetchBlobParams = string | (() => Promise<Blob>)

export const useFetchBlob = (fetchBlob: FetchBlobParams) => {
    const [blobUrl, setBlobUrl] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    useEffect(() => {
        let didCancel = false
        let objectUrl = ''
        setIsLoading(true)
        setIsError(false)

        const fetchData = async () => {
            try {
                const blob = typeof fetchBlob === 'string' ? await getBlobFromUrl(fetchBlob) : await fetchBlob()
                if (!didCancel) {
                    objectUrl = URL.createObjectURL(blob)
                    setBlobUrl(objectUrl)
                    setIsLoading(false)
                }
            } catch (error) {
                if (!didCancel) {
                    setIsError(true)
                    setIsLoading(false)
                }
            }
        }

        fetchData()

        return () => {
            didCancel = true

            if (objectUrl) {
                URL.revokeObjectURL(objectUrl)
            }
        }
    }, [fetchBlob])

    return { blobUrl, isError, isLoading }
}
