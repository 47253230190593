import { observable } from 'mobx'

import { DBObject } from './DBObject'

export class AudioClip extends DBObject {
    @observable url = ''

    @observable duration = 0

    toDocument(useExistingModDate?: boolean) {
        const { url, duration } = this
        return this._toDocument({ url, duration, model: 12 }, useExistingModDate)
    }

    copy() {
        let copy = new AudioClip(this._id, this.db)
        copy = Object.assign(copy, this)
        return copy
    }

    async setURL(url: string) {
        if (this.url === url.trim()) {
            return
        }

        const doc = this._toDocument({ url, model: 12 })
        await this.db.put(doc)
    }
}
