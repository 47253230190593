import { useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import API from '../../../models3/API'
import { Root } from '../../../models3/Root'
import { useOnlineStatus } from '../../app/OnlineStatusContext'
import { displaySuccess } from '../../utils/DynamicErrors'
import { displayError } from '../../utils/Errors'
import { LoadingIcon } from '../../utils/Icons'
import TextInput from '../../utils/TextInput'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:MemberAdder')

interface IMemberAdder {
    rt: Root
}

const MemberAdder = observer(({ rt }: IMemberAdder) => {
    const { t, i18n } = useTranslation()
    const { isOnline, checkIsOnline } = useOnlineStatus()

    const [adding, setAdding] = useState(false)
    const [loading, setLoading] = useState(false)

    const { project, iAmAdmin } = rt

    if (!iAmAdmin || !isOnline) {
        return null
    }

    if (loading) {
        return (
            <tr>
                <td>
                    <LoadingIcon className="initialization-message-icon" />
                </td>
            </tr>
        )
    }

    if (!adding)
        return (
            <tr>
                <td>
                    {' '}
                    <button
                        type="button"
                        className="add-user"
                        onClick={() => {
                            setAdding(true)
                        }}
                    >
                        {t('Add User')}
                    </button>{' '}
                </td>
            </tr>
        )

    async function onEnter(email: string) {
        if (!project.canAddMember(email)) {
            // canAddMember returns string. '' means we can add member.
            setLoading(true)
            try {
                try {
                    if (!(await checkIsOnline())) {
                        throw Error(t('offlineActionError'))
                    }
                    await API.addCognitoUser(project.name, email, i18n.language)
                    displaySuccess(
                        t('User {{email}} has been created, and a temporary password has been sent to it.', { email })
                    )
                } catch (err) {
                    const error = err as Error
                    if (error.message.includes('UsernameExistsException')) {
                        log(`User ${email} exists already, so not added`)
                    } else {
                        throw error
                    }
                }

                await project.addMember(email)
            } catch (error) {
                displayError(error)
            } finally {
                setLoading(false)
            }
        }
        setAdding(false)
    }

    return (
        <tr>
            <td>
                <div className="passage-box">
                    <TextInput
                        type="email"
                        message={t('Type Enter to add new member or Esc to cancel.')}
                        initialValue=""
                        validate={project.canAddMember}
                        _onEscape={() => setAdding(false)}
                        _onEnter={onEnter}
                    />
                </div>
            </td>
        </tr>
    )
})

export default MemberAdder
