import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { PublishedBible } from '../../../types'
import { BibleTranslationTitle } from '../../enhancedResources/BibleTranslationTitle'
import { Switch } from '../../utils/Switch'

import '../ProjectSettings.css'

type VisibleBiblesTableProps = {
    filteredBibles: PublishedBible[]
    visibleBibleIds: Set<string>
    handleSwitch: (value: boolean, bibleId: string) => void
}

export const VisibleBiblesTable = ({ filteredBibles, visibleBibleIds, handleSwitch }: VisibleBiblesTableProps) => {
    const { t } = useTranslation()
    return (
        <div className="bibles-table-container">
            <Table striped>
                <tbody>
                    {filteredBibles.map((bible) => {
                        const isVisible = visibleBibleIds.size ? visibleBibleIds.has(bible.id) : true
                        const className = isVisible ? '' : 'hidden-item'
                        return (
                            <tr key={bible.id}>
                                <td className={`bibles-table__bible-language-col ${className}`}>
                                    {bible.language.name}
                                </td>
                                <td className={className}>
                                    <BibleTranslationTitle bible={bible} />
                                </td>
                                <td className="bibles-table__show-hide-col">
                                    <Switch
                                        value={isVisible}
                                        setValue={(value) => handleSwitch(value, bible.id)}
                                        tooltip={t('showOrHide')}
                                    />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </div>
    )
}
