export class PassageSegmentGloss {
    constructor(
        public identity: string, // email of user or A B C D
        public gloss: string
    ) {}

    dbg() {
        const type = this.constructor.name
        const { identity, gloss } = this
        const doc = { type, identity, gloss }
        return doc
    }

    copy() {
        return new PassageSegmentGloss(this.identity, this.gloss)
    }
}
