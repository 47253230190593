import { FC, useState } from 'react'

import { observer } from 'mobx-react'
import { confirmAlert } from 'react-confirm-alert'
import { useTranslation } from 'react-i18next'

import { ProjectStage } from '../../../models3/ProjectStage'
import { ProjectTask } from '../../../models3/ProjectTask'
import { Root } from '../../../models3/Root'
import { EditDifficultyButton } from '../../utils/Buttons'
import TextInput from '../../utils/TextInput'
import DifficultyView from '../DifficultyView'

import './EditableProjectTask.css'

interface ITaskDetailsMenu {
    rt: Root
    setEditingDetails: (editing: boolean) => void
}

const TaskDetailsMenu: FC<ITaskDetailsMenu> = ({ rt, setEditingDetails }) => {
    const { t } = useTranslation()

    if (!rt.iAmAdmin) return null // only admins can edit task details

    return (
        <div className="task-details-menu">
            <span
                data-toggle="tooltip"
                data-id={`edit-details-${name}`}
                title={t('Change details.')}
                onClick={(e) => {
                    e.preventDefault()
                    setEditingDetails(true)
                }}
            >
                <i className="fas fa-fw fa-asterisk" />
            </span>
        </div>
    )
}

interface ITaskMenu {
    rt: Root
    task: ProjectTask
    setEditingName: (editing: boolean) => void
    setEditingDetails: (editing: boolean) => void
    setEditingDifficulty: (editing: boolean) => void
    deleteTask: () => void
}

const TaskMenu: FC<ITaskMenu> = ({ rt, task, setEditingName, setEditingDetails, setEditingDifficulty, deleteTask }) => {
    const { t } = useTranslation()

    if (!rt.iAmAdmin || rt.useMobileLayout) return null

    function confirmDeletion(doDeletion: () => void) {
        confirmAlert({
            title: t('Delete task?'),
            message: t('Are you sure you want to delete task {{taskName}}?', { taskName: task.displayedName }),
            buttons: [
                {
                    label: t('Keep task'),
                    onClick: () => {}
                },
                {
                    label: t('Delete task'),
                    onClick: doDeletion
                }
            ]
        })
    }

    return (
        <div className="task-menu">
            <span
                className="portion-button"
                data-toggle="tooltip"
                data-id={`edit-${name}`}
                title={t('Rename task.')}
                onClick={(e) => {
                    e.preventDefault()
                    setEditingName(true)
                }}
            >
                <i className="fa fa-fw fa-pencil-alt" />
            </span>
            <span
                className="portion-button"
                data-toggle="tooltip"
                data-id={`delete-${name}`}
                title={t('Delete task')}
                onClick={(e) => {
                    e.preventDefault()
                    confirmDeletion(() => {
                        deleteTask()
                    })
                }}
            >
                <i className="fa fa-fw fa-trash-alt" />
            </span>
            <EditDifficultyButton
                enabled
                onClick={() => setEditingDifficulty(true)}
                className="portion-button clickable"
                tooltip={`${t('difficultyLevel')}: ${task.difficulty}.\n${t('Click to change.')}`}
            />
            {!task.details && (
                // If there are not details yet for this task we show the edit details button with
                // the other menu items. Otherwise we show the edit details button beside the details.
                <TaskDetailsMenu {...{ rt, setEditingDetails }} />
            )}
        </div>
    )
}

interface ITaskDetails {
    rt: Root
    task: ProjectTask
    editingDetails: boolean
    setEditingDetails: (editing: boolean) => void
}

const TaskDetails: FC<ITaskDetails> = ({ rt, task, editingDetails, setEditingDetails }) => {
    const { t } = useTranslation()

    const { useMobileLayout } = rt
    const { details } = task

    if (useMobileLayout) return null
    if (!editingDetails && !details) return null

    function renameDetails(newDetails: string) {
        task.setDetails(newDetails)
        setEditingDetails(false)
    }

    if (editingDetails)
        return (
            <div className="task-details">
                <TextInput
                    initialValue={details}
                    message={t('Type Enter to change details or Esc to cancel.')}
                    _onEscape={() => setEditingDetails(false)}
                    _onEnter={renameDetails}
                    allowEmptyValue
                />
            </div>
        )

    if (!details) return null

    return (
        <div className="blank-task-description">
            {details}
            <TaskDetailsMenu {...{ rt, setEditingDetails }} />
        </div>
    )
}

interface IEditableProjectTask {
    rt: Root
    task: ProjectTask
    stage: ProjectStage
    deleteTask: () => void
}

export const EditableProjectTask: FC<IEditableProjectTask> = observer(({ rt, task, stage, deleteTask }) => {
    const { t } = useTranslation()

    const [editingName, setEditingName] = useState(false)
    const [editingDetails, setEditingDetails] = useState(false)
    const [editingDifficulty, setEditingDifficulty] = useState(false)

    function validate(value: string) {
        value = value.trim()
        const otherTasks = stage.tasks.filter((stageTask) => stageTask._id !== task._id)
        if (otherTasks.find((otherTask) => otherTask.name === value)) {
            return t('Duplicate name')
        }
        return value === '' ? t('Empty name') : ''
    }

    function rename(newName: string) {
        if (!validate(newName)) {
            task.setName(stage, newName)
        }
        setEditingName(false)
    }

    let editableTaskName = task.name
    const parts = task.name.split(/^(\d+\.?\d*\s*)*/)
    if (parts.length > 0) {
        editableTaskName = parts[parts.length - 1]
    }

    return (
        <div className="editable-project-task">
            <div className="task-header">
                {editingName ? (
                    <TextInput
                        initialValue={editableTaskName}
                        message={t('Type Enter to change name or Esc to cancel.')}
                        _onEscape={() => {
                            setEditingName(false)
                        }}
                        _onEnter={rename}
                        validate={validate}
                    />
                ) : (
                    <span className="task-view">
                        {task.displayedName}
                        <TaskMenu
                            {...{ rt, task, setEditingName, setEditingDetails, setEditingDifficulty, deleteTask }}
                        />
                    </span>
                )}
            </div>
            {editingDifficulty && (
                <div>
                    <DifficultyView
                        stopEditing={() => {
                            setEditingDifficulty(false)
                        }}
                        difficulty={task.difficulty}
                        setDifficulty={task.setDifficulty}
                    />
                </div>
            )}
            <TaskDetails {...{ rt, task, editingDetails, setEditingDetails }} />
        </div>
    )
})
