import { FC } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { MemberRole } from '../../../models3/Member'
import { Project } from '../../../models3/Project'
import { groupBy } from '../../utils/Helpers'

interface MembersByRoleViewProps {
    project: Project
    includeRoles?: MemberRole[] | undefined
}

const MembersByRoleView: FC<MembersByRoleViewProps> = ({ project, includeRoles }) => {
    const { t } = useTranslation()
    const membersByRole = groupBy(project.members, (m) => m.role)

    const localizedRole = (role: MemberRole) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return t(role)
    }

    return (
        <>
            {Object.keys(membersByRole)
                .filter((role) => !includeRoles || includeRoles.includes(role as MemberRole))
                .sort()
                .map((role) => (
                    <div key={role}>
                        {(includeRoles === undefined || includeRoles.length > 1) && (
                            <em>{localizedRole(role as MemberRole)}:</em>
                        )}
                        <ul>
                            {membersByRole[role as MemberRole]
                                .sort((a, b) => a.email.toLocaleLowerCase().localeCompare(b.email.toLocaleLowerCase()))
                                .map((member) => (
                                    <li key={member.email}>{member.email}</li>
                                ))}
                        </ul>
                    </div>
                ))}
        </>
    )
}

export default observer(MembersByRoleView)
