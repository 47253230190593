import { useTranslation } from 'react-i18next'

import DebugScheduledDownloads from './DebugScheduledDownloads'
import RefetchLocalDatabase from './RefetchLocalDatabase'
import VideoCacheResetRequest from './VideoCacheResetRequest'
import { Root } from '../../../models3/Root'
import { useAppRoot } from '../../app/RootContext'
import { LocalStorageKeys } from '../../app/slttAvtt'
import { AllMissingRecordingsTable } from '../../utils/MissingRecordings'

interface DownloadReportProps {
    rt: Root
}

export const DownloadReport = ({ rt }: DownloadReportProps) => {
    const appRoot = useAppRoot()
    const { t } = useTranslation()
    return (
        <div>
            <AllMissingRecordingsTable rt={rt} header={<h3>{t('missingRecordingsHeader')}</h3>} />
            <DebugScheduledDownloads rt={rt} />
            {localStorage.getItem(LocalStorageKeys.DEBUG_DOWNLOADS) && (
                <>
                    <VideoCacheResetRequest />
                    <RefetchLocalDatabase appRoot={appRoot} />
                </>
            )}
        </div>
    )
}
