import { useTranslation } from 'react-i18next'

import { PublishedBible } from '../../types'
import { HeadsetIcon } from '../utils/Icons'

import './EnhancedResources.css'

export const BibleTranslationTitle = ({ bible }: { bible: PublishedBible }) => {
    const { t } = useTranslation()
    return (
        <div>
            <div className="er-bible-header">
                <span className="bible-abbreviation">{bible.abbreviation}</span>
                {bible.hasAudio ? <HeadsetIcon className="er-has-audio-icon" tooltip={t('Audio')} /> : <span />}
            </div>
            <div className="er-bible-sub-header">{bible.name}</div>
        </div>
    )
}
