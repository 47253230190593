import { observable } from 'mobx'

import { DBObject } from './DBObject'

export class ProjectImage extends DBObject {
    src = ''

    @observable rank = ''

    toDocument() {
        const { src, rank } = this
        return this._toDocument({ src, rank, model: 4 })
    }

    async setRank(rankNumber: number) {
        this.rank = DBObject.numberToRank(rankNumber)
        const doc = this.toDocument()
        await this.db.put(doc)
    }
}
