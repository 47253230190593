import { refToVerseId } from './RefRange'
import API from '../models3/API'

const PUBLISHED_AUDIO_KEY_PREFIX = 'APIBIBLE/audio'
const PUBLISHED_AUDIO_TIMECODES_BASE_PATH = `${process.env.REACT_APP_RESOURCE_URL}/public/APIBIBLE/audio`

export type PlaybackRange = {
    start?: number
    end?: number
}

export const getAudioResponse = async (
    resource: string, // e.g. RVR60
    bbbccc: string // e.g. 001002 (Gen 2)
) => {
    const resourceKey = `${PUBLISHED_AUDIO_KEY_PREFIX}/${resource}/${bbbccc}.opus`
    const response = await API.getPrivateResource(resourceKey)

    if (!response.ok) {
        throw Error(`${response.url}: ${response.statusText}`)
    }

    return response
}

export const getAudio = async (
    resource: string, // e.g. RVR60
    bbbccc: string // e.g. 001002 (Gen 2)
) => {
    const response = await getAudioResponse(resource, bbbccc)
    return response.blob()
}

export interface ChapterTimeCodes {
    chapter: string
    codes: VerseRangeTimeCode[]
}

export interface VerseRangeTimeCode {
    verseId: string // e.g. 1 or 1-2
    start: string // e.g. 4.160
    end: string // e.g. 4.160
}

export const getTimeCodesResponse = async (
    resource: string, // e.g. RVR60
    bbbccc: string // e.g. 001002 (Gen 2)
) => {
    const url = `${PUBLISHED_AUDIO_TIMECODES_BASE_PATH}/${resource}/${bbbccc}.json`
    const response = await fetch(url)

    if (!response.ok) {
        if (response.status === 403) {
            // some chapters don't have timecodes
            return
        }

        throw Error(`${response.url}: ${response.statusText}`)
    }

    return response
}

export const getTimeCodes = async (
    resource: string, // e.g. RVR60
    bbbccc: string // e.g. 001002 (Gen 2)
) => {
    const response = await getTimeCodesResponse(resource, bbbccc)
    if (!response) {
        return []
    }

    const timeCodes = await response.json()
    return timeCodes as VerseRangeTimeCode[]
}

export const getAllVersesInRange = (range: string) => {
    // verse range may contain letters, e.g. "1b-4b", and not just numbers like "3-7"
    const boundaries = range.split('-').map((verse) => Number(verse.replace(/\D/g, '')))
    if (!boundaries.length) {
        return []
    }
    const difference = boundaries[boundaries.length - 1] - boundaries[0]
    return Array.from(Array(difference + 1), (x, i) => String(i + boundaries[0]))
}

const doesSingleVerseOverlapRange = (verse: string, range: string) => {
    return getAllVersesInRange(range).includes(verse)
}

export const convertBbbcccvvvToPublishedAudioVerse = (bbbcccvvv: string) => {
    return Number(refToVerseId(bbbcccvvv)).toString()
}

export const getTimeRangeForVerses = (verses: string[], timeCodes: VerseRangeTimeCode[]) => {
    const newTimeRange: PlaybackRange = { start: undefined, end: undefined }
    if (!verses.length) {
        return newTimeRange
    }
    const firstVerse = verses[0]
    const lastVerse = verses[verses.length - 1]

    const startTimeCode = timeCodes.find((code) => doesSingleVerseOverlapRange(firstVerse, code.verseId))
    const endTimeCode = timeCodes.find((code) => doesSingleVerseOverlapRange(lastVerse, code.verseId))

    if (startTimeCode) {
        newTimeRange.start = Number(startTimeCode.start)
    }

    if (endTimeCode) {
        newTimeRange.end = Number(endTimeCode.end)
    }

    return newTimeRange
}
