import { Dispatch, SetStateAction, useState } from 'react'

import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { CopyPassageOptions } from './CopyDataModal'
import useAddProject from '../../../hooks/useAddProject'
import { GroupProjectConfiguration } from '../../../models3/Project'
import { Root } from '../../../models3/Root'
import { CancelButton, OKButton } from '../../utils/Buttons'
import { ErrorList } from '../../utils/ErrorList'
import { LoadingIcon } from '../../utils/Icons'
import { Switch } from '../../utils/Switch'
import TextInput from '../../utils/TextInput'

interface ProjectAddResultProps {
    creationInProgress: boolean
    newProjectName: string
    closeModal: () => void
}

const ProjectAddResult = ({ creationInProgress, newProjectName, closeModal }: ProjectAddResultProps) => {
    const { t } = useTranslation()

    if (creationInProgress) {
        return (
            <Modal.Body>
                <div className="project-modal-loading-message-parent">
                    <LoadingIcon className="" />
                    <span className="project-modal-loading-message">{t('Adding project...')}</span>
                </div>
            </Modal.Body>
        )
    }

    return (
        <>
            <Modal.Body>{t('Successfully added project: ') + newProjectName}</Modal.Body>
            <Modal.Footer>
                <div className="button-row">
                    <OKButton
                        enabled
                        onClick={() => closeModal()}
                        buttonClassName=""
                        className="ok-button"
                        tooltip={t('OK')}
                    />
                </div>
            </Modal.Footer>
        </>
    )
}

type GroupProjectConfigurationEditorProps = {
    configuration: GroupProjectConfiguration
    setGroupProjectConfig: Dispatch<SetStateAction<GroupProjectConfiguration>>
}

const GroupProjectConfigurationEditor = ({
    configuration,
    setGroupProjectConfig
}: GroupProjectConfigurationEditorProps) => {
    const { t } = useTranslation()
    const { copyPortions, latestDraftOnly, includeResources } = configuration
    return (
        <>
            <h5>{t('Copy group portions?')}</h5>
            <div>
                <Switch
                    value={copyPortions}
                    setValue={(value) => setGroupProjectConfig((config) => ({ ...config, copyPortions: value }))}
                    tooltip=""
                />
                {copyPortions && (
                    <CopyPassageOptions
                        latestDraftOnly={latestDraftOnly}
                        includeResources={includeResources}
                        setLatestDraftOnly={(value) =>
                            setGroupProjectConfig((config) => ({
                                ...config,
                                latestDraftOnly: value
                            }))
                        }
                        setIncludeResources={(value) =>
                            setGroupProjectConfig((config) => ({
                                ...config,
                                includeResources: value
                            }))
                        }
                    />
                )}
            </div>
        </>
    )
}

interface ProjectAddModalProps {
    groupRoot?: Root
    closeModal: () => void
}

export const ProjectAddModal = ({ groupRoot, closeModal }: ProjectAddModalProps) => {
    const { t } = useTranslation()
    const [projectName, setProjectName] = useState('')
    const [isGroup, setIsGroup] = useState(false)
    const [showResultPage, setShowResultPage] = useState(false)
    const [groupProjectConfig, setGroupProjectConfig] = useState<GroupProjectConfiguration>({
        copyAdminsOnly: true,
        copyPlan: true,
        copyPortions: false,
        latestDraftOnly: true,
        includeResources: false
    })
    const { addProject, validateProjectName, creationInProgress, error, newProjectName } = useAddProject({
        groupRoot,
        projectName,
        isGroup,
        groupProjectConfig
    })

    const projectNameChanged = (name: string) => {
        const newName = name.trim()
        setProjectName(newName)
        return validateProjectName(newName)
    }

    return (
        <Modal show onHide={() => closeModal()} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    {!groupRoot && t('Add project')}
                    {groupRoot &&
                        t('Add project to group {{groupName}}', {
                            groupName: groupRoot.project.getFormattedDisplayName()
                        })}
                </Modal.Title>
            </Modal.Header>

            {showResultPage && !error && (
                <ProjectAddResult
                    creationInProgress={creationInProgress}
                    newProjectName={newProjectName}
                    closeModal={() => closeModal()}
                />
            )}

            {(!showResultPage || error) && (
                <>
                    <Modal.Body>
                        {error && <ErrorList errors={[{ text: error, key: '' }]} />}
                        <div className="project-modal-section">
                            <h5> {t('Project Name')}</h5>
                            <div className="project-name-box">
                                <TextInput
                                    message={
                                        groupRoot
                                            ? t('Project name will be appended with the group name.')
                                            : t('Project name is set.')
                                    }
                                    initialValue={projectName}
                                    validate={projectNameChanged}
                                    _onEnter={() => ''}
                                />
                            </div>
                            {!groupRoot && (
                                <div>
                                    <h5>{t('Group')}</h5>
                                    <Switch value={isGroup} setValue={(value) => setIsGroup(value)} />
                                </div>
                            )}
                            {groupRoot && (
                                <GroupProjectConfigurationEditor
                                    configuration={groupProjectConfig}
                                    setGroupProjectConfig={setGroupProjectConfig}
                                />
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="button-row">
                            <OKButton
                                enabled={!validateProjectName(projectName)}
                                onClick={() => {
                                    setShowResultPage(true)
                                    addProject()
                                }}
                                buttonClassName=""
                                className="ok-button"
                                tooltip={t('Add project')}
                            />
                            <CancelButton
                                enabled
                                onClick={() => closeModal()}
                                className="cancel-button"
                                tooltip={t('Cancel')}
                            />
                        </div>
                    </Modal.Footer>
                </>
            )}
        </Modal>
    )
}
