/* eslint-disable no-console */
/* eslint-disable max-classes-per-file */

// http://uk.discovermeteor.com/chapters/errors/

import React, { Component, ErrorInfo } from 'react'

import { t } from 'i18next'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import API from '../../models3/API'
import { helpDocUrl, supportEmail } from '../app/slttAvtt'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:Errors')

// Ids of log messages generated, needed to ensure no duplicates
const logIds: any[] = []

export const isUnrecoverableHttpError = (errorMessage: string) =>
    ['400', '413'].some((errorString) => {
        return errorMessage.indexOf(errorString) >= 0
    })

Error.stackTraceLimit = 40

let previousTime = 0
let errorCount = 0

function throttleErrors() {
    const currentTime = Date.now()
    if (currentTime > previousTime + 3600 * 1000) {
        errorCount = 0
        previousTime = currentTime
    }

    errorCount += 1
    return errorCount > 3
}

function stringify(err: any) {
    if (typeof err === 'string') return err
    if (err instanceof Error) return err.toString()
    return JSON.stringify(err)
}

function stringifyStack(err: any) {
    if (typeof err === 'string') return err
    if (err instanceof Error) return err.stack ?? err.toString()
    return JSON.stringify(err)
}

/**
 * Send error message (and current root state info) to central log project
 */

export async function syncErrorMessage(errorMessage: any) {
    try {
        if (throttleErrors()) return

        errorMessage = stringify(errorMessage)

        log('syncErrorMessage', errorMessage)

        const _window = window as any
        const { appRoot } = _window

        // Should always be able to get rt for the current project but if not there
        // is not much we can usefully report, just return
        const { rt } = appRoot
        if (!rt) return

        const { project, name, username } = rt
        const { db } = project

        // Get unique _id for this log message
        const _id = db.getNewId(logIds, new Date(), `_${name}_`)
        logIds.push({ _id })

        const modDate = db.getDate()

        const remoteSeq = db.getRemoteSeq()

        const dbg = rt.dbg()

        const doc = { _id, modDate, name, username, errorMessage, dbg, remoteSeq }

        await API.sync('_log_', [doc], 100000000 /* don't return new entries */)
    } catch (error) {
        log('logError failed', error)
    }
}

/* Format message and send to central error log
 */
export function logError(err: string | Error, message?: string) {
    try {
        if (message) {
            message += '\n'
        } else {
            message = ''
        }

        log('###', message)
        syncErrorMessage(message + stringifyStack(err))
    } catch (error) {
        console.log('logError failed', error, err, message)
    }
}
interface ErrorBoundaryProps {
    fallbackAction?: () => void
}

interface ErrorBoundaryState {
    errorExists: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps | Readonly<ErrorBoundaryProps>) {
        super(props)
        this.state = {
            // eslint-disable-next-line react/no-unused-state
            errorExists: false
        }
    }

    static getDerivedStateFromError() {
        return { errorExists: true }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        logError(error, 'ErrorBoundary Error')
        const parts = errorInfo.componentStack.split('\n')
        const message = `ErrorBoundary ErrorInfo${parts.length >= 2 ? parts[1] : ''}`
        const info = `${errorInfo.componentStack}\n\nSTACK\n${error.stack ?? '*nostack*'}`
        logError(info, message)

        const { fallbackAction } = this.props
        fallbackAction?.()
    }

    render() {
        const { children } = this.props
        const { errorExists } = this.state

        if (errorExists) {
            return <h4>{t('Something went wrong...')}</h4>
        }

        return children
    }
}

export default observer(ErrorBoundary)
interface CustomErrorBoundaryProps {
    fallbackUI: JSX.Element
    fallbackAction?: () => void
}

interface CustomErrorBoundaryState {
    errorExists: boolean
}

@observer
export class CustomErrorBoundary extends Component<CustomErrorBoundaryProps, CustomErrorBoundaryState> {
    constructor(props: CustomErrorBoundaryProps) {
        super(props)
        this.state = {
            errorExists: false
        }
    }

    static getDerivedStateFromError() {
        return { errorExists: true }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        logError(error, 'CustomErrorBoundary Error')
        const parts = errorInfo.componentStack.split('\n')
        const message = `ErrorBoundary ErrorInfo${parts.length >= 2 ? parts[1] : ''}`
        logError(errorInfo.componentStack, message)

        const { fallbackAction } = this.props
        fallbackAction?.()
    }

    render() {
        const { errorExists } = this.state
        const { fallbackUI, children } = this.props
        if (errorExists) {
            return fallbackUI
        }

        return children
    }
}

/**
 * Display a non-serious error to the user.
 */
export function displayError(err: any, message?: string, component?: React.ReactFragment) {
    const message2 = message || stringify(err)
    console.log('<ERROR>', err, message2)

    logError(err, message)
    if (component) {
        return toast.error(component, { autoClose: 20000 })
    }
    return toast.error(message2, { autoClose: 20000 })
}

/**
 * Report a serious error to logging system, and display a simple error message to user.
 */
export function systemError(err: any, notifyUser = true) {
    const message2 = stringify(err)
    console.log('<SYSTEM ERROR>', err, message2)

    syncErrorMessage(stringifyStack(err))

    if (notifyUser) {
        toast.error(t('systemErrorMessage', { supportEmail }), {
            autoClose: 20000
        })
    }
}

export function displayInfo(message: string, helpId?: string) {
    const { appRoot } = window as any

    const content = helpId ? (
        <span>
            {message}
            <a href={helpDocUrl(appRoot.rt.language, helpId)} target="_blank" rel="noreferrer">
                <span className="sl-help-icon fas fa-question-circle" />
            </a>
        </span>
    ) : (
        message
    )

    console.log('<INFO>', message)
    return toast.info(content, { autoClose: 60000, position: toast.POSITION.TOP_RIGHT })
}

export function displaySuccess(message: string) {
    message = stringify(message)
    console.log('<SUCCESS>', message)
    return toast.success(message, { position: toast.POSITION.TOP_RIGHT })
}

export function dismissDisplay(_id: any) {
    toast.dismiss(_id)
}

interface ErrorComponentProps {
    errorMessage: string
    linkText: string
    link: string
}

const ErrorMessageWithHelp = ({ errorMessage, linkText, link }: ErrorComponentProps) => {
    return (
        <div>
            {errorMessage}
            <br />
            <br />
            <a href={link} className="error-link" target="_blank" rel="noopener noreferrer">
                {linkText}
            </a>
        </div>
    )
}

export const RecordingNotAllowedByBrowserErrorMessage = () => {
    const { i18n } = useTranslation()

    const linkId = 'permission-denied-while-trying-to-record'

    return (
        <ErrorMessageWithHelp
            errorMessage={t('noRecordingPermission')}
            linkText={t('Click here for help.')}
            link={helpDocUrl(i18n.language, linkId)}
        />
    )
}
