/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useTranslation } from 'react-i18next'

import { Root } from '../../../models3/Root'
import { SegmentEditorPanel } from '../../segments/SegmentPanelOrder'
import { DragAndDropIcon } from '../../utils/Icons'
import { Switch } from '../../utils/Switch'

export const RecordingLayoutSortableItem = ({ rt, id }: { rt: Root; id: SegmentEditorPanel }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })
    const { t } = useTranslation()
    const [visible, setVisible] = useState(!rt.project.segmentEditorHiddenPanels.includes(id))

    return (
        <div ref={setNodeRef} style={{ transform: CSS.Transform.toString(transform), transition }}>
            <div className="sortable-container-item">
                <div {...attributes} {...listeners} data-toggle="tooltip">
                    <DragAndDropIcon className="fa-lg" />
                </div>
                <span className={visible ? '' : 'hidden-item'}>{t(id)}</span>
                <Switch
                    value={visible}
                    setValue={async (value) => {
                        setVisible(value)
                        await rt.project.setSegmentEditorPanelVisibility(id, value)
                    }}
                    tooltip={t('showOrHide')}
                    className="sortable-container-item-switch"
                />
            </div>
        </div>
    )
}
