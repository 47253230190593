import { useState } from 'react'

import { t } from 'i18next'
import { observer } from 'mobx-react'

import { ProjectStage } from '../../../models3/ProjectStage'
import { useAppRoot } from '../../app/RootContext'
import { GenericIconButton } from '../../utils/Buttons'
import TextInput from '../../utils/TextInput'

interface IProjectTaskAdder {
    addTask: (name: string) => void
    stage: ProjectStage
}

const ProjectTaskAdder = observer(({ addTask, stage }: IProjectTaskAdder) => {
    const { rt } = useAppRoot()

    const [adding, setAdding] = useState(false)

    const validate = (value: string) => {
        value = value.trim()
        if (stage.tasks.find((task) => task.name === value)) {
            return t('Duplicate name')
        }
        if (value === '') {
            return t('Empty name')
        }
        return ''
    }

    const onEnter = (newTaskName: string) => {
        if (!validate(newTaskName)) {
            addTask(newTaskName)
        }
        setAdding(false)
    }

    if (!rt) {
        return null
    }

    const _addTask = t('Add task.')

    if (adding) {
        return (
            <div className="project-task-adder">
                <TextInput
                    placeholder={_addTask}
                    message={t('Type Enter to add new task or Esc to cancel.')}
                    validate={validate}
                    _onEscape={() => setAdding(false)}
                    _onEnter={onEnter}
                />
            </div>
        )
    }

    return (
        <GenericIconButton
            onClick={() => setAdding(true)}
            className="task-adder"
            tooltip={_addTask}
            enabled
            iconName="fa-plus-square"
            iconType="far"
        />
    )
})

export default ProjectTaskAdder
