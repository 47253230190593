/* eslint-disable import/no-cycle */
import { VideoCache } from './VideoCache'
import { VideoCacheRecord } from './VideoCacheRecord'
import { canAccessInternet } from '../components/app/OnlineStatusContext'

// eslint-disable-next-line @typescript-eslint/no-var-requires
// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:VideoCacheUploader')

export class VideoCacheUploader {
    static systemError = (err: any): void => {
        throw Error(err)
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static uploadDone: (_id: string) => Promise<void>

    uploadInProgress = false

    constructor(public displayError: (message: any) => void) {
        VideoCacheUploader.systemError = displayError
    }

    postMessage(message: any) {
        log('postMessage', message)
        this.onmessage({ data: message } as MessageEvent)
    }

    async onmessage(ev: MessageEvent) {
        const { _id } = ev.data
        log('onmessage', _id)

        const vcr = VideoCacheRecord.get(_id)
        if (!vcr.needsUploaded) return

        await vcr.saveUploadRequest()
        await this.startClientUpload()
    }

    // If client upload is not already in progress, start it
    private async startClientUpload() {
        if (!this.uploadInProgress) {
            this.uploadInProgress = true
            try {
                await VideoCacheUploader.uploadAllPendingRequests()
            } catch (error) {
                log(`startClientUpload exception ${error}`)
                VideoCacheUploader.systemError(error)
            } finally {
                this.uploadInProgress = false
            }
            log('Done uploading')
        }
    }

    // Have client upload all videos waiting in the VideoCache.cachedVideos database
    private static async uploadAllPendingRequests() {
        // If there is no internet, we must not enter here as it will go on forever
        // until there are no more videos to upload
        if (!(await canAccessInternet('uploadAllPendingRequests'))) {
            log('Skipping starting uploading because there is no internet access')
            return
        }

        // Since canAccessInternet() can be expensive if excessively used, just check navigator.onLine here.
        // This can give a false positive, but network error is handled below
        log('Starting uploads')
        while (navigator.onLine) {
            // eslint-disable-next-line no-await-in-loop
            const vcrs = await VideoCache.getAllVcrs()
            const pendingUploads = vcrs.filter((vcr) => vcr.needsUploaded)
            if (pendingUploads.length === 0) break
            log(`upload requests pending=${pendingUploads.length}`)

            // eslint-disable-next-line no-restricted-syntax
            for (const vcr of pendingUploads) {
                // eslint-disable-next-line no-await-in-loop
                await VideoCacheUploader.uploadPendingRequest(vcr)
            }
        }
    }

    // Have client upload one video
    private static async uploadPendingRequest(vcr: VideoCacheRecord) {
        const { _id } = vcr
        log(`uploadPendingRequest [${_id}]`)

        await vcr.saveUploadStartTime()
        await vcr.uploadToServer()

        // eslint-disable-next-line no-param-reassign
        vcr = VideoCacheRecord.get(_id)
        if (vcr.uploaded) {
            await vcr.saveUploadFinishTime()
            log(`uploadPendingRequest complete rate=${vcr.uploadRate().toFixed(1)} KB/s`)
        } else {
            log(`uploadPendingRequest failed after ${Date.now() - vcr.uploadStartTimeMs}ms, error=${vcr.error}`)
        }
    }
}
