import { t } from 'i18next'
import { observable, computed } from 'mobx'
import _ from 'underscore'

import { DBObject } from './DBObject'
import { Project } from './Project'
import { ProjectPlan } from './ProjectPlan'
import { InternalProjectStatus, ProjectTask } from './ProjectTask'
import { getRankOfAddingBetween, remove } from './Utils'

export class ProjectStage extends DBObject {
    @observable tasks: ProjectTask[] = []

    @observable name = ''

    @observable rank = ''

    @observable index = 0 // position in containing list of stages. Non-persisted

    @computed get displayedName() {
        const { name, index } = this
        return `${index} ${name}`
    }

    // Stage and task names used to begin with numeric indices. This strips them off if
    // they are still there.
    static validName(name: string) {
        return name.replace(/^(\d+\.?\d*\s*)*/, '')
    }

    dbg() {
        const { tasks, name, rank, index } = this
        return {
            name,
            rank,
            index,
            tasks: tasks.map((task) => task.dbg())
        }
    }

    toSnapshot() {
        const { name, rank, index } = this
        const snapshot: any = { name, rank, index }
        snapshot.tasks = this.tasks.map((task) => task.toSnapshot())
        return snapshot
    }

    toDocument() {
        const { name, rank } = this
        return this._toDocument({ name, rank, model: 8 })
    }

    async setRank(rankNumber: number) {
        this.rank = DBObject.numberToRank(rankNumber)
        const doc = this.toDocument()
        await this.db.put(doc)
    }

    async setName(plan: ProjectPlan, name: string) {
        name = name.trim()

        // Remove index from beginning if it exists, and then
        // add the correct index
        name = ProjectStage.validName(name)
        if (plan.stages.find((stage) => stage.name === name)) {
            return
        }

        const doc = this._toDocument({ name, model: 8 })
        await this.db.put(doc)
    }

    createTask(
        plan: ProjectPlan,
        id: string,
        rank: string,
        creationDate: Date,
        name: string,
        details: string,
        difficulty: number
    ) {
        name = name.trim()
        if (this.tasks.find((task) => task.name === name)) {
            throw Error(`${t('System Error')}: Duplicate name`)
        }

        const newId = this.db.getNewId(this.tasks, creationDate, 'tsk_')
        const task = new ProjectTask(`${this._id}/${newId}`, this.db)

        task.name = ProjectTask.validName(name)
        task.details = details

        if (difficulty < 0.0) {
            difficulty = 1.0
        }

        task.difficulty = difficulty

        if (id === '') {
            task.id = plan.getUniqueTaskId(this)
        } else {
            task.id = id
        }

        task.rank = rank

        return task
    }

    // Add a task and persist it in the DB.
    async addTask(plan: ProjectPlan, addBeforeIndex: number, name: string, details?: string, difficulty?: number) {
        details = details || ''
        difficulty = difficulty || 0
        const creationDate = new Date(Date.now())

        const rank = getRankOfAddingBetween({
            startIndex: addBeforeIndex - 1,
            endIndex: addBeforeIndex,
            items: this.tasks
        })

        const task = this.createTask(plan, '', rank, creationDate, name, details, difficulty)
        await this.db.put(task.toDocument())

        const _task = _.findWhere(this.tasks, { _id: task._id })
        if (!_task) throw Error('could not find newly added task')

        this.updateIndices()
        return _task
    }

    // Remove a task and update the status of passages and passage videos that may
    // be affected.
    async removeTask(_id: string, project: Project) {
        if (!project.plans.length) {
            return
        }

        const plan = project.plans[0]
        const { tasks } = plan
        const deletedPosition = tasks.findIndex((task) => task._id === _id)
        if (deletedPosition < 0) {
            return
        }

        // If a video was assigned to the deleted task, assign it to the next task.
        const oldStatus = tasks[deletedPosition].id
        const newPosition = Math.min(deletedPosition + 1, tasks.length - 1)
        const newStatus = tasks[newPosition].id

        await plan.updateVideoStatus(project, [oldStatus], newStatus)

        await remove(this.tasks, _id)
        this.updateIndices()
    }

    updateIndices() {
        const { index } = this
        if (this.name !== InternalProjectStatus.NOT_STARTED && this.name !== InternalProjectStatus.FINISHED) {
            for (const [j, task] of this.tasks.entries()) {
                task.stagePosition = index
                task.taskPosition = j + 1
            }
        } else if (this.name === InternalProjectStatus.FINISHED && this.tasks.length) {
            this.tasks[0].id = InternalProjectStatus.FINISHED
        } else if (this.name === InternalProjectStatus.NOT_STARTED && this.tasks.length) {
            this.tasks[0].id = InternalProjectStatus.NOT_STARTED
        }
    }
}
