import { FC } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Project } from '../../../models3/Project'
import { GenericIcon } from '../../utils/Icons'

type VideoCompressionPreferencesProps = {
    project: Project
    iconClassName: string
}

const VIDEO_RESOLUTIONS = [
    { value: 480, label: '480p' },
    { value: 720, label: '720p' }
]
const VIDEO_SIZES = [
    { value: 100, label: '100 MB' },
    { value: 250, label: '250 MB' }
]
// CRF values range from 18 to 28
const VIDEO_QUALITIES_RANGE = Array.from({ length: 28 - 18 + 1 }, (_, i) => i + 18)

export const VideoCompressionPreferences: FC<VideoCompressionPreferencesProps> = observer(
    ({ project, iconClassName }) => {
        const { t } = useTranslation()

        const {
            compressedVideoResolution,
            compressedVideoQuality,
            maxVideoSizeMB,
            setCompressedVideoQuality,
            setCompressedVideoResolution,
            setMaxVideoSizeMB
        } = project

        const videoQualities = VIDEO_QUALITIES_RANGE.map((quality, i) => {
            const crfLabel =
                i === 0
                    ? ` ${t('videoCompressionHigh')}`
                    : i === VIDEO_QUALITIES_RANGE.length - 1
                    ? ` ${t('videoCompressionLow')}`
                    : ''

            return { value: quality, label: `${quality} CRF${crfLabel}` }
        })
        const videoSettingContainer = 'flex-centered video-setting-option'
        const optionLabel = 'team-preference-option-label'

        return (
            <div className="project-preferences-switch">
                <GenericIcon className={iconClassName} iconName="fa-video" tooltip={t('projectSettingsRecordVideo')} />
                <div className={videoSettingContainer}>
                    <div className={optionLabel}>{t('Resolution')}</div>
                    <select
                        value={compressedVideoResolution}
                        onChange={(e) => setCompressedVideoResolution(parseInt(e.target.value))}
                    >
                        {VIDEO_RESOLUTIONS.map(({ label, value }) => (
                            <option key={value} value={value}>
                                {label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className={videoSettingContainer}>
                    <div className={optionLabel}>{t('videoSettingsQuality')}</div>
                    <select
                        value={compressedVideoQuality}
                        onChange={(e) => setCompressedVideoQuality(parseInt(e.target.value))}
                    >
                        {videoQualities.map(({ label, value }) => (
                            <option key={value} value={value}>
                                {label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="flex-centered">
                    <div className={optionLabel}>{t('maxSizeMB')}</div>
                    <select value={maxVideoSizeMB} onChange={(e) => setMaxVideoSizeMB(parseInt(e.target.value))}>
                        {VIDEO_SIZES.map(({ label, value }) => (
                            <option key={value} value={value}>
                                {label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        )
    }
)
