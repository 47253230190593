import { Component, FC } from 'react'

import { observable } from 'mobx'
import { observer /* inject */ } from 'mobx-react'
import SplitPane from 'react-split-pane'

import RootVideoPlayer from './RootVideoPlayer'
import { VideoMainBottom } from './VideoMainBottom'
import VideoRecorder, { AudioRecorderComponent, AVTTRecordingState } from './VideoRecorder'
import VideoToolbar from './VideoToolbar'
import { RecordingDoneParams, VideoUploader } from './VideoUploader'
import { BiblicalTermMarker } from '../../models3/BiblicalTermMarker'
import { MIN_SEGMENT_LENGTH, PassageSegment } from '../../models3/PassageSegment'
import { PassageVideo } from '../../models3/PassageVideo'
import { getVisiblePassageOrPortionRefRanges } from '../../models3/ProjectReferences'
import { Root } from '../../models3/Root'
import { RecordingType } from '../../types'
import { LocalStorageKeys } from '../app/slttAvtt'
import { BiblicalTermMarkerEditor } from '../glosses/BiblicalTermMarkerEditor'
import { displayError, systemError } from '../utils/Errors'
import { incrementTime } from '../utils/Helpers'

import './Video.css'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:VideoMain')

interface WrapperProps {
    useSplitPane: boolean
    onSizeChange: (size: number) => void
    size: number
}

const OptionalSplitPane: FC<WrapperProps> = observer(({ children, size, onSizeChange, useSplitPane }) => {
    return useSplitPane ? (
        <SplitPane
            split="horizontal"
            minSize={300}
            maxSize={750}
            size={size}
            onChange={onSizeChange}
            style={{ position: 'relative', height: 'auto', overflow: 'visible' }}
        >
            {children}
        </SplitPane>
    ) : (
        <div>{children}</div>
    )
})

interface IVideoMain {
    rt: Root
    onVideoEnded?: () => void
    videoHasLoaded?: () => void
    videoHasChanged?: () => void
    startPlaying?: () => void
    stopPlaying?: () => void
    recordingState: AVTTRecordingState
    setRecordingState: (state: AVTTRecordingState) => void
}

const defaultVideoMainTopHeight = 300

class VideoMain extends Component<IVideoMain> {
    videoBeingRecorded?: { recording: PassageVideo; recordingType: RecordingType }

    @observable videoUploader?: VideoUploader

    @observable recordAudioOnly = false

    @observable mediaStream?: MediaStream

    @observable biblicalTermMarker?: BiblicalTermMarker

    @observable isNewBiblicalTermMarker = false

    @observable videoMainTopHeight = defaultVideoMainTopHeight

    recorderComponent: any

    constructor(props: IVideoMain) {
        super(props)

        this.record = this.record.bind(this)
        this.stop = this.stop.bind(this)
        this._record = this._record.bind(this)
        this._stop = this._stop.bind(this)
        this.onRecordingDone = this.onRecordingDone.bind(this)
        this.keydown = this.keydown.bind(this)
        this.pauseRecording = this.pauseRecording.bind(this)
        this.resumeRecording = this.resumeRecording.bind(this)
        this.createBiblicalTermMarker = this.createBiblicalTermMarker.bind(this)
        this.setBiblicalTermMarker = this.setBiblicalTermMarker.bind(this)
    }

    componentDidMount() {
        const { rt } = this.props

        rt.addListener('record', this.record)
        rt.addListener('stop', this.stop)
        window.addEventListener('keydown', this.keydown)
        this.videoMainTopHeight = rt.getDefaultPaneSize(
            LocalStorageKeys.VIDEO_MAIN_TOP_HEIGHT,
            defaultVideoMainTopHeight
        )
    }

    componentDidUpdate(prevProps: IVideoMain) {
        const { rt } = this.props

        if (prevProps.rt.name !== rt.name) {
            window.removeEventListener('keydown', this.keydown)
            prevProps.rt.removeListener('record', this.record)
            prevProps.rt.removeListener('stop', this.stop)
            rt.addListener('record', this.record)
            rt.addListener('stop', this.stop)
            window.addEventListener('keydown', this.keydown)
        }
    }

    componentWillUnmount() {
        const { rt } = this.props

        window.removeEventListener('keydown', this.keydown)
        rt.removeListener('record', this.record)
        rt.removeListener('stop', this.stop)
    }

    async onRecordingDone({ err, blobsCount, url, duration, mimeType }: Partial<RecordingDoneParams>) {
        const { rt } = this.props

        if (err) {
            this.videoBeingRecorded = undefined
            rt.recording = false
            displayError(err)
            return
        }

        if (!this.videoBeingRecorded) {
            return
        }

        this.videoBeingRecorded.recording.url = `${url}-${blobsCount}`
        this.videoBeingRecorded.recording.duration = duration ?? 0
        this.videoBeingRecorded.recording.mimeType = mimeType ?? ''

        try {
            await this.addVideo(this.videoBeingRecorded)
        } catch (error) {
            systemError(error)
        }
    }

    createBiblicalTermMarker = async () => {
        const { rt } = this.props
        const marker = await rt.createBiblicalTermMarker(rt.currentTime)
        if (marker) {
            this.isNewBiblicalTermMarker = true
            this.biblicalTermMarker = marker
        }
    }

    setBiblicalTermMarker = (marker: BiblicalTermMarker) => {
        this.isNewBiblicalTermMarker = false
        this.biblicalTermMarker = marker
    }

    // Handle 'record' event that was sent by root.record(...).
    // This code is shared by the record main video and record patch paths.
    // The resulting data is directed to the correct location by onRecordingDone
    // which is set differently for various paths.
    record(videoBeingRecorded: { recording: PassageVideo; recordingType: RecordingType }, recordAudioOnly: boolean) {
        if (this.videoBeingRecorded) {
            log(`record ignored`)
            return // ignore if already recording
        }

        const { rt } = this.props
        const onRecordingDone2 = async ({ err, blobsCount, url, duration, mimeType }: Partial<RecordingDoneParams>) => {
            await this.onRecordingDone({ err, blobsCount, url, duration, mimeType })
            this.videoBeingRecorded = undefined
            rt.recording = false
        }
        this.recordAudioOnly = recordAudioOnly
        this.videoBeingRecorded = videoBeingRecorded
        this.videoUploader = new VideoUploader(videoBeingRecorded.recording.url, onRecordingDone2.bind(this))
        rt.recording = true
    }

    stop() {
        const { rt } = this.props
        const { recording, playing } = rt
        log(`stop ${recording} ${playing}`)

        if (recording) {
            this.recorderComponent.stop()
        }
    }

    // This is similar to uploadRecording from BaseRecordingFilePicker. Maybe extract common code?
    async addVideo(newRecording: { recording: PassageVideo; recordingType: RecordingType }) {
        const { rt } = this.props
        const { passage } = rt
        if (!passage) {
            return
        }

        const updatePassageVideo = async (recording: PassageVideo | null) => {
            // We set passageVideo to null first in order to ensure that
            // VideoMain will update. Otherwise there is a race condition
            // where DBAcceptor partially set up the passageVideo and triggers
            // a VideoMain render before the passageVideo has all the info
            // to correctly display
            await rt.setPassageVideo(null)
            await rt.setPassageVideo(recording)
        }

        const addPatchAndUpdateState = async ({
            baseRecording,
            patch,
            baseSegment
        }: {
            baseRecording: PassageVideo
            patch: PassageVideo
            baseSegment: PassageSegment
        }) => {
            const onTopSegment = baseSegment.actualSegment(passage)
            await passage.addPatchVideo({
                baseRecording,
                patch,
                baseSegment,
                onTopSegment
            })

            const updatedPassageVideo = rt.passageVideo
            await updatePassageVideo(updatedPassageVideo)
            rt.setPassageSegment(baseSegment)
            rt.resetCurrentTime(incrementTime(onTopSegment.time))
        }

        const handleBaseVideo = async (recording: PassageVideo) => {
            const videoToCopyFrom = passage.latestVideo
            const newVideo = await passage.addNewBaseVideo({
                video: recording,
                videoToCopyFrom,
                project: rt.project,
                options: { warnAboutOldData: true }
            })

            await updatePassageVideo(newVideo)
        }

        const handleAppendedSegment = async (recording: PassageVideo) => {
            const { passageVideo } = rt

            if (!passageVideo?.isAppendable()) {
                return
            }

            const allSegments = passageVideo.getAllBaseSegments()
            const lastSegment = allSegments.length !== 0 ? allSegments[allSegments.length - 1] : null
            if (!lastSegment || lastSegment.isPatched) {
                return
            }

            // Because the segment is not patched, its position is relative to the base
            // recording, and we can use its position directly. Make sure the previous
            // segment is long enough we don't mistakenly think it's too short.
            await passageVideo.addSegment({
                position: lastSegment.position + MIN_SEGMENT_LENGTH + 0.001,
                isHidden: true
            })
            const newSegments = rt.passageVideo?.getAllBaseSegments()
            if (!newSegments || newSegments.length < 2) {
                return
            }

            const lastVisibleSegment = newSegments[newSegments.length - 2]
            await addPatchAndUpdateState({
                baseRecording: passageVideo,
                patch: recording,
                baseSegment: lastVisibleSegment
            })
        }

        const handlePatchedSegment = async (recording: PassageVideo) => {
            const { passageVideo, passageSegment, timeline } = rt
            if (!passageVideo || !passageSegment) {
                return
            }

            const segment = rt.patchableSelectionPresent()
                ? await passageVideo.createSelectionSegment(passage, timeline)
                : passageSegment
            await addPatchAndUpdateState({ baseRecording: passageVideo, patch: recording, baseSegment: segment })
        }

        const { recordingType, recording } = newRecording
        if (recordingType === RecordingType.BASE) {
            await handleBaseVideo(recording)
        } else if (recordingType === RecordingType.APPENDED_SEGMENT) {
            await handleAppendedSegment(recording)
        } else if (recordingType === RecordingType.PATCH) {
            await handlePatchedSegment(recording)
        }
    }

    pauseRecording() {
        this.recorderComponent?.pause()
    }

    resumeRecording() {
        this.recorderComponent?.resume()
    }

    keydown(e: KeyboardEvent) {
        const { rt, recordingState } = this.props
        const { videoPlaybackKeydownEnabled, passage } = rt

        // Ignore keydown handlers if this event meets certain conditions
        const element = (e.target && e.target.toString()) || ''
        const el = e.target as Element
        const shouldReject =
            element.includes('HTMLInputElement') ||
            element.includes('HTMLTextAreaElement') ||
            (el.getAttribute && el.getAttribute('contenteditable') === 'true') ||
            (el.getAttribute && el.getAttribute('role') === 'dialog') ||
            !videoPlaybackKeydownEnabled ||
            passage?.videoBeingCompressed
        if (shouldReject) {
            log('keydown rejected non-global')
            return
        }

        e.stopPropagation()

        log(`keydown code=${e.code}`, e)

        const adjustCurrentTime = function (delta: number) {
            e.preventDefault()
            rt.adjustCurrentTime(delta)
        }

        if (e.code === 'ArrowLeft' && e.shiftKey) {
            adjustCurrentTime(-1.0)
            return
        }

        if (e.code === 'ArrowLeft' && e.metaKey) {
            adjustCurrentTime(-0.05)
            return
        }

        if (e.code === 'ArrowRight' && e.shiftKey) {
            adjustCurrentTime(1.0)
            return
        }

        if (e.code === 'ArrowRight' && e.metaKey) {
            adjustCurrentTime(0.05)
            return
        }

        if (e.code === 'Space') {
            if (rt.recording) {
                if (recordingState === 'RECORDING') {
                    this.pauseRecording()
                } else if (recordingState === 'PAUSED') {
                    this.resumeRecording()
                }
            } else if (rt.playing) {
                rt.pause()
            } else {
                rt.play(undefined, undefined, rt.currentTime)
            }

            e.preventDefault()
            return
        }

        if (
            e.code === 'Enter' &&
            rt.recording &&
            recordingState !== 'NOT_INITIALIZED' &&
            recordingState !== 'INITIALIZED' &&
            recordingState !== 'STOPPED'
        ) {
            rt.stop()
        }
    }

    // This is than handler for the VideoToolbar record button click
    _record(recordingType: RecordingType) {
        const { rt } = this.props

        // this will trigger a 'record' event on root which will be handled by this.record()
        rt.record(recordingType)
    }

    _stop() {
        const { rt } = this.props

        rt.stop()
    }

    render() {
        const { rt, recordingState, setRecordingState } = this.props
        const { project, portion, passage, passageVideo, recording, currentTime, currentVideos, useMobileLayout } = rt
        const {
            videoUploader,
            _record,
            _stop,
            recordAudioOnly,
            mediaStream,
            pauseRecording,
            resumeRecording,
            biblicalTermMarker,
            isNewBiblicalTermMarker,
            setBiblicalTermMarker,
            videoMainTopHeight
        } = this

        const { recordingCountdown, autoGainControl } = project

        if (!recording) {
            this.recorderComponent = null
        }

        const compressingVideo = !!passage?.videoBeingCompressed

        let isAllAudio =
            (!passageVideo && rt.project.recordAudioOnly) ||
            currentVideos.viewableVideos.every((vv) => vv.video.isAudioOnly())
        if (recording) {
            isAllAudio = recordAudioOnly
        }

        const refRanges = getVisiblePassageOrPortionRefRanges({ passage, portion })
        const terms = project.getKeyTermsThatOccurInVerses(refRanges)

        const markers = (passage && passageVideo?.getVisibleBiblicalTermMarkers(passage)) ?? []
        const markerIndex = biblicalTermMarker
            ? markers.findIndex((marker) => marker._id === biblicalTermMarker._id)
            : -1

        let termId = ''
        if (biblicalTermMarker?.targetGlossId) {
            for (const term of terms) {
                const gloss = term.glosses.find((g) => g._id === biblicalTermMarker.targetGlossId)
                if (gloss) {
                    termId = term._id
                    break
                }
            }
        }

        return (
            <div className="video-main">
                {biblicalTermMarker && passageVideo && passage && (
                    <BiblicalTermMarkerEditor
                        closeViewer={() => {
                            this.biblicalTermMarker = undefined
                            this.isNewBiblicalTermMarker = false
                        }}
                        terms={terms}
                        termId={termId}
                        biblicalTermMarker={biblicalTermMarker}
                        isNewMarker={isNewBiblicalTermMarker}
                        passage={passage}
                        allowEditing={rt.iAmTranslator}
                        goToNextMarker={() => {
                            if (markerIndex >= 0 && markerIndex < markers.length - 1) {
                                setBiblicalTermMarker(markers[markerIndex + 1])
                            }
                        }}
                        goToPreviousMarker={() => {
                            if (markerIndex > 0) {
                                setBiblicalTermMarker(markers[markerIndex - 1])
                            }
                        }}
                        markers={markers}
                    />
                )}
                <VideoToolbar
                    rt={rt}
                    playAllVideos={() => rt.playAll()}
                    playCurrentVideo={() => rt.play()}
                    pausePlayback={() => rt.pause()}
                    pauseRecording={pauseRecording}
                    record={_record}
                    stopRecording={_stop}
                    recordingState={recordingState}
                    resumeRecording={resumeRecording}
                    openBiblicalTermMarkerEditor={this.createBiblicalTermMarker}
                />
                <OptionalSplitPane
                    size={videoMainTopHeight}
                    onSizeChange={(size) => {
                        this.videoMainTopHeight = size
                        rt.setDefault(LocalStorageKeys.VIDEO_MAIN_TOP_HEIGHT, size.toFixed(0))
                    }}
                    useSplitPane={!isAllAudio}
                >
                    <div className={isAllAudio ? 'avtt-audio-area' : 'avtt-video-area'}>
                        {!compressingVideo && recording && recordAudioOnly && (
                            <AudioRecorderComponent
                                ref={(c) => {
                                    this.recorderComponent = c
                                }}
                                videoUploader={videoUploader}
                                setMediaStream={(stream) => (this.mediaStream = stream)}
                                setRecordingState={setRecordingState}
                                recordingCountdown={recordingCountdown}
                                usePauseAndResume
                                appendSilenceToEnd={this.videoBeingRecorded?.recordingType === RecordingType.BASE}
                                autoGainControl={autoGainControl}
                            />
                        )}
                        {!compressingVideo && recording && !recordAudioOnly && (
                            <VideoRecorder
                                ref={(c) => {
                                    this.recorderComponent = c
                                }}
                                setMediaStream={(stream) => (this.mediaStream = stream)}
                                usePauseAndResume
                                setRecordingState={setRecordingState}
                                videoUploader={videoUploader}
                                recordingCountdown={recordingCountdown}
                                autoGainControl={autoGainControl}
                            />
                        )}

                        {!compressingVideo && !recording && passage && (
                            <RootVideoPlayer rt={rt} showSegmentLabels={!useMobileLayout} initialTime={currentTime} />
                        )}
                    </div>
                    <VideoMainBottom
                        rt={rt}
                        mediaStream={mediaStream}
                        recordingState={recordingState}
                        setBiblicalTermMarker={setBiblicalTermMarker}
                        isAudioOnly={recordAudioOnly}
                    />
                </OptionalSplitPane>
            </div>
        )
    }
}

export default observer(VideoMain)
