/* eslint-disable import/no-cycle */
import * as P from 'parsimmon'

import { Project } from '../models3/Project'

export const ptxBookIds = [
    'GEN',
    'EXO',
    'LEV',
    'NUM',
    'DEU',
    'JOS',
    'JDG',
    'RUT',
    '1SA',
    '2SA', // 10

    '1KI',
    '2KI',
    '1CH',
    '2CH',
    'EZR',
    'NEH',
    'EST',
    'JOB',
    'PSA',
    'PRO', // 20

    'ECC',
    'SNG',
    'ISA',
    'JER',
    'LAM',
    'EZK',
    'DAN',
    'HOS',
    'JOL',
    'AMO', // 30

    'OBA',
    'JON',
    'MIC',
    'NAM',
    'HAB',
    'ZEP',
    'HAG',
    'ZEC',
    'MAL',
    'MAT', // 40

    'MRK',
    'LUK',
    'JHN',
    'ACT',
    'ROM',
    '1CO',
    '2CO',
    'GAL',
    'EPH',
    'PHP', // 50

    'COL',
    '1TH',
    '2TH',
    '1TI',
    '2TI',
    'TIT',
    'PHM',
    'HEB',
    'JAS',
    '1PE', // 60

    '2PE',
    '1JN',
    '2JN',
    '3JN',
    'JUD',
    'REV'

    /*
    'TOB',
    'JDT',
    'ESG',
    'WIS', // 70

    'SIR',
    'BAR',
    'LJE',
    'S3Y',
    'SUS',
    'BEL',
    '1MA',
    '2MA',
    '3MA',
    '4MA', // 80

    '1ES',
    '2ES',
    'MAN',
    'PS2',
    'ODA',
    'PSS',
    */
]

const arBookNames = [
    'تكوين',
    'خروج',
    'لاويين',
    'عدد',
    'تثنية',
    'يهوذا',
    'ارميا',
    'راعوث',
    '1 صموئيل',
    '2 صموئيل',

    '1 ملوك',
    '2 ملوك',
    '1 اخبار',
    '2 اخبار',
    'عزرا',
    'نحميا',
    'استير',
    'يونان',
    'مزامير',
    'امثال',

    'جامعة',
    'نشيد الانشاد',
    'اشعياء',
    'يوحنا',
    'مراثي',
    'حزقيال',
    'دانيال',
    'هوشع',
    'يشوع',
    'عاموس',

    'عوبديا',
    'يوئيل',
    'ميخا',
    'ناحوم',
    'حبقوق',
    'صفنيا',
    'حجى',
    'زكريا',
    'ملاخي',
    'متى',

    'مرقس',
    'لوقا',
    'ايوب',
    'اعمال',
    'رومية',
    '1 كورنثوس',
    '2 كورنثوس',
    'غلاطية',
    'افسس',
    'فيلبي',

    'كولوسي',
    '1 تسالونيكي',
    '2 تسالونيكي',
    '1 تيموثاوس',
    '2 تيموثاوس',
    'تيطس',
    'فليمون',
    'عبرانيين',
    'يعقوب',
    '1 بطرس',

    '2 بطرس',
    '1 يوحنا',
    '2 يوحنا',
    '3 يوحنا',
    'قضاة',
    'رؤيا'
]

const biBookNames = [
    'Jenesis',
    'Eksodas',
    'Levitikas',
    'Namba',
    'Dutronome',
    'Josua',
    'Jajes',
    'Rut',
    '1 Samuel',
    '2 Samuel',
    '1 King',
    '2 King',
    '1 Kronikel',
    '2 Kronikel',
    'Esra',
    'Nehemaea',
    'Esta',
    'Job',
    'Sam',
    'Ol Proveb',
    'Prija',
    'Sing Blong Solomon',
    'Aesea',
    'Jeremaea',
    'Ol Krae',
    'Esikel',
    'Daniel',
    'Hosea',
    'Joel',
    'Amos',
    'Obadaea',
    'Jona',
    'Maeka',
    'Neham',
    'Habakuk',
    'Sefanaea',
    'Hagae',
    'Sekaraea',
    'Malakae',
    'Matiu',
    'Mak',
    'Luk',
    'Jon',
    'Wok',
    'Rom',
    '1 Korin',
    '2 Korin',
    'Galesia',
    'Efesas',
    'Filipae',
    'Kolosi',
    '1 Tesalonaeka',
    '2 Tesalonaeka',
    '1 Timoti',
    '2 Timoti',
    'Taetas',
    'Filimon',
    'Hibrus',
    'Jemes',
    '1 Pita',
    '2 Pita',
    '1 Jon',
    '2 Jon',
    '3 Jon',
    'Jud',
    'Revelesen'
]

const enBookNames = [
    'Genesis',
    'Exodus',
    'Leviticus',
    'Numbers',
    'Deuteronomy',
    'Joshua',
    'Judges',
    'Ruth',
    '1 Samuel',
    '2 Samuel',

    '1 Kings',
    '2 Kings',
    '1 Chronicles',
    '2 Chronicles',
    'Ezra',
    'Nehemiah',
    'Esther',
    'Job',
    'Psalms',
    'Proverbs',

    'Ecclesiastes',
    'Song of Songs',
    'Isaiah',
    'Jeremiah',
    'Lamentations',
    'Ezekiel',
    'Daniel',
    'Hosea',
    'Joel',
    'Amos',

    'Obadiah',
    'Jonah',
    'Micah',
    'Nahum',
    'Habakkuk',
    'Zephaniah',
    'Haggai',
    'Zechariah',
    'Malachi',
    'Matthew',

    'Mark',
    'Luke',
    'John',
    'Acts',
    'Romans',
    '1 Corinthians',
    '2 Corinthians',
    'Galatians',
    'Ephesians',
    'Philippians',

    'Colossians',
    '1 Thessalonians',
    '2 Thessalonians',
    '1 Timothy',
    '2 Timothy',
    'Titus',
    'Philemon',
    'Hebrews',
    'James',
    '1 Peter',

    '2 Peter',
    '1 John',
    '2 John',
    '3 John',
    'Jude',
    'Revelation'
]

const esBookNames = [
    'Génesis',
    'Éxodo',
    'Levítico',
    'Números',
    'Deuteronomio',
    'Josué',
    'Jueces',
    'Rut',
    '1 Samuel',
    '2 Samuel',

    '1 Reyes',
    '2 Reyes',
    '1 Crónicas',
    '2 Crónicas',
    'Esdras',
    'Nehemías',
    'Ester',
    'Job',
    'Salmos',
    'Proverbios',

    'Eclesiastés',
    'Cantar de los cantares',
    'Isaías',
    'Jeremías',
    'Lamentaciones',
    'Ezequiel',
    'Daniel',
    'Oseas',
    'Joel',
    'Amós',

    'Abdías',
    'Jonás',
    'Miqueas',
    'Nahúm',
    'Habacuc',
    'Sofonías',
    'Hageo',
    'Zacarías',
    'Malaquías',
    'Mateo',

    'Marcos',
    'Lucas',
    'Juan',
    'Hechos',
    'Romanos',
    '1 Corintios',
    '2 Corintios',
    'Gálatas',
    'Efesios',
    'Filipenses',

    'Colosenses',
    '1 Tesalonicenses',
    '2 Tesalonicenses',
    '1 Timoteo',
    '2 Timoteo',
    'Tito',
    'Filemón',
    'Hebreos',
    'Santiago',
    '1 Pedro',

    '2 Pedro',
    '1 Juan',
    '2 Juan',
    '3 Juan',
    'Judas',
    'Apocalipsis'
]

const frBookNames = [
    'Genèse',
    'Exode',
    'Lévitique',
    'Nombres',
    'Deutéronome',
    'Josué',
    'Juges',
    'Ruth',
    '1 Samuel',
    '2 Samuel',

    '1 Rois',
    '2 Rois',
    '1 Chroniques',
    '2 Chroniques',
    'Esdras',
    'Néhémie',
    'Esther',
    'Job',
    'Psaumes',
    'Poverbes',

    'Ecclésiaste',
    'Cantiques',
    'Esaïe',
    'Jérémie',
    'Lamentations',
    'Ezéchiel',
    'Daniel',
    'Osée',
    'Joël',
    'Amos',

    'Abdias',
    'Jonas',
    'Michée',
    'Nahoum',
    'Habaquq',
    'Sophonie',
    'Aggée',
    'Zacharie',
    'Malachie',
    'Matthieu',

    'Marc',
    'Luc',
    'Jean',
    'Actes',
    'Romains',
    '1 Corinthiens',
    '2 Corinthiens',
    'Galates',
    'Ephésiens',
    'Philippiens',

    'Colossiens',
    '1 Thessaloniciens',
    '2 Thessaloniciens',
    '1 Timothée',
    '2 Timothée',
    'Tite',
    'Philémon',
    'Hébreux',
    'Jacques',
    '1 Pierre',

    '2 Pierre',
    '1 Jean',
    '2 Jean',
    '3 Jean',
    'Jude',
    'Apocalypse'
]

const haBookNames = [
    'Farawa',
    'Fitowa',
    'Littafin Firistoci',
    'Littafin Ƙidaya',
    'Maimaitawar Shari’a',
    'Joshuwa',
    'Littafin Mahukunta',
    'Rut',
    '1 Sama’ila',
    '2 Sama’ila',

    '1 Sarakuna',
    '2 Sarakuna',
    '1 Tarihi',
    '2 Tarihi',
    'Ezra',
    'Nehemiya',
    'Esta',
    'Ayuba',
    'Zabura',
    'Karin Magana',

    'Mai Hadishi',
    'Waƙar Waƙoƙi',
    'Ishaya',
    'Irmiya',
    'Makoki',
    'Ezekiyel',
    'Daniyel',
    'Yusha’u',
    'Yowel',
    'Amos',

    'Obadiya',
    'Yunusa',
    'Mika',
    'Nahum',
    'Habakuk',
    'Zafaniya',
    'Haggai',
    'Zakariya',
    'Malakai',
    'Matiyu',

    'Markas',
    'Luka',
    'Yahaya',
    'Ayyukan manzanni',
    'Romawa',
    '1 Korantiyawa',
    '2 Korantiyawa',
    'Galatiyawa',
    'Afisawa',
    'Filibiyawa',

    'Kolosiyawa',
    '1 Tasalonikawa',
    '2 Tasalonikawa',
    '1 Timoti',
    '2 Timoti',
    'Titus',
    'Filiman',
    'Ibraniyawa',
    'Yakubu',
    '1 Bitrus',

    '2 Bitrus',
    '1 Yahaya',
    '2 Yahaya',
    '3 Yahaya',
    'Yahuza',
    'Wahayin Yahaya'
]

const ptBookNames = [
    'Gênesis',
    'Êxodo',
    'Levítico',
    'Números',
    'Deuteronômio',
    'Josué',
    'Juízes',
    'Rute',
    '1 Samuel',
    '2 Samuel',

    '1 Reis',
    '2 Reis',
    '1 Crônicas',
    '2 Crônicas',
    'Esdras',
    'Neemias',
    'Ester',
    'Jó',
    'Salmos',
    'Provérbios',

    'Eclesiastes',
    'Cântico dos Cânticos',
    'Isaías',
    'Jeremias',
    'Lamentações',
    'Ezequiel',
    'Daniel',
    'Oseias',
    'Joel',
    'Amós',

    'Obadias',
    'Jonas',
    'Miquéias',
    'Naum',
    'Habacuque',
    'Sofonias',
    'Ageu',
    'Zacarias',
    'Malaquias',
    'Mateus',

    'Marcos',
    'Lucas',
    'João',
    'Atos',
    'Romanos',
    '1 Coríntios',
    '2 Coríntios',
    'Gálatas',
    'Efésios',
    'Filipenses',

    'Colossenses',
    '1 Tessalonicenses',
    '2 Tessalonicenses',
    '1 Timóteo',
    '2 Timóteo',
    'Tito',
    'Filemon',
    'Hebreus',
    'Tiago',
    '1 Pedro',

    '2 Pedro',
    '1 João',
    '2 João',
    '3 João',
    'Judas',
    'Apocalipse'
]

const ruBookNames = [
    'Бытие',
    'Исход',
    'Левит',
    'Числа',
    'Второзаконие',
    'Иисус Навин',
    'Книга Судей',
    'Руфь',
    '1-я Царств',
    '2-я Царств',

    '3-я Царств',
    '4-я Царств',
    '1 Пар.',
    '2 Пар.',
    'Ездра',
    'Неемия',
    'Есфирь',
    'Иов',
    'Псалтирь',
    'Притчи',

    'Екклесиаст',
    'Песни Песней',
    'Исаия',
    'Иеремия',
    'Плач Иеремии',
    'Иезекииль',
    'Даниил',
    'Осия',
    'Иоиль',
    'Амос',

    'Авдия',
    'Иона',
    'Михей',
    'Наум',
    'Аввакум',
    'Софония',
    'Аггей',
    'Захария',
    'Малахия',
    'от Матфея',

    'от Марка',
    'от Луки',
    'от Иоанна',
    'Деяния Апостолов',
    'Послание к Римлянам',
    '1-е Коринфянам',
    '2-е к Коринфянам',
    'Галатам',
    'Ефесянам',
    'Филиппийцам',

    'Колоссянам',
    '1-е Фессалоникийцам',
    '2-е Фессалоникийцам',
    '1-е Тимофею',
    '2-е Тимофею',
    'Титу',
    'Филимону',
    'Евреям',
    'Иакова',
    '1 Петра',

    '2 Петра',
    '1 Иоанна',
    '2 Иоанна',
    '3 Иоанна',
    'Джуд',
    'Откровение'
]

const swBookNames = [
    'Mwanzo',
    'Kutoka',
    'Walawi',
    'Hesabu',
    'Kumbukumbu la Sheria',
    'Yuda',
    'Yeremia',
    'Ruthu',
    '1 Samueli',
    '2 Samueli',

    '1 Wafalme',
    '2 Wafalme',
    '1 Mambo ya Nyakati',
    '2 Mambo ya Nyakati',
    'Ezra',
    'Nehemia',
    'Esta',
    'Yona',
    'Zaburi',
    'Methali',

    'Mhubiri',
    'Wimbo Ulio Bora',
    'Isaya',
    'Yohana',
    'Maombolezo',
    'Ezekieli',
    'Danieli',
    'Hosea',
    'Yoshua',
    'Amosi',

    'Obadia',
    'Yoeli',
    'Mika',
    'Nahumu',
    'Habakuki',
    'Sefania',
    'Hagai',
    'Zekaria',
    'Malaki',
    'Mathayo',

    'Marko',
    'Luka',
    'Yobu',
    'Matendo',
    'Waroma',
    '1 Wakorintho',
    '2 Wakorintho',
    'Wagalatia',
    'Waefeso',
    'Wafilipi',

    'Wakolosai',
    '1 Wathesalonike',
    '2 Wathesalonike',
    '1 Timotheo',
    '2 Timotheo',
    'Tito',
    'Filemoni',
    'Waebrania',
    'Yakobo',
    '1 Petro',

    '2 Petro',
    '1 Yohane',
    '2 Yohane',
    '3 Yohane',
    'Waamuzi',
    'Ufunuo'
]

const tpiBookNames = [
    'Jenesis',
    'Kisim Bek',
    'Wok Pris',
    'Namba',
    'Lo',
    'Josua',
    'Hetman',
    'Rut',
    '1 Samuel',
    '2 Samuel',

    '1 King',
    '2 King',
    '1 Stori',
    '2 Stori',
    'Esra',
    'Nehemia',
    'Esta',
    'Jop',
    'Buk Song',
    'Gutpela Sindaun',

    'Saveman',
    'Solomon',
    'Aisaia',
    'Jeremaia',
    'Krai',
    'Esekiel',
    'Daniel',
    'Hosea',
    'Joel',
    'Amos',

    'Obadia',
    'Jona',
    'Maika',
    'Nahum',
    'Habakuk',
    'Sefanaia',
    'Hagai',
    'Sekaraia',
    'Malakai',
    'Matyu',

    'Mak',
    'Luk',
    'Jon',
    'Aposel',
    'Rom',
    '1 Korin',
    '2 Korin',
    'Galesia',
    'Efesus',
    'Filipai',

    'Kolosi',
    '1 Tesalonaika',
    '2 Tesalonaika',
    '1 Timoti',
    '2 Timoti',
    'Taitus',
    'Filemon',
    'Hibru',
    'Jems',
    '1 Pita',

    '2 Pita',
    '1 Jon',
    '2 Jon',
    '3 Jon',
    'Jut',
    'Kamapim Tok Hait'
]

const zhCnBookNames = [
    '创世纪',
    '出埃及记',
    '利未记',
    '民数记',
    '申命记',
    '约书亚记',
    '士师记',
    '路得记',
    '撒母耳记上',
    '撒母耳记下',
    '列王记上',
    '列王记下',
    '历代志上',
    '历代志下',
    '以斯拉记',
    '尼希米记',
    '以斯帖记',
    '约伯记',
    '诗篇',
    '箴言',
    '传道书',
    '雅歌',
    '以赛亚书',
    '耶利米书',
    '耶利米哀歌',
    '以西结书',
    '但以理书',
    '何西阿书',
    '约珥书',
    '阿摩司书',
    '俄巴底亚书',
    '约拿书',
    '弥迦书',
    '那鸿书',
    '哈巴谷书',
    '西番雅书',
    '哈该书',
    '撒迦利亚',
    '玛拉基书',
    '马太福音',
    '马可福音',
    '路加福音',
    '约翰福音',
    '使徒行传',
    '罗马书',
    '歌林多前书',
    '歌林多后书',
    '加拉太书',
    '以弗所书',
    '腓利比书',
    '歌罗西书',
    '帖撒罗尼迦前书',
    '帖撒罗尼迦后书',
    '提摩太前书',
    '提摩太后书',
    '提多书',
    '腓利门书',
    '希伯来书',
    '雅各书',
    '彼得前书',
    '彼得后书',
    '约翰一书',
    '约翰二书',
    '约翰三书',
    '犹大书',
    '启示录'
]

export const bookNamesByLanguage: { [key: string]: string[] } = {
    ar: arBookNames,
    bi: biBookNames,
    en: enBookNames,
    es: esBookNames,
    fr: frBookNames,
    ha: haBookNames,
    pt: ptBookNames,
    ru: ruBookNames,
    tpi: tpiBookNames,
    sw: swBookNames,
    'zh-CN': zhCnBookNames
}

export const getBookNames = (languageCode: string) => bookNamesByLanguage[languageCode] ?? bookNamesByLanguage.en

// return a list of human readable book names in canonical order
export const displayableBookNames = (project?: Project) =>
    project ? Object.values(project.bookNames) : bookNamesByLanguage.en

// \u0030 - \u0039 are the digits 0-9
export const validBookNameParser = P.regexp(/([0-9] )?[\u0021-\u002F\u003A-\uFFFF]+( [\u0021-\u002F\u003A-\uFFFF]+)*/u)

const getBookNameMap = (validBookNames: string[]) => {
    const bookNameToBookCount: any = {}
    validBookNames.forEach((bookName, idx) => {
        for (let i = 2; i <= bookName.length; ++i) {
            const partial = bookName.slice(0, i).toLowerCase()
            if (bookNameToBookCount[partial] !== undefined)
                bookNameToBookCount[partial] = 0 // 2 books have same partial
            else bookNameToBookCount[partial] = idx + 1
        }
    })
    return bookNameToBookCount
}

const bookNameParser = (bookNameMap: any): P.Parser<number> =>
    validBookNameParser
        .map((s) => String(s))
        .chain((name) => {
            const n = bookNameMap[name.toLowerCase()]

            if (n) {
                return P.succeed(n)
            }
            return P.fail('*Invalid book name')
        })

export const projectBookNameParser = (allBookNames: string[]): P.Parser<number> => {
    const bookNamesMap = getBookNameMap(allBookNames)
    return bookNameParser(bookNamesMap)
}

export const languageBookNameParser = (languageCode: string): P.Parser<number> => {
    const allBookNames = getBookNames(languageCode)
    return projectBookNameParser(allBookNames)
}
