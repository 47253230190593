import { FC } from 'react'

import { observer } from 'mobx-react'

import { useAppRoot } from './RootContext'
import { Root } from '../../models3/Root'
import { groupBy } from '../utils/Helpers'

interface ProjectSelectorProps {
    currentRoot: Root
    selectProject: (projectName: string) => void
}

export const ProjectSelector: FC<ProjectSelectorProps> = observer(({ currentRoot, selectProject }) => {
    const appRoot = useAppRoot()

    const projectOption = (name: string, displayName: string) => {
        return (
            <option value={name} key={name}>
                {displayName}
            </option>
        )
    }

    if (!appRoot.rts.length) {
        return (
            <div className="project-selector-dropdown">
                <div>{projectOption(currentRoot.name, currentRoot.displayName)}</div>
            </div>
        )
    }

    const projects = appRoot.rts
        .slice()
        .sort((a, b) => a.displayName.toLocaleLowerCase().localeCompare(b.displayName.toLocaleLowerCase()))
        .map(({ name, displayName, group }) => {
            return { name, displayName, group: group ?? '' }
        })

    const projectsByGroup = groupBy(
        projects.filter((project) => Boolean(project.group)),
        (rt) => rt.group
    )
    const groups = Object.keys(projectsByGroup)

    return (
        <div className="project-selector-dropdown">
            <div>
                <select
                    className="custom-select"
                    value={currentRoot.name}
                    onChange={(e) => selectProject(e.target.value)}
                >
                    {projects
                        .filter((project) => !project.group && !groups.includes(project.name))
                        .map(({ name, displayName }) => projectOption(name, displayName))}

                    {groups.map((group) => (
                        <optgroup label={group} key={group}>
                            {projectOption(group, group)}
                            {projectsByGroup[group].map(({ name, displayName }) => projectOption(name, displayName))}
                        </optgroup>
                    ))}
                </select>
            </div>
        </div>
    )
})
