import { t } from 'i18next'
import { delay } from 'q'
import _ from 'underscore'

import { displayInfo } from './Errors'
import { s } from './Fmt'
import { AudioSection, doRangesOverlap } from './Helpers'
import { fetchBlob2 } from '../../models3/API2'
import { FfmpegParameters } from '../../models3/FfmpegParameters'
import { Passage } from '../../models3/Passage'
import { PassageVideo, VideoSlice } from '../../models3/PassageVideo'
import { MimeType } from '../../types'
import { ViewableVideoCollection } from '../video/ViewableVideoCollection'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:VideoCompressor')

const COMPRESSION_SERVER_ORIGIN = 'http://localhost:29678'
const VIDEO_DIMENSION_LARGE = { width: 1280, height: 720 }
const VIDEO_DIMENSION_SMALL = { width: 854, height: 480 }

let firstFileNameLog = true

function fileNameLog(name: string) {
    const parts = name.split('/')

    if (firstFileNameLog) {
        firstFileNameLog = false
        log('temp directory', parts.slice(0, -1).join('/'))
    }

    return `...${name.slice(name.endsWith('.mp4') ? -8 : -4)}`
}

function sizeInMb(_size: number) {
    return `${(_size / (1024 * 1024)).toFixed(1)}mb`
}

const sliceOverlapsSection = (slice: VideoSlice, section: AudioSection) =>
    doRangesOverlap(section, {
        start: slice.video.positionToTime(slice.position),
        end: slice.video.positionToTime(slice.endPosition)
    })

const fitSliceInsideSection = (slice: VideoSlice, passage: Passage, section: AudioSection) => {
    const sectionStartPosition = slice.video.timeToPosition(passage, section.start)
    const sectionEndPosition = slice.video.timeToPosition(passage, section.end)
    const start = Math.max(sectionStartPosition, slice.position)
    const end = Math.min(sectionEndPosition, slice.endPosition)
    return new VideoSlice(slice.video, start, end, slice.src)
}

/*
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
IMPORTANT NOTE: When cherry-picking the changes from the compressor-v2 branch
there are some other changes you must also get. The changes deal with setting the
mimeType field on PassageVideo These changes most likely come from:
- DB acceptor
- ProjectModels
- any files that set the mimeType of PassageVideo.
There may be more files with changes, but these are the most likely candidates.
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
*/

/*
Prior to AVTT 0.4, we were not tracking the mime type of each PassageVideo. Without
the mime type, we can't easily tell from the client (here) if the PassageVideo is
storing a video or an audio file. For this reason, PassageVideos with no mime type
are exported as mp4s, just like we do with videos. This is the easiest solution for
now.

If the PassageVideo was created in AVTT 0.4 or newer, then we do have the mime type.

This is displayed in the chart below.

----------------------------------------------------------------
PassageVideo storing
audio created in      < AVTT 0.4       >= AVTT 0.4      >= 0.8.1
----------------------------------------------------------------
container             mp4 (with AAC
                      audio track and
                      no video track)     mp3            ogg
----------------------------------------------------------------
codec                 libx264             libmp3lame     opus
----------------------------------------------------------------
bitrate               varies              96kbps
----------------------------------------------------------------
sample rate           varies              44.1 kHz
----------------------------------------------------------------
# channels            varies              1
----------------------------------------------------------------
*/

type VideoCompressionOptions = {
    crf: number
    resolution: number
    maxFileSizeMB: number
    rescaleVideo?: boolean
}

class VideoCompressor {
    private ffmpegParameters = new FfmpegParameters()

    public crf: number

    public resolution: number

    public maxFileSizeMB: number

    private setProgressMessage: (message: string) => void

    public rescaleVideo = true

    constructor(options: VideoCompressionOptions, setProgressMessage: (message: string) => void) {
        const { crf, resolution, maxFileSizeMB, rescaleVideo } = options
        this.crf = crf
        this.resolution = resolution
        this.maxFileSizeMB = maxFileSizeMB
        this.setProgressMessage = setProgressMessage
        if (rescaleVideo !== undefined) {
            this.rescaleVideo = rescaleVideo
        }
    }

    // If you make any changes here, make sure that you update PassageVideo.resolution
    // and PassageVideo.crf!
    setFfmpegParameters = (resolution: number, crf: number) => {
        const ffmpegParameters = new FfmpegParameters()

        ffmpegParameters.outputOptions = [`-crf ${crf}`, '-pix_fmt yuv420p', '-r 30', '-c:v libx264']

        if (this.rescaleVideo) {
            const { height, width } =
                resolution > VIDEO_DIMENSION_SMALL.height ? VIDEO_DIMENSION_LARGE : VIDEO_DIMENSION_SMALL

            // https://ffmpeg.org/ffmpeg-filters.html#pad

            ffmpegParameters.videoFilters = [
                `scale=-1:${height}`,
                `pad='max(iw,${width})':${height}:-1:-1`,
                `crop=${width}:${height}`
            ]

            log(
                `setFfmpegParameters crf=${crf} scale=${JSON.stringify(ffmpegParameters.videoFilters)}`,
                ffmpegParameters
            )
        }

        this.ffmpegParameters = ffmpegParameters
    }

    setAudioFfmpegParameters = () => {
        const ffmpegParameters = new FfmpegParameters()

        ffmpegParameters.outputOptions = [
            '-codec:a libmp3lame',
            '-ac 1', // number of channels
            '-b:a 96k',
            '-ar 44100' // sample rate
        ]

        log('setFfmpegParameters', ffmpegParameters)
        this.ffmpegParameters = ffmpegParameters
    }

    deleteSection = async (vvc: ViewableVideoCollection, passage: Passage, sectionsToKeepSeconds: AudioSection[]) => {
        this.setProgressMessage(t('Initializing...'))
        const { video } = vvc.viewableVideos[0]

        // get all slices
        const slices = video.createSlicesWithNoGaps(passage, -1, -1)

        // get slices that do not include deleted section
        const includedSlices = sectionsToKeepSeconds.reduce((prev, section) => {
            const overlappingSlices = slices
                .filter((slice) => sliceOverlapsSection(slice, section))
                .map((slice) => fitSliceInsideSection(slice, passage, section))
            return prev.concat(...overlappingSlices)
        }, [] as VideoSlice[])

        return this.concatenateParts(vvc, passage, includedSlices, video)
    }

    concatenateSelection = async (
        vvc: ViewableVideoCollection,
        passage: Passage,
        selectionStartTime: number,
        selectionEndTime: number,
        fileExtension?: string
    ) => {
        this.setProgressMessage(t('Initializing...'))
        const { video } = vvc.viewableVideos[0]
        const slices = video.createSlicesWithNoGaps(passage, selectionStartTime, selectionEndTime)
        return this.concatenateParts(vvc, passage, slices, video, fileExtension)
    }

    compressVideo = async (file: File) => {
        let uploadedFilePath = ''
        let compressedFilePath = ''

        try {
            this.setProgressMessage(t('Starting compression...'))

            this.checkFreeSpace(file.size) // throws if not enough free space

            uploadedFilePath = await this.uploadFileToServer(file)

            let fileExtension = 'mp4'
            if (file.type.startsWith('audio')) {
                fileExtension = 'mp3'
                await this.verifyVersionTwoRunning()
            }

            if (fileExtension === 'mp4') {
                this.setFfmpegParameters(this.resolution, this.crf)
            } else {
                this.setAudioFfmpegParameters()
            }

            compressedFilePath = await this.compressVideoWithId(uploadedFilePath, fileExtension)

            log('waitForFileToGenerate', fileNameLog(compressedFilePath))
            await this.waitForFileToGenerate(compressedFilePath, 0, 1, this.setProgressMessage)

            const compressedFile = await this.downloadFile(compressedFilePath)
            log('compressedFile size', sizeInMb(compressedFile.size))

            return { compressedFile, ffmpegParameters: this.ffmpegParameters }
        } finally {
            await this.deleteFile(uploadedFilePath)
            await this.deleteFile(compressedFilePath)
        }
    }

    // In order to download a video we first concatenate all its parts.
    // If you want to force all videos to have a certain file extension, provide a
    // value for fileExtension
    private concatenateParts = async (
        vvc: ViewableVideoCollection,
        passage: Passage,
        slices: VideoSlice[],
        passageVideo: PassageVideo,
        fileExtension?: string
    ) => {
        await vvc.waitUntilDownloaded()
        const uploadedSrcs = []
        let srcsToConcatenate: string[] = []
        let concatenatedFilePath = ''

        try {
            const uploadedSrcsMap = new Map<string, string>()

            // Upload all the pieces of this video to the compression server
            for (const slice of slices) {
                slice.src = uploadedSrcsMap.get(slice.video._id) || ''

                if (!slice.src) {
                    const blob = await vvc.getBlob(slice.video)
                    slice.src = await this.uploadFileToServer(blob as File)
                    uploadedSrcsMap.set(slice.video._id, slice.src)
                }

                uploadedSrcs.push(slice.src)
            }

            let fileExtensionToUse = 'mp3'
            if (slices.every((slice) => slice.video.mimeType.startsWith('video'))) {
                fileExtensionToUse = 'mp4'
            }

            if (fileExtension !== undefined) {
                fileExtensionToUse = fileExtension
            }

            if (fileExtensionToUse === 'mp3') {
                await this.verifyVersionTwoRunning()
            }

            const compressedSlices = await this.compressAndSlice(
                passageVideo,
                passage,
                slices,
                this.setProgressMessage,
                fileExtensionToUse
            )
            srcsToConcatenate = compressedSlices.map((slice) => slice.src)
            if (srcsToConcatenate.length === 0) {
                throw new Error('No files to concatenate')
            }

            this.setProgressMessage(t('Finalizing...'))
            concatenatedFilePath = srcsToConcatenate[0]

            if (srcsToConcatenate.length > 1) {
                concatenatedFilePath = await this.concatenateWithIds(srcsToConcatenate, fileExtensionToUse)
                await this.waitForFileToGenerate(concatenatedFilePath, 0, 1, () => {})
            }

            return await this.downloadConcatenatedFile(concatenatedFilePath)
        } finally {
            this.setProgressMessage('')
            log('concatenateVideos delete temporary files')
            const filesToDelete = _.uniq([...uploadedSrcs, ...srcsToConcatenate, concatenatedFilePath])
            for (const src of filesToDelete) {
                await this.deleteFile(src)
            }
        }
    }

    // Return a list of files which we will concatenate to form the final video.
    // All these files will be compressed with the same parameters so joining them will be fast.
    //
    // If base video has not been compressed, compress all videos using team preferences
    // If base video has been compressed, all videos should have same resolution as base video
    // Also need to compress videos that are being sliced
    //
    // We pass fileExtension as an argument because when concatenating files, we need
    // every file to have the same codec.
    private compressAndSlice = async (
        passageVideo: PassageVideo,
        passage: Passage,
        slices: VideoSlice[],
        setProgressMessage: (message: string) => void,
        fileExtension: string
    ) => {
        const returnSlices: VideoSlice[] = []
        try {
            const baseVideo = passageVideo.baseVideo(passage) || passageVideo

            // Setup desired resolution and crf for resulting files
            const resolution = baseVideo.isCompressed ? baseVideo.resolution : this.resolution
            const crf = baseVideo.isCompressed ? baseVideo.crf : this.crf

            for (const [i, slice] of slices.entries()) {
                log(`compressAndSlice slice=${i}`)
                const { position, endPosition, video, src } = slice

                if (position + 0.1 > endPosition) {
                    log(`compressAndSlice - skip tiny slice [${i}, ${position}..${endPosition}]`)
                    continue
                }

                const trimmed = position > 0 || endPosition < video.duration

                // In theory we could skip compression of some segments.
                // However, if something caused the video dimensions of one segment to be in any way
                // different from another sections, it looks to me like playback become very erratic
                // in some players. For now just compress everything.

                // If this video is not trimmed and already has the desired resolution, no further compression is needed
                // if (!trimmed && video.resolution === resolution && video.crf === crf) {
                //     srcsToConcatenate.push(src)
                //     log(`compressAndSlice - no compression needed [${i}, ${fileNameLog(src)}]`)
                //     debugger
                //     continue
                // }

                if (fileExtension === 'mp4') {
                    this.setFfmpegParameters(resolution, crf)
                } else {
                    this.setAudioFfmpegParameters()
                }

                if (trimmed) {
                    log(`startPosition=${position.toFixed(1)}, endPosition=${endPosition.toFixed(1)}`)
                    this.ffmpegParameters.outputOptions.push(`-ss ${position}`)
                    this.ffmpegParameters.outputOptions.push(`-to ${endPosition}`)
                }

                const compressedFilePath = await this.compressVideoWithId(src, fileExtension)

                await this.waitForFileToGenerate(compressedFilePath, i, slices.length, setProgressMessage)

                returnSlices.push(new VideoSlice(slice.video, slice.position, slice.endPosition, compressedFilePath))
            }

            return returnSlices
        } catch (error) {
            for (const slice of returnSlices) {
                await this.deleteFile(slice.src)
            }
            throw error
        }
    }

    private waitForFileToGenerate = async (
        filePath: string,
        index: number,
        total: number,
        setProgressMessage: (message: string) => void
    ) => {
        while (true) {
            const { error, finished, percent } = await this.getProgress(filePath)

            if (error) {
                log('waitForFileToGenerate ERROR', s(error))
                throw new Error(error)
            }

            if (finished) {
                break
            }

            let message = t('recordingCompressing')
            if (total > 1) {
                message += ` ${index + 1}/${total}`
            }
            message += '.'

            if (percent) {
                message += ` ${percent.toFixed(0)}%`
            }

            setProgressMessage(message)

            await delay(1000)
        }
    }

    private deleteFile = async (filePath: string) => {
        filePath = filePath.trim()
        if (filePath === '') return
        log('deleteFile', filePath)

        const response = await fetch(`${COMPRESSION_SERVER_ORIGIN}/?filePath=${filePath}`, {
            method: 'DELETE'
        })

        if (!response.ok) {
            throw new Error(`${response.status} - ${response.statusText}`)
        }
    }

    private compressVideoWithId = async (filePath: string, fileExtension: string) => {
        const { ffmpegParameters } = this
        const body = JSON.stringify({
            filePath,
            ffmpegParameters,
            fileExtension
        })

        log('compressVideoWithId input=', fileNameLog(filePath), s(ffmpegParameters))

        const response = await fetch(`${COMPRESSION_SERVER_ORIGIN}/compress`, {
            method: 'PUT',
            headers: { 'Content-Type': MimeType.JSON },
            body
        })

        // log('compressVideoWithId', response)
        if (!response.ok) {
            throw new Error(`${response.status} - ${response.statusText}`)
        }

        const { filePath: _filePath } = await response.json()
        log('compressVideoWithId result=', fileNameLog(_filePath))
        if (!_filePath) throw Error('Compress, no filePath returned')
        return _filePath as string
    }

    // private getFileMetadata = async (filePath: string) => {
    //     let response = await fetch(`${COMPRESSION_SERVER_ORIGIN}/metadata/?filePath=${filePath}`)
    //     log('getFileMetadata', s(response))

    //     if (!response.ok) {
    //         throw new Error(`${response.status} - ${response.statusText}`)
    //     }
    //     return response.json()
    // }

    private checkFreeSpace = async (_size: number) => {
        const response = await fetch(`${COMPRESSION_SERVER_ORIGIN}/freeSpace`)
        if (!response.ok) {
            throw new Error(`${response.status} - ${response.statusText}`)
        }
        const json = await response.json()
        const { freeSpace } = json

        if (freeSpace < 1.5 * _size) {
            const error = new Error('Not enough free space available')
            error.name = 'NotEnoughFreeSpace'
            throw error
        }
    }

    // Concatenates all the files and returns a path to the concatenated file.
    private concatenateWithIds = async (ids: string[], fileExtension: string) => {
        log(
            'concatenateWithIds input=',
            ids.map((id) => fileNameLog(id))
        )

        const body = JSON.stringify({ filePaths: ids, fileExtension })
        const response = await fetch(`${COMPRESSION_SERVER_ORIGIN}/concatenate`, {
            method: 'PUT',
            headers: { 'Content-Type': MimeType.JSON },
            body
        })

        if (!response.ok) {
            throw new Error(`${response.status} - ${response.statusText}`)
        }

        const { filePath } = await response.json()
        log('concatenateWithIds result=', fileNameLog(filePath))
        if (!filePath) throw Error('concatenateWithIds, no filePath returned')
        return filePath as string
    }

    // Upload a file to the server and return a path to the uploaded version of the file.
    private uploadFileToServer = async (file: File) => {
        log(`uploadFileToServer size=${sizeInMb(file.size)}, type=${file.type}`)

        const formData = new FormData()
        formData.append('file', file, file.name)
        const response = await fetch(COMPRESSION_SERVER_ORIGIN, {
            method: 'PUT',
            body: formData
        })

        if (!response.ok) {
            const error = new Error(response.statusText)
            if (response.status === 413) {
                error.name = 'PayloadTooLarge'
            }
            throw error
        }

        const { filePath } = await response.json()
        log('uploadFileToServer done', fileNameLog(filePath))
        if (!filePath) throw Error('Upload, no filePath returned')
        return filePath as string
    }

    private async getProgress(filePath: string) {
        const response = await fetch(`${COMPRESSION_SERVER_ORIGIN}/progress/?filePath=${filePath}`)
        if (!response.ok) {
            throw new Error(`${response.status} - ${response.statusText}`)
        }

        const { error, finished, percent } = await response.json()
        return { error, finished, percent }
    }

    private async downloadFile(filePath: string) {
        const data = await fetchBlob2(`${COMPRESSION_SERVER_ORIGIN}/?filePath=${filePath}`, () => {})
        log('downloadFile', sizeInMb(data.size))

        let fileExtension = 'mp4'
        if (data.type.startsWith('audio')) {
            fileExtension = 'mp3'
        }

        this.setProgressMessage('')
        const file = new File([data], `compressed-video.${fileExtension}`, { type: data.type })
        return file
    }

    private async downloadConcatenatedFile(filePath: string) {
        const data = await fetchBlob2(`${COMPRESSION_SERVER_ORIGIN}/?filePath=${filePath}`, () => {})
        log('downloadConcatenatedFile size=', sizeInMb(data.size))

        let fileExtension = 'mp4'
        if (data.type.startsWith('audio')) {
            fileExtension = 'mp3'
        }

        this.setProgressMessage('')
        const file = new File([data], `concatenated-video.${fileExtension}`, { type: data.type })
        return file
    }

    static async getVersion() {
        try {
            const version = await fetch(`${COMPRESSION_SERVER_ORIGIN}/version`)
            const data = await version.json()
            return data.version as string
        } catch (err) {
            return ''
        }
    }

    static async checkIfServerRunning() {
        const isServerRunning = await VideoCompressor.isServerRunning()
        if (isServerRunning) return true

        displayInfo(
            t('Before doing this, start sltt_video_compressor. For more information click here: '),
            'compressor'
        )
        return false
    }

    static async isServerRunning() {
        try {
            const response = await fetch(`${COMPRESSION_SERVER_ORIGIN}/version`)
            if (!response.ok) {
                return false
            }
            return true
        } catch (err) {
            return false
        }
    }

    async verifyVersionTwoRunning() {
        const compressorVersion = await VideoCompressor.getVersion()
        if (compressorVersion === '1.0') {
            displayInfo(
                t(
                    'You need a newer version of sltt_video_compressor for this feature. To get the newer version, click here: '
                ),
                'compressor'
            )
            throw new Error()
        }
    }
}

export default VideoCompressor
