import JSZip from 'jszip'

import { Passage } from '../../models3/Passage'
import { Portion } from '../../models3/Portion'
import { Project } from '../../models3/Project'
import { refToBookId, refToPtxBookId, refRangesMinMax } from '../../resources/RefRange'
import { refToUsfmBookHeader } from '../../resources/Usfm'
import { ExportTextFormat, ExportTextType, ExportType } from '../../types'
import { groupBy } from '../utils/Helpers'

// Sort by starting reference, then by ending reference, then by name
// Warning: input passages array is mutated, so make a copy if you need to keep the original
export const getSortedPassages = (passages: Passage[]) =>
    passages.sort((a, b) => {
        // defaultReferences is a getter function, so save it to avoid repeated calls
        const aReferences = a.defaultReferences
        const bReferences = b.defaultReferences

        if (!aReferences.length && !bReferences.length) {
            return a.name.localeCompare(b.name)
        }

        if (!aReferences.length) {
            return 1 // "a" is after "b" since "a" has no references
        }

        if (!bReferences.length) {
            return -1 // "a" is before "b" since "b" has no references
        }

        const aRefSummary = refRangesMinMax(aReferences)
        const bRefSummary = refRangesMinMax(bReferences)

        return (
            aRefSummary.startRef.localeCompare(bRefSummary.startRef) ||
            aRefSummary.endRef.localeCompare(bRefSummary.endRef) ||
            a.name.localeCompare(b.name)
        )
    })

export const rerankPortionPassages = async (portion: Portion) => {
    const passagesCopy = getSortedPassages([...portion.passages]) // do not mutate the original array
    await Promise.all(passagesCopy.map((passage, index) => passage.setRank((index + 1) * 100)))
}

export const getSortedPassagesGroupedByBook = (passages: Passage[]) => {
    const withRecording = passages.filter((passage) => passage.videosNotDeleted.length)
    const sorted = getSortedPassages(withRecording).filter((passage) => passage.defaultReferences.length)
    return groupBy(sorted, (passage) => refToBookId(passage.defaultReferences[0].startRef))
}

export interface BookText {
    bookId: string
    text: string
}

export const getBookTexts = ({
    passages,
    project,
    exportTextType,
    exportTextFormat
}: {
    passages: Passage[]
    project: Project
    exportTextType: ExportTextType
    exportTextFormat?: ExportTextFormat
}): BookText[] => {
    const bookPassages = getSortedPassagesGroupedByBook(passages)
    const bookTexts: BookText[] = []

    for (const bookId of Object.keys(bookPassages)) {
        const passagesForBook = bookPassages[bookId]
        const textsForBook: string[] = []
        let previousChapterId = ''

        for (const passage of passagesForBook) {
            const passageText =
                exportTextType === ExportTextType.TRANSCRIPTION
                    ? passage.latestVideo?.getTranscription({
                          passage,
                          project,
                          exportTextFormat,
                          includeHeader: false,
                          previousChapterId
                      })
                    : passage.latestVideo?.getBackTranslation({ passage, project })

            previousChapterId = passageText?.lastChapterId ?? ''
            if (passageText) {
                textsForBook.push(passageText.text)
            }
        }

        if (textsForBook.length > 0) {
            const concatenatedText = textsForBook.join('\n')
            const header = exportTextFormat === ExportTextFormat.USFM ? refToUsfmBookHeader(bookId, project) : ''
            bookTexts.push({ bookId, text: `${header}${concatenatedText}` })
        }
    }

    return bookTexts
}

export const bookTextsToString = (bookTexts?: BookText[]) =>
    bookTexts?.map(({ text: bookText }) => bookText).join('\n') ?? ''

export const getFileExtension = ({
    exportType,
    exportTextFormat
}: {
    exportType: ExportType
    exportTextFormat?: ExportTextFormat
}) => (exportType === ExportType.TRANSCRIPTION && exportTextFormat === ExportTextFormat.USFM ? '.usfm' : '.txt')

export const bookTextsToZip = async ({
    bookTexts,
    exportType,
    exportTextFormat
}: {
    bookTexts: BookText[]
    exportType: ExportType
    exportTextFormat?: ExportTextFormat
}) => {
    const zip = new JSZip()
    const fileExtension = getFileExtension({ exportType, exportTextFormat })
    const folderName =
        exportType === ExportType.TRANSCRIPTION ? ExportTextType.TRANSCRIPTION : ExportTextType.BACK_TRANSLATION

    bookTexts.forEach(({ bookId, text }) => {
        const ptxBookId = refToPtxBookId(bookId)
        zip.file(`${folderName}/${ptxBookId}${fileExtension}`, text)
    })

    const zipFile = await zip.generateAsync({ type: 'blob' })
    return new Blob([zipFile])
}
