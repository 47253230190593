import { observable } from 'mobx'

import { DBObject } from './DBObject'

export class TargetGloss extends DBObject {
    @observable lexicalLink = ''

    @observable text = ''

    static model = 21

    toDocument() {
        const { lexicalLink, text } = this
        return this._toDocument({ lexicalLink, text, model: TargetGloss.model })
    }
}
