import { observable } from 'mobx'

import { newerThanCutoffDate, IDateFormatter } from './DateUtilities'
import { DBObject } from './DBObject'
import { IDB } from './IDB'
import { normalizeUsername } from './Utils'
import { isAllowedImage } from '../components/images/AllowedImages'

export class PassageNoteItem extends DBObject {
    position = 0

    duration = 0

    @observable fileType = ''

    @observable url = ''

    @observable text = ''

    @observable resolved = false

    @observable unresolved = false

    @observable consultantOnly = false

    @observable _rev = 0

    // email address of people who have viewed this
    @observable viewedBy: string[] = []

    constructor(_id: string, db: IDB) {
        super(_id, db)
        this.setUMTRank() // ensure items sorted by UMT time
    }

    toDocument() {
        const { position, duration, fileType, url, text, resolved, unresolved, viewedBy, rank, consultantOnly } = this
        return this._toDocument({
            position,
            duration,
            fileType,
            url,
            text,
            resolved,
            unresolved,
            viewedBy,
            rank,
            consultantOnly
        })
    }

    toSnapshot() {
        const snapshot = this.toDocument()

        return snapshot
    }

    dbg() {
        const doc = this.toDocument()
        return doc
    }

    copy() {
        let copy = new PassageNoteItem(this._id, this.db)
        copy = Object.assign(copy, this)
        copy.viewedBy = Array.from(this.viewedBy)
        return copy
    }

    isTextItem() {
        return this.text || !this.url
    }

    isVideoItem() {
        return this.fileType && !isAllowedImage(this.fileType)
    }

    isAudioItem() {
        return this.fileType.startsWith('audio')
    }

    isImageItem() {
        return this.fileType && isAllowedImage(this.fileType)
    }

    async setConsultantOnly(consultantOnly: boolean) {
        if (this.consultantOnly === consultantOnly) {
            return
        }

        const doc = this._toDocument({})
        doc.consultantOnly = consultantOnly
        await this.db.put(doc)
    }

    async updateText(text: string): Promise<void> {
        const doc = this._toDocument({})
        if (this.text === text) {
            return
        }
        doc.text = text
        await this.db.put(doc)
    }

    async addViewedBy(email: string) {
        const { viewedBy } = this
        email = normalizeUsername(email)

        if (email === '' || this.creator === email || viewedBy.includes(email)) return

        const doc = this._toDocument({})
        // Force the _id for a task change to be different than the _id for the base passage
        doc._id += '@viewedby'

        doc.viewedByUser = email
        await this.db.put(doc)
    }

    isUnviewedAfterDate(username: string, cutoff: Date) {
        const { creator, viewedBy, resolved, unresolved } = this
        const newerThanCutoff = newerThanCutoffDate(this.creationDate, cutoff)
        const isCreator = creator === username
        const hasViewed = viewedBy.includes(username)
        return newerThanCutoff && !resolved && !unresolved && !isCreator && !hasViewed
    }

    displayedCreationDate(dateFormatter: IDateFormatter) {
        const date = new Date(this.creationDate)
        return dateFormatter.format(date)
    }
}
