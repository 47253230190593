import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { LocalStorageKeys } from '../app/slttAvtt'
import { getNumberFromLocalStorage } from '../utils/Helpers'

export const useReviewProjectTabIndex: () => [number, Dispatch<SetStateAction<number>>] = () => {
    const [tabIndex, setTabIndex] = useState(() =>
        getNumberFromLocalStorage({
            key: LocalStorageKeys.REVIEW_PROJECT_TAB_INDEX,
            defaultValue: 0
        })
    )

    useEffect(() => {
        localStorage.setItem(LocalStorageKeys.REVIEW_PROJECT_TAB_INDEX, tabIndex.toString())
    }, [tabIndex])

    return [tabIndex, setTabIndex]
}
