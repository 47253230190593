import { FormGroup, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { RecordingMediaType } from '../../types'
import { GenericIcon, HeadsetIcon } from '../utils/Icons'

interface RecordingTypeChooserProps {
    recordingTypes: RecordingMediaType[]
    selected: RecordingMediaType
    setSelected: (recordingType: RecordingMediaType) => void
    enabled: boolean
}

export const RecordingTypeChooser = ({ recordingTypes, selected, setSelected, enabled }: RecordingTypeChooserProps) => {
    const { t } = useTranslation()

    return (
        <form>
            <FormGroup controlId="recording-type-chooser">
                {recordingTypes.map((recordingType) => (
                    <div className="passage-recording-type-chooser" key={recordingType}>
                        <Form.Check
                            type="radio"
                            id={recordingType}
                            key={recordingType}
                            onChange={() => {
                                setSelected(recordingType)
                            }}
                            checked={selected === recordingType}
                            label={t(recordingType)}
                            disabled={enabled === false}
                        />
                        {recordingType === 'Audio' ? (
                            <HeadsetIcon
                                className="passage-recording-type-headset-icon"
                                tooltip={t(recordingType)}
                                enabled={enabled}
                            />
                        ) : (
                            <GenericIcon
                                className="passage-recording-type-video-icon"
                                iconName="fa-video"
                                tooltip={t(recordingType)}
                                enabled={enabled}
                            />
                        )}
                    </div>
                ))}
            </FormGroup>
        </form>
    )
}
