import React, { FC, useCallback, useEffect, useRef, useMemo, useState } from 'react'

import { TFunction } from 'i18next'
import BlotFormatter, { DeleteAction, ImageSpec, ResizeAction } from 'quill-blot-formatter'
import { useTranslation } from 'react-i18next'
import ReactQuill, { Quill } from 'react-quill-new'

import { CancelButton, OKButton, UndoButton } from './Buttons'
import ErrorBoundary, { displayError, systemError } from './Errors'
import { fmt } from './Fmt'
import { resizeImage } from './ImageResizer'
import MagicUrl from './LinkFormatter'

import 'react-quill-new/dist/quill.snow.css'
import './RichTextEditor.css'

/* Test scenarios

  * PassageDocuments

     - edit/save
     - edit/cancel
     - edit title only
     - edit on two different machines at same time
     - edit different tabs on different machines
     - edit and click away to segments tab ***
     - view/revert to earlier version with <<
     - delete document
     - lock document 
     - drag/drop (large) image
     - paste image
     - insert link
     - all formatting options

  * Project Preferences

 */

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:EditableRichText')

Quill.register('modules/magicUrl', MagicUrl)
Quill.register('modules/blotFormatter', BlotFormatter)

export const MAX_RICHTEXT_SIZE = 100000 // Backend express currently has 256k limit, so make this smaller but reasonable

function convertToValidHTML(str: string) {
    return str.replace(/(\n|\r)/gi, '<br>').replace(/(\t|&nbsp;)/gi, ' ')
}

const compressAndInsertImage = async (file: File, reactQuillRef: React.RefObject<ReactQuill>, t: TFunction) => {
    if (!file.type.startsWith('image/')) {
        displayError(t('File is not an image.'))
        return
    }
    try {
        const imageData = await resizeImage(file, 600, 600)
        const quill = reactQuillRef.current?.getEditor()
        if (quill) {
            const range = quill.getSelection(true)
            quill.insertEmbed(range.index, 'image', imageData, 'user')
            quill.setSelection(range.index + 2, 0, 'user')
        }
    } catch (err) {
        log(err)
        displayError(t('Could not insert image.'))
    }
}

type IRichTextViewer = {
    text: string
    prefix: string
}

const RichTextViewer: FC<IRichTextViewer> = ({ text, prefix }) => {
    return (
        <div className="rich-text-viewer">
            <ReactQuill
                readOnly
                modules={{ toolbar: false }}
                value={convertToValidHTML(text)}
                className={`${prefix}__editing-area rich-text__editing-area`}
                bounds=".rich-text-editor"
            />
        </div>
    )
}

class CustomImageSpec extends ImageSpec {
    getActions() {
        return [ResizeAction, DeleteAction]
    }
}

type TextEditorToolbarProps = {
    enableSave: boolean
    save: () => void
    cancel: () => void
    id: string
}

const TextEditorToolbar: FC<TextEditorToolbarProps> = ({ enableSave, save, cancel, id }) => {
    const { t } = useTranslation()
    const cmd = navigator.platform.startsWith('Mac') ? '\u2318' : 'Ctrl'

    return (
        <div id={id} className="text-editor-toolbar">
            <div className="text-editor-toolbar-left">
                <button type="button" className="ql-bold" title={`${t('Bold')} (${cmd}+B)`} />
                <button type="button" className="ql-italic" title={`${t('Italic')} (${cmd}+I)`} />
                <button type="button" className="ql-underline" title={`${t('Underline')} (${cmd}+U)`} />
                <button type="button" className="ql-strike" title={t('strikethrough')} />
                <button type="button" className="ql-list" title={t('Ordered list')} value="ordered" />
                <button type="button" className="ql-list" title={t('Unordered list')} value="bullet" />
                <button type="button" className="ql-indent" title={t('Outdent')} value="-1" />
                <button type="button" className="ql-indent" title={t('Indent')} value="+1" />
                <button type="button" className="ql-direction" title={t('richTextDirectionRTL')} value="rtl" />
                <button type="button" className="ql-link" title={t('Link/unlink')} />
                <button type="button" className="ql-image" title={t('Insert image')} />
                <select className="ql-color" title={t('Font color')} />
                <select className="ql-background" title={t('Text background color')} />
                <button type="button" className="ql-clean" title={t('Clear formatting')} />
                <UndoButton
                    onClick={() => {}}
                    buttonClassName="ql-undo"
                    className="text-editor-toolbar-button"
                    tooltip={`${t('Undo')} (${cmd}+Z)`}
                    enabled
                />
            </div>
            <div className="text-editor-toolbar-right">
                <OKButton
                    enabled={enableSave}
                    onClick={() => {
                        save()
                    }}
                    buttonClassName="text-editor-save-button"
                    className="text-editor-toolbar-button text-editor-toolbar-right-button"
                    tooltip={t('Save edits and close')}
                />
                <CancelButton
                    enabled
                    onClick={() => {
                        cancel()
                    }}
                    className="text-editor-cancel-button text-editor-toolbar-button text-editor-toolbar-right-button"
                    tooltip={t('Cancel edits')}
                />
            </div>
        </div>
    )
}

type IRichTextEditor = {
    text: string
    onChange: (text: string) => void
    enableSave: boolean
    save: () => void
    cancel: () => void
    placeholder?: string
    prefix: string
}

const RichTextEditor: FC<IRichTextEditor> = ({ text, onChange, enableSave, save, cancel, placeholder, prefix }) => {
    const { t } = useTranslation()
    const editingAreaClassName = `${prefix}__editing-area__editing rich-text__editing-area`
    const reactQuillRef = useRef<ReactQuill>(null)

    // We need access to the save function inside a memoized function.
    // The memoized function cannot access the latest version of save by itself,
    // but it can access save if it's stored in a ref.
    const saveRef = useRef(save)

    // Handle pasting images into the text.
    // TODO: Turn drop/paste into a Quill module.
    useEffect(() => {
        async function onDrop(e: HTMLElementEventMap['drop']) {
            log('onDrop')
            e.stopPropagation()
            e.preventDefault()
            try {
                if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
                    // https://developer.mozilla.org/en-US/docs/Web/API/document/caretRangeFromPoint
                    // caretRangeFromPoint is considered non-standard, and yet I've seen
                    // 2 QuillJS libraries that use it.
                    if (document.caretRangeFromPoint) {
                        const selection = document.getSelection()
                        const range = document.caretRangeFromPoint(e.clientX, e.clientY)
                        if (selection && range) {
                            selection.setBaseAndExtent(
                                range.startContainer,
                                range.startOffset,
                                range.startContainer,
                                range.startOffset
                            )
                        }
                    }

                    await compressAndInsertImage(e.dataTransfer.files[0], reactQuillRef, t)
                }
            } catch (error) {
                saveRef.current()
                systemError(error)
            }
        }

        reactQuillRef.current?.focus()

        const quill = reactQuillRef.current?.getEditor()
        quill?.root.addEventListener('drop', onDrop)

        return () => {
            quill?.root.removeEventListener('drop', onDrop)
        }
    }, [t])

    useEffect(() => {
        saveRef.current = save
    }, [save])

    const toolbarId = useMemo(() => `toolbar-${Date.now()}`, [])

    // Quill configuration needs to be memoized. If any part of the configuration
    // changes identity, react-quill will rebuild the text editor.
    // https://github.com/zenoamaro/react-quill/issues/596
    const modules = useMemo(() => {
        return {
            toolbar: {
                container: `#${toolbarId}`,
                handlers: {
                    undo(this: { quill: Quill }) {
                        try {
                            const { quill } = this
                            const quillHistory = quill.getModule('history') as Quill['history']
                            quillHistory.undo()
                        } catch (error) {
                            saveRef.current?.()
                            systemError(error)
                        }
                    },
                    link(this: { quill: Quill }) {
                        try {
                            const { quill } = this
                            const range = quill.getSelection(true)
                            const contents = quill.getContents(range.index, range.length)
                            const attributes = contents.map((op) => op.attributes)
                            const wholeSelectionIsLink = attributes.every(
                                (s) => s !== undefined && s.link !== undefined
                            )
                            if (range.length === 0) {
                                // NOTE: Quill does not allow localizing link helper text
                                // https://github.com/slab/quill/issues/2922
                                const linkText = '🔗'
                                const link = 'https://example.com'
                                quill.insertText(range.index, linkText, 'user')
                                quill.setSelection(range.index, linkText.length, 'user')
                                quill.format('link', link, 'user')
                                quill.setSelection(range.index, 0, 'user')
                            } else if (wholeSelectionIsLink) {
                                // Remove link formatting
                                quill.format('link', false)
                            } else {
                                const link = quill.getText(range.index, range.length)
                                quill.format('link', link, 'user')
                                quill.setSelection(range.index, 0, 'user')
                            }
                        } catch (error) {
                            saveRef.current?.()
                            systemError(error)
                        }
                    },
                    image(this: { quill: Quill }) {
                        try {
                            const element = document.createElement('input')
                            element.setAttribute('type', 'file')
                            element.setAttribute('accept', 'image/*')
                            element.setAttribute('style', 'display:none')
                            element.onchange = async () => {
                                if (element.files?.length) {
                                    await compressAndInsertImage(element.files[0], reactQuillRef, t)
                                }
                            }
                            document.body.appendChild(element)

                            element.click()

                            window.requestAnimationFrame(() => {
                                document.body.removeChild(element)
                            })
                        } catch (error) {
                            //
                        }
                    }
                }
            },
            keyboard: {
                bindings: {
                    saveAndClose: {
                        key: 'Enter',
                        shiftKey: true,
                        handler() {
                            saveRef.current?.()
                        }
                    }
                }
            },
            magicUrl: true,
            blotFormatter: {
                specs: [CustomImageSpec]
            }
        }
    }, [t, toolbarId])

    return (
        <div className="rich-text-editor">
            <TextEditorToolbar {...{ enableSave, save, cancel, id: toolbarId }} />
            <ReactQuill
                readOnly={false}
                defaultValue={convertToValidHTML(text)}
                ref={reactQuillRef}
                onChange={onChange}
                modules={modules}
                placeholder={placeholder}
                className={editingAreaClassName}
                bounds=".rich-text-editor"
            />
        </div>
    )
}

type IEditableRichText = {
    savedText: string
    save: (stringifiedState: string) => void
    cancel: () => void
    editorOpen: boolean
    setEditorOpen: (value: boolean) => void
    placeholder?: string
    prefix: string // prefix for css classes
    sizeLimit?: number
}

// Wrap editor with ErrorBoundary and unexpected dismount protection

export const EditableRichText: FC<IEditableRichText> = ({
    savedText,
    save,
    cancel,
    editorOpen,
    setEditorOpen,
    placeholder,
    prefix,
    sizeLimit = MAX_RICHTEXT_SIZE
}) => {
    log('EditableRichText render', fmt({ editorOpen }))

    const { t } = useTranslation()
    const changedTextRef = useRef<string | undefined>(undefined)
    const [enableSave, setEnableSave] = useState(false)

    const _save = useCallback(() => {
        log('_save', changedTextRef.current?.slice(0, 50))
        save(changedTextRef.current ?? savedText)
        changedTextRef.current = undefined
        setEditorOpen(false)
    }, [save, savedText, setEditorOpen])

    useEffect(() => {
        // Our text is the same as savedText, therefore nothing has changed
        changedTextRef.current = undefined
    }, [savedText])

    function _cancel() {
        log('cancel')
        changedTextRef.current = undefined // ensure unmount does not save changes
        cancel()
        setEditorOpen(false)
    }

    // If we have unexpected unmount while editing, save changes
    useEffect(() => {
        return () => {
            const doSave = changedTextRef.current !== undefined
            log('unmount', fmt({ doSave }))
            if (doSave) {
                _save()
            }
            setEditorOpen(false)
        }
    }, [_save, setEditorOpen])

    return (
        <div className={`${prefix}__outer-wrapper`}>
            <div className={`${prefix}__inner-wrapper`}>
                <ErrorBoundary
                    fallbackAction={() => {
                        _save()
                        systemError('Rich text editor crashed')
                    }}
                >
                    {editorOpen && (
                        <RichTextEditor
                            {...{ text: savedText, placeholder, prefix, enableSave, save: _save, cancel: _cancel }}
                            onChange={(text) => {
                                // sizeLimit = 0 means there is no size limit (will be saved in S3 if too large)
                                if (sizeLimit && text.length > sizeLimit) {
                                    if (enableSave) {
                                        displayError(t('richTextTooLargeError'))
                                        setEnableSave(false)
                                    }
                                    return
                                }
                                changedTextRef.current = text
                                setEnableSave(true)
                            }}
                        />
                    )}
                    {!editorOpen && <RichTextViewer {...{ text: savedText, prefix }} />}
                </ErrorBoundary>
            </div>
        </div>
    )
}
